/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file HistoryCollapseIcon.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import { getComponentThemeValue } from '../CustomStyles/customStyleUtils';

const Styles = () => ({
  collapseStyle: {
    width: 18,
    height: 18
  }
});

const HistoryCollapseIcon = ({
  classes, customThemeStyles, theme, masterTheme
}) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 18 18"
    classes={{
      root: classes.collapseStyle
    }}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M0.26429 8.67753C-0.097355 8.28523 -0.0878107 7.67387 0.292924 7.29314L7.29292 0.293138C7.68345 -0.097387 8.31661 -0.0973871 8.70714 0.293138C9.09766 0.683662 9.09766 1.31683 8.70714 1.70735L2.41424 8.00025L8.70714 14.2931C9.09766 14.6837 9.09766 15.3168 8.70714 15.7074C8.31661 16.0979 7.68345 16.0979 7.29292 15.7074L0.292924 8.70735C0.283002 8.69743 0.273447 8.6875 0.26429 8.67753Z" fill={getComponentThemeValue(customThemeStyles, 'root', 'iconColor', false, 'textColor', masterTheme, theme)} />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.26429 8.67753C8.90265 8.28523 8.91219 7.67387 9.29292 7.29314L16.2929 0.293138C16.6834 -0.097387 17.3166 -0.0973871 17.7071 0.293138C18.0977 0.683662 18.0977 1.31683 17.7071 1.70735L11.4142 8.00025L17.7071 14.2931C18.0977 14.6837 18.0977 15.3168 17.7071 15.7074C17.3166 16.0979 16.6834 16.0979 16.2929 15.7074L9.29292 8.70735C9.283 8.69743 9.27345 8.6875 9.26429 8.67753Z" fill={getComponentThemeValue(customThemeStyles, 'root', 'iconColor', false, 'textColor', masterTheme, theme)} />
  </SvgIcon>
);

HistoryCollapseIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  customThemeStyles: PropTypes.object,
  theme: PropTypes.string,
  masterTheme: PropTypes.string
};

HistoryCollapseIcon.defaultProps = {
  theme: 'default',
  customThemeStyles: {},
  masterTheme: 'plus'
};

export default withStyles(Styles)(HistoryCollapseIcon);
