/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file Recommendations.jsx
 * @author Srikanth Vadla
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import messages from '../../defaultMessages';
import { getThemeValue } from '../../Common/CustomStyles/customStyleUtils';

const Styles = () => ({
  recommendationMainContainer: {
    padding: '0px 0px 10px 0px'
  },
  recommendationContainer: {
    gap: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexFlow: 'wrap',
    order: 1,
    paddingInline: '0px 2px',
    margin: '0px',
    overflow: 'hidden'
  },
  recommendationItm: {
    border: '1px solid',
    fontSize: '14px',
    maxWidth: '100%',
    fontStyle: 'normal',
    alignItems: 'center',
    fontFamily: 'TT Commons',
    fontWeight: '400',
    lineHeight: '16px',
    borderRadius: '8px',
    letterSpacing: '0.24px',
    textTransform: 'none',
    justifyContent: 'center',
    maxHeight: 50,
    boxSizing: 'border-box',
    padding: '5px 10px',
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
    borderColor: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
    '& span': {
      textAlign: 'left',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': 2,
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical'
    }
  },
  titleStyle: {
    display: 'block',
    fontSize: 16,
    fontFamily: 'TT Commons',
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
  }
});

const Recommendations = ({
  classes,
  intl,
  recommendedPrompts,
  showRecommendedPrompts,
  onPredefinedQueryClick
}) => {
  const { formatMessage } = intl;
  const getPromptList = (promptArray) => (
    <div className={`${classes.recommendationContainer}`}>
      {
        promptArray.map((prompt) => (
          <Button
            component="button"
            onClick={() => { onPredefinedQueryClick(prompt, false); }}
            className={`${classes.recommendationItm}`}
          >
            { prompt }
          </Button>
        ))
      }
    </div>
  );
  return (
    <>
      {(recommendedPrompts && recommendedPrompts.length > 0) && (
        <div className={`${classes.recommendationMainContainer}`} id="recommendation_prompt_container">
          <Typography variant="paragraph" className={classes.titleStyle}>
            {
              formatMessage(messages.recommendationPromptTitle)
            }
          </Typography>
            {
              (showRecommendedPrompts && recommendedPrompts && recommendedPrompts.length > 0) && getPromptList(recommendedPrompts)
            }
        </div>
      )}
    </>
  );
};

Recommendations.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  showRecommendedPrompts: PropTypes.bool.isRequired,
  recommendedPrompts: PropTypes.array,
  onPredefinedQueryClick: PropTypes.func.isRequired
};

Recommendations.defaultProps = {
  recommendedPrompts: []
};

export default withStyles(Styles)(injectIntl(Recommendations));
