/* eslint-disable no-param-reassign */
/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to Hero banner for authome
 *
 * @file HeroBanner.js
 * @author Mohamed yasar arabath M
 */
import Framework from '@greenville/framework';
import { applySnapshot, getSnapshot, types } from 'mobx-state-tree';
import Notifications from '../../bookshelf/model/BannerMapping';
import {
  FETCH_HERO_MAPPED_CHANNEL,
  POPULAR_CHANNELS_REQUESTED,
  HERO_BANNER_TYPES,
  ACTIVE,
  BUNDLE,
  GO_TO_PAGE_MAX_LENGTH,
  CHANNEL_LINKS
} from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';


const CallForAction = types.model('CallForAction', {
  text: types.maybeNull(types.string),
  ariaLabel: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  cardIndex: types.maybeNull(types.number),
  isPrimary: types.optional(types.boolean, false)
});

const ComponentsToRender = types.model('ComponentsToRender', {
  showHeroContentCard: types.optional(types.boolean, false),
  showPromotionalCTA: types.optional(types.boolean, false),
  showSubscriptionStatusCard: types.optional(types.boolean, false),
  showPromotionalLinks: types.optional(types.boolean, false),
  showAssets: types.optional(types.boolean, false),
  showToc: types.optional(types.boolean, false),
  showPromotionalCard: types.optional(types.boolean, false),
  showPopularChannels: types.optional(types.boolean, false),
  showMarketingCard: types.optional(types.boolean, false),
  showExamPrepCard: types.optional(types.boolean, false),
  showMondlyMarketingCard: types.optional(types.boolean, false),
  showAiChatInput: types.optional(types.boolean, false),
  showRelatedChannelCard: types.optional(types.boolean, false)
});

const HeroContentCardConfig = types.model('HeroContentCardConfig', {
  showInfo: types.optional(types.boolean, false),
  showLinks: types.optional(types.boolean, false),
  showGoToPage: types.optional(types.boolean, false),
  showDelete: types.optional(types.boolean, false),
  showCallForAction: types.optional(types.boolean, false),
  position: types.maybeNull(types.string, ''),
  showMyEtextbookLink: types.optional(types.boolean, false),
  variant: types.maybeNull(types.string, '')
});

const MondlyMarketingCardConfig = types.model('MondlyMarketingCardConfig', {
  position: types.maybeNull(types.string, '')
});

const heroHeaderConfig = types.model('heroHeaderConfig', {
  showTitle: types.optional(types.boolean, false),
  showCTA: types.optional(types.boolean, false),
  showDescription: types.optional(types.boolean, false),
  showSubscriptionStatus: types.optional(types.boolean, false),
  isPrimaryCTA: types.optional(types.boolean, false)
});

const Config = types.model('Config', {
  variant: types.optional(types.string, ''),
  showHeroHeading: types.optional(types.boolean, false),
  showHeroSubHeading: types.optional(types.boolean, false),
  componentsToRender: types.optional(ComponentsToRender, {
    showHeroContentCard: false,
    showPromotionalCTA: false,
    showSubscriptionStatusCard: false,
    showPromotionalLinks: false,
    showAssets: false,
    showToc: false,
    showPromotionalCard: false,
    showPopularChannels: false,
    showMarketingCard: false,
    showExamPrepCard: false,
    showMondlyMarketingCard: false,
    showAiChatInput: false,
    showRelatedChannelCard: false
  }),
  heroContentCard: types.optional(HeroContentCardConfig, {
    showInfo: false,
    showLinks: false,
    showGoToPage: false,
    showDelete: false,
    showCallForAction: false,
    position: '',
    showMyEtextbookLink: false,
    variant: ''
  }),
  heroHeader: types.optional(heroHeaderConfig, {
    showTitle: false,
    showCTA: false,
    showDescription: false,
    showSubscriptionStatus: false,
    isPrimaryCTA: false
  }),
  mondlyMarketingCard: types.optional(MondlyMarketingCardConfig, {
    position: ''
  }),
  heroAIchatCard: types.optional(MondlyMarketingCardConfig, {
    position: ''
  })
});

const AssetCardProps = types.model('AssetCardProps', {
  url: types.maybeNull(types.string)
});

const Styles = types.model('Styles', {
  color: types.maybeNull(types.string)
});

const AssetsData = types.model('AssetsData', {
  label: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  thumbnailUrl: types.maybeNull(types.string),
  isCompleted: types.optional(types.boolean, false),
  isVideo: types.optional(types.boolean, false),
  overlayCta: types.maybeNull(CallForAction),
  cardProps: types.maybeNull(AssetCardProps),
  styles: types.optional(Styles, {})
});

const Assets = types.model('Assets', {
  template: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  isLoading: types.optional(types.boolean, false),
  cards: types.maybeNull(types.array(AssetsData))
});

const TOCdata = types.model('TOCdata', {
  id: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  children: types.array((types.late(() => TOCdata)))
});

const TableOfContents = types.model('TableOfContents', {
  data: types.maybeNull(TOCdata),
  initialChapterId: types.maybeNull(types.string),
  chapterId: types.maybeNull(types.string),
  chapterTitle: types.maybeNull(types.string),
  parentChapterTitle: types.maybeNull(types.string),
  isTOCFetchFailed: types.optional(types.boolean, false),
  isVariant2: types.optional(types.boolean, false)
});

const TitleInfo = types.model('TitleInfo', {
  author: types.maybeNull(types.string),
  bookTitle: types.maybeNull(types.string),
  courseName: types.maybeNull(types.string),
  coverImageUrl: types.maybeNull(types.string),
  isbn: types.maybeNull(types.union(types.integer, types.string)),
  productModelName: types.maybeNull(types.string),
  book_id: types.maybeNull(types.string),
  product_id: types.maybeNull(types.string),
  course_id: types.maybeNull(types.string),
  notificationsData: types.maybeNull(Notifications),
  showRemoveEntitlement: types.optional(types.boolean, false)
});

const PopularChannelsCard = types.model('PopularChannelsCard', {
  type: types.maybeNull(types.string),
  channelId: types.maybeNull(types.string),
  thumbnailUrl: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  active: types.optional(types.boolean, true),
  learnUrl: types.maybeNull(types.string),
  aiTutorUrl: types.maybeNull(types.string),
  exploreUrl: types.maybeNull(types.string),
  showExams: types.optional(types.boolean, false),
  recommended: types.optional(types.boolean, false),
  isMostRelated: types.optional(types.boolean, false),
  group: types.maybeNull(types.string)
});

const PopularChannels = types.model('PopularChannels', {
  isPopularChannelInitiated: types.optional(types.boolean, false),
  channelCards: types.maybeNull(types.array(PopularChannelsCard))
});

const GoToPage = types.model('GoToPage', {
  placeholder: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
  initialValue: types.maybeNull(types.string),
  maxLength: types.optional(types.number, GO_TO_PAGE_MAX_LENGTH)
});

const SubscriptionStatusList = types.model('SubscriptionStatusList', {
  id: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  checked: types.optional(types.boolean, false)
});

const SubscriptionStatus = types.model('SubscriptionStatus', {
  title: types.maybeNull(types.string),
  listContent: types.maybeNull(types.array(SubscriptionStatusList))
});

const BookLinks = types.model('BookLinks', {
  icon: types.maybeNull(types.string),
  text: types.maybeNull(types.string)
});

const RemoveCourseDialogData = types.model('RemoveCourseDialogData', {
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  primaryCtaText: types.maybeNull(types.string),
  secondaryCtaText: types.maybeNull(types.string)
});

const eTextFeaturesCopy = types.model('eTextFeaturesCopy', {
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string)
});

const myEtextbookLinkData = types.model('myEtextbookLinkData', {
  text: types.maybeNull(types.string),
  linkText: types.maybeNull(types.string)
});

const HeroContentCard = types.model('HeroContentCard', {
  heading: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  thumbnailImage: types.maybeNull(types.string),
  callForAction: types.maybeNull(CallForAction),
  links: types.maybeNull(types.array(BookLinks)),
  removeCourseDialogData: types.maybeNull(RemoveCourseDialogData),
  eTextFeaturesCopy: types.maybeNull(eTextFeaturesCopy),
  myEtextbookLinkData: types.optional(myEtextbookLinkData, {})
});

/**
 * A mobx model for Hero banner
 *
 */
const HeroBanner = types.model('HeroBanner', {
  bannerType: types.maybeNull(types.string),
  channelId: types.maybeNull(types.string),
  config: types.maybeNull(Config),
  heroHeading: types.maybeNull(types.string),
  heroSubHeading: types.maybeNull(types.string),
  assets: types.maybeNull(Assets),
  popularChannels: types.maybeNull(PopularChannels),
  subscriptionStatus: types.maybeNull(SubscriptionStatus),
  tableOfContents: types.maybeNull(TableOfContents),
  titleInfo: types.maybeNull(TitleInfo),
  heroHeaderTitle: types.maybeNull(types.string),
  heroHeaderCta: types.maybeNull(CallForAction),
  goToPage: types.maybeNull(GoToPage),
  heroContentCard: types.maybeNull(HeroContentCard)
}).views(self => ({
  /**
   * Constructs and returns popular channel data for hero
   * Applicable for Channel only subscriber and No active book or No channel subscription
   *
   * @returns
   */
  getPopularChannels() {
    const { popularChannels } = self;
    const { channelCards } = popularChannels || {};
    const language = Framework.getStoreRegistry().getStore('language');
    const title = language.getMessage('hero.studyExamPrepAllYourCourses');
    const description = language.getMessage('hero.popularchannels.desc');
    let popularChannelData = {};

    if (channelCards && channelCards.length > 0) {
      popularChannelData = {
        headingText: title,
        description,
        channelCards: CommonUtils.getActiveChannelCards(channelCards)
      };
    }
    popularChannelData.numOfCardsToShowConfig = [
      {
        breakPoint: 0,
        numOfCards: 6
      }
    ];

    return popularChannelData;
  },

  /**
   * Constructs and returns popular channel data
   *
   * @returns
   */
  getPopularChannelsData(hasOnlyOneActiveBook) {
    const { popularChannels } = self;
    const { channelCards } = popularChannels || {};
    const language = Framework.getStoreRegistry().getStore('language');
    const numOfCardsToShow = hasOnlyOneActiveBook ? 6 : 4;
    let popularChannelData = null;

    if (channelCards && channelCards.length > 0) {
      const popularChannelCards = this.getPopularChannels(channelCards);
      popularChannelData = {
        headingText: language.getMessage('hero.popularChannels.headingv1'),
        description: language.getMessage('hero.popularChannels.descriptionv2'),
        channelCards: popularChannelCards || [],
        numOfCardsToShowConfig: [
          {
            breakPoint: 0,
            numOfCards: 6
          },
          {
            breakPoint: 1024,
            numOfCards: 4
          },
          {
            breakPoint: 1280,
            numOfCards: numOfCardsToShow
          }
        ],
        styleVaraint: 'varaintA'
      };
    }

    return popularChannelData;
  },
  /**
   * Constructs and returns marketing card data for hero
   * Applicable for fresh hero book user and
   * No active book or No channel subscription
   *
   * @returns
   */
  getMarketingCardData() {
    const { MARKETING_TEMPLATE } = HERO_BANNER_TYPES;
    const { bannerType, popularChannels } = self;
    const language = Framework.getStoreRegistry().getStore('language');
    const showHeading = bannerType === MARKETING_TEMPLATE;
    let headingText = null;

    if (showHeading && popularChannels && popularChannels.numOfUsers) {
      headingText = language.getMessage('hero.study.numOfUsers').replace(
        '{num_of_users}', popularChannels.numOfUsers.toLocaleString()
      );
    }

    return {
      headingText,
      cards: CommonUtils.getChannelMarketingCardData()
    };
  },
  /**
   * Constructs and returns promotional card data
   *
   * @returns
   */
  getPromotionalCardData() {
    const language = Framework.getStoreRegistry().getStore('language');
    const title = language.getMessage('hero.studyandexamprep');
    const listContent = [
      language.getMessage('hero.promotionalcard.list1'),
      language.getMessage('hero.promotionalcard.list2'),
      language.getMessage('hero.promotionalcard.list3')
    ];
    const cta = {
      text: language.getMessage('hero.learnmore')
    };

    return {
      title,
      listContent: listContent?.map(item => ({ text: item })),
      cta
    };
  },
  getPromotionalLinks(channelId) {
    const language = Framework.getStoreRegistry().getStore('language');
    const {
      learnUrl,
      showExams,
      aiTutorUrl
    } = this.getChannelData(channelId) || {};
    const links = [];

    if (learnUrl) links.push({ id: CHANNEL_LINKS.LEARN, text: language.getMessage('hero.learnTxt') });
    if (showExams) links.push({ id: CHANNEL_LINKS.EXAM_PREP, text: language.getMessage('hero.examPrepTxt') });
    if (aiTutorUrl) links.push({ id: CHANNEL_LINKS.AI_TUTOR, text: language.getMessage('hero.aITutorTxt') });

    return links;
  },
  getAssetsInitialState() {
    return {
      template: null,
      title: null,
      cards: null,
      isLoading: true
    };
  }
})).actions(self => ({
  resetAssets() {
    self.assets = self.getAssetsInitialState();
  },
  reset() {
    self.bannerType = null;
    self.channelId = null;
    self.config = {
      showHeroHeading: false,
      componentsToRender: {},
      heroContentCard: {},
      heroHeader: {}
    };
    self.heroHeading = null;
    self.assets = self.getAssetsInitialState();
    self.subscriptionStatus = {
      title: null,
      listContent: null
    };
    self.tableOfContents = {
      data: null,
      initialChapterId: null,
      chapterId: null,
      chapterTitle: null,
      parentChapterTitle: null,
      isTOCFetchFailed: false
    };
    self.titleInfo = null;
    self.heroHeaderCta = null;
    self.goToPage = {
      placeholder: null,
      label: null,
      value: null,
      initialValue: null,
      maxLength: GO_TO_PAGE_MAX_LENGTH
    };
    self.heroContentCard = {
      heading: null,
      title: null,
      thumbnailImage: null,
      callForAction: null,
      links: null
    };
  },
  setChannelId(channelId) {
    self.channelId = channelId;
  },
  setHeroBanner(heroBanner, fetchHeroMappedChannel) {
    const renderHeroBanner = { ...heroBanner, popularChannels: self.popularChannels };
    applySnapshot(self, renderHeroBanner);
    if (fetchHeroMappedChannel) {
      Framework.getEventManager().publish(FETCH_HERO_MAPPED_CHANNEL);
    }
  },
  setHeroBannerMappedChannel(mappedChannel) {
    self.mappedChannel = mappedChannel;
  },
  setPopularChannels(popularChannels) {
    self.popularChannels = {
      ...self.popularChannels,
      channelCards: popularChannels
    };
  },
  setHeroHeading(heroHeading) {
    self.heroHeading = heroHeading;
  },
  setConfig(config) {
    self.config = { ...self.config, ...config };
  },
  setTableOfContents(updateTableOfContents) {
    const { tableOfContents } = getSnapshot(self);
    const renderTableOfContents = { ...tableOfContents, ...updateTableOfContents };
    self.tableOfContents = renderTableOfContents;
  },
  setGoToPage(updatedGoToPageNo) {
    const { goToPage } = getSnapshot(self);
    const renderGoToPage = { ...goToPage, value: updatedGoToPageNo };
    self.goToPage = renderGoToPage;
  },
  setHeroHeaderCTA(cta) {
    const { heroHeader } = self.config || {};

    if (heroHeader?.showCTA) {
      self.heroHeaderCta = cta;
    }
  },
  setAssets(assets, isLoading = false) {
    self.assets = { ...assets, isLoading };
  },
  setSubscriptionStatus(subscriptionStatus) {
    self.subscriptionStatus = subscriptionStatus;
  },
  setNotification(notification = {}) {
    const { titleInfo = {} } = self || {};

    self.titleInfo = { ...titleInfo, notificationsData: notification };
  },
  fetchPopularChannels(enableExactLiteMapping = false) {
    const { isPopularChannelInitiated } = self.popularChannels || {};

    if (!isPopularChannelInitiated) {
      self.popularChannels = {
        isPopularChannelInitiated: true,
        channelCards: []
      };
      Framework.getEventManager().publish(POPULAR_CHANNELS_REQUESTED, enableExactLiteMapping);
    }
  },
  disablePopularChannels(inactiveChannelIds) {
    const { channelCards } = self.popularChannels || {};
    if (inactiveChannelIds && inactiveChannelIds.length > 0 && channelCards.length > 0) {
      const renderChannelCards = channelCards.map((channelCard) => {
        const channelItem = channelCard;
        if (inactiveChannelIds.includes(channelItem.channelId)) {
          channelItem.active = false;
        }

        return { ...channelItem };
      });
      self.popularChannels.channelCards = [...renderChannelCards];
    }
  },
  getAllPopularChannels() {
    const {
      channelCards
    } = self?.popularChannels || {};

    if (channelCards && channelCards.length > 0) {
      return channelCards;
    }

    return null;
  },
  getChannelData(channelId) {
    const {
      channelCards
    } = self?.popularChannels || {};

    if (channelCards && channelCards.length > 0) {
      return channelCards.find(channel => channel.channelId === channelId);
    }

    return null;
  }
}));
export default HeroBanner;
