/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file MessageIcon.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import { getComponentThemeValue } from '../CustomStyles/customStyleUtils';

const Styles = () => ({
  messageIcon: {
    width: 16,
    height: 16,
    marginTop: 4
  }
});

const MessageIcon = ({
  classes, theme, masterTheme, customThemeStyles
}) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 16 16"
    classes={{
      root: classes.messageIcon
    }}
  >
    <path d="M8 -9.15527e-05C12.4183 -9.15527e-05 16 3.58163 16 7.99991C16 12.4182 12.4183 15.9999 8 15.9999C6.72679 15.9999 5.49591 15.7018 4.38669 15.1392L4.266 15.0749L0.621091 15.985C0.311269 16.0624 0.0262241 15.8368 0.00130987 15.5437L0.00114131 15.4623L0.0149329 15.3786L0.925 11.7349L0.86169 11.6152C0.406602 10.7185 0.124331 9.74214 0.0327465 8.72817L0.00737596 8.34625L0 7.99991C0 3.58163 3.58172 -9.15527e-05 8 -9.15527e-05ZM8 0.999908C4.13401 0.999908 1 4.13391 1 7.99991C1 9.21695 1.31054 10.3877 1.89352 11.4248C1.94046 11.5083 1.9621 11.6029 1.95692 11.6972L1.94274 11.7911L1.187 14.8119L4.21104 14.0582C4.27294 14.0428 4.33662 14.0395 4.39873 14.0478L4.4903 14.069L4.57701 14.1074C5.61362 14.6897 6.7837 14.9999 8 14.9999C11.866 14.9999 15 11.8659 15 7.99991C15 4.13391 11.866 0.999908 8 0.999908ZM8.5 8.99991C8.77614 8.99991 9 9.22377 9 9.49991C9 9.74537 8.82312 9.94952 8.58987 9.99185L8.5 9.99991H5.5C5.22386 9.99991 5 9.77605 5 9.49991C5 9.25445 5.17687 9.0503 5.41012 9.00796L5.5 8.99991H8.5ZM10.5 5.99991C10.7761 5.99991 11 6.22377 11 6.49991C11 6.74537 10.8231 6.94952 10.5899 6.99185L10.5 6.99991H5.5C5.22386 6.99991 5 6.77605 5 6.49991C5 6.25445 5.17687 6.0503 5.41012 6.00796L5.5 5.99991H10.5Z" fill={getComponentThemeValue(customThemeStyles, 'root', 'iconColor', false, 'messageIconColor', masterTheme, theme)} />
  </SvgIcon>
);

MessageIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  customThemeStyles: PropTypes.object,
  theme: PropTypes.string,
  masterTheme: PropTypes.string
};

MessageIcon.defaultProps = {
  theme: 'default',
  customThemeStyles: {},
  masterTheme: 'plus'
};

export default withStyles(Styles)(MessageIcon);
