/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file ChatHistoryList.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ChatHistoryStyles from './ChatHistoryStyles';
import ChatHistoryListItem from './ChatHistoryListItem';
import ChatHistoryDeleteItemSnackBar from './ChatHistoryDeleteItemSnackBar';
import Utils from '../../Common/Utils';
import {
  confirmRenameOfThreadInChatHistoryGAEvent,
  deleteThreadInChatHistoryGAEvent,
  undoDeleteThreadInChatHistoryGAEvent
} from '../../analytics/analyticsEvents';

const ChatHistoryList = ({
  classes,
  historyData,
  onRenderHistory,
  threadDeleteTimeOut,
  onDeleteTitle,
  getBerlinClientAPI,
  handleGAEvents,
  bookId,
  customThemeStyles,
  theme,
  masterTheme
}) => {
  const [deleteSnackOpenStatus, setDeleteSnackOpenStatus] = React.useState(false);
  const [selectedThread, setSelectedThread] = React.useState('');
  const [deleteThreadTimeOut, setDeleteThreadTimeOut] = React.useState('');
  const [isRenameSuccess, setIsRenameSuccess] = React.useState(false);

  const handleRename = (threadId, value) => {
    const headerParams = {
      threadId
    };
    const payload = {
      title: value
    };
    setIsRenameSuccess(false);
    getBerlinClientAPI().renameChatHistoryTitle(
      payload,
      headerParams,
      (response) => {
        if (response && historyData && historyData.list && historyData.list.length) {
          historyData.list.forEach((item) => {
            if (item.threadId === threadId) {
              const threadUpdatedDay = Utils.getHistoryFormat(item.updatedOn);
              confirmRenameOfThreadInChatHistoryGAEvent(handleGAEvents, threadUpdatedDay, bookId, threadId);
              setIsRenameSuccess(true);
              // eslint-disable-next-line no-param-reassign
              item.title = value;
            }
          });
        }
      }
    ).catch((error) => {
      console.log('Oops! Something went wrong', error);
    });
  };

  const handleHistorListItemDelete = (threadId) => {
    const headerParams = {
      threadId
    };
    getBerlinClientAPI().deleteChatHistoryTitle(
      headerParams,
      (data) => {
        if (data) {
          clearTimeout(deleteThreadTimeOut);
          setDeleteThreadTimeOut('');
          onDeleteTitle(threadId);
        }
      }
    ).catch((error) => {
      console.log('Oops! Something went wrong', error);
    });
  };

  const handleDelete = (threadId) => {
    setSelectedThread(threadId);
    setDeleteSnackOpenStatus(true);
    if (historyData && historyData.list && historyData.list.length) {
      historyData.list.forEach((item) => {
        if (item.threadId === threadId) {
          const threadUpdatedDay = Utils.getHistoryFormat(item.updatedOn);
          deleteThreadInChatHistoryGAEvent(handleGAEvents, threadUpdatedDay, bookId, threadId);
        }
      });
    }
    document.getElementById(threadId).style.display = 'none';
    const deleteThreadTime = setTimeout(() => {
      setDeleteSnackOpenStatus(false);
      handleHistorListItemDelete(threadId);
    }, threadDeleteTimeOut);
    setDeleteThreadTimeOut(deleteThreadTime);
  };

  const onClose = () => {
    setDeleteSnackOpenStatus(false);
  };

  const onOpen = () => {
    setDeleteSnackOpenStatus(true);
  };

  const handleUndoConfirm = (threadId) => {
    document.getElementById(threadId).style.removeProperty('display');
    setDeleteSnackOpenStatus(false);
    clearTimeout(deleteThreadTimeOut);
    if (historyData && historyData.list && historyData.list.length) {
      historyData.list.forEach((item) => {
        if (item.threadId === threadId) {
          const threadUpdatedDay = Utils.getHistoryFormat(item.updatedOn);
          undoDeleteThreadInChatHistoryGAEvent(handleGAEvents, threadUpdatedDay, bookId, threadId);
        }
      });
    }
  };
  return (
    <div className={classes.historyListWrapper}>
      {
        (historyData && historyData.list && historyData.list.length > 0) && (
          <>
            <Typography variant="h5" className={classes.historyListTitleStyle}>
              {historyData.title}
            </Typography>
            {
              historyData.list.map((data, index) => (
                data.title && (
                  <ChatHistoryListItem
                    data={data}
                    key={index}
                    onRenderHistory={onRenderHistory}
                    isRenameSuccess={isRenameSuccess}
                    customThemeStyles={customThemeStyles}
                    onRename={handleRename}
                    onDelete={handleDelete}
                    masterTheme={masterTheme}
                    theme={theme}
                  />
                )
              ))
            }
          </>
        )
      }
      {
        deleteSnackOpenStatus && (
          <ChatHistoryDeleteItemSnackBar
            autoCloseTime={threadDeleteTimeOut}
            open={deleteSnackOpenStatus}
            onOpen={onOpen}
            onClose={onClose}
            onUndo={handleUndoConfirm}
            selectedThread={selectedThread}
            theme={theme}
            masterTheme={masterTheme}
            customThemeStyles={customThemeStyles}
          />
        )
      }
    </div>
  );
};

ChatHistoryList.propTypes = {
  masterTheme: PropTypes.string,
  theme: PropTypes.string,
  classes: PropTypes.object.isRequired,
  historyData: PropTypes.object.isRequired,
  customThemeStyles: PropTypes.object,
  onRenderHistory: PropTypes.func.isRequired,
  handleGAEvents: PropTypes.func.isRequired,
  bookId: PropTypes.string.isRequired,
  onDeleteTitle: PropTypes.func.isRequired,
  threadDeleteTimeOut: PropTypes.number.isRequired,
  getBerlinClientAPI: PropTypes.func.isRequired
};

ChatHistoryList.defaultProps = {
  masterTheme: 'plus',
  customThemeStyles: {},
  theme: 'default'
};

export default withStyles(ChatHistoryStyles)(injectIntl(ChatHistoryList));
