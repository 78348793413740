/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Translation file for Russian language
 *
 * @file ru.js
 * @author Manimaran.S
 */

const ru = {
  'popularChannelSlider.allChannels': 'All Channels',
  'popularChannelSlider.recommended': 'Recommended',
  'channel.noBundleRelatedtitle': 'Browse Study & Exam Prep related to your other classes.',
  'hero.myetextbook.link': 'library',
  'hero.myetextbook.text': 'Not the right title? Access your eTextbook',
  'hero.ctanewtext': 'Open Study & Exam Prep',
  'hero.welcomeToPPlus': 'Welcome to Pearson+',
  'hero.etextfeaturestitle': 'Find study materials in your eTextbook',
  'hero.etextfeaturesdescription': 'Your eTextbook comes with study materials designed to help you succeed. Study with flashcards and more right in your eTexbook.',
  'aichat.title1': 'Get AI-powered help for anything related to this eTextbook.',
  'aichat.chatDesc1': 'Type a question to start',
  'aichat.title2': 'Get AI-powered help',
  'aichat.chatDesc2': 'Ask about anything related to this eTextbook',
  'subscriptiontext.isSubscribed': 'Part of your subscription',
  'subscriptiontext.nonSubscriber': 'Upgrade for full access',
  'topicConatiner.otherHeader': 'Related practice and video lessons',
  'topicConatiner.exactLiteHeader': 'Related practice',
  'authHome.h1': 'Authenticated Home',
  'moremenu.downloadApp': 'Download the app',
  'moremenu.downloadAppAlreadyDownload': '%Click here%, чтобы загрузить приложение на другое устройство и автоматически войти в систему или использовать свой уникальный QR-код для безопасного входа в уже установленное приложение.',
  'moremenu.downloadAppNotDownload': '%Click here%, чтобы загрузить приложение и безопасно войти в систему с помощью уникального QR-кода.',
  'channel.noBundletitle': 'Use the Study & Exam Prep for your related & other courses',
  'onboard.next': 'Далее',
  'onboard.studyPlan': 'Пользовательский план обучения создан!',
  'onboard.addCourseAnother': 'Хотите добавить еще один курс?',
  'onboard.purchase': 'Мне нужно приобрести электронный учебник Pearson',
  'onboard.addCourseWithoutEtext': 'Add course using Study & Exam Prep',
  'onboard.addCourseWithoutEtextBundle': 'Добавьте курс, используя Study & Exam Prep',
  'onboard.doYouWantOtherCourse': 'Хотите добавить еще один курс?',
  'onboard.mayBeLater': 'Может быть, позже',
  'onboard.close': 'Close',
  'course.addTitle': 'Добавить курс',
  'bookshelf.TITLE': 'Моя книжная полка',
  'common.LOGOUT': 'Выход из системы',
  'common.GO_HOME': 'На главную страницу',
  'common.PURCHASE': 'Покупка',
  'courses.WELCOME': 'Здравствуйте, {userName}! Добро пожаловать на курс {courseName}!',
  'courses.action.SHOW_ASSIGNMENTS': 'Показать задания',
  'courses.toc.PUBLISH_SUCCESS': 'Успешно!',
  'courses.toc.PUBLISH_MESSAGE': 'Студенты увидят изменения в результате следующего открытия ими содержимого.',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'Подписка не найдена',
  'errors.SUBSCRIPTION_GENERIC_DESC': `Ваша учетная запись не предполагает доступа к этому заголовку. Если вы полагаете, 
    что получили это сообщение по ошибке, обратитесь в Службу технической поддержки Pearson`,
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'Подписка не найдена',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': `Ваша подписка не предусматривает доступа к eText; тем не менее, 
    вы можете оформить подписку, используя кредитную карту.`,
  'foxit.ABOUT': 'О продукте',
  'foxit.COPY_RIGHT': 'PDF Reader на платформе Foxit. Copyright (C) 2003-2019 гг., Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonPrivacyPolicy.html',
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonEULA.html',
  'menu.GET_THE_APP_DOWNLOAD': 'Загрузить приложение для мобильных устройств',
  'menu.CONTACT_SUPPORT': 'Обратиться в Службы поддержки',
  'menu.ABOUT': 'О продукте',
  'menu.FOXIT': 'На платформе Foxit',
  'menu.TERMS_OF_USE': 'Условия использования',
  'menu.PRIVACY_POLICY': 'Политика конфиденциальности',
  'menu.SIGN_OUT': 'Выйти',
  'menu.PRINT_PAGE': 'Печать страницы',
  'menu.GET_LOOSE_LEAF': 'Получить отдельный лист',
  'menu.AVAILABLE': 'Также доступно',
  'menu.PRINT_VERSION': 'Нужна печатная версия?',
  'menu.COPY_RIGHT': 'Copyright © {year} Pearson Education Inc. Все права защищены.',
  'menu.tooltip.SCHEDULE': 'График',
  'print.FOOTER_MESSAGE': 'Printed by {fullName}({email}) on {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': 'Здравствуйте {userName}',
  'device.SWAPPED_ALREADY': 'Вы уже заменили этот месяц',
  'device.SWITCH_DEVICE': 'Переключиться на текущее устройство?',
  'device.SWITCH_ONCE': 'Поскольку переключение возможно только один раз в месяц, вы не сможете отменить действие.',
  'device.SEAT_TAKEN': 'Это место занято',
  'device.DISCONNECT_DEVICE': 'Регистрация возможна только на трех устройствах. Чтобы продолжить, отключите одно их устройств.',
  'device.ALERT_TEXT': 'Помните: вы можете менять устройства один раз в месяц.',
  'device.YOUR_MOJO': 'Вы в процессе перехода к Mojo',
  'device.DISCONNECT': 'Отключить',
  'device.CONTINUE': 'Продолжить',
  'device.HEADER': 'End your other browser session?',
  'device.CLOSE': 'На главную страницу',
  'device.CODE': 'Continue here by entering the authentication code we sent to {maskedEmail}. Not working? Check your spam folder, {email}, or contact {support}.',
  'device.TIMER': 'Введите код в течение {time}',
  'device.RESEND': '{resend}',
  'device.FAQ': 'FAQ',
  'device.RESEND_NAME': 'Resend',
  'device.NOTE': 'If you regularly use Pearson+ in 2 places, like at work and at home, make sure to log out whenever you’re done. You can also use the Pearson+ mobile app on up to 2 devices. For more info, see our {faq}.',
  'device.NOTE_HEADER': 'Avoid this next time',
  'device.CONTACT_SUPPORT': '',
  'device.CONTACT_EMAIL': '',
  'device.CONTACT_FAQ': '',
  'device.INCORRECT': 'Неверно — проверьте код или запросите его повторно.',
  'device.MANY_ATTEMPTS': 'Слишком много попыток, запросите код повторно',
  'device.EXPIRED': 'Ваше время истекло, запросите код повторно',
  'device.ACCESS_HEADER': 'Вы вошли в систему!',
  'device.ACCESS_DESC': 'Ваши данные пользователя в рамках сеанса аутентифицированы. Хотите сразу изменить пароль?',
  'device.ACCESS_CHANGE_PASSWORD': 'Изменить пароль',
  'device.ACCESS_RESET_PASSWORD': 'Сброс пароля',
  'device.THREATONE_TITLE': 'Всё хорошо?',
  'device.THREATONE_DESC': 'Мы заметили подозрительную активность в вашей учетной записи.',
  'device.THREATONE_DESC_TWO': 'Советуем вам без промедления сменить пароль.',
  'device.MAY_BE_LATER': 'Может быть, позже',
  'device.OKAY': 'Принято',
  'device.ACCOUNT_LOCK_DESC': 'Для обеспечения безопасности мы вывели вас из системы. Мы отправили ссылку для смены пароля на электронную почту, связанную с вашей учётной записью.',
  'device.ACCOUNT_LOCK_TITLE': 'Мы заметили подозрительную активность в вашей учетной записи',
  'device.ACCOUNT_LOCK_TIMER': 'Вы будете выведены из системы {time}',
  'menu.myAccount': 'Моя учетная запись',
  'menu.helpCenter': 'Справочный центр',
  'menu.preferencesCenter': 'Центр предпочтений',
  'menu.HELP_SUPPORT': 'Справка и поддержка',
  'menu.FAQS': 'Часто задаваемые вопросы',
  'menu.CHAT_WITH_SUPPORT': 'Поговорить с поддержкой',
  'menu.QUICK_WALKTHROUGH': 'Ознакомительный тур',
  'menu.LEGAL': 'Правовые аспекты',
  'menu.PRIVACY_NOTICE': 'Уведомление о конфиденциальности',
  'menu.TITLE_INFO': 'Информация заголовка',
  'menu.PEARSON_ACCESSIBILITY': 'Доступ Pearson',
  'menu.DEVICE_WARNING_MESSAGE': 'Регистрируйте {deviceLimit} устройств. Меняйте по 1 устройству в месяц.',
  'menu.ACCESSIBILITY': 'Доступ',
  'menu.ACCESSIBILITY_SUPPORT': 'Поддержка доступа',
  'menu.DEVICES': 'Недавние устройства',
  'menu.THIS_DEVICE': 'Это устройство',
  'menu.MOJO_APP_INFO': 'Перейдите в магазин приложений на своем телефоне или планшете, чтобы использовать Pearson+ в автономном режиме.',
  'menu.DEVICE_LABEL': 'Устройство',
  'navigation.PAGE': 'Страница',
  'launchcode.CREATEBY': 'Создано:',
  'launchcode.sentvia': 'Отправлено через:',
  'launchcode.CODEINLAST': 'Коды в течение последних 7 дней:',
  'launchcode.TIMESENT': 'Время отправки:',
  'launchcode.ACCOUNTEMAIL': 'Электронная почта учетной записи:',
  'launchcode.LAUNCHDIALOGTITLE': 'Журнал кодов OTP',
  'menu.OTP_CODES': 'Коды OTP',
  'menu.LAUNCH_CODE': 'Запустить журнал кодов',
  'menu.SEND_CODE': 'Отправить пользователю новый код по электронной почте',
  'menu.SEND_CODE_PHONE': 'Отправить пользователю новый код в текстовом сообщении',
  'menu.COMMUNITY_GUIDELINES': 'Рекомендации сообщества',
  'textmessage.TITLE': 'Введите номер телефона',
  'textmessage.PHONE_NUMBER': 'Номер телефона',
  'textmessage.SEND_CODE': 'Отправить код',
  'textmessage.INVALID_NUMBER': 'Недействительный номер телефона.',
  'textmessage.SUCCESS_MESSAGE': 'Код отправлен',
  'search.searchPlaceHolderPPlus': 'Search by ISBN, Title, or Author',
  'search.searchPlaceHolderEText': 'Поиск в списке',
  'remove.title': 'Удалить заголовок?',
  'remove.subTitle': 'Вы можете вернуться позже — выполните поиск для повторного добавления.',
  'remove.buttonText': 'Удалить',
  'footer.downloadMobileApp': 'Загрузить приложение для мобильных устройств',
  'footer.termsOfUse': 'Условия использования',
  'footer.privacy': 'Конфиденциальность',
  'footer.cookies': 'Куки',
  'footer.doNotSellMyPersonalInformation': 'Не продавать мою личную информацию',
  'footer.accessibility': 'Доступ',
  'footer.patentNotice': 'Патентное уведомление',
  'footer.copyrights': 'гг. Pearson. Все права защищены.',
  'channel.videos': 'Видео',
  'channel.questions': 'Вопросы',
  'channel.descriptionA': 'Дополните чтение каналами.',
  'channel.descriptionB': 'Получайте помощь по сложным темам, пользуясь каналами.',
  'channel.openchannel': 'Открыть канал',
  'hero.continueReading': 'Продолжить чтение',
  'hero.openBook': 'Открыть книгу',
  'hero.channel.open': 'Открыть канал',
  'hero.addATitle': 'Добавить заголовок',
  'hero.toc.defaultPlacehoder': '--- select a topic ---',
  'hero.viewInactiveTitles': 'Просмотреть неактивные заголовки',
  'library.marketingCard': {
    title: 'Choose from over 2,000 eTextbooks',
    subTitle: 'with audio, flashcards, notes, and more.',
    primaryButton: {
      text: 'Найдите свой учебник eTextbook'
    },
    secondaryButton: {
      text: 'View all titles'
    }
  },
  'hero.mappedChannelV2': {
    ariaLabel: 'channel',
    heading: 'Recommended study help Channel',
    callForAction: {
      text: 'Open'
    }
  },
  'hero.openEtextBook': 'Открыть электронный учебник',
  'hero.openEtextBookV1': 'Открыть электронный учебник',
  'hero.heroHeading': 'Потому что вы изучаете <span>{channel_title}</span>',
  'hero.heroHeadingForBundleUser': 'Study & Exam Prep',
  'hero.study.help': 'Получите помощь в учебе',
  'hero.study.jumpBackIn': 'Возвращайтесь!',
  'hero.study.numOfUsers': 'В настоящее время Каналы используют <b>{num_of_users}</b> учащихся.',
  'hero.heroBook.sectionHeading': 'Ориентируйтесь в своем курсе',
  'hero.heroBook.channelsSectionHeading': 'Начните новую учебную сессию.',
  'hero.heroBook.sectionHeadingNewUser': 'Предлагаем помощь в учебе',
  'hero.heroBook.sectionSubHeading': 'Разберитесь в сложных темах с помощью практических вопросов и видеоинструкций с учетом особенностей вашего курса.',
  'hero.heroChannel.cardsHeading': 'Продолжайте с того места, на котором остановились',
  'hero.marketingcard.subheading': 'Учитесь эффективнее благодаря видеороликам, раскрывающим сложные понятия, и дополнительным практическим задачам с учетом особенностей вашего курса.',
  'hero.marketingcard1.copy.title': 'Обучайтесь с помощью видеоматериалов',
  'hero.marketingcard1.copy.description': 'Смотрите видеоинструкции, раскрывающие сложные темы.',
  'hero.marketingcard2.copy.title': 'Практические задачи',
  'hero.marketingcard2.copy.description': 'Готовьтесь к экзаменам с помощью тысяч похожих вопросов и видеорешений.',
  'hero.marketingcard3.copy.title': 'Получите квалифицированную помощь',
  'hero.marketingcard3.copy.description': 'Задавайте вопросы и получайте ответы от экспертов в данной области.',
  'hero.popularTopics.heading': 'Другие популярные темы на этом канале',
  'hero.popularChannels.heading': 'Популярные Каналы',
  'hero.popularChannels.headingv1': 'Проходите другой курс?',
  'hero.popularChannels.description': 'Получите помощь в изучении лучших Каналов.',
  'hero.popularChannels.descriptionv1': 'Получите помощь по другим курсам, выбрав канал.',
  'hero.popularChannels.descriptionv2': 'Просмотрите популярные темы для помощи в учебе.',
  'hero.startReading': 'Начать чтение',
  'hero.practice': 'Тренировка',
  'hero.noTitleNoChannel.sectionHeading': 'Подготовьтесь к экзаменам в кратчайшие сроки',
  'hero.herobook.mappedChannelHeading': 'Рекомендуемая помощь в обучении на этом канале',
  'libraryL2.myBooks': 'My Books',
  'libraryL2.archived': 'Archived',
  'hero.heroHeadingNoMappedChannel': 'Based on what you\'re studying',
  'hero.practiceProblem': 'Practice Problem',
  'nativeAppBanner.drawer.title': 'Open in ...',
  'nativeAppBanner.drawer.pearsonApp': 'Pearson+ App',
  'nativeAppBanner.banner.title': 'Pearson+ app',
  'nativeAppBanner.banner.description': 'Learn on the go',
  open: 'Open',
  continue: 'Continue',
  'hero.promotionalcard.list1': 'Небольшие видеоролики, в которых разбираются сложные темы',
  'hero.promotionalcard.list2': 'Тысячи вопросов, подобных экзаменационным',
  'hero.promotionalcard.list3': 'Получайте ответы от экспертов в данной области',
  'hero.studyandexamprep': 'Обучение и подготовка к экзаменам',
  'hero.heroBook.sectionSubHeadingV1': 'Разберитесь в сложных темах с помощью практических вопросов и видеоинструкций с учетом особенностей вашего курса.',
  'hero.heroBook.sectionSubHeadingV2': 'Break down tough topics with concept explanations and practice questions tailored to your course.',
  'hero.SeeAllStudyOptions': 'See all study options',
  'hero.tocLabel': 'View content related to your eTextbook',
  'hero.herobook.mappedChannelHeadingV1': 'Recommended videos and practice questions',
  'hero.popularTopics.headingV1': 'Other popular topics',
  'hero.heroHeadingV1': 'For <span>{replace_value}</span>',
  'hero.learnmore': 'Узнать больше',
  'hero.subscriptionStatusTitle': 'Статус подписки Pearson+',
  'hero.eTextbook': 'Учебник eTextbook',
  'hero.mylabOrMastering': 'MyLab or Mastering',
  'hero.goToPage.label': 'Перейти на страницу',
  'subscription.eTextbook': 'Учебник eTextbook',
  'subscription.mylab': 'MyLab or Mastering',
  'subscription.studyTitle': 'Обучение и подготовка к экзаменам',
  'subscription.tryItOut': 'Try it out',
  'print.printAvailTitle': 'Хотите распечатать эту страницу? Вот как вы можете это сделать',
  'print.printAvailDesc': 'В электронном учебнике выберите значок учетной записи в правом верхнем углу. В опциях выберите «Печать страницы», а затем выберите настройки печати.',
  'print.printNotAvailTitle': 'The print option for this eText is not enabled.',
  'print.printNotAvailDesc': 'For convenient offline access, please download your title through the Pearson+ mobile app and enjoy the flexibility of studying anytime, anywhere!',
  'hero.header.cta': '{hero_header_cta_text} study tools',
  'hero.marketing.title': 'Найдите свой учебник eTextbook',
  'hero.marketing.subTitle': 'Choose from over 2,000 eTextbooks with audio, flashcards, notes, and more. Only $10.99/mo for a 4-month term.',
  'hero.allyourcourses': 'all your courses',
  'hero.popularchannels.desc': 'Get study help with these top subjects',
  'hero.removecourse.title': 'Удалить курс с панели инструментов?',
  'hero.removecourse.description': 'Вы можете в любой момент вернуть курс обратно на панель инструментов, нажав Add course («Добавить курс»).',
  'hero.removecourse.primaryCtaText': 'Удалить',
  'hero.removecourse.secondaryCtaText': 'Отмена',
  'hero.subscriptionStatusTitle1': 'What\'s Available for you in Pearson+',
  'hero.emptystate.cta': 'Поиск в электронных учебниках',
  'hero.watch_it_again': 'Watch it again',
  'hero.mondly.learnLang.title': 'Бесплатно выучите новый язык',
  'hero.mondly.learnLang.title2': 'Бесплатно выучите новый язык!',
  'hero.mondly.subTitle': 'Пользователи электронных учебников Pearson получают три бесплатных месяца Mondly от Pearson.',
  'hero.mondly.description': 'Большинство приложений для изучения языков позволяют учиться только с базовым английским языком. Но лучше всего учиться на родном языке, поэтому Mondly позволяет вам обучаться на любом из 41 языка.',
  'hero.mondly.startLearningCTA': 'Начать обучение',
  'hero.mondly.marketing.bestApp': 'Best App',
  'hero.mondly.marketing.appStore': 'App Store',
  'hero.mondly.marketing.appYear': 'App of the Year',
  'hero.mondly.marketing.fbStart': 'Facebook FbStart - 2017 EMEA Winner',
  'hero.mondly.marketing.editorChoice': 'Editor Choice',
  'hero.mondly.marketing.googlePlay': 'Google Play',
  'header.menu.moreAtPearson': 'Подробнее на сайте Pearson',
  'hero.extractingExamDetails': 'Извлечение сведений об экзамене',
  'hero.finishingTheProcess': 'Завершение процесса',
  'hero.hereWhatWeGotFromYourSyllabus': 'Вот что мы получили из вашего учебного плана',
  'hero.school': 'Учебное заведение:',
  'hero.class': 'Класс:',
  'hero.textbook': 'Учебник:',
  'hero.examSchedule': 'Расписание экзаменов:',
  'hero.confirm': 'Подтвердить',
  'onboard.aboutCourse': 'Расскажите нам о своем курсе',
  'onboard.studyMaterials': 'Выберите свой предмет, и мы создадим приборную панель с индивидуальными учебными материалами.',
  'hero.personalizedStudyPlan': 'С помощью искусственного интеллекта мы предоставим вам индивидуальный план обучения.',
  'hero.welcomeToP': 'Добро пожаловать на P+!',
  'hero.learnYourWayAround': 'Потратьте минуту на ознакомление или отложите до следующего раза в настройках аккаунта.',
  'hero.weveCreatedADashboard': 'Мы создали приборную панель для вашего курса здесь.',
  'hero.youCanAddYourPearsonCourse': 'Вы также можете добавлять свои собственные курсы, не относящиеся к Pearson, и бесплатно тестировать учебные материалы.',
  'libraryL2.back': 'Назад',
  'hero.getExamreadyWithVideoLessons': 'Подготовьтесь к экзамену с помощью видеоуроков и комплектов практических заданий, разработанных специально для вашего курса.',
  'hero.youreTexbook': 'Ваш электронный учебник и все его инструменты находятся здесь.',
  'hero.VideosAndPractice': 'В разделе \'Обучение и подготовка к экзаменам\' всегда можно найти дополнительные видеоматериалы и практические вопросы.',
  'hero.pleaseSelectTheChapters': 'Вберите главы, которые будут рассматриваться на этом экзамене:',
  'hero.tableOfContents': 'Оглавление',
  'hero.search': 'Поиск',
  'hero.notes': 'Примечания и основные моменты',
  'hero.doYouWantToAddACourse': 'Добавить курс?',
  'hero.whatsAvailableForYou': 'Что доступно для вас',
  'hero.whatsAvailableForYouInPearson': 'Что вам доступно в Pearson+',
  'hero.continueStudying': 'Продолжить обучение',
  'hero.startStudying': 'Начать обучение',
  'hero.unlockYourStudyExamPrep': 'Unlock your Study & Exam Prep for other Courses now!',
  'header.menu.learnLanguage': 'Выучите новый язык',
  'hero.subscriptionStatusTitle2': 'Ваши ресурсы Pearson+',
  'hero.getExamreadyForYou': 'Подготовьтесь к экзаменам по другим предметам с помощью программы Study & Exam Prep.',
  'hero.weVideoLessons': 'У нас есть видеоуроки и комплекты практических заданий, разработанных специально с учетом ваших потребностей.',
  'hero.tryForFree': 'Попробуйте бесплатно',
  'hero.addCourseUsingStudyExamPrep': 'Добавьте курс, используя Study & Exam Prep',
  'hero.for': 'Для',
  'hero.myETextbooks': 'Мои учебники eTextbook',
  'header.continueInTheApp': 'Продолжить в приложении',
  'hero.goToMyLabMastering': 'Перейти в MyLab/Mastering',
  'hero.learnTxt': 'Узнать',
  'hero.aITutorTxt': 'Репетитор на базе искусственного интеллекта',
  'hero.exploreTxt': 'Знакомьтесь',
  'hero.studyExamPrepAllYourCourses': 'Обучение и подготовка к экзаменам по всем предметам',
  'hero.examSchedules': 'Расписание экзаменов',
  'hero.examSchedules2': 'Расписание экзаменов',
  'hero.uploadYourSyllabus': 'Загрузите учебный план',
  'hero.addAnExam': 'Добавить экзамен',
  'hero.UploadYourSyllabus.studyExamPrepPack': 'Upload your syllabus to get the most out of your Study & Exam Prep',
  'hero.UploadYourSyllabus.studyExamPrep': 'Загрузите свой учебный план, чтобы максимально эффективно использовать ваше пакет для обучения и подготовки к экзаменам',
  'hero.getRecommendationsOnWhat': 'Получите рекомендации о том, что и когда следует изучать',
  'hero.noSyllabusSharingExamSchedule': 'Нет учебной программы? Можно делиться расписанием экзаменов',
  'hero.selectDropSyllabusFile': 'Выберите или перетащите файл учебной программы в формате PDF или DOCX.',
  'hero.uploadTxt': 'Загрузить',
  'hero.examName': 'Название экзамена',
  'hero.completeTxt': 'Полный режим',
  'hero.exactTopicsChapter': 'Позже вы сможете указать точные темы для каждой главы.',
  'hero.uploadingDocument': 'Загрузка документа',
  'hero.activatingAIComponents': 'Активация компонентов искусственного интеллекта',
  'hero.extractingClassSchoolData': 'Извлечение данных о классе и учебном заведении',
  'hero.extractingTextbookChapters': 'Извлечение глав учебника',
  'hero.examPrepTxt': 'Подготовка к экзаменам',
  'hero.extractingDataFromSyllabus': 'Извлечение данных из учебной программы...',
  'hero.myLabMastering': 'MyLab/Mastering',
  'AI.toolTip': 'Ask me about anything in your eTextbook',
  'hero.dateFormat': 'DD/MM/YY',
  'header.qrCode.subHeaderTxt': 'С помощью QR-кода вы сможете быстро и безопасно войти в приложение Pearson+ на своем мобильном устройстве.',
  'header.qrCode.dontHaveApp': 'Нет приложения?',
  'header.qrCode.footerTxt': 'Отсканировав этот код, вы перейдете в App Store или Google Play. После загрузки приложения войдите в систему, просканировав код повторно.',
  'header.qrCode.errorMsg': 'QR-код не загрузился. Закройте и попробуйте снова.',
  'hero.yourETextbook': 'Ваш электронный учебник',
  'onboard.addCourseWithEtextBundle': 'Add course using Study & Exam Prep',
  'onboard.disableTooltipText': 'All Study & Exam Prep courses are added!',
  'subscriptionstatus.bundlecta': 'Access Study & Exam Prep',
  'subscriptionstatus.nonbundlecta': 'Получите доступ к Обучению и подготовке к экзаменам Study & Exam Prep',
  'channel.genericBundle': 'Use your Study & Exam Prep for help in your other courses',
  'channel.relatedBundle': 'Also use your Study & Exam Prep for help in your other courses',
  'onboard.courseAdded': 'Курсы добавлены!',
  'hero.flashcards': 'Дидактические карты',
  'aichat.title': 'AI Study Tool',
  'aichat.placeholder': 'Which topic or concept from your eTextbook can I explain for you?',
  'aichat.charDesc': 'Ask me anything related to <b>%BOOK_TITLE%</b>.',
  'relatedChannelCard.title': 'Enhance your learning with this related resource',
  'relatedChannelCard.badgeText': 'Related Study & Exam Prep',
  'sessionInactivity.title': 'Are you still there?',
  'sessionInactivity.description': 'Your session will time out in <b>%TIMER%</b> due to inactivity.<br/>Would you like to stay signed in?',
  'sessionInactivity.keepMeSignIn': 'Keep me signed in',
  'sessionInactivity.signOut': 'Sign Out',
  'sessionTimeout.title': 'Session timed out',
  'sessionTimeout.description': 'Your session has timed out due to inactivity.',
  'print.qrCode.header': 'С помощью QR-кода вы сможете открыть приложение Pearson+ на своем мобильном устройстве.',
  'print.qrCode.footer': 'Отсканировав этот код, вы перейдете в App Store или Google Play. После установки приложения войдите в систему для загрузкии доступа к материалу.'
};

export default ru;
