/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Translation file for Italian language
 *
 * @file it.js
 * @author Manimaran.S
 */

const it = {
  'popularChannelSlider.allChannels': 'All Channels',
  'popularChannelSlider.recommended': 'Recommended',
  'channel.noBundleRelatedtitle': 'Browse Study & Exam Prep related to your other classes.',
  'hero.myetextbook.link': 'library',
  'hero.myetextbook.text': 'Not the right title? Access your eTextbook',
  'hero.ctanewtext': 'Open Study & Exam Prep',
  'hero.welcomeToPPlus': 'Welcome to Pearson+',
  'hero.etextfeaturestitle': 'Find study materials in your eTextbook',
  'hero.etextfeaturesdescription': 'Your eTextbook comes with study materials designed to help you succeed. Study with flashcards and more right in your eTexbook.',
  'aichat.title1': 'Get AI-powered help for anything related to this eTextbook.',
  'aichat.chatDesc1': 'Type a question to start',
  'aichat.title2': 'Get AI-powered help',
  'aichat.chatDesc2': 'Ask about anything related to this eTextbook',
  'subscriptiontext.isSubscribed': 'Part of your subscription',
  'subscriptiontext.nonSubscriber': 'Upgrade for full access',
  'topicConatiner.otherHeader': 'Related practice and video lessons',
  'topicConatiner.exactLiteHeader': 'Related practice',
  'authHome.h1': 'Authenticated Home',
  'moremenu.downloadApp': 'Download the app',
  'moremenu.downloadAppAlreadyDownload': "%Click here% per scaricare l'app su un altro dispositivo e accedere automaticamente o per utilizzare il codice QR univoco per accedere in sicurezza all'app già installata.",
  'moremenu.downloadAppNotDownload': "%Click here% per scaricare l'app su un altro dispositivo e accedi in sicurezza con il tuo codice QR univoco",
  'channel.noBundletitle': 'Use the Study & Exam Prep for your related & other courses',
  'onboard.next': 'Successivo',
  'onboard.studyPlan': 'Piano di studi personalizzato creato!',
  'onboard.addCourseAnother': 'Vuoi aggiungere un altro corso?',
  'onboard.addCourseWithoutEtextBundle': 'Aggiungi corso con Preparazione studio ed esami',
  'onboard.doYouWantOtherCourse': 'Vuoi aggiungere un altro corso?',
  'onboard.purchase': 'Devo acquistare un libro di testo elettronico di Pearson',
  'onboard.addCourseWithoutEtext': 'Add course using Study & Exam Prep',
  'onboard.mayBeLater': 'Forse più tardi',
  'onboard.aboutCourse': 'Raccontaci del tuo corso',
  'onboard.close': 'Close',
  'course.addTitle': 'Aggiungi corso',
  'bookshelf.title': 'Il mio libretto',
  'common.LOGOUT': 'Disconnessione',
  'common.GO_HOME': 'Vai alla pagina iniziale',
  'common.PURCHASE': 'Acquista',
  'courses.WELCOME': 'Buongiorno {userName}, benvenuto al tuo corso {courseName}!',
  'courses.action.SHOW_ASSIGNMENTS': 'Mostra assegnazioni',
  'courses.toc.PUBLISH_SUCCESS': 'Successo!',
  'courses.toc.PUBLISH_MESSAGE': 'Gli studenti vedranno le tue modifiche la prossima volta che apriranno i contenuti.',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'Sottoscrizione non trovata',
  'errors.SUBSCRIPTION_GENERIC_DESC': `Il tuo account non può accedere a questo titolo. Se pensi di aver ricevuto questo
    messaggio per sbaglio, contatta l’Assistenza tecnica Pearson.`,
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'Sottoscrizione non trovata',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': `La tua sottoscrizione non include l’accesso al testo. Tuttavia, puoi registrarti
    adesso usando una carta di credito.`,
  'foxit.ABOUT': 'Info',
  'foxit.COPY_RIGHT': 'Lettore PDF alimentato da Foxit. Copyright (C) 2003-2019 di Foxit Software Incorporated',
  'link.ACCESSIBILITY': 'https://it.pearson.com/accessibilita.html',
  'link.PRIVACY': 'https://it.pearson.com/privacy-policy.html',
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://it.pearson.com/supporto.html',
  'link.TOSURL': 'https://it.pearson.com/termini-e-condizioni-uso.html',
  'link.FAQS_NONMOJO':'',
  'link.CHAT_WITH_SUPPORT_NONMOJO':'https://it.pearson.com/supporto.html',
  'link.TERMS_OF_USE_NONMOJO':'https://it.pearson.com/termini-e-condizioni-uso.html',
  'link.PRIVACY_NOTICE_NONMOJO':'https://it.pearson.com/privacy-policy.html',
  'link.PEARSON_ACCESSIBILITY_NONMOJO':'https://it.pearson.com/accessibilita.html',
  'link.ACCESSIBILITY_SUPPORT_NONMOJO':'',
  'menu.GET_THE_APP_DOWNLOAD': 'Scarica l’app mobile',
  'menu.CONTACT_SUPPORT': 'Contatta l’Assistenza',
  'menu.ABOUT': 'Info',
  'menu.FOXIT': 'Offerto da Foxit',
  'menu.TERMS_OF_USE': 'Termini e condizioni d\'uso',
  'menu.PRIVACY_POLICY': 'Informativa sulla privacy',
  'menu.SIGN_OUT': 'Disconnetti',
  'menu.PRINT_PAGE': 'Stampa pagina',
  'menu.GET_LOOSE_LEAF': 'Ottieni versione con pagine sfuse',
  'menu.AVAILABLE': 'Anche disponibile',
  'menu.PRINT_VERSION': 'Vuoi la versione stampata?',
  'menu.COPY_RIGHT': 'Copyright \u00A9 {year} Pearson Education Inc. Tutti i diritti riservati',
  'menu.tooltip.SCHEDULE': 'Programmazione',
  'print.FOOTER_MESSAGE': 'Stampato da {fullName}({email}) il {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': 'Buongiorno {userName}',
  'device.SWAPPED_ALREADY': 'Per questo mese hai già effettuato lo scambio',
  'device.SWITCH_DEVICE': 'Beralih ke perangkat saat ini?',
  'device.SWITCH_ONCE': 'Poiché puoi scambiare solo una volta al mese, non ti sarà possibile revocare questa azione.',
  'device.SEAT_TAKEN': 'Quel posto è occupato',
  'device.DISCONNECT_DEVICE': 'Puoi eseguire il login solo su 3 dispositivi. Per proseguire qui, scollega un altro dispositivo.',
  'device.ALERT_TEXT': 'Ricorda che puoi scambiare dispositivi solo una volta al mese.',
  'device.YOUR_MOJO': 'Stai raggiungendo mojo',
  'device.DISCONNECT': 'Scollegati',
  'device.CONTINUE': 'Prosegui',
  'device.HEADER': 'End your other browser session?',
  'device.CLOSE': 'Torna alla pagina iniziale',
  'device.CODE': 'Continue here by entering the authentication code we sent to {maskedEmail}. Not working? Check your spam folder, {email}, or contact {support}.',
  'device.TIMER': 'È necessario {time} per inserire il codice qui',
  'device.RESEND': '{resend}',
  'device.FAQ': 'FAQ',
  'device.RESEND_NAME': 'Resend',
  'device.NOTE': 'If you regularly use Pearson+ in 2 places, like at work and at home, make sure to log out whenever you’re done. You can also use the Pearson+ mobile app on up to 2 devices. For more info, see our {faq}.',
  'device.NOTE_HEADER': 'Avoid this next time',
  'device.CONTACT_SUPPORT': '',
  'device.CONTACT_EMAIL': '',
  'device.CONTACT_FAQ': '',
  'device.INCORRECT': 'Non è corretto. Ricontrolla il codice o invialo di nuovo.',
  'device.MANY_ATTEMPTS': 'Troppi tentativi, prova a inviare di nuovo il codice',
  'device.EXPIRED': 'Tempo scaduto, prova a inviare di nuovo il codice',
  'device.ACCESS_HEADER': 'Accesso riuscito!',
  'device.ACCESS_DESC': 'La sessione è stata autenticata. Desideri aggiornare la password mentre ci sei?',
  'device.ACCESS_CHANGE_PASSWORD': 'Cambia password',
  'device.ACCESS_RESET_PASSWORD': 'Reimposta password',
  'device.THREATONE_TITLE': 'Tutto bene?',
  'device.THREATONE_DESC': 'Abbiamo notato delle attività sospette sul tuo account.',
  'device.THREATONE_DESC_TWO': 'Potrebbe essere il momento adatto per cambiare la tua password.',
  'device.MAY_BE_LATER': 'Forse più tardi',
  'device.OKAY': 'Ok',
  'device.ACCOUNT_LOCK_DESC': 'Per la tua sicurezza, abbiamo eseguito il logout. Ti abbiamo inviato un link di reset della password all’indirizzo email associato al tuo account.',
  'device.ACCOUNT_LOCK_TITLE': 'Abbiamo notato delle attività sospette sul tuo account',
  'device.ACCOUNT_LOCK_TIMER': 'Sarai disconnesso tra {time}',
  'menu.myAccount': 'Il mio account',
  'menu.helpCenter': 'Centro assistenza',
  'menu.preferencesCenter': 'Centro preferenze',
  'menu.HELP_SUPPORT': 'Supporto',
  'menu.FAQS': 'Domande frequenti (FAQ)',
  'menu.CHAT_WITH_SUPPORT': 'Contatta il supporto',
  'menu.QUICK_WALKTHROUGH': 'Guida rapida',
  'menu.LEGAL': 'Note legali',
  'menu.PRIVACY_NOTICE': 'Informativa privacy',
  'menu.TITLE_INFO': 'Informazioni',
  'menu.PEARSON_ACCESSIBILITY': 'Accessibilità',
  'menu.DEVICE_WARNING_MESSAGE': 'Registra {deviceLimit} dispositivi. Scambiane 1 al mese.',
  'menu.ACCESSIBILITY': 'Accessibilità',
  'menu.ACCESSIBILITY_SUPPORT': 'Supporto accessibilità',
  'menu.DEVICES': 'Dispositivi recenti',
  'menu.THIS_DEVICE': 'Questo dispositivo',
  'menu.MOJO_APP_INFO': 'Vai all\'app store sul telefono o sul tablet per utilizzare Pearson+ offline.',
  etextappinfo: 'Vai all\'app store sul telefono o sul tablet per utilizzare eText offline.',
  'menu.DEVICE_LABEL': 'Dispositivo',
  'menu.DEVICE_DISCONNECT': 'Scollegati',
  'navigation.PAGE': 'Pagina',
  'launchcode.CREATEBY': 'Creato da:',
  'launchcode.sentvia': 'Inviato via:',
  'launchcode.CODEINLAST': 'Codici negli ultimi 7 giorni:',
  'launchcode.TIMESENT': 'Orario invio:',
  'launchcode.ACCOUNTEMAIL': 'e-mail account:',
  'launchcode.LAUNCHDIALOGTITLE': 'Log codice OTP',
  'menu.OTP_CODES': 'Codici OTP',
  'menu.LAUNCH_CODE': 'Avvia il log di codice',
  'menu.SEND_CODE': 'Invia il nuovo codice all\'utente tramite e-mail',
  'menu.SEND_CODE_PHONE': 'Invia il nuovo codice all\'utente tramite SMS',
  'menu.COMMUNITY_GUIDELINES': 'Linee guida della comunità',
  'textmessage.TITLE': 'Inserisci il tuo numero di telefono',
  'textmessage.PHONE_NUMBER': 'Numero di telefono',
  'textmessage.SEND_CODE': 'Invia codice',
  'textmessage.INVALID_NUMBER': 'Numero di telefono non valido',
  'textmessage.SUCCESS_MESSAGE': 'Codice inviato',
  'search.searchPlaceHolderPPlus': 'Search by ISBN, Title, or Author',
  'search.searchPlaceHolderEText': 'Ricerca nel tuo elenco',
  'remove.title': 'Rimuovere titolo?',
  'remove.subTitle': 'Puoi sempre tornarci in un secondo momento, semplicemente utilizzando la ricerca per aggiungerlo di nuovo.',
  'remove.buttonText': 'Rimuovi',
  'footer.downloadMobileApp': 'Scarica l’app mobile',
  'footer.termsOfUse': 'Termini di utilizzo',
  'footer.privacy': 'Privacy',
  'footer.cookies': 'Cookie',
  'footer.doNotSellMyPersonalInformation': 'Non vendere i miei dati personali',
  'footer.accessibility': 'Accessibilità',
  'footer.patentNotice': 'Informazioni sui brevetti',
  'footer.copyrights': 'Pearson Tutti i diritti riservati.',
  'channel.videos': 'Video',
  'channel.questions': 'Esercizi',
  'channel.descriptionA': 'Completa la tua lettura con i Canali.',
  'channel.descriptionB': 'Ottieni assistenza su argomenti difficili con i Canali.',
  'channel.openchannel': 'Apri canale',
  'hero.continueReading': 'Prosegui la lettura',
  'hero.openBook': 'Apri libro',
  'hero.channel.open': 'Apri canale',
  'hero.addATitle': 'Aggiungi un titolo',
  'hero.toc.defaultPlacehoder': '--- select a topic ---',
  'hero.viewInactiveTitles': 'Visualizza i titoli inattivi',
  'library.marketingCard': {
    title: 'Choose from over 2,000 eTextbooks',
    subTitle: 'with audio, flashcards, notes, and more.',
    primaryButton: {
      text: 'Trova il tuo eText'
    },
    secondaryButton: {
      text: 'View all titles'
    }
  },
  'hero.mappedChannelV2': {
    ariaLabel: 'channel',
    heading: 'Recommended study help Channel',
    callForAction: {
      text: 'Open'
    }
  },
  'hero.openEtextBook': 'Apri l’e-Textbook',
  'hero.openEtextBookV1': 'Apri l’e-Textbook',
  'hero.heroHeading': 'Poiché stai studiando <span>{channel_title}</span>',
  'hero.heroHeadingForBundleUser': 'Study & Exam Prep',
  'hero.study.help': 'Richiedi un supporto allo studio',
  'hero.study.jumpBackIn': 'Vai indietro',
  'hero.study.numOfUsers': 'Attualmente ci sono <b>{num_of_users}</b> studenti che utilizzano Canali.',
  'hero.heroBook.sectionHeading': 'Rendi più fluidi i tuoi corsi',
  'hero.heroBook.channelsSectionHeading': 'Inizia una nuova sessione di studio',
  'hero.heroBook.sectionHeadingNewUser': 'Ecco il tuo aiuto allo studio',
  'hero.heroBook.sectionSubHeading': 'Suddividi gli argomenti più complessi con domande di esercitazione e spiegazioni in video su misura per il tuo corso.',
  'hero.heroChannel.cardsHeading': 'Riprendi da dove hai lasciato',
  'hero.marketingcard.subheading': 'Studia in modo più efficiente grazie a dei video che spiegano nel dettaglio i concetti più difficili e con dei problemi pratici extra, tutto su misura per il tuo corso.',
  'hero.marketingcard1.copy.title': 'Impara con i video',
  'hero.marketingcard1.copy.description': 'Guarda dei brevi video di spiegazione che illustrano nel dettaglio gli argomenti più complessi.',
  'hero.marketingcard2.copy.title': 'Problemi pratici',
  'hero.marketingcard2.copy.description': 'Preparati per gli esami con migliaia di quiz pratici e video soluzioni',
  'hero.marketingcard3.copy.title': 'Chiedi aiuto a un esperto',
  'hero.marketingcard3.copy.description': 'Invia delle domande e ricevi delle risposte dagli esperti in materia.',
  'hero.popularTopics.heading': 'Altri argomenti di tendenza in questo Canale',
  'hero.popularChannels.heading': 'Canali più popolari',
  'hero.popularChannels.headingv1': 'Stai preparando un altro corso?',
  'hero.popularChannels.description': 'Richiedi un supporto allo studio con questi Canali top.',
  'hero.popularChannels.descriptionv1': 'Ottieni aiuto per altri corsi scegliendo un canale.',
  'hero.popularChannels.descriptionv2': 'Sfoglia gli argomenti di supporto allo studio più popolari.',
  'hero.startReading': 'Apri',
  'hero.practice': 'Esercitati',
  'hero.noTitleNoChannel.sectionHeading': 'Preparati per gli esami in tempo record',
  'hero.herobook.mappedChannelHeading': 'Supporto allo studio consigliato in questo Canale',
  'libraryL2.myBooks': 'My Books',
  'libraryL2.archived': 'Archived',
  'libraryL2.back': 'Indietro',
  'hero.heroHeadingNoMappedChannel': 'Based on what you\'re studying',
  'hero.practiceProblem': 'Practice Problem',
  'nativeAppBanner.drawer.title': 'Open in ...',
  'nativeAppBanner.drawer.pearsonApp': 'Pearson+ App',
  'nativeAppBanner.banner.title': 'Pearson+ app',
  'nativeAppBanner.banner.description': 'Learn on the go',
  open: 'Open',
  continue: 'Continue',
  'hero.promotionalcard.list1': 'Brevi video di spiegazione che illustrano nel dettaglio gli argomenti più complessi',
  'hero.promotionalcard.list2': 'Migliaia di quiz simili a un esame',
  'hero.promotionalcard.list3': 'Ricevi delle risposte dagli esperti in materia',
  'hero.studyandexamprep': 'Preparazione studio ed esami',
  'hero.SeeAllStudyOptions': 'See all study options',
  'hero.tocLabel': 'View content related to your eTextbook',
  'hero.herobook.mappedChannelHeadingV1': 'Recommended videos and practice questions',
  'hero.popularTopics.headingV1': 'Other popular topics',
  'hero.heroHeadingV1': 'For <span>{replace_value}</span>',
  'hero.learnmore': 'Scopri di più',
  'hero.subscriptionStatusTitle': 'Stato abbonamento Pearson+',
  'hero.eTextbook': 'Libri eText',
  'hero.mylabOrMastering': 'MyLab or Mastering',
  'hero.goToPage.label': 'Vai alla pagina',
  'subscription.eTextbook': 'Libri eText',
  'subscription.mylab': 'MyLab or Mastering',
  'subscription.studyTitle': 'Preparazione studio ed esami',
  'subscription.tryItOut': 'Try it out',
  'print.printAvailTitle': 'Desideri stampare la pagina? Ecco come',
  'print.printAvailDesc': 'Nel tuo libro eText, seleziona l’icona dell’account in alto a destra. Dalle opzioni, scegli “Stampa pagina” e poi scegli le impostazioni di stampa.',
  'print.printNotAvailTitle': 'The print option for this eText is not enabled.',
  'print.printNotAvailDesc': 'For convenient offline access, please download your title through the Pearson+ mobile app and enjoy the flexibility of studying anytime, anywhere!',
  'hero.continueStudying': 'Continua a studiare',
  'hero.header.cta': '{hero_header_cta_text} study tools',
  'hero.marketing.title': 'Trova il tuo eText',
  'hero.marketing.subTitle': 'Choose from over 2,000 eTextbooks with audio, flashcards, notes, and more. Only $10.99/mo for a 4-month term.',
  'hero.allyourcourses': 'all your courses',
  'hero.popularchannels.desc': 'Get study help with these top subjects',
  'hero.removecourse.title': 'Rimuovere corso dal Cruscotto digitale?',
  'hero.removecourse.description': 'Puoi riaggiungere il corso al cruscotto digitale facendo clic su "Aggiungi corso"',
  'hero.removecourse.primaryCtaText': 'Rimuovi',
  'hero.removecourse.secondaryCtaText': 'Annulla',
  'hero.subscriptionStatusTitle1': 'What\'s Available for you in Pearson+',
  'hero.emptystate.cta': 'Cerca libri eText',
  'hero.watch_it_again': 'Watch it again',
  'hero.mondly.learnLang.title': 'Impara una nuova lingua gratuitamente',
  'hero.mondly.learnLang.title2': 'Impara una nuova lingua gratuitamente!',
  'hero.mondly.subTitle': 'Gli utenti Pearson con eTextbook hanno tre mesi gratis di Mondly di Pearson.',
  'hero.mondly.description': 'La maggior parte delle app di insegnamento delle lingue ti permettono di impararle solo dall\'inglese. Ma è meglio impararle partendo dalla propria lingua madre, pertanto Mondly ti permette di imparare da tutte le nostre 41 lingue disponibili.',
  'hero.mondly.marketing.bestApp': 'Best App',
  'hero.mondly.marketing.appStore': 'App Store',
  'hero.mondly.marketing.appYear': 'App of the Year',
  'hero.mondly.marketing.fbStart': 'Facebook FbStart - 2017 EMEA Winner',
  'hero.mondly.marketing.editorChoice': 'Editor Choice',
  'hero.mondly.marketing.googlePlay': 'Google Play',
  'header.menu.learnLanguage': 'Impara una lingua',
  'hero.subscriptionStatusTitle2': 'Le tue risorse Pearson+',
  'hero.extractingExamDetails': 'Estrazione dei dettagli dell\'esame',
  'hero.finishingTheProcess': 'Termine del processo',
  'hero.hereWhatWeGotFromYourSyllabus': 'Ecco cosa abbiamo estratto dal tuo programma scolastico',
  'hero.school': 'Scuola:',
  'hero.class': 'Classe:',
  'hero.textbook': 'Libro di testo:',
  'hero.examSchedule': 'Programma degli esami:',
  'hero.confirm': 'Conferma',
  'onboard.studyMaterials': 'Scegli la tua materia e creeremo una dashboard con materiali di studio personalizzati.',
  'hero.personalizedStudyPlan': 'Utilizzeremo l\'IA per fornirti un piano di studi personalizzato.',
  'hero.welcomeToP': 'Benvenuto in P+!',
  'hero.learnYourWayAround': 'Prenditi un minuto per imparare come funziona, oppure segui queste istruzioni in seguito durante la configurazione dell\'account.',
  'hero.weveCreatedADashboard': 'Abbiamo creato una dashboard qui per il tuo corso.',
  'hero.youCanAddYourPearsonCourse': 'Puoi anche aggiungere i tuoi corsi fuori da Pearson e provare gratuitamente i materiali di studio.',
  'hero.getExamreadyWithVideoLessons': 'Preparati agli esami con le video lezioni e set di pratica personalizzati per il tuo corso.',
  'hero.youreTexbook': 'Il tuo libro di testo elettronico e tutti i suoi strumenti sono qui.',
  'hero.VideosAndPractice': 'Ci sono sempre più video e domande per fare pratica disponibili in Preparazione studio ed esami.',
  'hero.pleaseSelectTheChapters': 'Seleziona i capitoli trattati in questo esame:',
  'hero.search': 'Cerca',
  'hero.notes': 'Note ed evidenziazioni',
  'hero.tableOfContents': 'Sommario',
  'hero.mondly.startLearningCTA': 'Iniziare l’apprendimento',
  'hero.doYouWantToAddACourse': 'Vuoi aggiungere un corso?',
  'hero.whatsAvailableForYou': 'Disponibile per te',
  'hero.whatsAvailableForYouInPearson': 'Disponibile per te su Pearson+',
  'hero.startStudying': 'Inizia a studiare',
  'hero.unlockYourStudyExamPrep': 'Unlock your Study & Exam Prep for other Courses now!',
  'hero.getExamreadyForYou': 'Preparati per gli esami degli altri tuoi corsi con Preparazione studio ed esami.',
  'hero.weVideoLessons': 'Troverai videolezioni e set di pratica personalizzati su misura per le tue necessità.',
  'hero.tryForFree': 'Prova gratuitamente',
  'hero.heroBook.sectionSubHeadingV1': 'Suddividi gli argomenti più complessi con domande di esercitazione e spiegazioni in video su misura per il tuo corso.',
  'hero.heroBook.sectionSubHeadingV2': 'Break down tough topics with concept explanations and practice questions tailored to your course.',
  'hero.addCourseUsingStudyExamPrep': 'Aggiungi corso con Preparazione studio ed esami',
  'hero.for': 'Per',
  'header.menu.moreAtPearson': 'Altro da Pearson',
  'hero.myETextbooks': 'I miei Libri eText',
  'header.continueInTheApp': 'Continua nell\'app',
  'hero.goToMyLabMastering': 'Vai a MyLab/Mastering',
  'hero.learnTxt': 'Formazione',
  'hero.aITutorTxt': 'Tutor AI',
  'hero.exploreTxt': 'Esplora',
  'hero.studyExamPrepAllYourCourses': 'Preparazione studio ed esami per tutti i tuoi corsi',
  'hero.examSchedules': 'Programma degli esami',
  'hero.examSchedules2': 'Programma degli esami',
  'hero.uploadYourSyllabus': 'Carica il tuo programma scolastico',
  'hero.addAnExam': 'Aggiungi un esame',
  'hero.UploadYourSyllabus.studyExamPrepPack': 'Upload your syllabus to get the most out of your Study & Exam Prep',
  'hero.UploadYourSyllabus.studyExamPrep': 'Carica il tuo programma scolastico per ottenere il massimo dalla tua preparazione allo studio e agli esami',
  'hero.getRecommendationsOnWhat': 'Ottieni consigli su cosa studiare e quando',
  'hero.noSyllabusSharingExamSchedule': 'Non hai un programma? Puoi condividere il tuo programma degli esami',
  'hero.selectDropSyllabusFile': 'Seleziona o trascina un file col programma di studio in formato PDF o DOCX',
  'hero.uploadTxt': 'Carica',
  'hero.examName': 'Nome esame',
  'hero.completeTxt': 'Completo',
  'hero.exactTopicsChapter': 'Potrai specificare gli argomenti esatti di ogni capitolo in seguito.',
  'hero.uploadingDocument': 'Caricamento del documento',
  'hero.activatingAIComponents': 'Attivazione dei componenti IA',
  'hero.extractingClassSchoolData': 'Estrazione dei dati sulla classe e sulla scuola',
  'hero.extractingTextbookChapters': 'Estrazione dei capitoli del libro di testo',
  'hero.extractingDataFromSyllabus': 'Estrazione dati dal programma scolastico...',
  'hero.examPrepTxt': 'Preparazione esame',
  'hero.myLabMastering': 'MyLab/Mastering',
  'AI.toolTip': 'Ask me about anything in your eTextbook',
  'hero.dateFormat': 'DD/MM/YY',
  'header.qrCode.subHeaderTxt': 'Utilizza questo codice QR per accedere in modo rapido e sicuro all\'app Pearson+ sul tuo dispositivo mobile.',
  'header.qrCode.dontHaveApp': 'Non hai l\'app?',
  'header.qrCode.footerTxt': 'Scansione il codice per visitare l\'App Store e Google Play. Una volta scaricata l\'app, accedi scansionandolo di nuovo.',
  'header.qrCode.errorMsg': 'Caricamento del codice QR non riuscito. Chiudi e riprova.',
  'hero.yourETextbook': 'I tuoi eTextbook',
  'onboard.addCourseWithEtextBundle': 'Add course using Study & Exam Prep',
  'onboard.disableTooltipText': 'All Study & Exam Prep courses are added!',
  'subscriptionstatus.bundlecta': 'Access Study & Exam Prep',
  'subscriptionstatus.nonbundlecta': 'Accedi a Preparazione studio ed esami',
  'channel.genericBundle': 'Use your Study & Exam Prep for help in your other courses',
  'channel.relatedBundle': 'Also use your Study & Exam Prep for help in your other courses',
  'onboard.courseAdded': 'Corso aggiunto!',
  'hero.flashcards': 'Flashcard',
  'aichat.title': 'AI Study Tool',
  'aichat.placeholder': 'Which topic or concept from your eTextbook can I explain for you?',
  'aichat.charDesc': 'Ask me anything related to <b>%BOOK_TITLE%</b>.',
  'relatedChannelCard.title': 'Enhance your learning with this related resource',
  'relatedChannelCard.badgeText': 'Related Study & Exam Prep',
  'sessionInactivity.title': 'Are you still there?',
  'sessionInactivity.description': 'Your session will time out in <b>%TIMER%</b> due to inactivity.<br/>Would you like to stay signed in?',
  'sessionInactivity.keepMeSignIn': 'Keep me signed in',
  'sessionInactivity.signOut': 'Sign Out',
  'sessionTimeout.title': 'Session timed out',
  'sessionTimeout.description': 'Your session has timed out due to inactivity.',
  'print.qrCode.header': "Utilizza questo codice QR per aprire l'app Pearson+ sul tuo dispositivo mobile.",
  'print.qrCode.footer': "Scansiona il codice per visitare l'App store e Google Play. Una volta installata l'app, accedi per scaricarla e accedere al tuo titolo."
};

export default it;
