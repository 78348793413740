/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * AuthHome AI Chat Study Tool
 *
 * @file AuthHomeChatComponent.jsx
 * @author Mohamed yasar arabath M
 */

import React, { memo } from 'react';
import { withStyles } from '@material-ui/core';
import { ChatComponent } from '@vega/aitutor';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../common/components/ErrorBoundary';
import { LOCATION_IN_APP } from '../../common/constants';

import AuthHomeChatComponentStyles from './styles/AuthHomeChatComponentStyles';

const AuthHomeChatComponent = ({
  classes,
  config,
  isPageLoaded,
  openBotContainer,
  showChatBotIcon,
  showUserHistory,
  users,
  masterTheme,
  theme,
  context,
  streamHeader,
  streamBaseUrl,
  disableDrag,
  initiatedPoint,
  pageList,
  executeCommand,
  onBotClose,
  handleGAEvents,
  handleChatbotOpened,
  onLDTrack,
  toggleBotContainer,
  setAITutorAutoOpen,
  handleCancelRequest,
  closeContentSelector,
  getBerlinClientAPI,
  getAuthToken,
  getCurrentSection,
  playList,
  sourceLinkUrl
}) => {
  const props = {
    classes,
    isPageLoaded,
    openBotContainer,
    showChatBotIcon,
    showUserHistory,
    users,
    masterTheme,
    theme,
    context,
    streamHeader,
    streamBaseUrl,
    disableDrag,
    initiatedPoint,
    pageList,
    executeCommand,
    onBotClose,
    handleGAEvents,
    handleChatbotOpened,
    onLDTrack,
    toggleBotContainer,
    setAITutorAutoOpen,
    handleCancelRequest,
    closeContentSelector,
    getBerlinClientAPI,
    getAuthToken,
    getCurrentSection,
    playList,
    sourceLinkUrl
  };

  const {
    enableFreeResponse,
    enableParagraph,
    ...restConfig
  } = config;

  return (
    <ErrorBoundary>
      <ChatComponent
        classes={{ botContainer: classes.botContainer }}
        {...restConfig}
        {...props}
        AITutorContext={{
          enableFreeResponse,
          enableParagraph
        }}
      />
    </ErrorBoundary>
  );
};

AuthHomeChatComponent.defaultProps = {
  masterTheme: 'plus',
  config: {},
  theme: 'default',
  streamHeader: {},
  showChatBotIcon: true,
  showUserHistory: true,
  disableDrag: true,
  openBotContainer: false,
  initiatedPoint: LOCATION_IN_APP.AUTHHOME.toLocaleUpperCase(),
  pageList: [],
  playList: [],
  executeCommand: '',
  sourceLinkUrl: '',
  onBotClose: () => {},
  onLDTrack: () => {},
  handleGAEvents: () => {},
  handleChatbotOpened: () => {},
  toggleBotContainer: () => {},
  setAITutorAutoOpen: () => {},
  handleCancelRequest: () => {},
  closeContentSelector: () => {},
  getBerlinClientAPI: () => {},
  getAuthToken: () => {},
  getCurrentSection: () => { }
};

AuthHomeChatComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  config: PropTypes.object,
  isPageLoaded: PropTypes.bool.isRequired,
  openBotContainer: PropTypes.bool,
  showChatBotIcon: PropTypes.bool,
  showUserHistory: PropTypes.bool,
  disableDrag: PropTypes.bool,
  initiatedPoint: PropTypes.string,
  users: PropTypes.object.isRequired,
  masterTheme: PropTypes.string,
  theme: PropTypes.string,
  context: PropTypes.object.isRequired,
  streamHeader: PropTypes.object,
  streamBaseUrl: PropTypes.string.isRequired,
  pageList: PropTypes.array,
  playList: PropTypes.array,
  executeCommand: PropTypes.string,
  sourceLinkUrl: PropTypes.string,
  onBotClose: PropTypes.func,
  handleGAEvents: PropTypes.func,
  handleChatbotOpened: PropTypes.func,
  onLDTrack: PropTypes.func,
  toggleBotContainer: PropTypes.func,
  setAITutorAutoOpen: PropTypes.func,
  handleCancelRequest: PropTypes.func,
  closeContentSelector: PropTypes.func,
  getBerlinClientAPI: PropTypes.func,
  getAuthToken: PropTypes.func,
  getCurrentSection: PropTypes.func
};

export default memo(withStyles(AuthHomeChatComponentStyles)(AuthHomeChatComponent));
