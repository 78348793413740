/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file Study channels video player config
 * @author Arifhusain Soudagar
 */

import { getComponentThemeValue } from './CustomStyles/customStyleUtils';

export const defaultConfig = (video, hideChannelsPopout) => {
  const views = video && video.params && video.params.metadata && video.params.metadata.views;
  const config = {
    creatorNameOnly: false,
    cardHeadText: 'video',
    linkText: 'See in the channel',
    inlineVideoPlayer: true,
    disableRedirect: true,
    hideBookmark: false,
    hideCompleted: false,
    hideMetadata: false,
    hideTitle: false,
    hidePopoutVideo: hideChannelsPopout,
    hideTutor: false,
    hideViews: !views,
    hideChannelsLink: false,
    hideCardHead: false,
    hideCardTitle: false,
    hideDuration: false,
    popoutVideoZIndex: 1302,
    popoutVideoPercentageWidth: 40
  };
  return config;
};

export const videoConfig = {
  videoAutoplay: true,
  hideTitle: false,
  hideTutor: false,
  hideMetadata: true,
  hidePaywall: true,
  hideStats: false,
  hideInfo: false,
  hideActions: false,
  hideBookMarkAction: false,
  hideCompleteAction: false,
  hideRanksAction: false
};

export const channelsClasses = (theme, masterTheme, customThemeStyles) => {
  const videoPlayerClasses = {
    root: {
      backgroundColor: (getComponentThemeValue(customThemeStyles, 'root', 'backgroundColor', false, 'bgColor', masterTheme, theme)),
      padding: '12px 4px'
    },
    contentWrapper: {},
    infoWrapper: {},
    avatar: {},
    title: {
      color: (getComponentThemeValue(customThemeStyles, 'root', 'textColor', false, 'textColor', masterTheme, theme))
    },
    tutorName: {
      color: (getComponentThemeValue(customThemeStyles, 'root', 'textColor', false, 'textColor', masterTheme, theme))
    },
    dotStyles: {
      color: (getComponentThemeValue(customThemeStyles, 'root', 'textColor', false, 'textColor', masterTheme, theme))
    },
    viewsStyles: {
      color: (getComponentThemeValue(customThemeStyles, 'root', 'textColor', false, 'textColor', masterTheme, theme))
    },
    channelLinkStyles: {},
    cardHeadWrapper: {},
    cardHeadTitleStyles: {
      color: (getComponentThemeValue(customThemeStyles, 'root', 'textColor', false, 'textColor', masterTheme, theme))
    },
    durationIconStyles: {
      color: (getComponentThemeValue(customThemeStyles, 'root', 'textColor', false, 'textColor', masterTheme, theme))
    },
    durationTextStyles: {
      color: (getComponentThemeValue(customThemeStyles, 'root', 'textColor', false, 'textColor', masterTheme, theme))
    },
    thumbWrapper: {},
    thumbInnerContainer: {},
    bookmarkIcon: {},
    watchAgainBadge: {},
    reloadIcon: {},
    playIcon: {},
    playIconWrapper: {}
  };
  return videoPlayerClasses;
};

export const paywallModalProps = {
  disablePortal: true,
  disableScrollLock: true,
  BackdropProps: {
    style: {
      position: 'absolute'
    }
  }
};
