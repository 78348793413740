/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Base environment settings. This base environment config can be overriden in environment-specific configs.
 *
 * @author Hari Gangadharan
 */

const LOCATION_HOST = typeof window !== 'undefined' ? `https://${window.location.host}` : null;
const SSI_CLIENT_ID = 'I3l3CiMiPkq8LpGSK9IMRcHBJlSQKhYH';

const base = {
  ENVIRONMENT: 'stg',
  PARTNER_ENV: 'stg',
  CSG_API_BASE_URL: 'https://content-service.stg-prsn.com/csg/api/v3',
  MARIN_API_BASE_URL: 'https://plus-stg.pearson.com/marin/api/1.0',
  MARIN_API_BASE_URL_V2: 'https://plus-stg.pearson.com/marin/api/2.0',
  PRISM_API_BASE_URL: 'https://prism-stg.pearsoned.com/api',
  PAPER_API_BASE_URL: 'https://paperapi.stg-openclass.com/nextext-api/api/platforms/bookshelf',
  STPAPER_API_BASE_URL: 'https://stpaperapi.stg-prsn.com/etext/v2',
  SPECTRUM_API_BASE_URL: 'https://spectrum-stg.pearsoned.com/api/',
  SPECTRUM_API_V4_BASE_URL: 'https://spectrum-stg.pearsoned.com/api/v4', // TODO: Need to check with Spectrum team for notescopy support
  IDC_API_BASE_URL: 'https://etext-instructor-stg.pearson.com/idc-api/',
  ETEXT_API_BASE_URL: 'https://etext-stg.pearson.com/api/nextext-api/v1/api',
  ELIGIBILITY_API_BASE_URL: 'https://api-c-pgi.pearson.com/eu/mojo-uat/v1/',
  MARQUEE_API_TIMEOUT: 10000,
  ELIGIBILITY_API_TIMEOUT: 10000,
  CSG_API_TIMEOUT: 10000,
  MARIN_API_TIMEOUT: 20000,
  PRISM_API_TIMEOUT: 10000,
  STPAPER_API_TIMEOUT: 20000,
  SPECTRUM_API_TIMEOUT: 10000,
  IDC_API_TIMEOUT: 60000,
  ETEXT_API_TIMEOUT: 10000,
  ELIGIBILITY_RULE_GROUP: 'urn:pearson:gip:elgcontext:af4b723a525a6dea',
  PRODUCT: 'eText',
  BROWSER_REQUIREMENTS_URL: 'https://www.pearson.com/us/higher-education/products-services-teaching/'
    + 'course-content/textbooks-and-etexts/pearson-etext/system-requirements.html',
  CLIENT_ID: '29s6iaLv3PblrAbzCP9kHlFY5ntGeNN7',
  SSI_CLIENT_ID,
  GTM_CONTAINER_ID: 'GTM-5WD3HSR',
  GTM_AUTH: 'neDCHn1snhB9TDBHGb9iJA',
  GTM_PREVIEW: 'env-4',
  PSEUDO_LOCALE_CODE: 'pt',
  CONSOLE_URL: 'https://console-stg.pearson.com',
  ZEPPELIN_URL: 'https://zeppelin.stg-openclass.com/products',
  TENANT_ID_ETEXT: '30258aed-46bb-4ec5-909e-ecea2431772b',
  TENANT_KEY_ETEXT: '6551b60c-b521-4949-be3a-1be09d8ede6f',
  TENANT_KEY_ETEXT_PDF: 'a7a95b0e-6294-4588-9ee2-87767bbecb05',
  CONTENT_HOST: LOCATION_HOST || this.PLUS_URL,
  PXE_BASE_PATH: 'eps/pearson-reader/api/item',
  CITE_BASE_PATH: 'eps/sanvan/api/item',
  COLLECTIONS_BASE_PATH: 'eps/bluesky/api/item',
  EPUB_BASE_PATH: 'epub/bronte',
  BRONTE_BASE_PATH: 'epub/bronteViewer',
  EPUB_STD_BASE_PATH: 'epub/standard',
  SPECTRUM_X_TENANTKEY: '297a5917-24fa-43fe-a27b-426126bd7cf4',
  NEWRELIC_CONFIG_NAME: '/newrelic/newrelic.js',
  NEWRELIC_ENABLED: false,
  NEWRELIC_CONFIG: {
    accountId: 1322480,
    agentId: 30030167,
    applicationId: 30030167,
    licenseKey: 'd527718953',
    trustKey: 691807
  },
  SMARTTHINKING_CONFIG: {
    baseUrl: 'https://staging.services.smarthinking.com/api/payment-mojo',
    secret: '20BBEBB8-DCC1-4544-AD32-7F3973CCED7A'
  },
  ALGOLIA_SEARCH_CONFIG: {
    appId: 'US5AZOW6I2',
    apiKey: '57452bbe0a913fc54683a4a4ce46c5ca',
    productIndex: 'stg2-mojo-product-index',
    categoryIndex: 'stg2-mojo-category-index',
    accountUrl: 'https://pearson-stg2.pearson.com/store/en-us/pearsonplus/account',
    changePassword: 'https://pearson-stg2.pearson.com/store/en-us/pearsonplus/account/change-password',
    resetPassword: `https://login-stg.pearson.com/v1/piapi-int/iesui/forgot-unpw?client_id=${SSI_CLIENT_ID}&login_success_url=${LOCATION_HOST}/home`,
    helpCenter: 'https://pearson-stg2.pearson.com/en-us/pearsonplus/support.html',
    searchQuery: 'https://pearson-stg2.pearson.com/en-us/pearsonplus/search.html?utm_source=authhome&utm_medium=webapp&utm_campaign=search&utm_content=searchbar&aq=',
    preferencesCenter: 'https://pearson-stg2.pearson.com/store/en-us/pearsonplus/account/preferences'
  },
  ADD_A_TITLE_URL: {
    HERO: 'https://pearson-stg2.pearson.com/en-us/pearsonplus/search.html?utm_source=authhome&utm_medium=webapp&utm_campaign=add_title_hero',
    LIBRARY: 'https://pearson-stg2.pearson.com/en-us/pearsonplus/search.html?utm_source=authhome&utm_medium=webapp&utm_campaign=add_title_library&utm_content=addatitle&aq='
  },
  PMC_API_BASE_URL: 'https://hyb-bo-stg2-gold-hyb-ue1.pmc.pearsondev.tech',
  PMC_API_TIMEOUT: 10000,
  LAUNCH_DARKLY_BASE_URL: 'https://app.launchdarkly.com/api/v2',
  LD_ENVIRONMENT: 'staging',
  LD_API_ACCESS_KEY: 'api-6a1d0842-a151-484e-8fd4-9fc2fb1d253d',
  LD_CLIENT_SIDE_ID: '601af2742c6a070a54423c29',
  LD_ANONYMOUS_USER_ID: 'ise_anonymous_stg_user',
  LD_ANONYMOUS_HASH: 'f0e9ee2c87cfbefd1f4e8ec2509e92918e95029b24af63b12b429f0c8b0ad89a',
  BOOKSHELF_CONFIG: {
    checkout: 'https://pearson-stg2.pearson.com/en-us/pearsonplus/pricing.html?utm_source=authhome&utm_medium=display&utm_campaign=XXLEGP0721PLUS&utm_content=',
    addAnother: 'https://pearson-stg2.pearson.com/en-us/pearsonplus/search.html?utm_source=authhome&utm_medium=display&utm_campaign=XXLEGP0721PLUS&utm_content=',
    pmcHeader: 'https://pearson-stg2.pearson.com/en-us/pearsonplus.nav-content.standalone.html',
    pmcSearch: 'https://pearson-stg2.pearson.com',
    purchase: 'https://pearson-stg2.pearson.com/en-us/pearsonplus/search.html',
    logo: 'https://pearson-stg2.pearson.com/en-us/pearsonplus/pricing.html?utm_source=authhome&utm_medium=webapp&utm_campaign=logo&utm_content=logo',
    updatePayment: 'https://pearson-stg2.pearson.com/store/en-us/pearsonplus/account/payment-methods',
    faq: 'https://pearson-stg2.pearson.com/en-us/pearsonplus/support/etext-app.html',
    changePassword: 'https://pearson-stg2.pearson.com/store/en-us/pearsonplus/account/change-password',
    updateProfile: 'https://pearson-stg2.pearson.com/store/en-us/pearsonplus/my-account/update-profile',
    chatbot: {
      url: 'https://pearson--testbau.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
      baseUrl: 'https://pearson--testbau.sandbox.my.salesforce.com',
      supportUrl: 'https://pearson--testbau.sandbox.my.site.com/getsupport',
      id: '00D3O0000006nBx',
      name: 'NAUS_HETS_DTC_CS_Bot',
      baseLiveAgentContentURL: 'https://c.la2-c1cs-fra.salesforceliveagent.com/content',
      deploymentId: '572b0000000ClKC',
      buttonId: '5735p000000sXvm',
      baseLiveAgentURL: 'https://d.la2-c1cs-fra.salesforceliveagent.com/chat',
      eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I5p000000sXuZEAU_17a41f20db5',
      isOfflineSupportEnabled: false,
      eswUrl: 'https://pearson--testbau.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js'
    },
    more: {
      MOJO_TERMS: 'https://pearson-stg2.pearson.com/en-us/pearsonplus/legal/terms-of-use.html',
      MOJO_PRIVACY: 'https://pearson-stg2.pearson.com/en-us/pearsonplus/legal/user-privacy-policy.html',
      MOJO_COOKIES: 'https://pearson-stg2.pearson.com/en-us/pearsonplus/legal/cookie-policy.html',
      MOJO_INFO: 'https://pearson-stg2.pearson.com/en-us/pearsonplus/legal/cookie-policy.html',
      MOJO_ACCESSIBILITY: 'https://pearson-stg2.pearson.com/en-us/pearsonplus/legal/accessibility.html',
      MOJO_NOTICE: 'https://pearson-stg2.pearson.com/en-us/pearsonplus/legal/patent-notice.html',
      MOJO_PEARSON_ACCESSIBILITY: 'https://www.pearson.com/us/higher-education/products-services-teaching/course-content/textbooks-and-etexts/pearson-etext/accessibility.html',
      MOJO_SUPPORT: 'https://pearson-stg2.pearson.com/en-us/pearsonplus/support.html',
      MOJO_APP: 'https://www.pearson.com/en-us/pearsonplus/mobile-app.html',
      COMMUNITY_GUIDELINES: 'https://pearson-stg2.pearson.com/en-us/pearsonplus/community-guidelines.html'
    }
  },
  PRODUCT_TOKEN: '1',
  PLUS_URL: 'https://plus-stg.pearson.com',
  ETEXT_URL: 'https://etext-ise-stg.pearson.com',
  HIDE_APPINFO: false,
  MARKETING_OPT: false,
  IES_BASE_URL:'https://login-stg.pearson.com/v1/piapi-int',
  API_KEY: 'b6e9fef3-7c4a-44f5-accf-0d166ebfc516-02',
  API_SECRET: '78d38578-7de4-45ef-a624-f546ab1b97b7',
  HELP_CENTER_NON_MOJO: 'https://pearson--testbau.sandbox.my.site.com/getsupport/s/',
  CHANNELS_MAPPING_URL: 'https://api.studychannel-dev.pearsondev.tech/v1',
  CHANNELS_MAPPING_URL_TIMEOUT: 10000,
  STUDY_CHANNEL_URL: 'https://pearson-stg2.pearson.com/channels',
  BRAZE_API_KEY: '279126d1-af51-450b-a51c-31b9e5ee30d4',
  BRAZE_BASE_URL: 'sdk.fra-02.braze.eu',
  PENDO_API_KEY: '4cbde5b8-5b8a-448f-4e97-334af64c8427',
  APPSFLYER_BASE_URL: 'https://pearson-plus.onelink.me/TvKb',
  HEI_LEARN_LANGUAGE_URL: 'https://www.mondly.com/offer/highered',
  BERLIN_URL_TIMEOUT: 180000,
  BERLIN: {
    STREAM_BASE_URL: 'https://plus-stg.pearson.com/ber-api/api',
    TENANT_ID: '7408ccc0-86ac-40e5-9d6d-9e1eae93900c',
    TENANT_KEY: 'f917690b-f60f-4f77-93c8-2ef8eacf5d3e'
  },
  SESSION__WARNING_SECONDS: 300,
  APPSFLYERS_STATIC_URL: 'https://pearson-plus.onelink.me/TvKb/e65hpolw'
};

export default base;
