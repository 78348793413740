/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file Header.jsx
 * @author Sarathkumar Venkatachalam, Vignesh Selvam, Sai Kiran
 */

import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import * as constants from '../../Common/constants';
import CloseIcon from '../../Common/icons/CloseIcon';
import messages from '../../defaultMessages';
import { eventAction, gaEvents } from '../../analytics/analytics';
import ChatComponentStyles from './ChatComponentStyles';
import ExpandSquareIcon from '../../Common/icons/ExpandSquareIcon';

const TAB = 'tab';
const Header = ({
  classes, onClose, intl, isExpanded, onExpandCollapse, masterTheme, theme, handleGAEvents, botOpenedTime, isSendButtonEnabled, isNewTopicEnabled, aiStudyToolAutoOpen, showHistory, botFromLeftPanel, aiStudyToolNameABTest, customThemeStyles, customConfig
}) => {
  const { formatMessage } = intl;
  const [show, setShow] = useState(false);
  const [close, setClose] = useState(false);

  const handleClose = () => {
    setClose(false);
    const botUsedTime = new Date().getTime();
    const additionalFields = {
      event_label: aiStudyToolAutoOpen ? constants.GPT_EVENTS.SYSTEM : constants.GPT_EVENTS.CLICKED,
      event_value: null,
      event_action: eventAction.clickClose,
      open_duration: botUsedTime - botOpenedTime
    };
    gaEvents(handleGAEvents, additionalFields);
    // const botUsedTime = new Date(null);
    // botUsedTime.setSeconds(Math.ceil(new Date().getTime() - botOpenedTime) / 1000);
    // const additionalGAFields = {
    //   event_action: eventAction.openTimeDuration,
    //   event_label: null,
    //   event_value: botUsedTime.toISOString().substr(11, 8)
    // };
    // gaEvents(handleGAEvents, additionalGAFields);
    onClose(false);
  };
  const handleExpandKeyDown = (event) => {
    const eventKey = event.key && event.key.toLowerCase();
    if (event.shiftKey && eventKey === TAB) {
      event.preventDefault();
      if (isExpanded) {
        if (isNewTopicEnabled) {
          document.getElementById('clear').focus();
        } else if (isSendButtonEnabled) {
          document.getElementById('send').focus();
        } else {
          document.getElementById('faqLink').focus();
        }
      } else {
        document.getElementById('faqLink').focus();
      }
    }
  };
  const headingText = customConfig?.cannedMessages?.appHeading;
  const showBetaTag = customConfig?.headTag?.show;
  const betaTagText = customConfig?.headTag?.text;
  return (
    <div className={classes.chatHeaderContainer}>
      <div className={classes.chatHeaderContainerLeft}>
        <h1 id="aiStudyToolBot" className={`${classes.chatHeaderContainerLeftHeading} ${botFromLeftPanel ? classes.chatHeaderContainerLeftHeadingLeftPanel : ''}`}>
          {headingText || (aiStudyToolNameABTest ? formatMessage(messages.studyTool) : formatMessage(messages.tutor))}
        </h1>
        {(typeof showBetaTag === 'undefined' || showBetaTag) && (
        <div className={classes.chatHeaderContainerLeftBeta}>
          {betaTagText || formatMessage(messages.beta)}
        </div>
        )}
      </div>
      <div className={classes.chatHeaderContainerRight}>
        <Tooltip
          title={formatMessage(isExpanded ? messages.default : messages.expanded)}
          arrow
          classes={{
            tooltip: classes.tooltipStyleHeader,
            arrow: classes.arrowStyleHeader
          }}
          open={show}
          disableHoverListener
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          <IconButton
            id={isExpanded ? 'collapse_button' : 'expand_button'}
            onClick={() => { onExpandCollapse(); setShow(false); }}
            aria-label={`${isExpanded ? 'Minimize' : 'Fullscreen'}`}
            aria-pressed={isExpanded}
            role="button"
            className={`buttonClickable ${showHistory ? classes.disableClick : classes.enableClick}`}
            onKeyDown={(event) => handleExpandKeyDown(event)}
          >
            <ExpandSquareIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={formatMessage(messages.close)}
          arrow
          classes={{
            tooltip: classes.tooltipStyleHeader,
            arrow: classes.arrowStyleHeader
          }}
          open={close}
          onOpen={() => setClose(true)}
          onClose={() => setClose(false)}
        >
          <IconButton
            id="close_button"
            onClick={handleClose}
            className={`${classes.alignLeft} buttonClickable ${showHistory ? classes.disableClick : ''}`}
            aria-label="Close"
            role="button"
          >
            <CloseIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

Header.defaultProps = {
  isExpanded: false,
  aiStudyToolAutoOpen: false,
  botOpenedTime: 0,
  customThemeStyles: {},
  isSendButtonEnabled: false,
  aiStudyToolNameABTest: false,
  customConfig: {},
  isNewTopicEnabled: false
};

Header.propTypes = {
  intl: intlShape.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  customThemeStyles: PropTypes.object,
  isExpanded: PropTypes.bool,
  aiStudyToolAutoOpen: PropTypes.bool,
  botOpenedTime: PropTypes.number,
  onExpandCollapse: PropTypes.func.isRequired,
  customConfig: PropTypes.object,
  handleGAEvents: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  masterTheme: PropTypes.string.isRequired,
  isSendButtonEnabled: PropTypes.bool,
  aiStudyToolNameABTest: PropTypes.bool,
  isNewTopicEnabled: PropTypes.bool,
  showHistory: PropTypes.bool.isRequired,
  botFromLeftPanel: PropTypes.bool.isRequired
};

export default memo(withStyles(ChatComponentStyles)(injectIntl(Header)));
