/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file Clarify.jsx
 * @author Sarathkumar Venkatachalam, Vignesh Selvam, Sai Kiran
 */

/* eslint-disable */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  Input,
  Checkbox,
  ListItemText,
  InputLabel,
  IconButton,
  Box
} from '@material-ui/core';
import messages from '../../defaultMessages';
import * as constants from '../../Common/constants';
import SearchIcon from '../../Common/icons/SearchIcon';
import CloseIcon from '../../Common/icons/CloseIcon';
import { eventAction, gaEvents } from '../../analytics/analytics';
import ThemeConstants from '../../Common/ThemeConstants';
import { getThemeValue } from '../../Common/CustomStyles/customStyleUtils';

const styles = () => ({
  clarifyContainer: {
    border: 'none',
    padding: 0,
    borderRadius: '18px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    paddingInlineStart:"12px"
  },
  clarifyOptionWrapper: {
    border: 'none',
    padding: 0,
    borderRadius: '18px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    boxSizing: 'border-box'
  },
  quizOptionWrapper: {
    border: 'none',
    padding: 0,
    // minHeight: 184,
    // background: '#e8eefa',
    borderRadius: '18px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    marginTop: 15
  },
  clarifyContainer__question: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: props => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
    margin: 0,
    marginBottom: 8
  },
  clarifyContainer__button: {
    padding: '8px 24px',
    width: 88,
    height: 40,
    background: props => `${(getThemeValue(props, 'secondarySendBtn', 'bgColor', false, 'bgColor'))} !important`,
    border: '1px solid #bcc1cb',
    borderRadius: '24px',
    textTransform: 'none',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    marginTop: 8,
    color: props => (getThemeValue(props, 'secondarySendBtn', 'textColor', false, 'textColor'))
  },
  defaultRadio: {
    width: 20,
    height: 20,
    background: props => `url(${ThemeConstants[props.masterTheme][props.theme].defaultRadio}) center no-repeat !important`
  },
  selectedStyle: {
    // color: props => `${ThemeConstants[props.masterTheme][props.theme].selectedRadioBtnColor} !important`,
    width: 20,
    height: 20,
    background: props => `url(${ThemeConstants[props.masterTheme][props.theme].radioSelectedBG}) center no-repeat !important`
  },
  radioRootStyle: {
    padding: 5,
    alignSelf: 'start',
    color: props => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
    '&:hover': {
      background: props => `${ThemeConstants[props.masterTheme][props.theme].radioHoverColor} !important`
    },
    '&:focus': {
      background: props => `${ThemeConstants[props.masterTheme][props.theme].radioHoverColor} !important`
    },
    '&.Mui-focusVisible': {
      background: props => `${ThemeConstants[props.masterTheme][props.theme].radioHoverColor} !important`
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20
    }
  },
  labelStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    color: props => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
  },
  selectedLabel: {
    fontWeight: '400 !important',
    color: props => `${(getThemeValue(props, 'root', 'textColor', false, 'textColor'))} !important`
  },
  labelRootStyle: {
    padding: 0,
    display: 'flex',
    alignItems: 'center'
  },
  textBoxStyle: {
    maxHeight: 100,
    padding: 0,
    borderBottom: 'none',
    border: '1px solid #BCC1CB',
    backgroundColor: '#FEFEFE',
    borderRadius: 4
  },
  textBoxInputStyle: {
    padding: '10px 16px',
    textAlign: 'center'
  },
  underlineStyle: {
    '&:before': {
      borderBottom: '1px solid #BCC1CB !important'
    },
    '&:hover': {
      borderBottom: 'none !important'
    },
    '&:after': {
      borderBottom: 'none !important'
    }

  }, // TODO Need to Revisit this
  inputStyle: {
    borderBottom: 'none !important'
  },
  selectUnderlineStyle: {
    '&:before': {
      borderBottom: 'none !important'
    },
    '&:hover': {
      borderBottom: '1px solid #BCC1CB !important'
    },
    '&:after': {
      borderBottom: 'none !important'
    }

  }, // TODO Need to Revisit this
  pageRangeWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '50%'
  },
  errorStyle: {
    color: 'red',
    fontFamily: 'TT Commons',
    fontSize: 16,
    marginTop: 5,
    display: 'block'
  },
  alignmentStyle: {
    marginLeft: 10,
    marginRight: 10
  },
  quizHelpText: {
    fontFamily: 'TT Commons',
    display: 'flex',
    marginTop: 15,
    color: props => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chipWrapper: {
    backgroundColor: props => ThemeConstants[props.masterTheme][props.theme].chipBgColor,
    borderRadius: 8,
    marginLeft: 15,
    marginBottom: 5,
    width: 102,
    height: 24,
    display: 'flex',
    padding: '2px 5px 2px 12px',
    alignItems: 'center',
    boxSizing: 'border-box'
  },
  chipStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    width: 65,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    cursor: 'default',
    color: props => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
  },
  selectBoxStyle: {
    width: 288,
    background: props => `${ThemeConstants[props.masterTheme][props.theme].bgColor} !important`,
    border: '1px solid #BCC1CB',
    borderRadius: 4,
    minHeight: 40,
    marginTop: '0 !important',
    '& svg': {
      color: props => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
    }
  },
  selectBoxFocusStyle: {
    '&:focus': {
      backgroundColor: 'transparent !important'
    }
  },
  checkboxStyle: {
    padding: 0,
    color: props => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
    '&:hover': {
      backgroundColor: 'transparent !important',
      color: props => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
    }
  },
  textWrapperStyle: {
    margin: 0,
    '& span': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: 200,
      whiteSpace: 'nowrap',
      paddingLeft: 6,
      fontFamily: 'TT Commons',
      fontSize: 16,
      color: props => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
    }
  },
  shrinkStyle: {
    display: 'none !important'
  },
  outlinedLabelStyle: {
    display: 'flex',
    top: -10,
    alignItems: 'center'
  },
  inputLabelStyle: {
    color: '#020917',
    fontFamily: 'TT Commons',
    fontSize: 16,
    marginLeft: 9,
    color: props => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
  },
  closeIconStyle: {
    width: 9,
    height: 9
  },
  closeButtonStyle: {
    width: 10,
    height: 10,
    padding: 8,
    marginLeft: 5,
    boxSizing: 'border-box'
  },
  checkedStyle: {
    color: '#f50057 !important'
  },
  disabledButtonStyle: {
    opacity: '.5',
    color: props => `${(getThemeValue(props, 'root', 'textColor', false, 'textColor'))} !important`
  },
  menuItemStyle: {
    color: props => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
    backgroundColor: props => `${ThemeConstants[props.masterTheme][props.theme].bgColor} !important`
  },
  formControlStyle: {
    display: 'block',
    marginBottom: 10
  },
  descriptionStyle: {
    display: 'block',
    fontSize: 16,
    fontFamily: 'TT Commons',
    color: props => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
  },
  optionDescription: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: props => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
    margin: 0,
    marginBottom: 8
  }
});
const START = 'start';
const END = 'end';
const Clarify = ({
  classes,
  onRangeSubmit,
  intl,
  type,
  onSend,
  chapterList,
  handleGAEvents,
  hasPrintPageAvailable,
  disableOptions,
  theme,
  masterTheme,
  customConfig,
  isFromFreeFormInput,
  enableTopicInQuizFlow,
  enableAIShortAnswer
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedQuizOption, setSelectedQuizOption] = useState('');
  const [startPageRange, setStartPageRange] = useState('');
  const [endPageRange, setEndPageRange] = useState('');
  const [showPageRange, setShowPageRange] = useState(false);
  const [showChapters, setShowChapters] = useState(false);
  const [pageError, setPageError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const { formatMessage } = intl;
  const [disabled, setDisabled] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState([]);
  const [chapterError, setChapterError] = useState(false);
  const [showQuizOptions, setShowQuizOptions] = useState(true);
  const optionRef = useRef();

  useEffect(()=>{
    optionRef.current.focus();
  },[])
  const handleChange = (event) => {
    const currentValue = event.target.value;
    setSelectedOption(currentValue);
    if (currentValue === constants.PAGE_RANGE) {
      setShowPageRange(true);
      setShowChapters(false);
    } else if (currentValue === constants.CHAPTERS) {
      setShowPageRange(false);
      setShowChapters(true);
    } else if (currentValue === constants.SPECIFIC_TOPIC) {
      setShowPageRange(false);
      setShowChapters(false);
      setShowQuizOptions(false);
    } else {
      setShowPageRange(false);
      setShowChapters(false);
      setShowQuizOptions(true);
    }
  };

  const handleQuizOptionChange = (event) => {
    setSelectedQuizOption(event.target.value);
  }

  const handleChapterChange = (event) => {
    setSelectedChapter(event.target.value);
  };

  const handlePageRange = (event, index) => {
    if (index === START) {
      setStartPageRange(event.target.value);
    } else {
      setEndPageRange(event.target.value);
    }
  }

  const handleSend = () => {
    let eventActionLabel = null;
    // let pageRange = null;
    // let chapterRange = null;
    if (selectedOption === constants.CURRENT_PAGE) {
      eventActionLabel = 'Current Page'
    } else if (selectedOption === constants.PAGE_RANGE) {
      // pageRange = 0;
      // for (let i = startPageRange; i <= endPageRange; i++) {
      //   pageRange = pageRange + 1;
      // }
      eventActionLabel = 'Page Range'
    } else if (selectedOption === constants.CHAPTERS) {
      // chapterRange = selectedChapter.length;
      eventActionLabel = 'Chapters'
    } else if (selectedOption === constants.CURRENT_SECTION) {
      eventActionLabel = 'Current Section'
    } else if (selectedOption === constants.CURRENT_CHAPTER) {
      eventActionLabel = 'Whole chapter'
    } else if (selectedOption === constants.SPECIFIC_TOPIC) {
      eventActionLabel = constants.GPT_EVENTS.TOPIC
    } else {
      eventActionLabel = selectedOption;
    }
    const additionalFields = {
      event_action: eventAction.clickContentRange,
      event_label: eventActionLabel,
      event_value: null,
      command_source_code: 'click'
      // page_range: pageRange,
      // chapter_range: chapterRange
    };
    gaEvents(handleGAEvents, additionalFields);
    gaEvents(handleGAEvents, {
        event_label: null,
        event_value: null,
        event_action: eventAction.clickSend,
        command_source_code: 'click',
        location_in_app: constants.GPT_EVENTS.GPT
      });
    if(type === constants.QUIZ){
      gaEvents(handleGAEvents, {
        event_action: eventAction.clickPacticeFormat,
        event_label: selectedQuizOption === constants.FREE_RESPONSE ? 'Free Response' : 'Multi choice',
        event_value: null,
        command_source_code: 'click'
      });
    }
    if (type === constants.SUMMARY) {
      gaEvents(handleGAEvents, {
        event_action: eventAction.clickSummaryFormat,
        event_label: 'Bullet Points',
        event_value: null,
        command_source_code: 'click'
      });
    }
    if (selectedOption === constants.PAGE_RANGE) {
      if (startPageRange && endPageRange) {
        setPageError(false);
        setHelperText('');
        const pageIndex = {
          startIndex: startPageRange,
          endIndex: endPageRange
        };
        if (type === constants.QUIZ || type === constants.SUMMARY) {
          onSend(type, selectedOption, pageIndex, null, selectedQuizOption);
        } else {
          onRangeSubmit(selectedOption, pageIndex);
        }
        setDisabled(true);
      } else {
        setPageError(true);
        setHelperText(formatMessage(messages.pageRangeError));
      }
    } else if (selectedOption === constants.CHAPTERS) {
      if (selectedChapter.length) {
        setDisabled(true);
        if (type === constants.QUIZ || type === constants.SUMMARY) {
          onSend(type, selectedOption, null, selectedChapter, selectedQuizOption);
        } else {
          onRangeSubmit(selectedOption, null, false, selectedChapter, selectedQuizOption);
        }
      } else {
        setChapterError(true);
      }
    } else if (selectedOption === constants.CURRENT_CHAPTER) {
      if (type === constants.QUIZ || type === constants.SUMMARY) {
        onSend(type, selectedOption, null, selectedChapter, selectedQuizOption, true);
      } else {
        onRangeSubmit(selectedOption, null, false, selectedChapter);
      }
    } else {
      setDisabled(true);
      if (type === constants.QUIZ || type === constants.SUMMARY) {
        onSend(type, selectedOption, null, null, selectedQuizOption, true);
      } else {
        onRangeSubmit(selectedOption);
      }
    }
  };

  const handleChipClose = (value) => {
    const newChaperList = JSON.parse(JSON.stringify(selectedChapter));
    const chapterIndex = newChaperList.indexOf(value);
    newChaperList.splice(chapterIndex, 1);
    setSelectedChapter(newChaperList);
  };

  const getCannedMessages = (config) => {
    const { cannedMessages, contentDetails } = config || {};
    return {
      chapterName: contentDetails?.chapterName ? ` (${contentDetails?.chapterName})` : '',
      sectionName: contentDetails?.sectionName ? ` (${contentDetails?.sectionName})` : '',
      summarizeOptionsHeading: cannedMessages?.summarizeOptionsHeading ? cannedMessages?.summarizeOptionsHeading : '',
      practiceOptionsHeading: cannedMessages?.practiceOptionsHeading ? cannedMessages?.practiceOptionsHeading : '',
      practiceOptionsTypeHeading: cannedMessages?.practiceOptionsTypeHeading ? cannedMessages?.practiceOptionsTypeHeading : '',
    };
  };

  const customMessages = useMemo(() => getCannedMessages(customConfig), [customConfig]);

  const customChapterName = customMessages.chapterName;
  const customSectionName = customMessages.sectionName;
  const customSummarizeOptionsHeading = customMessages.summarizeOptionsHeading;
  const customPracticeOptionsHeading = customMessages.practiceOptionsHeading;
  const customPracticeOptionsTypeHeading = customMessages.practiceOptionsTypeHeading;

  return (
    <div className={classes.clarifyContainer}>
    <fieldset className={classes.clarifyOptionWrapper}>
      {
        isFromFreeFormInput && (
          <Box>
            <Typography variant="paragraph" className={classes.descriptionStyle}>
              I'm sorry, I can't find that topic in this eTextbook.
            </Typography>
            <Typography variant="paragraph" className={classes.descriptionStyle}>
              Try another request or choose a section for me to focus on:
            </Typography>
          </Box>
        )
      }
      {!isFromFreeFormInput && (
        type === constants.SUMMARY ? (
          <legend id="summaryOptionsTitle" className={classes.clarifyContainer__question}>{customSummarizeOptionsHeading || formatMessage(messages.clarifyTitle)}</legend>
        ) : (
          <legend id="summaryOptionsTitle" className={classes.clarifyContainer__question}>{customPracticeOptionsHeading || formatMessage(messages.clarifyQuizTitle)}</legend>
        )
      )
      }

      <RadioGroup role="none" name="options" value={selectedOption} onChange={handleChange}>
        {/* {
          hasPrintPageAvailable && (
            <FormControlLabel
              value={constants.CURRENT_PAGE}
              control={
                <Radio
                  disabled={disabled}
                  checked={selectedOption === constants.CURRENT_PAGE}
                  classes={{
                    checked: classes.selectedStyle,
                    root: classes.radioRootStyle,
                    disabled: classes.disabledButtonStyle
                  }}
                />
              }
              label={formatMessage(messages.currentPage)}
              classes={{
                root: classes.labelRootStyle,
                disabled: classes.disabledButtonStyle,
                label: selectedOption === constants.CURRENT_PAGE ? `${classes.labelStyle} ${classes.selectedLabel}` : classes.labelStyle
              }}
            />
          )
        }
        {
          hasPrintPageAvailable && (
            <FormControlLabel
              value={constants.PAGE_RANGE}
              control={
                <Radio
                  disabled={disabled || !hasPrintPageAvailable}
                  checked={selectedOption === constants.PAGE_RANGE}
                  classes={{
                    checked: classes.selectedStyle,
                    root: classes.radioRootStyle,
                    disabled: classes.disabledButtonStyle
                  }}
                />
              }
              label={formatMessage(messages.pageRange)}
              classes={{
                root: classes.labelRootStyle,
                disabled: classes.disabledButtonStyle,
                label: selectedOption === constants.PAGE_RANGE ? `${classes.labelStyle} ${classes.selectedLabel}` : classes.labelStyle
              }}
            />
          )
        }
        {
          showPageRange && (
            <div className={classes.pageRangeWrapper}>
              <TextField
                id="startpageRange"
                name="startpageRange"
                autoComplete="off"
                disabled={disabled}
                InputProps={{
                  classes: {
                    root: classes.textBoxStyle,
                    input: classes.textBoxInputStyle,
                    underline: classes.underlineStyle
                  }
                }}
                value={startPageRange}
                onChange={event => handlePageRange(event, START)}
              />
              <Typography variant="span" className={classes.alignmentStyle}>{formatMessage(messages.to)}</Typography>
              <TextField
                id="endPageRange"
                name="endPageRange"
                autoComplete="off"
                disabled={disabled}
                InputProps={{
                  classes: {
                    root: classes.textBoxStyle,
                    input: classes.textBoxInputStyle,
                    underline: classes.underlineStyle
                  }
                }}
                value={endPageRange}
                onChange={event => handlePageRange(event, END)}
              />
            </div>
          )
        }
        {
          (showPageRange && pageError) && <Typography variant='span' className={classes.errorStyle}>{helperText}</Typography>
        } */}
        <FormControlLabel
          value={constants.CURRENT_SECTION}
          control={
            <Radio
              inputRef={optionRef}
              disabled={disabled || disableOptions}
              checked={selectedOption === constants.CURRENT_SECTION}
              classes={{
                root: classes.radioRootStyle,
                disabled: classes.disabledButtonStyle
              }}
              icon={<span className={classes.defaultRadio} />}
              checkedIcon={<span className={classes.selectedStyle} />}
            />
          }
          label={formatMessage(messages.currentSection) + customSectionName}
          classes={{
            root: classes.labelRootStyle,
            disabled: classes.disabledButtonStyle,
            label: selectedOption === constants.CURRENT_SECTION ? `${classes.labelStyle} ${classes.selectedLabel}` : classes.labelStyle
          }}
          key="current_section"
        />
        <FormControlLabel
          value={constants.CURRENT_CHAPTER}
          control={
            <Radio
              disabled={disabled || disableOptions}
              checked={selectedOption === constants.CURRENT_CHAPTER}
              classes={{
                root: classes.radioRootStyle,
                disabled: classes.disabledButtonStyle
              }}
              icon={<span className={classes.defaultRadio} />}
              checkedIcon={<span className={classes.selectedStyle} />}
            />
          }
          label={formatMessage(messages.currentChapter) + customChapterName}
          classes={{
            root: classes.labelRootStyle,
            disabled: classes.disabledButtonStyle,
            label: selectedOption === constants.CURRENT_CHAPTER ? `${classes.labelStyle} ${classes.selectedLabel}` : classes.labelStyle
          }}
          key="current_chapter"
          />
          {
            (type === constants.QUIZ && enableTopicInQuizFlow) && (
              <FormControlLabel
                value={constants.SPECIFIC_TOPIC}
                control={
                  <Radio
                    disabled={disabled || disableOptions}
                    checked={selectedOption === constants.SPECIFIC_TOPIC}
                    classes={{
                      root: classes.radioRootStyle,
                      disabled: classes.disabledButtonStyle
                    }}
                    icon={<span className={classes.defaultRadio} />}
                    checkedIcon={<span className={classes.selectedStyle} />}
                  />
                }
                label={formatMessage(messages.specificTopic)}
                classes={{
                  root: classes.labelRootStyle,
                  disabled: classes.disabledButtonStyle,
                  label: selectedOption === constants.SPECIFIC_TOPIC ? `${classes.labelStyle} ${classes.selectedLabel}` : classes.labelStyle
                }}
                key={constants.SPECIFIC_TOPIC}
              />
            )
          }
        {/* <FormControlLabel
          value={constants.CHAPTERS}
          control={
            <Radio
              disabled={disabled}
              checked={selectedOption === constants.CHAPTERS}
              classes={{
                checked: classes.selectedStyle,
                root: classes.radioRootStyle,
                disabled: classes.disabledButtonStyle
              }}
            />
          }
          label={formatMessage(messages.chapters)}
          classes={{
            root: classes.labelRootStyle,
            disabled: classes.disabledButtonStyle,
            label: selectedOption === constants.CHAPTERS ? `${classes.labelStyle} ${classes.selectedLabel}` : classes.labelStyle
          }}
        /> */}
      </RadioGroup>
      </fieldset>
      {
        showChapters && (
          <FormControl variant="outlined" className={classes.formControlStyle}>
            <InputLabel id="chapter-select-title"
              classes={{
                shrink: classes.shrinkStyle,
                outlined: classes.outlinedLabelStyle
              }}
            >
              <SearchIcon masterTheme={masterTheme} theme={theme} />
              <Typography
                variant="span"
                className={classes.inputLabelStyle}
              >
                {formatMessage(messages.findChapters)}
              </Typography>
            </InputLabel>
            <Select
              labelId="chapter-select-title"
              id="chapter-mutiple-chip"
              multiple
              disabled={disabled}
              value={selectedChapter}
              onChange={(event) => {
                handleChapterChange(event);
              }}
              input={<Input
                classes={{
                  underline: classes.selectUnderlineStyle
                }}
                className={classes.inputStyle}
                id="select-multiple-chip"
              />
              }
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {
                    selected.map((value) => (
                      <div className={classes.chipWrapper} onMouseDown={(event) => { event.stopPropagation() }}>
                        <Typography variant='span' className={classes.chipStyle}>{value}</Typography>
                        <IconButton disabled={disabled} onClick={() => { handleChipClose(value); }} className={classes.closeButtonStyle}>
                          <CloseIcon style={classes.closeIconStyle} theme={theme} masterTheme={masterTheme} />
                        </IconButton>
                      </div>
                    ))
                  }
                </div>
              )}
              className={classes.selectBoxStyle}
              classes={{
                select: classes.selectBoxFocusStyle
              }}
            >
              {chapterList.map((chapter) => (
                <MenuItem
                  key={chapter.chapterId}
                  value={chapter.chapterTitle}
                  className={classes.menuItemStyle}
                >
                  <Checkbox
                    className={classes.checkboxStyle}
                    classes={{ checked: classes.checkedStyle }}
                    checked={selectedChapter.indexOf(chapter.chapterTitle) > -1}
                  />
                  <ListItemText className={classes.textWrapperStyle} primary={chapter.chapterTitle} />
                </MenuItem>
              ))
              }
            </Select>
          </FormControl>
        )
      }
      {
        (showChapters && chapterError) && <Typography variant='span' className={classes.errorStyle}>Please select chapter</Typography>
      }
      {(type === constants.QUIZ && showQuizOptions && enableAIShortAnswer) && <fieldset className={classes.quizOptionWrapper}>
        <legend id="quizOptionsTitle" className={classes.optionDescription}>{customPracticeOptionsTypeHeading || formatMessage(messages.quizOptionTitle)}</legend>
        <RadioGroup role="none" name="quizoptions" value={selectedQuizOption} onChange={handleQuizOptionChange}>
          <FormControlLabel
            value={constants.MULTIPLE_CHOICE}
            control={
              <Radio
                checked={selectedQuizOption === constants.MULTIPLE_CHOICE}
                disabled={disableOptions}
                classes={{
                  root: classes.radioRootStyle
                }}
                icon={<span className={classes.defaultRadio} />}
                checkedIcon={<span className={classes.selectedStyle} />}
              />
            }
            label={formatMessage(messages.multipleChoice)}
            classes={{
              root: classes.labelRootStyle,
              label: selectedQuizOption === constants.MULTIPLE_CHOICE ? `${classes.labelStyle} ${classes.selectedLabel}` : classes.labelStyle
            }}
          />
          <FormControlLabel
            value={constants.FREE_RESPONSE}
            control={
              <Radio
                checked={selectedQuizOption === constants.FREE_RESPONSE}
                disabled={disableOptions}
                classes={{
                  root: classes.radioRootStyle
                }}
                icon={<span className={classes.defaultRadio} />}
                checkedIcon={<span className={classes.selectedStyle} />}
              />
            }
            label={formatMessage(messages.freeResponse)}
            classes={{
              root: classes.labelRootStyle,
              label: selectedQuizOption === constants.FREE_RESPONSE ? `${classes.labelStyle} ${classes.selectedLabel}` : classes.labelStyle
            }}
          /> 
        </RadioGroup>
      </fieldset>}
      <Button
        className={classes.clarifyContainer__button}
        onClick={handleSend}
        disabled={!selectedOption || disabled || disableOptions}
        classes={{ disabled: classes.disabledButtonStyle }}
      >
        {formatMessage(messages.send)}
      </Button>
    </div>
  );
};

Clarify.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  onRangeSubmit: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  chapterList: PropTypes.array.isRequired,
  handleGAEvents: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  hasPrintPageAvailable: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  masterTheme: PropTypes.string.isRequired,
  isFromFreeFormInput: PropTypes.bool,
  disableOptions: PropTypes.bool,
  enableTopicInQuizFlow: PropTypes.bool.isRequired,
  enableAIShortAnswer: PropTypes.bool.isRequired
};

Clarify.defaultProps = {
  isFromFreeFormInput: false,
  disableOptions: false,
}

export default withStyles(styles)(injectIntl(Clarify));