/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file HistoryIcon.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import { getComponentThemeValue } from '../CustomStyles/customStyleUtils';

const Styles = () => ({
  historyIcon: {
    width: 20,
    height: 20
  }
});

const HistoryIcon = ({
  classes, theme, masterTheme, customThemeStyles
}) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 16 16"
    classes={{
      root: classes.historyIcon
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.631 4.19994C16.7174 7.8137 15.4792 12.4346 11.8654 14.521C8.25167 16.6074 3.63077 15.3693 1.54436 11.7555C1.32958 11.3835 1.45704 10.9078 1.82904 10.693C2.20105 10.4783 2.67673 10.6057 2.89151 10.9777C4.54836 13.8475 8.2179 14.8307 11.0877 13.1739C13.9574 11.517 14.9407 7.84747 13.2838 4.97772C11.627 2.10796 7.95742 1.12471 5.08766 2.78156C3.95822 3.43365 3.09446 4.41358 2.58801 5.57771H4.50671C4.93626 5.57771 5.28893 5.92594 5.28893 6.35549C5.28893 6.78505 4.93626 7.13327 4.50671 7.13327H0.820991C0.606213 7.13327 0.411769 7.04621 0.271019 6.90546C0.130269 6.76471 0.0432129 6.57027 0.0432129 6.35549V2.6666C0.0432129 2.23705 0.391436 1.88438 0.820991 1.88438C1.25055 1.88438 1.59877 2.23705 1.59877 2.6666V4.10446C2.24959 3.01204 3.17363 2.09043 4.30988 1.43441C7.92365 -0.651996 12.5446 0.586171 14.631 4.19994ZM8.00003 3.82216C8.49095 3.82216 8.88892 4.22013 8.88892 4.71105V8.52541L10.4073 10.0438C10.7532 10.3896 10.7532 10.9503 10.4073 11.2961C10.0615 11.6419 9.50085 11.6419 9.15504 11.2961L7.42879 9.56988C7.23459 9.40683 7.11115 9.16224 7.11115 8.88883V4.71105C7.11115 4.22013 7.50911 3.82216 8.00003 3.82216Z"
      fill={getComponentThemeValue(customThemeStyles, 'root', 'iconColor', false, 'textColor', masterTheme, theme)}
    />
  </SvgIcon>
);

HistoryIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  customThemeStyles: PropTypes.object,
  theme: PropTypes.string,
  masterTheme: PropTypes.string
};

HistoryIcon.defaultProps = {
  theme: 'default',
  masterTheme: 'plus',
  customThemeStyles: {}
};

export default withStyles(Styles)(HistoryIcon);
