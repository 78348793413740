/* eslint-disable no-debugger */
/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file ChatComponentStyles.jsx : A Component that handles the overall chat component styles
 * @author Arifhusain Soudagar
 */

import ThemeConstants from '../../Common/ThemeConstants';
import tutorBody from '../../Common/icons/tutorBody.svg';
import tutorEyes from '../../Common/icons/tutorEyes.svg';
import tutorDefaultEyes from '../../Common/icons/tutorDefaultEyes.svg';
import tutorFace from '../../Common/icons/tutorFace.svg';
import TutorActiveBody from '../../Common/icons/TutorActiveBody';
import tutorActiveEyes from '../../Common/icons/tutorActiveEyes.svg';
import tutorActiveFace from '../../Common/icons/tutorActiveFace.svg';
import { getThemeValue } from '../../Common/CustomStyles/customStyleUtils';

const footerHeight = (props) => ((!props.showChatBotIcon && props.containerHeight) ? `${props.containerHeight}px` : 'calc(100% - 50px)');

const ChatComponentStyles = {
  //* Index styles
  botContainer: {
    height: '100vh'
  },
  container: {
    boxSizing: 'border-box',
    width: 400,
    height: '85%',
    padding: '0px 0px 10px',
    position: 'absolute',
    right: 20,
    bottom: 10,
    border: (props) => ThemeConstants[props.masterTheme][props.theme].borderColor,
    borderRadius: 12,
    boxShadow: '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    zIndex: 1302,
    background: (props) => (getThemeValue(props, 'root', 'backgroundColor', false, 'bgColor')),
    overflowX: 'hidden',
    overflowY: 'hidden'
    // transformOrigin: 'bottom right !important'
  },
  fullScreenStyle: {
    width: '70% !important',
    height: (props) => footerHeight(props),
    left: 96,
    bottom: 0,
    top: 0,
    border: 'none !important',
    borderRadius: '0px 12px 12px 0px !important'
  },
  leftPanelNormalScreenStyle: {
    maxWidth: '396px',
    borderRadius: '0px 16px 16px 0px',
    height: (props) => footerHeight(props),
    left: '98px',
    top: '0px',
    color: '#05112A',
    border: 'none !important',
    boxShadow: 'none',
    zIndex: '1200 !important'
  },
  removeShadow: {
    boxShadow: 'none'
  },
  disableDrag: {
    pointerEvents: 'none'
  },
  playAnimation: {
    animation: '$playAnimation 500ms ease forwards'
  },
  stopAnimation: {
    animation: '$stopAnimation 500ms ease forwards'
  },
  '@keyframes playAnimation': {
    '0%': {
      display: 'flex',
      transform: 'translate(0, 0%)'
    },
    '50%': {
      transform: 'translate(-3%, 0%)'
    },
    '80%': {
      transform: 'translate(-5%, 0%)'
    },
    '100%': {
      transform: 'translate(-7%, 0%)'
    }
  },
  '@keyframes stopAnimation': {
    '0%': {
      display: 'flex',
      transform: 'translate(7%, 0%)'
    },
    '50%': {
      transform: 'translate(5%, 0%)'
    },
    '80%': {
      transform: 'translate(3%, 0%)'
    },
    '100%': {
      transform: 'translate(0%, 0%)'
    }
  },
  expandContainer: {
    width: '75%',
    height: '80%'
  },
  containerPadding: {
    paddingBottom: '8px !important'
  },
  header: {
    // flex: '0.1',
    width: '100%',
    padding: '24px 16px 24px 24px',
    boxSizing: 'border-box',
    cursor: 'move',
    marginBottom: 24,
    borderBottom: (props) => (getThemeValue(props, 'header', 'borderBottm', false, 'borderColor'))

  },
  leftPanelHeader: {
    padding: '24px 16px 20px 24px'
  },
  messageContainer: {
    flex: '1',
    width: '100%',
    padding: '0 10px 10px 24px',
    boxSizing: 'border-box',
    marginBottom: '8px',
    display: 'grid',
    alignItems: 'end',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 7,
      padding: 4,
      height: 7
    },
    '&::-webkit-scrollbar-track': {
      background: (props) => (getThemeValue(props, 'scrollBar', 'bgColor', false, 'bgColor')),
      borderRadius: '0px 4px 4px 0px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: (props) => (getThemeValue(props, 'scrollBar', 'thumbColor', false, 'scrollThumbBgColor')),
      borderRadius: 4,
      width: 6
    }
  },
  inputTextBox: {
    flex: '0.1',
    width: '100%',
    padding: '0px 24px 10px 24px',
    boxSizing: 'border-box',
    position: 'relative'
  },
  ctaButtons: {
    flex: '0.1',
    width: '100%',
    padding: '0px 24px',
    boxSizing: 'border-box'
  },
  footer: {
    width: '100%',
    padding: '12px 24px 0px',
    boxSizing: 'border-box'
  },
  snackBarContainer: {
    background: (props) => (getThemeValue(props, 'snackBar', 'bgColor', false, 'snackBarBgColor')),
    color: (props) => (getThemeValue(props, 'snackBar', 'textColor', false, 'snackBarTextColor'))
  },
  chatInputTextBoxContainer: {
    display: 'flex',
    backgroundColor: (props) => (getThemeValue(props, 'textBox', 'bgColor', false, 'textboxBgColor')),
    '& input': {
      width: '100%',
      boxSizing: 'border-box',
      borderRadius: 4,
      border: '1px solid #bcc1cb',
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '16px',
      display: 'flex',
      color: '#60646d',
      flex: 'none',
      order: 0,
      flexGrow: 1,
      outline: 'transparent',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '12px 16px'
    }
  },
  mathChatInputContainer: {
    border: (props) => ThemeConstants[props.masterTheme][props.theme].textboxBorder,
    alignItems: 'flex-start',
    borderRadius: 5
  },
  mathIconStyle: {
    width: 32,
    height: 32,
    fill: (props) => ThemeConstants[props.masterTheme][props.theme].mathIconFillColor,
    '&:hover': {
      fill: (props) => ThemeConstants[props.masterTheme][props.theme].mathIconHoverColor
    },
    '&:focus': {
      fill: (props) => ThemeConstants[props.masterTheme][props.theme].mathIconHoverColor
    },
    '&:active': {
      fill: (props) => ThemeConstants[props.masterTheme][props.theme].mathIconActiveColor
    }
  },
  iconWrapper: {
    position: 'absolute',
    zIndex: 1301,
    right: -75,
    transformOrigin: 'center'
  },
  '@media screen and (min-width: 1440px)': {
    iconWrapper: {
      right: 35,
      bottom: 40
    },
    botRotate: {
      animation: '$spin 500ms linear 100ms, $moveRightToLeft 500ms cubic-bezier(0,0,0, 1) 100ms'
    },
    '@keyframes moveRightToLeft': {
      '0%': { right: -71 },
      '100%': { right: 35 }
    },
    '@keyframes spin': {
      '0%': { transform: 'rotate(180deg)' },
      '100%': { transform: 'rotate(0deg)' }
    }
  },
  '@media screen and (min-width: 1281px) and (max-width: 1440px)': {
    iconWrapper: {
      right: 23,
      bottom: 31
    },
    botRotate: {
      animation: '$spin 500ms linear 100ms, $moveRightToLeft 500ms cubic-bezier(0,0,0, 1) 100ms'
    },
    '@keyframes moveRightToLeft': {
      '0%': { right: -60 },
      '100%': { right: 23 }
    },
    '@keyframes spin': {
      '0%': { transform: 'rotate(180deg)' },
      '100%': { transform: 'rotate(0deg)' }
    }
  },
  '@media screen and (min-width: 1025px) and (max-width: 1280px)': {
    iconWrapper: {
      right: 7,
      bottom: 27
    },
    botRotate: {
      animation: '$spin 500ms linear 100ms, $moveRightToLeft 500ms cubic-bezier(0,0,0, 1) 100ms'
    },
    '@keyframes moveRightToLeft': {
      '0%': { right: -60 },
      '100%': { right: 11 }
    },
    '@keyframes spin': {
      '0%': { transform: 'rotate(180deg)' },
      '100%': { transform: 'rotate(0deg)' }
    }
  },
  '@media screen and (min-width: 835px) and (max-width: 1024px)': {
    iconWrapper: {
      right: 7,
      bottom: 27
    },
    botRotate: {
      animation: '$spin 500ms linear 100ms, $moveRightToLeft 500ms cubic-bezier(0,0,0, 1) 100ms'
    },
    '@keyframes moveRightToLeft': {
      '0%': { right: -75 },
      '100%': { right: 7 }
    },
    '@keyframes spin': {
      '0%': { transform: 'rotate(180deg)' },
      '100%': { transform: 'rotate(0deg)' }
    }
  },
  '@media screen and (min-width: 415px) and (max-width: 834px)': {
    iconWrapper: {
      right: 7,
      bottom: 31
    },
    botRotate: {
      animation: '$spin 500ms linear 100ms, $moveRightToLeft 500ms cubic-bezier(0,0,0, 1) 100ms'
    },
    '@keyframes moveRightToLeft': {
      '0%': { right: -75 },
      '100%': { right: 7 }
    },
    '@keyframes spin': {
      '0%': { transform: 'rotate(180deg)' },
      '100%': { transform: 'rotate(0deg)' }
    }
  },
  '@media screen and (min-width: 376px) and (max-width: 414px)': {
    iconWrapper: {
      right: -1,
      bottom: 27
    },
    botRotate: {
      animation: '$spin 500ms linear 100ms, $moveRightToLeft 500ms cubic-bezier(0,0,0, 1) 100ms'
    },
    '@keyframes moveRightToLeft': {
      '0%': { right: -22 },
      '100%': { right: -1 }
    },
    '@keyframes spin': {
      '0%': { transform: 'rotate(180deg)' },
      '100%': { transform: 'rotate(0deg)' }
    }
  },
  '@media screen and (max-width: 375px)': {
    iconWrapper: {
      right: -5,
      bottom: 27
    },
    botRotate: {
      animation: '$spin 500ms linear 100ms, $moveRightToLeft 500ms cubic-bezier(0,0,0, 1) 100ms'
    },
    '@keyframes moveRightToLeft': {
      '0%': { right: -22 },
      '100%': { right: -5 }
    },
    '@keyframes spin': {
      '0%': { transform: 'rotate(180deg)' },
      '100%': { transform: 'rotate(0deg)' }
    }
  },
  botRotate: {
    right: -75,
    'animation-fill-mode': 'forwards',
    transform: 'rotate(180deg)'
  },
  textBoxStyle: {
    overflowY: 'auto',
    maxHeight: 100,
    padding: '12px 16px',
    borderRadius: 4,
    fontSize: 14,
    display: 'initial',
    overflowX: 'hidden',
    color: (props) => (getThemeValue(props, 'textBox', 'textColor', false, 'textColor')),
    border: (props) => (getThemeValue(props, 'textBox', 'border', false, 'textboxBorder'))
  },
  textBoxInputStyle: {
    padding: 0,
    width: '100%',
    overflow: 'initial !important',
    '&::placeholder': {
      color: (props) => (getThemeValue(props, 'textBox', 'placeholderColor', false, 'placeHolderTextColor')),
      opacity: '1'
    }
  },
  inputStyle: {
    fontSize: 14
  },
  underlineStyle: {
    '&:before': {
      borderBottom: 'none !important',
      content: 'initial !important'
    },
    '&:after': {
      borderBottom: 'none !important'
    }
  }, // TODO Need to Revisit this
  quizMessage: {
    fontSize: 16,
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
    fontFamily: 'TT Commons',
    display: 'block'
  },
  errorMessage: {
    fontSize: 16,
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
    fontFamily: 'TT Commons',
    '& a': {
      fontSize: 16,
      fontFamily: 'TT Commons',
      color: '#2D9BF0'
    }
  },
  quizErrorMessage: {
    fontSize: 16,
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
    fontFamily: 'TT Commons',
    marginBottom: 10,
    display: 'block'
  },
  chatBotOpen: {
    zIndex: '1301 !important',
    transform: 'scale(1) !important',
    transformOrigin: 'right bottom !important',
    transition: 'transform 0.3s cubic-bezier(0, 0.04, 0, 0.04) 0s !important'
  },
  botWindowStyle: {
    zIndex: '1300 !important'
  },
  chatBotClose: {
    transform: 'scale(0) !important',
    transformOrigin: 'right bottom !important',
    transition: 'transform 0.3s cubic-bezier(0, 0.04, 0, 0.04) 0s !important'
  },
  abChatBotClose: {
    transform: 'scale(0) !important',
    transformOrigin: 'left !important',
    transition: 'transform 0.3s cubic-bezier(0, 0.04, 0, 0.04) 0s !important'
  },
  quizResult: {
    fontSize: 16,
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
    fontFamily: 'TT Commons',
    display: 'block'
  },
  alignmentBottom: {
    marginBottom: 32
  },
  floatingIconTooltipStyle: {
    maxWidth: 400,
    fontFamily: (props) => (getThemeValue(props, 'floatingIconToolTip', 'fontFamily', false, 'toolTipFontFamily')),
    fontSize: (props) => (getThemeValue(props, 'floatingIconToolTip', 'fontSize', false, 'toolTipFontSize')),
    fontWeight: (props) => (getThemeValue(props, 'floatingIconToolTip', 'fontWeight', false, 'toolTipFontWeight')),
    backgroundColor: (props) => (getThemeValue(props, 'floatingIconToolTip', 'bgColor', false, 'toolTipBgColor')),
    color: (props) => (getThemeValue(props, 'floatingIconToolTip', 'textColor', false, 'toolTipTextColor')),
    border: (props) => (`1px solid ${getThemeValue(props, 'floatingIconToolTip', 'bgColor', false, 'toolTipBgColor')}`),
    top: (props) => ((props.customConfig && props.customConfig.floatingIcon && props.customConfig.floatingIcon.toolTipPlacement) ? 0 : 16),
    marginLeft: '-1px'
  },
  floatingIconTooltipArrowStyle: {
    fontSize: 12,
    height: (props) => ((props.customConfig && props.customConfig.floatingIcon && props.customConfig.floatingIcon.toolTipPlacement) ? '1rem !important' : 9),
    marginTop: (props) => ((props.customConfig && props.customConfig.floatingIcon && props.customConfig.floatingIcon.toolTipPlacement) ? '2px !important' : 4),
    '&::before': {
      border: (props) => (`1px solid ${getThemeValue(props, 'floatingIconToolTip', 'bgColor', false, 'toolTipBgColor')}`),
      background: (props) => (getThemeValue(props, 'floatingIconToolTip', 'bgColor', false, 'toolTipBgColor'))
    },
    color: '#161C29',
    marginBottom: 10
  },
  tooltipStyle: {
    maxWidth: 400,
    fontFamily: 'sans-serif',
    fontSize: 14,
    fontWeight: 400,
    backgroundColor: (props) => (getThemeValue(props, 'toolTip', 'bgColor', false, 'toolTipBgColor')),
    color: (props) => (getThemeValue(props, 'toolTip', 'textColor', false, 'toolTipTextColor')),
    border: (props) => (`1px solid ${getThemeValue(props, 'toolTip', 'bgColor', false, 'toolTipBgColor')}`),
    top: (props) => ((props.customConfig && props.customConfig.floatingIcon && props.customConfig.floatingIcon.toolTipPlacement) ? 0 : 16),
    marginLeft: '-1px'
  },
  tooltipStyleHistory: {
    fontFamily: 'sans-serif',
    fontSize: 14,
    backgroundColor: '#161C29',
    color: '#FEFEFE',
    border: '1px solid #161C29',
    top: 3,
    marginLeft: '-5px'
  },
  arrowStyle: {
    fontSize: 12,
    height: 9,
    marginTop: 4,
    '&::before': {
      border: (props) => (`1px solid ${getThemeValue(props, 'toolTip', 'bgColor', false, 'toolTipBgColor')}`),
      background: (props) => (getThemeValue(props, 'toolTip', 'bgColor', false, 'toolTipBgColor'))
    },
    color: '#161C29',
    marginBottom: 10
  },
  stepAlignmentBottom: {
    paddingBottom: 24
  },
  psStepAlignmentBottom: {
    paddingBottom: 10
  },
  //* Cta button styles
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  clearButton: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: ' center',
    justifyContent: 'center',
    letterSpacing: '0.4px',
    width: 130,
    height: 40,
    borderRadius: 24,
    textTransform: 'none',
    fontFamily: 'TT Commons',
    background: (props) => (getThemeValue(props, 'newTopicBtn', 'bgColor', false, 'textboxBgColor')),
    border: (props) => (getThemeValue(props, 'newTopicBtn', 'border', false, 'clearBorderColor')),
    color: (props) => (`${getThemeValue(props, 'newTopicBtn', 'textColor', false, 'textColor')} !important`),
    '&:hover': {
      background: (props) => (getThemeValue(props, 'newTopicBtn', 'bgColor', true, 'historyListHoverColor')),
      color: (props) => (`${getThemeValue(props, 'newTopicBtn', 'textColor', true, 'textColor')} !important`)
    }
  },
  clearButtonWithHistory: {
    paddingLeft: 15,
    width: 169
  },
  sendButton: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 24px',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    width: 88,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 24,
    borderColor: 'transparent',
    textTransform: 'none',
    fontFamily: 'TT Commons',
    marginLeft: 8,
    background: (props) => (getThemeValue(props, 'primarySendBtn', 'bgColor', false, 'primarySendButton')),
    color: (props) => (getThemeValue(props, 'primarySendBtn', 'textColor', false, 'primarySendButtonTextColor')),
    '&:hover': {
      background: 'linear-gradient(135deg, #D6492A 0%, #BA015A 63.44%, #940159 100%)'
    }
  },
  sendButtonDisabled: {
    color: '#FEFEFE !important',
    opacity: '0.4'
  },
  clearButtonDisabled: {
    opacity: '0.4'
  },
  iconsTooltip: {
    padding: 8,
    width: 'fit-content',
    background: '#161C29',
    borderRadius: '4px',
    color: 'white',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.24px'
  },
  tooltipArrow: {
    color: '#161C29'
  },
  welcomeBtnsWrapper: {
    display: 'flex',
    gap: '15px'
  },
  //* WelcomeMessage Styles
  welcomeBtnStyles: {
    height: '32px',
    borderRadius: '8px',
    background: (props) => (getThemeValue(props, 'ctaButtons', 'bgColor', false, 'welcomeBtnsBgColor')),
    color: (props) => (getThemeValue(props, 'ctaButtons', 'textColor', false, 'textColor')),
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.24px',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: (props) => (getThemeValue(props, 'ctaButtons', 'bgColor', true, 'welcomeBtnsBgHoverColor')),
      color: (props) => (getThemeValue(props, 'ctaButtons', 'textColor', true, 'textColor'))
    }
  },
  expandedActionButtonStyle: {
    width: '33%'
  },
  predefinedBtnStyles: {
    maxWidth: '85%',
    borderRadius: '8px',
    backgroundColor: (props) => (getThemeValue(props, 'ctaButtons', 'bgColor', false, 'predefinedBtnsBgColor')),
    color: (props) => (getThemeValue(props, 'ctaButtons', 'textColor', false, 'textColor')),
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.24px',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    textTransform: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: (props) => (getThemeValue(props, 'ctaButtons', 'bgColor', true, 'welcomeBtnsBgHoverColor')),
      color: (props) => (getThemeValue(props, 'ctaButtons', 'textColor', true, 'textColor'))
    },
    '& span': {
      textAlign: 'left',
      margin: '5px 10px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': 2,
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical'
    }
  },
  recommendationButtonWrapperStyle: {
    marginBottom: '0 !important',
    marginTop: '0 !important'
  },
  recommendationWrapper: {
    overflowY: 'initial !important',
    border: 'none !important',
    backgroundColor: 'initial !important',
    width: '100% !important',
    padding: '0 !important'
  },
  predefinedBtnWrapper: {
    marginBottom: 92,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24
  },
  JustifyContent: {
    justifyContent: 'space-between !important'
  },
  btnWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    gap: 10
  },
  welcomeStyle: {
    display: 'block',
    fontSize: 16,
    fontFamily: 'TT Commons',
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
  },
  welcomeWrapper: {
    width: '100%',
    padding: '0 24px 24px 24px',
    overflowY: 'scroll',
    marginBottom: 8,
    boxSizing: 'border-box',
    outline: 'none',
    flex: 1,
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '&::-webkit-scrollbar': {
      width: 7,
      padding: 4
    },
    '&::-webkit-scrollbar-track': {
      background: (props) => (getThemeValue(props, 'scrollBar', 'bgColor', false, 'bgColor')),
      borderRadius: '0px 4px 4px 0px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: (props) => (getThemeValue(props, 'scrollBar', 'thumbColor', false, 'scrollThumbBgColor')),
      borderRadius: 4,
      width: 6
    }
  },
  welcomeWrapperFaded: {
    animation: '$welcomeWrapperFadeout 500ms ease-out'
  },
  '@keyframes welcomeWrapperFadeout': {
    '0%': {
      opacity: 0
    },
    '25%': {
      opacity: 0.25
    },
    '50%': {
      opacity: 0.5
    },
    '100%': {
      opacity: 1
    }
  },
  //* Header Styles
  chatHeaderContainer: {
    display: 'flex',
    width: '100',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    alignItems: 'center',
    position: 'relative',
    zIndex: 100
  },
  chatHeaderContainerLeft: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 304
  },
  chatHeaderContainerLeftHeading: {
    margin: 0,
    fontWeight: 700,
    fontSize: '32px',
    fontFamily: 'TT Commons',
    color: (props) => (getThemeValue(props, 'header', 'textColor', false, 'textColor'))
  },
  chatHeaderContainerLeftHeadingLeftPanel: {
    fontSize: '30px'
  },
  chatHeaderContainerLeftBeta: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: 24,
    borderRadius: '4px',
    fontWeight: 700,
    fontSize: '16px',
    fontFamily: 'TT Commons',
    marginLeft: 12,
    background: (props) => (getThemeValue(props, 'betaTag', 'bgColor', false, 'betaBgColor')),
    color: (props) => (getThemeValue(props, 'betaTag', 'textColor', false, 'betaTextColor'))
  },
  chatHeaderContainerRight: {
    display: 'flex',
    alignItems: 'center'
  },
  tooltipStyleHeader: {
    fontFamily: 'TT Commons',
    fontSize: 14,
    backgroundColor: (props) => (getThemeValue(props, 'toolTip', 'bgColor', false, 'toolTipBgColor')),
    color: (props) => (getThemeValue(props, 'toolTip', 'textColor', false, 'toolTipTextColor')),
    border: (props) => (`1px solid ${getThemeValue(props, 'toolTip', 'bgColor', false, 'toolTipBgColor')}`)
  },
  arrowStyleHeader: {
    fontSize: 12,
    top: 12,
    '&::before': {
      border: (props) => (`1px solid ${getThemeValue(props, 'toolTip', 'bgColor', false, 'toolTipBgColor')}`),
      top: 12,
      background: (props) => (getThemeValue(props, 'toolTip', 'bgColor', false, 'toolTipBgColor'))
    },
    color: (props) => (getThemeValue(props, 'toolTip', 'textColor', false, 'toolTipTextColor'))
  },
  alignLeft: {
    marginLeft: 8,
    pointerEvents: 'all'
  },
  enableClick: {
    pointerEvents: 'all',
    padding: 0,
    background: 'none',
    '&:hover': {
      padding: 0,
      background: 'none'
    }
  },
  disableClick: {
    pointerEvents: 'none'
  },
  //* Footer Styles
  footerContainer: {
    fontSize: '14px',
    fontFamily: 'TT Commons',
    textAlign: 'left'
  },
  footerText: {
    color: '#60646D',
    fontFamily: 'TT Commons',
    fontSize: '14px'
  },
  footerSubText: {
    color: '#60646D',
    fontFamily: 'TT Commons',
    fontSize: '14px'
  },
  linkText: {
    color: (props) => (getThemeValue(props, 'anchorLink', 'textColor', false, 'faqColor')),
    textDecoration: 'underline',
    fontFamily: 'TT Commons',
    fontSize: '14px'
  },
  stopRegenerationBtn: {
    background: '#E8EEFA',
    color: '#020917',
    padding: '6px 12px 6px 12px',
    borderRadius: '8px',
    position: 'absolute',
    top: -50,
    left: '33%',
    zIndex: 99999,
    textTransform: 'none',
    fontFamily: 'TT Commons',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.24px',
    '&:hover': {
      zIndex: 99999,
      fontSize: '16px',
      padding: '6px 12px 6px 12px',
      background: '#E8EEFA',
      color: '#020917',
      fontFamily: 'TT Commons',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.24px'
    }
  },
  suggestionText: {
    fontFamily: 'TT Commons',
    fontSize: '16px',
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
    marginBottom: 10
  },
  suggestionActionContainer: {
    display: 'flex'
  },
  bodyIcon: {
    background: `url(${tutorBody}) no-repeat`,
    width: 80,
    height: 80,
    position: 'relative'
  },
  faceIcon: {
    background: `url(${tutorFace}) no-repeat`,
    width: 54,
    height: 54,
    position: 'absolute',
    top: 11,
    left: 13
  },
  eyeIcon: {
    background: `url(${tutorEyes}) no-repeat`,
    width: 50,
    height: 48,
    position: 'absolute',
    animation: '$botIconAnimation 5s ease infinite'
  },
  bodyActiveIcons: {
    background: `url(data:image/svg+xml;base64,${window.btoa(TutorActiveBody)}) no-repeat center center`,
    width: 80,
    height: 80,
    position: 'relative',
    transform: 'scale(1.2)',
    '-ms-transform': 'scale(1.2)',
    '-moz-transform': 'scale(1.2)',
    '-webkit-transform': 'scale(1.2)',
    '-o-transform': 'scale(1.2)',
    filter: 'drop-shadow(0 0 0.25rem #62FFEEC2)'
  },
  faceActiveIcons: {
    background: `url(${tutorActiveFace}) no-repeat center center`,
    width: 50,
    height: 50,
    position: 'absolute',
    borderRadius: '50%',
    top: 15,
    left: 15
  },
  eyeActiveIcons: {
    background: `url(${tutorActiveEyes}) no-repeat`,
    position: 'absolute',
    animation: '$botIconActiveAnimation 5s ease infinite'
  },
  '@keyframes botIconAnimation': {
    '0%': {
      background: `url(${tutorDefaultEyes}) no-repeat`,
      top: 3,
      left: -1
    },
    '5%': {
      background: `url(${tutorEyes}) no-repeat`,
      top: 3,
      left: 3
    },
    '95%': {
      background: `url(${tutorEyes}) no-repeat`,
      top: 3,
      left: 3
    },
    '100%': {
      background: `url(${tutorDefaultEyes}) no-repeat`,
      top: 3,
      left: -1
    }
  },
  '@keyframes botIconActiveAnimation': {
    '0%': {
      background: `url(${tutorDefaultEyes}) no-repeat`,
      width: 44,
      height: 48,
      top: 1,
      left: -1
    },
    '5%': {
      background: `url(${tutorActiveEyes}) no-repeat`,
      width: 50,
      height: 48,
      top: 1,
      left: 2
    },
    '95%': {
      background: `url(${tutorActiveEyes}) no-repeat`,
      width: 50,
      height: 48,
      top: 1,
      left: 2
    },
    '100%': {
      background: `url(${tutorDefaultEyes}) no-repeat`,
      width: 44,
      height: 48,
      top: 1,
      left: -1
    }
  },
  botIconStyle: {
    '&:hover': {
      backgroundColor: 'initial'
    }
  },
  hideChatbotContainer: {
    visibility: 'hidden'
  },
  validationMessageStyle: {
    fontSize: 14,
    fontWeight: '400',
    marginTop: 8,
    marginLeft: 15,
    color: '#FF6767 !important',
    fontStyle: 'italic',
    lineHeight: '16px',
    letterSpacing: '0.24px',
    fontFamily: 'TT Commons'
  },
  visuallyHidden: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 1,
    display: 'block'
  },
  scrollArrowButtonStyle: {
    padding: 6,
    position: 'absolute',
    left: 'calc((100% - 54px) / 2)',
    top: -65,
    zIndex: 99999,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  functionIconStyle: {
    padding: 5,
    margin: '3px 3px 3px 0',
    background: 'transparent',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)'
    },
    '&:focus': {
      background: '#CCC',
      fill: (props) => ThemeConstants[props.masterTheme][props.theme].mathIconHoverColor
    },
    '&:active': {
      fill: (props) => ThemeConstants[props.masterTheme][props.theme].mathIconActiveColor,
      backgroundColor: (props) => ThemeConstants[props.masterTheme][props.theme].mathIconActiveBgColor
    }
  },
  clearButtonWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: 10
  },
  historyButtonStyle: {
    padding: 9,
    border: (props) => (getThemeValue(props, 'historyBtn', 'border', false, 'clearBorderColor')),
    backgroundColor: (props) => (getThemeValue(props, 'historyBtn', 'bgColor', false, 'textboxBgColor')),
    '&:hover': {
      backgroundColor: (props) => (getThemeValue(props, 'historyBtn', 'bgColor', true, 'textboxBgColor'))
    }
  },
  historyIconWrapper: {
    paddingRight: 8,
    top: 8,
    left: 35
  },
  historyWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 350,
    height: '100%',
    zIndex: 999999,
    backgroundColor: (props) => (getThemeValue(props, 'root', 'backgroundColor', false, 'historyDrawerBgColor')),
    filter: 'drop-shadow(0 40px 64px #414E651A)',
    boxShadow: '16px 0px 24px 0px rgba(65, 78, 101, 0.10)'
  },
  expandedHistoryWrapper: {
    width: '50%'
  },
  blurEffect: {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(140, 144, 153, 0.4)',
    backdropFilter: 'blur(3px)',
    borderRadius: 6,
    zIndex: 99999
  },
  openHistoryContainer: {
    animation: '$leftToRight 0.25s ease-out',
    animationDirection: 'normal',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
    transform: 'translateX(-100%)'
  },
  closeHistoryContainer: {
    animation: '$rightToLeft 0.15s ease-in',
    animationDirection: 'normal',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
    transform: 'translateX(0%)'
  },
  '@keyframes leftToRight': {
    to: {
      transform: 'translateX(0%)'
    }
  },
  '@keyframes rightToLeft': {
    to: {
      transform: 'translateX(-100%)'
    }
  },
  addBlur: {
    pointerEvents: 'none'
  },
  mathInput: {
    width: '100%',
    padding: '15px 10px',
    maxHeight: 80,
    overflowY: 'auto',
    outline: 'none !important',
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
  },
  alignmentTop: {
    marginTop: 0
  }
};

export default ChatComponentStyles;
