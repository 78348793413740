/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import Framework from '@greenville/framework';

import SessionWarning from '../sessionWarning';
import * as constants from '../../../common/constants';
import CommonUtils from '../../../common/utils/CommonUtils';

/**
 * @author Mohamed yasar arabath M
 */
export default class SessionWarningPlugin {
  constructor(context) {
    this.context = context;
    this.language = this.context.props.language;
    this.context.state = {
      ...context.state,
      isSessionTimeOut: false,
      showSessionWaring: false
    };
    this.isIntergratedMLMLaunch = CommonUtils.isIntergratedMLMLaunch();
    this.isLogOutDirty = false;
    if (!this.isIntergratedMLMLaunch) {
      this.context.commonPlugin.subscribe('ldFetchSuccess', () => {
        let success = false;
        if (this.context.state.LDUserFlagsFetched) {
          this.init();
          success = true;
        }

        return success;
      });
    }
  }

  stateChangeListener = () => {
    this.context.commonPlugin.onBroadCast();
  }

  init = () => {
    if (this.context.state.flags.enableSessionTimeoutWarn && window.piSession) {
      window.piSession.on(window.piSession.SessionTimedOutEvent, () => {
        window.piSession.setOptions({
          requireLogin: false
        });
        this.context.setState({
          isSessionTimeOut: true,
          showSessionWaring: true
        });
      });
      window.piSession.on(window.piSession.SessionTimingOutEvent, () => {
        const { AUTHHOME_APPLICATION, EVENT_USER_INTERACTION, SESSION_EVENT } = constants;
        const customFields = {
          command_source_code: SESSION_EVENT.SYSTEM_FIELD
        };
        CommonUtils.dispatchGaEvent(AUTHHOME_APPLICATION, EVENT_USER_INTERACTION, SESSION_EVENT.SYSTEM_DISPLAY_TIMEOUT_EVENT_ACTION, SESSION_EVENT.INACTIVITY_EVENT_LABEL, customFields);
        this.context.setState({
          isSessionTimeOut: false,
          showSessionWaring: true
        });
      });
      window.piSession.on(window.piSession.SessionRejuvenatedEvent, () => {
        this.context.setState({
          isSessionTimeOut: false,
          showSessionWaring: false
        });
      });
      window.piSession.on(window.piSession.LogoutEvent, () => {
        if (!this.isLogOutDirty && !this.context.state.isSessionTimeOut) {
          Framework.logout(true);
        }
      });
    } else if (!this.context.state.flags.enableSessionTimeoutWarn && window.piSession) {
      window.piSession.on(window.piSession.SessionTimedOutEvent, () => {
        Framework.logout();
      });
    }
  }

  /**
   * return modal object to populate the Session Inactivity Props
   *
   *
   * @returns
   */
  getSessionInactivityProps() {
    const sessionTimeoutModalProps = {
      title: this.language.getMessage('sessionInactivity.title'),
      description: this.language.getMessage('sessionInactivity.description'),
      actions: [
        {
          text: this.language.getMessage('sessionInactivity.signOut'),
          isSecondary: true,
          callback: () => {
            this.isLogOutDirty = true;
            Framework.logout();
          }
        },
        {
          text: this.language.getMessage('sessionInactivity.keepMeSignIn'),
          isSecondary: false,
          callback: () => {
            if (window.piSession) {
              window.piSession.recordUserActivity();
            }
          }
        }
      ],
      onClose: () => {
        if (window.piSession) {
          window.piSession.recordUserActivity();
        }
      }
    };

    return sessionTimeoutModalProps;
  }

  /**
   * return modal object to populate the Session TimeOut Props
   *
   *
   * @returns
   */
  getSessionTimeOutProps() {
    const sessionTimeoutModalProps = {
      title: this.language.getMessage('sessionTimeout.title'),
      description: this.language.getMessage('sessionTimeout.description'),
      actions: [
        {
          text: this.language.getMessage('device.OKAY'),
          isSecondary: false,
          callback: () => Framework.logout(true)
        }
      ],
      onClose: () => Framework.logout(true)
    };
    return sessionTimeoutModalProps;
  }

  /**
   * return modal object to populate the Session modal
   *
   *
   * @returns
   */
  getSessionModalData(isSessionTimeOut) {
    return isSessionTimeOut ? this.getSessionTimeOutProps() : this.getSessionInactivityProps();
  }

  renderSessionWarning = () => {
    this.stateChangeListener();
    return !this.isIntergratedMLMLaunch && this.context.state.showSessionWaring
    && (
    <SessionWarning
      location={this.context.commonPlugin.location}
      data={this.getSessionModalData(this.context.state.isSessionTimeOut)}
      isSessionTimeOut={this.context.state.isSessionTimeOut}
    />
    );
  }
}
