/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file DisLikeIcon.jsx
 * @author Sarathkumar Venkatachalam, Vignesh Selvam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import { getComponentThemeValue } from '../CustomStyles/customStyleUtils';

const Styles = () => ({
  dislikeIcon: {
    width: 21,
    height: 20
  }
});

const DisLikeIcon = ({
  classes, theme, masterTheme, customThemeStyles
}) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 21 20"
    classes={{
      root: classes.dislikeIcon
    }}
  >
    <path
      d="M16.175 0.62495V13.825L10.55 19.575C10.3233 19.8083 10.0551 19.9458 9.74532 19.9875C9.43556 20.0292 8.83212 19.9667 8.54501 19.8C8.26167 19.6333 7.55667 19.4042 7.41501 19.1125C7.27334 18.8208 7.23334 18.5167 7.30501 18.2L8.57501 13.825H5.1875H1.6C1.2 13.825 0.849999 13.675 0.549999 13.375C0.249999 13.075 0.1 12.525 0.1 12.125V10.079C0.1 9.95967 0.0950069 9.84223 0.0950069 9.7125C0.0950069 9.58276 0.1 9.49292 0.1 9.35L3.25 2.1C3.39797 1.74583 3.64459 1.24287 3.98987 0.994951C4.33514 0.747035 4.69685 0.62495 5.075 0.62495H16.175ZM14.375 12.625V2.62495H5.00501L1.89501 9.8V11.825H11.125L9.29501 17.855L14.375 12.625ZM19.5 0.62495C19.9125 0.62495 20.2656 0.771201 20.5594 1.06495C20.8531 1.3587 21 1.9125 21 2.325V12.125C21 12.5375 20.8531 13.0912 20.5594 13.385C20.2656 13.6787 19.9125 13.825 19.5 13.825H16.175V12.125H19.195V2.62495H16.175V0.62495H19.5Z"
      fill={getComponentThemeValue(customThemeStyles, 'root', 'iconColor', false, 'textColor', masterTheme, theme)}
    />
  </SvgIcon>
);

DisLikeIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.string,
  customThemeStyles: PropTypes.object,
  masterTheme: PropTypes.string
};

DisLikeIcon.defaultProps = {
  theme: 'default',
  customThemeStyles: {},
  masterTheme: 'plus'
};

export default withStyles(Styles)(DisLikeIcon);
