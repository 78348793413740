/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file analytics.js
 * @author Arifhusain Soudagar
 */

import { BOT_EVENTS } from '../common/constants';

const event = {
  eventName: 'gptevent',
  eventCategory: 'GPT'
};
const studyChannelEvent = {
  eventName: 'studychannelsevent',
  eventCategory: 'Study Channels'
};

//* ****** Analytic Event Actions ****** *//

export const eventAction = {
  //* ****** Below events are from Instrumentation sheet ****** *//
  /** Event ID - 501 * */ userSeesSubscriptionPopUp: 'User sees Subscription Pop Up', //* PASS
  /** Event ID - 501 * */ clickSubscriptionWall: 'Click Subscription wall', //* PASS
  /** Event ID - 531 * */ clickChatGpt: 'Click Chat GPT', //* PASS
  /** Event ID - 532 * */ clickChatType: 'Click Chat Type', //* PASS
  /** Event ID - 533 * */ clickClearChat: 'Click Clear Chat', //* PASS
  /** Event ID - 534 * */ clickRegenerate: 'Click Regenerate', // TODO : UI is removed
  /** Event ID - 535 * */ clickSuggestion: 'Click Suggestions', // TODO : UI is removed
  /** Event ID - 536 * */ selectOutputType: 'Select Output type', //* PASS
  /** Event ID - 537 * */ clickHereIsWhyLink: 'Click Link', // TODO : UI is removed
  /** Event ID - 538 * */ clickThumbs: 'Click thumbs', //* PASS
  /** Event ID - 539 * */ dragObject: 'Drag object', //* PASS
  /** Event ID - 540 * */ clickExpandOrRestore: 'Click expand/restore', //* PASS
  /** Event ID - 541 * */ clickClose: 'Click close', //* PASS
  /** Event ID - 542 * */ clickMoreLikeThis: 'Click More Like This', // TODO: Yet to implement
  /** Event ID - 543 * */ clickOnEdit: 'Click on edit', // TODO: Yet to implement
  /** Event ID - 544 * */ clickSend: 'Click Send', //* PASS
  /** Event ID - 545 * */ clickUpdate: 'Click Update', // TODO: Yet to implement
  /** Event ID - 546 * */ clickSummaryFormat: 'Click Summary Format', //* PASS
  /** Event ID - 547 * */ clickPacticeFormat: 'Click Practice Format', //* PASS
  /** Event ID - 548 * */ clickContentRange: 'Click Content Range', //* PASS
  /** Event ID - 549 * */ clickSubmitNegativeFeedback: 'Click Submit Negative Feedback', //* PASS
  /** Event ID - 551 * */ clickFormatIcon: 'Click Format Icon', //* PASS
  /** Event ID - 557 * */ clickTopicSuggestions: 'Click topic suggestion', //* PASS
  /** Event ID - 558 * */ startChatbotConversation: 'Start chatbot conversation', //* PASS
  /** Event ID - 559 * */ answerPracticeQuestion: 'Answer practice question', //* PASS
  /** Event ID - 560 * */ aiErrorMessage: 'AI error message', //* PASS
  /** Event ID - 562 * */ openTimeDuration: 'Open time duration', //* PASS
  /** Event ID - 563 * */ skipButton: 'Click skip', //* PASS
  /** Event ID - 577 * */ triggerIntention: 'Trigger intention', //* PASS
  /** Event ID - 578 * */ longerTimeResponse: 'Longer time to response', //* PASS
  /** Event ID - 592 * */ clickChatContinuation: 'Click Chat Continuation', //* PASS
  /** Event ID - 595 * */ outputSuccess: 'Output Success', //* PASS
  /** Event ID - 599 * */ selectVideoScreenSize: 'Select Video Screen Size', // TODO do not have a callback for click - ON-HOLD
  //* Study channel events * */
  /** Event ID - 296 * */ selectVideo: 'Select Video', //* PASS
  /** Event ID - 296 * */ watchedSeconds: 'Watched X Seconds', //* PASS
  /** Event ID - 296 * */ assetComplete: 'Asset Complete', //* PASS
  /** Event ID - 628 * */ selectContinuousSuggestion: 'Select continuous suggestion', //* PENDING
  /** Event ID - 629 * */ continuousSuggestionShown: 'continuous suggestion shown', //* PENDING
  /** Event ID - 636 * */ ClickGlossaryTerm: 'Click glossary term', //* PASS
  /** Event ID - 659 * */ openChatHistory: 'Open Chat History', //* PASS
  /** Event ID - 660 * */ closeChatHistory: 'Close Chat History', //* PASS
  /** Event ID - 661 * */ inputTextForChatHistoryFilter: 'Input Text for Chat History Filter', //* PASS
  /** Event ID - 662 * */ openThreadFromChatHistory: 'Open Thread From Chat History', //* PASS
  /** Event ID - 663 * */ confirmRenameOfThreadInChatHistory: 'Confirm Rename of Thread in Chat History', //* PASS
  /** Event ID - 664 * */ deleteThreadInChatHistory: 'Delete Thread in Chat History', //* PASS
  /** Event ID - 665 * */ undoDeleteThreadInChatHistory: 'Undo Delete Thread in Chat History', //* PASS
  /** Event ID - 685 * */ openMathKeyboard: 'Click to Open Math Keyboard',
  /** Event ID - 686 * */ keyPressInMathKeyboard: 'Press First key In Math Keyboard',
  /** Event ID - 687 * */ sendMathTextFromMathKeyboard: 'Send Math from Math Keyboard to Text Input',
  /** Event ID - 703 * */ userClicksOptOutFeature: 'User clicks opt in/out of features',
  /** Event ID - 704 * */ userSeesOptOutFeature: 'User sees opt in/out link of features',
  /** Event ID - 705 * */ clickOnReferenceLink: 'Click on reference link',
  /** Event ID - 423 * */ openChannelsFromEReader: 'Open Channels from eReader',
  /** Event ID - 656 * */ fetchChannelsSearchResults: 'Fetch Channels Search Results',
  /** Event ID - 758 * */ clickMediaCard: 'Click Media Card',
  /** Event ID - 770 * */ clickMoreActions: 'Click More Actions'
};

//* ****** Analytic Event common fields ****** *//
const commonFields = {
  event: event.eventName,
  event_category: event.eventCategory,
  location_in_app: 'GPT',
  transaction_local_dt: new Date().toISOString().replace('Z', '')
};

//* ****** Analytic Event Call ****** *//
export const gaEvents = (callback, additionalFields) => {
  callback(BOT_EVENTS, {
    ...commonFields,
    ...additionalFields
  });
};

//* ****** Analytic Event common fields Study Channel ****** *//
export const studyChannelCommonFields = {
  event: studyChannelEvent.eventName,
  event_category: studyChannelEvent.eventCategory,
  transaction_local_dt: new Date().toISOString().replace('Z', '')
};

//* ****** Analytic Event Call Study Channel ****** *//
export const gaEventsStudyChannel = (callback, studyChannelAdditionalFields) => {
  callback(BOT_EVENTS, {
    ...studyChannelCommonFields,
    ...studyChannelAdditionalFields
  });
};
