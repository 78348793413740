/* eslint-disable no-unused-vars */
/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file SummaryListContainer.jsx
 * @author Sarathkumar Venkatachalam, Vignesh Selvam, Sai Kiran
 */

import React, {
  useState, useRef, useEffect, useLayoutEffect
} from 'react';
import { intlShape, injectIntl } from 'react-intl';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  ClickAwayListener,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Typography,
  MenuItem,
  Menu,
  Button
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Utilities } from '@aquila/core';
import * as constants from '../../Common/constants';
import ReadMore from './ReadMore';
import PositiveFeedback from '../../Components/FeedbackComponent/PositiveFeedback';
import NegativeFeedback from '../../Components/FeedbackComponent/NegativeFeedback';
import MoreMenuIcon from '../../Common/icons/MoreMenuIcon';
import LikeIcon from '../../Common/icons/LikeIcon';
import LikeIconFilled from '../../Common/icons/LikeIconFilled';
import DisLikeIcon from '../../Common/icons/DisLikeIcon';
import DisLikeIconFilled from '../../Common/icons/DisLikeIconFilled';
import messages from '../../defaultMessages';
import FlashCardIcon from '../../Common/icons/FlashCardIcon';
import NoteIcon from '../../Common/icons/NoteIcon';
import CopyIcon from '../../Common/icons/CopyIcon';
// import RegenerateIcon from '../../Common/icons/RegenerateIcon';
import Utils from '../../Common/Utils';
import { eventAction, gaEvents } from '../../analytics/analytics';
import ThemeConstants from '../../Common/ThemeConstants';
import FormatChange from '../../Components/FeedbackComponent/FormatChange';
import { clickMoreActionsGAEvent, clickThumbsGAEvent } from '../../analytics/analyticsEvents';
import LoaderIcon from '../../Common/icons/LoaderIcon';
import { getThemeValue } from '../../Common/CustomStyles/customStyleUtils';

const styles = () => ({
  orderList: {
    fontSize: '16px',
    paddingInlineStart: 20,
    margin: 0,
    lineHeight: '24px',
    listStyleType: 'disc',
    '& li': {
      fontSize: 16,
      fontFamily: 'TT Commons',
      color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
      marginBottom: 5
    }
  },
  moreIconStyle: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5
  },
  feedbackButton: {
    padding: 8
  },
  moreActionList: {
    border: '1px solid #BCC1CB',
    borderRadius: 4,
    margin: '0 auto',
    listStyle: 'none',
    background: (props) => (getThemeValue(props, 'menuList', 'bgColor', false, 'bgColor')),
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 5px rgb(0 0 0 / 12%)',
    width: 240
  },
  iconRootStyle: {
    minWidth: 20,
    marginRight: 7
  },
  textRootStyle: {
    fontSize: 16,
    fontFamily: 'TT Commons',
    color: (props) => (getThemeValue(props, 'menuList', 'textColor', false, 'textColor'))
  },
  regenerateStyle: {
    fontSize: 16,
    fontFamily: 'TT Commons',
    fontWeight: 700,
    color: '#020917',
    marginRight: 5
  },
  poperStyle: {
    zIndex: '99999 !important'
  },
  iconsTooltip: {
    padding: 8,
    width: 'fit-content',
    background: (props) => (getThemeValue(props, 'toolTip', 'bgColor', false, 'toolTipBgColor')),
    color: (props) => (getThemeValue(props, 'toolTip', 'textColor', false, 'toolTipTextColor')),
    border: (props) => (`1px solid ${getThemeValue(props, 'toolTip', 'bgColor', false, 'toolTipBgColor')}`),
    borderRadius: '4px',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.24px'
  },
  summaryTitle: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontSize: 16,
    fontWeight: 700,
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
    margin: '12px 0'
  },
  lessContentStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
  },
  summaryDescription: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontSize: 16,
    marginBottom: 10,
    display: 'block',
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
  },
  tooltipArrow: {
    color: (props) => (getThemeValue(props, 'toolTip', 'bgColor', false, 'toolTipBgColor'))
  },
  listItemRootStyle: {
    height: 24,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 16
  },
  listAlignment: {
    marginBottom: 0
  },
  formatWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  formatStyle: {
    color: (props) => ThemeConstants[props.masterTheme][props.theme].formatColor,
    fontSize: 14,
    fontFamily: 'TT Commons'
  },
  alignmentRight: {
    textAlign: 'right'
  },
  quizDescription: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontSize: 16,
    margin: '16px 0px 16px 0px',
    display: 'block',
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
  },
  buttonStyle: {
    height: '32px',
    width: '50%',
    borderRadius: '8px',
    backgroundColor: (props) => (getThemeValue(props, 'ctaButtons', 'bgColor', false, 'welcomeBtnsBgColor')),
    color: (props) => (getThemeValue(props, 'ctaButtons', 'textColor', false, 'textColor')),
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: '0.24px',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    textTransform: 'none',
    border: (props) => (getThemeValue(props, 'ctaButtons', 'border', false, 'welcomeBtnsBorderColor')),
    '&:hover': {
      backgroundColor: (props) => (getThemeValue(props, 'ctaButtons', 'bgColor', true, 'welcomeBtnsBgHoverColor')),
      color: (props) => (getThemeValue(props, 'ctaButtons', 'textColor', true, 'textColor'))
    }
  },
  alignRight: {
    marginRight: 12
  },
  summaryQuizActionWrapper: {
    marginTop: 15,
    display: 'flex',
    boxSizing: 'border-box',
    width: '100%'
  },
  summaryListContainer: {
    '& table': {
      fontFamily: 'TT Commons',
      borderCollapse: 'collapse',
      width: 350,
      marginTop: 20,
      marginBottom: 20,
      overflowX: 'auto',
      display: 'block'
    },
    '& td': {
      border: '1px solid #dddddd',
      textAlign: 'left',
      padding: 8,
      color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
    },
    '& th': {
      border: '1px solid #dddddd',
      textAlign: 'left',
      padding: 8,
      color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
    },
    '& tr:nth-child(even)': {
      backgroundColor: (props) => ThemeConstants[props.masterTheme][props.theme].welcomeBtnsBgColor
    }
  }
});

const SummaryListContainer = ({
  classes,
  intl,
  bookId,
  summaryData,
  optionSelected,
  handleChatGPTCustomNote,
  pageRange,
  onRegenerate,
  selectedChapter,
  pageId,
  selectedOptionType,
  chapterList,
  pageList,
  handleGAEvents,
  handleSubmitFeedback,
  theme,
  masterTheme,
  isFromCSMenu,
  botData,
  onFormatChange,
  showFormat,
  onCopy,
  lessContent,
  showHeader,
  onFlashCardClick,
  pageNums,
  pagesRange,
  selectedContent,
  chapterId,
  chapterTitle,
  topicTitle,
  sourceType,
  feedbackIconFocusId,
  chapterData,
  showSummaryQuiz,
  onSummaryQuiz,
  onSummaryMoveOn,
  summaryQuizButton,
  isExpanded,
  AIEnableMarkdown,
  showPractice,
  customThemeStyles
}) => {
  const { formatMessage } = intl;
  const [showMoreActionOptions, setMoreActionOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPositiveEl, setAnchorPositiveEl] = useState(null);
  const [anchorNegativeEl, setAnchorNegativeEl] = useState(null);
  const [showPositiveFeedbackPopUp, setShowPositiveFeedbackPopUp] = useState(false);
  const [showNegativeFeedbackPopUp, setShowNegativeFeedbackPopUp] = useState(false);
  const [isFormat, setIsFormat] = useState(showFormat);
  const [postiveFeedbackFlag, setPostiveFeedbackFlag] = useState(false);
  const [negativeFeedbackFlag, setNegativeFeedbackFlag] = useState(false);
  const [feedbackResponse, setFeedbackResponse] = useState({
    postiveFeedBackSubmitted: false, negativeFeedBackSubmitted: false
  });
  const [feedbackPressed, setFeedbackPressed] = useState({
    postiveFeedBackPressed: false, negativeFeedBackPressed: false
  });
  const [isClickAway, setIsClickAway] = useState(false);
  const [showQuizButton, setShowQuizButton] = useState(true);
  const likeIconRef = useRef(null);
  const disLikeIconRef = useRef(null);
  const [formattedSummaryData, setFormattedSummaryData] = useState('');

  useEffect(() => {
    if (feedbackIconFocusId && feedbackIconFocusId.status && likeIconRef.current && likeIconRef.current.dataset) {
      if (feedbackIconFocusId.id === likeIconRef.current.dataset.feedbackId && feedbackIconFocusId.type === 'LIKE') {
        likeIconRef.current.focus();
      } else if (feedbackIconFocusId.id === likeIconRef.current.dataset.feedbackId && feedbackIconFocusId.type === 'DISLIKE') {
        disLikeIconRef.current.focus();
      }
    }
  }, [feedbackIconFocusId]);

  useLayoutEffect(() => {
    const clientWidth = (document.getElementById('bot_container_id') && document.getElementById('bot_container_id').clientWidth) || 400;
    const tableList = document.querySelectorAll('.aiSummaryListContainer table');
    if (tableList && tableList.length) {
      tableList.forEach((element) => {
        element.style.width = `${clientWidth - 50}px`; // eslint-disable-line no-param-reassign
      });
    }
  }, [isExpanded]);

  const onMoreActionClick = (event, isMoreActionDialogueOpen = false) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setMoreActionOptions(!showMoreActionOptions);
    if (typeof isMoreActionDialogueOpen === 'boolean' && isMoreActionDialogueOpen) clickMoreActionsGAEvent(handleGAEvents, constants.GPT_EVENTS.SUMMARY, bookId);
  };

  const handleCopy = () => {
    onMoreActionClick();
    if (botData.requestId || summaryData.requestId) {
      const copyText = document.getElementById(botData.requestId || summaryData.requestId).innerHTML;
      Utils.exportCopyText(copyText);
      onCopy();
    }

    const additionalFields = {
      event_label: 'Copy',
      event_value: null,
      event_action: eventAction.selectOutputType,
      chat_type: constants.GPT_EVENTS.SUMMARY
    };
    gaEvents(handleGAEvents, additionalFields);
  };

  const getChapterDetails = () => {
    let chapterDetails = {
      chapterId: '',
      chapterTitle: ''
    };
    let filteredChapterList = null;
    const pageArray = [];

    switch (selectedOptionType) {
      case constants.CURRENT_PAGE:
      case constants.CURRENT_SECTION:
        chapterDetails = pageId.length && chapterList.filter((chapter) => chapter.pageIds.find((pageNo) => pageNo === pageId[0]))[0];
        break;
      case constants.PAGE_RANGE:
        pageList.filter((page) => (page.pageNo >= Number(pageRange.startIndex)
          && page.pageNo <= Number(pageRange.endIndex)) && pageArray.push(page.pageId));
        filteredChapterList = chapterList.filter((chapter) => chapter.pageIds.find((pageNo) => pageArray.find((item) => item === pageNo)));
        if (filteredChapterList.length === 1) {
          chapterDetails = filteredChapterList[0];
        }
        break;
      case constants.CHAPTERS:
        filteredChapterList = chapterList.filter((chapter) => selectedChapter.find((item) => item === chapter.chapterTitle));
        if (filteredChapterList.length === 1) {
          chapterDetails = filteredChapterList[0];
        }
        break;
      case constants.CURRENT_CHAPTER:
        chapterDetails = {
          chapterId,
          chapterTitle
        };
        break;
      default:
        chapterDetails = {
          chapterId: '',
          chapterTitle: ''
        };
        break;
    }
    return chapterDetails || {};
  };

  const handleFlashCard = () => {
    onMoreActionClick();
    const flashCardSummaryData = summaryData && summaryData.summaryText;
    onFlashCardClick(flashCardSummaryData, (botData && botData.pageIds) || (summaryData && summaryData.pageIds) || pageId, chapterData);
    // handleChatGPTFlashCard(text);
    const additionalFields = {
      event_label: 'Save as Flashcard',
      event_value: null,
      event_action: eventAction.selectOutputType,
      chat_type: constants.GPT_EVENTS.SUMMARY
    };
    gaEvents(handleGAEvents, additionalFields);
  };

  const handleRegenerate = () => {
    onRegenerate(pageRange);
  };

  const handleShowPositiveFeedbackPopUp = (event) => {
    setFeedbackResponse({ postiveFeedBackSubmitted: !feedbackResponse.postiveFeedBackSubmitted, negativeFeedBackSubmitted: false });
    setFeedbackPressed({ postiveFeedBackPressed: !feedbackPressed.postiveFeedBackPressed, negativeFeedBackPressed: false });
    setAnchorPositiveEl(anchorPositiveEl ? null : event.currentTarget);
    if (!feedbackResponse.postiveFeedBackSubmitted) setShowPositiveFeedbackPopUp(!showPositiveFeedbackPopUp);
    const option = optionSelected === constants.BULLET ? constants.GPT_EVENTS.BULLET_POINT_TYPE : constants.GPT_EVENTS.PARAGRAPH_TYPE;
    clickThumbsGAEvent(handleGAEvents, constants.GPT_EVENTS.UP, !feedbackResponse.postiveFeedBackSubmitted, option, constants.SUMMARY);
  };

  const handleShowNegativeFeedbackPopUp = (event) => {
    setFeedbackResponse({ postiveFeedBackSubmitted: false, negativeFeedBackSubmitted: !feedbackResponse.negativeFeedBackSubmitted });
    setFeedbackPressed({ postiveFeedBackPressed: false, negativeFeedBackPressed: !feedbackPressed.negativeFeedBackPressed });
    setAnchorNegativeEl(anchorNegativeEl ? null : event.currentTarget);
    if (!feedbackResponse.negativeFeedBackSubmitted) setShowNegativeFeedbackPopUp(!showNegativeFeedbackPopUp);
    const option = optionSelected === constants.BULLET ? constants.GPT_EVENTS.BULLET_POINT_TYPE : constants.GPT_EVENTS.PARAGRAPH_TYPE;
    clickThumbsGAEvent(handleGAEvents, constants.GPT_EVENTS.DOWN, !feedbackResponse.negativeFeedBackSubmitted, option, constants.SUMMARY);
  };

  const handleClose = (componentType, submitted = false, feedbackIdRef = null) => {
    if (feedbackIdRef) {
      likeIconRef.current.dataset.feedbackId = feedbackIdRef;
      disLikeIconRef.current.dataset.feedbackId = feedbackIdRef;
    }
    let payloadInfo = {
      type: 'summary',
      requestId: summaryData.requestId || botData.requestId,
      responseId: summaryData.responseId || botData.responseId,
      userComment: '',
      userSelectedOptions: [],
      data: summaryData.summaryText
    };
    if (componentType === constants.POSITIVE_FEEDBACK) {
      setShowPositiveFeedbackPopUp(false);
      setAnchorPositiveEl(null);
      payloadInfo = { ...payloadInfo, feedbackType: 'LIKE' };
      if (!submitted) likeIconRef.current.focus();
    }
    if (componentType === constants.NEGATIVE_FEEDBACK) {
      setShowNegativeFeedbackPopUp(false);
      setAnchorNegativeEl(null);
      payloadInfo = { ...payloadInfo, feedbackType: 'DISLIKE' };
      if (!submitted) disLikeIconRef.current.focus();
    }
    if (!submitted) {
      if (!postiveFeedbackFlag && componentType === constants.POSITIVE_FEEDBACK) {
        handleSubmitFeedback(payloadInfo, handleClose, false, likeIconRef.current.dataset.feedbackId);
        setPostiveFeedbackFlag(true);
      } else if (!negativeFeedbackFlag && componentType === constants.NEGATIVE_FEEDBACK) {
        handleSubmitFeedback(payloadInfo, handleClose, false, disLikeIconRef.current.dataset.feedbackId);
        setNegativeFeedbackFlag(true);
      }
    }
    setIsClickAway(false);
  };

  const handleSubmitPositiveFeedback = (value) => {
    const payloadInfo = {
      type: 'summary',
      requestId: summaryData.requestId || botData.requestId,
      responseId: summaryData.responseId || botData.responseId,
      feedbackType: 'LIKE',
      userComment: value,
      userSelectedOptions: [],
      data: summaryData.summaryText
    };
    setPostiveFeedbackFlag(true);
    // const additionalFields = {
    //   event_label: null,
    //   event_value: null,
    //   event_action: eventAction.providePositiveFeedback,
    //   input: value
    // };
    // gaEvents(handleGAEvents, additionalFields);
    handleSubmitFeedback(payloadInfo, handleClose, true, likeIconRef.current.dataset.feedbackId);
  };
  const handleSubmitNegativeFeedback = (value, selectedOptions) => {
    const payloadInfo = {
      type: 'summary',
      requestId: summaryData.requestId || botData.requestId,
      responseId: summaryData.responseId || botData.responseId,
      feedbackType: 'DISLIKE',
      userComment: value,
      userSelectedOptions: selectedOptions,
      data: summaryData.summaryText
    };
    const additionalFields = {
      event_label: value,
      event_value: null,
      event_action: eventAction.clickSubmitNegativeFeedback,
      multi_input: selectedOptions
    };
    gaEvents(handleGAEvents, additionalFields);
    setNegativeFeedbackFlag(true);
    handleSubmitFeedback(payloadInfo, handleClose, true, disLikeIconRef.current.dataset.feedbackId);
  };

  /* eslint-disable no-return-assign */
  const text = summaryData && summaryData.summaryText;

  const optionText = optionSelected === constants.BULLET ? 'bullet point' : 'paragraph';
  let typeText = 'Here is the summary of the content from the current eTextbook page';
  const commonText = 'Here is the summary of the content from ';
  // const formatChoice = `, in ${optionText} format:`;
  const formatChoice = ':';
  if (pageNums && Array.isArray(pageNums) && pageNums.length && !(pagesRange && pagesRange.length) && sourceType) {
    typeText = `${commonText} ${sourceType}${pageNums.length > 1 ? 's' : ''} ${pageNums.join()}${formatChoice}`;
  } else if (pageNums && Array.isArray(pageNums) && pageNums.length && !(pagesRange && pagesRange.length)) {
    typeText = `${commonText} page${pageNums.length > 1 ? 's' : ''} ${pageNums.join()}${formatChoice}`;
  } else if (pagesRange && pagesRange.length && pagesRange[0] && pagesRange[0].start && pagesRange[0].end) {
    typeText = `${commonText} pages ${pagesRange[0].start} - ${pagesRange[0].end}${formatChoice}`;
  } else if (pageRange && Object.keys(pageRange).length !== 0) {
    typeText = `${commonText} pages ${pageRange.startIndex}-${pageRange.endIndex}${formatChoice}`;
  } else if (selectedChapter && selectedChapter.length) {
    typeText = `${commonText} chapter${(selectedChapter.length > 1) ? 's' : ''}${formatChoice}`;
    typeText += Utils.concatChapterTitle(selectedChapter);
  } else if (selectedOptionType === constants.CURRENT_SECTION || selectedOptionType === constants.SECTION) {
    typeText = 'Here\'s a summary of your current section:';
  } else if (selectedOptionType === constants.CURRENT_CHAPTER) {
    typeText = 'Here\'s a summary of your whole chapter:';
  } else if (selectedOptionType === constants.CHAPTER) {
    typeText = 'Here\'s a summary of your current chapter:';
  } else if (topicTitle) {
    typeText = `${commonText} ${topicTitle}${formatChoice}`;
  }

  const handleNote = () => {
    onMoreActionClick();
    const chapterDetails = getChapterDetails();
    handleChatGPTCustomNote(text, chapterDetails.chapterId, chapterDetails.chapterTitle);
    const additionalFields = {
      event_label: 'Save as Note',
      event_value: null,
      event_action: eventAction.selectOutputType,
      chat_type: constants.GPT_EVENTS.SUMMARY
    };
    gaEvents(handleGAEvents, additionalFields);
  };

  const handleFormatChange = () => {
    setIsFormat(false);
    onFormatChange(optionSelected === constants.BULLET ? constants.PARAGRAPH : constants.BULLET, pageRange, selectedChapter, (botData && botData.pageIds) || (summaryData && summaryData.pageIds), (botData && botData.pageNums) || (summaryData && summaryData.pageNums) || pageNums, selectedContent, selectedOptionType, pagesRange);
  };

  const handleClickAway = (type) => {
    setIsClickAway(true);
    setTimeout(() => {
      handleClose(type);
    }, 200);
  };

  const handleSummaryQuiz = () => {
    setShowQuizButton(false);
    onSummaryQuiz(selectedOptionType, summaryData, (botData && botData.pageIds) || (summaryData && summaryData.pageIds) || pageId);
  };

  const handleSummaryMoveOn = () => {
    setShowQuizButton(false);
    onSummaryMoveOn(selectedOptionType, summaryData);
  };

  const processSummaryContent = async () => {
    const data = await Utilities.textFromContentV3(summaryData.summaryText);
    const markedData = Utils.convertToMarkedDownString(data, true);
    setFormattedSummaryData(markedData);
  };

  useEffect(() => {
    processSummaryContent(summaryData.summaryText);
  }, [summaryData?.summaryText]);

  return (
    <>
      {
        lessContent
          ? (
            <Typography className={classes.lessContentStyle}>
              {lessContent}
            </Typography>
          )
          : (
            <Box>
              <Box className={`aiSummaryListContainer ${classes.summaryListContainer}`}>
                {
                  !formattedSummaryData && <LoaderIcon />
                }
                {
                  formattedSummaryData && (
                    <Box>
                      <Typography variant="paragraph" className={classes.summaryDescription}>
                        {
                          isFromCSMenu && !(pageNums && pageNums.length > 0)
                            ? `Here is the summary of the content of the eTextbook content you selected, in ${optionText} format:` : `${typeText}`
                        }
                      </Typography>
                      <ReadMore summaryData={formattedSummaryData} masterTheme={masterTheme} theme={theme} requestId={summaryData.requestId} customThemeStyles={customThemeStyles} />
                    </Box>
                  )
                }
              </Box>
              <Box className={isFormat ? classes.actionContainer : classes.alignmentRight}>
                {
                  isFormat && (
                    <FormatChange
                      classes={classes}
                      optionSelected={optionSelected}
                      onFormatChange={handleFormatChange}
                      masterTheme={masterTheme}
                      theme={theme}
                    />
                  )
                }
                {
                  formattedSummaryData && (
                    <div>
                      <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} title="Report as positive" placement="top" arrow>
                        <IconButton ref={likeIconRef} className={classes.feedbackButton} onClick={(event) => handleShowPositiveFeedbackPopUp(event)} aria-label="Report as positive" aria-pressed={feedbackPressed.postiveFeedBackPressed}>
                          {feedbackResponse.postiveFeedBackSubmitted ? <LikeIconFilled masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} /> : <LikeIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />}
                        </IconButton>
                      </Tooltip>
                      <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} title="Report as negative" placement="top" arrow>
                        <IconButton ref={disLikeIconRef} className={classes.feedbackButton} onClick={(event) => handleShowNegativeFeedbackPopUp(event)} aria-label="Report as negative" aria-pressed={feedbackPressed.negativeFeedBackPressed}>
                          {feedbackResponse.negativeFeedBackSubmitted ? <DisLikeIconFilled masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} /> : <DisLikeIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />}
                        </IconButton>
                      </Tooltip>
                      <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-haspopup title={formatMessage(messages.moreActions)} placement="top" arrow>
                        <IconButton className={classes.feedbackButton} onClick={(event) => onMoreActionClick(event, true)} aria-label={constants.MORE_ACTIONS}>
                          <MoreMenuIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} title="Regenerate response" placement="top" arrow>
                <IconButton className={classes.feedbackButton} onClick={handleRegenerate}>
                  <RegenerateIcon />
                </IconButton>
              </Tooltip> */}
                    </div>
                  )
                }

              </Box>
              {
                showSummaryQuiz && showPractice && formattedSummaryData && (
                  <div>
                    <Typography variant="paragraph" className={classes.quizDescription}>
                      {formatMessage(messages.summaryQuizDescription)}
                    </Typography>
                    {
                      (summaryQuizButton && showQuizButton) && (
                        <div className={classes.summaryQuizActionWrapper}>
                          <Button
                            component="button"
                            onClick={handleSummaryQuiz}
                            className={`${classes.buttonStyle} ${classes.alignRight}`}
                          >
                            {formatMessage(messages.summaryQuizMe)}
                          </Button>
                          <Button
                            component="button"
                            onClick={handleSummaryMoveOn}
                            className={classes.buttonStyle}
                          >
                            {formatMessage(messages.summaryMoveOn)}
                          </Button>
                        </div>
                      )
                    }
                  </div>
                )
              }
              {
                showMoreActionOptions && (
                  <Menu
                    id="moreActionOptions"
                    open={showMoreActionOptions}
                    anchorEl={anchorEl}
                    classes={{ paper: classes.moreActionList }}
                    className={classes.poperStyle}
                    keepMounted
                    onClose={onMoreActionClick}
                  >
                    <MenuItem
                      button
                      onClick={handleFlashCard}
                      classes={{
                        root: classes.listItemRootStyle
                      }}
                    >
                      <ListItemIcon classes={{ root: classes.iconRootStyle }}>
                        <FlashCardIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />
                      </ListItemIcon>
                      <ListItemText classes={{ primary: classes.textRootStyle }}>
                        {formatMessage(messages.saveFlashcards)}
                      </ListItemText>
                    </MenuItem>
                    <MenuItem
                      button
                      onClick={handleNote}
                      classes={{
                        root: classes.listItemRootStyle
                      }}
                    >
                      <ListItemIcon classes={{ root: classes.iconRootStyle }}>
                        <NoteIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />
                      </ListItemIcon>
                      <ListItemText classes={{ primary: classes.textRootStyle }}>
                        {formatMessage(messages.saveNote)}
                      </ListItemText>
                    </MenuItem>
                    <MenuItem
                      button
                      classes={{
                        root: `${classes.listItemRootStyle} ${classes.listAlignment}`
                      }}
                      onClick={handleCopy}
                    >
                      <ListItemIcon classes={{ root: classes.iconRootStyle }}>
                        <CopyIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />
                      </ListItemIcon>
                      <ListItemText classes={{ primary: classes.textRootStyle }}>
                        {formatMessage(messages.copy)}
                      </ListItemText>
                    </MenuItem>
                  </Menu>
                )
              }
              {
                showPositiveFeedbackPopUp && (
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={() => { handleClickAway(constants.POSITIVE_FEEDBACK); }}
                  >
                    <PositiveFeedback
                      handleSubmitPositiveFeedback={handleSubmitPositiveFeedback}
                      handleClose={handleClose}
                      masterTheme={masterTheme}
                      theme={theme}
                      isClickAway={isClickAway}
                    />
                  </ClickAwayListener>
                )
              }
              {
                showNegativeFeedbackPopUp && (
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={() => { handleClickAway(constants.NEGATIVE_FEEDBACK); }}
                  >
                    <NegativeFeedback
                      handleSubmitNegativeFeedback={handleSubmitNegativeFeedback}
                      handleClose={handleClose}
                      masterTheme={masterTheme}
                      theme={theme}
                      isClickAway={isClickAway}
                    />
                  </ClickAwayListener>
                )
              }
            </Box>
          )
      }
    </>
  );
};

SummaryListContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  summaryData: PropTypes.object,
  optionSelected: PropTypes.string,
  handleChatGPTCustomNote: PropTypes.func.isRequired,
  pageRange: PropTypes.object,
  feedbackIconFocusId: PropTypes.object,
  onRegenerate: PropTypes.func.isRequired,
  selectedChapter: PropTypes.array,
  pageId: PropTypes.array,
  selectedOptionType: PropTypes.string.isRequired,
  chapterList: PropTypes.array.isRequired,
  pageList: PropTypes.array.isRequired,
  handleGAEvents: PropTypes.func.isRequired,
  handleSubmitFeedback: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  masterTheme: PropTypes.string.isRequired,
  isFromCSMenu: PropTypes.string,
  lessContent: PropTypes.string,
  botData: PropTypes.object,
  showFormat: PropTypes.bool,
  showHeader: PropTypes.bool,
  bookId: PropTypes.string.isRequired,
  onFormatChange: PropTypes.func,
  onFlashCardClick: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  pageNums: PropTypes.array,
  pagesRange: PropTypes.array,
  selectedContent: PropTypes.string,
  chapterId: PropTypes.string.isRequired,
  chapterTitle: PropTypes.string.isRequired,
  topicTitle: PropTypes.string,
  sourceType: PropTypes.string,
  chapterData: PropTypes.object,
  showSummaryQuiz: PropTypes.bool.isRequired,
  onSummaryQuiz: PropTypes.func,
  onSummaryMoveOn: PropTypes.func,
  summaryQuizButton: PropTypes.bool,
  isExpanded: PropTypes.bool,
  customThemeStyles: PropTypes.object,
  AIEnableMarkdown: PropTypes.bool,
  showPractice: PropTypes.bool.isRequired
};

SummaryListContainer.defaultProps = {
  pageRange: '',
  feedbackIconFocusId: {},
  lessContent: '',
  selectedChapter: [],
  isFromCSMenu: '',
  botData: {},
  summaryData: {},
  customThemeStyles: {},
  optionSelected: '',
  showFormat: true,
  showHeader: true,
  onFormatChange: () => {},
  pageNums: [],
  pagesRange: [],
  selectedContent: '',
  pageId: [],
  topicTitle: '',
  sourceType: '',
  chapterData: {},
  onSummaryQuiz: () => {},
  onSummaryMoveOn: () => {},
  summaryQuizButton: true,
  isExpanded: false,
  AIEnableMarkdown: false
};

export default withStyles(styles)(injectIntl(SummaryListContainer));
