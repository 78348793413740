/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2025 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file custom themes utilities
 * @author Arifhusain Soudagar
 */

import ThemeConstants from '../ThemeConstants';

const getThemeValue = (props, keyName, valueName, isHover, defaultValue) => {
  let themeValue = props && props.customThemeStyles && props.customThemeStyles[keyName] && props.customThemeStyles[keyName][valueName];
  if (isHover) {
    themeValue = props && props.customThemeStyles && props.customThemeStyles[keyName] && props.customThemeStyles[keyName].hover[valueName];
  }
  if (themeValue) return themeValue;
  return ThemeConstants[props.masterTheme][props.theme][defaultValue];
};
const getComponentThemeValue = (customThemeStyles, keyName, valueName, isHover, defaultValue, masterTheme, theme) => {
  let themeValue = customThemeStyles && customThemeStyles[keyName] && customThemeStyles[keyName][valueName];
  if (isHover) {
    themeValue = customThemeStyles && customThemeStyles[keyName] && customThemeStyles[keyName].hover[valueName];
  }
  if (themeValue) return themeValue;
  return ThemeConstants[masterTheme][theme][defaultValue];
};

export { getThemeValue, getComponentThemeValue };
