/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file PencilIcon.jsx
 * @author Arifhusain Soudagar
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import { getComponentThemeValue } from '../CustomStyles/customStyleUtils';

const Styles = () => ({
  pencilIcon: {
    width: 16,
    height: 16
  }
});

const PencilIcon = ({
  classes, masterTheme, theme, customThemeStyles
}) => (
  <SvgIcon className={classes.pencilIcon} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.888916 11.3624L10.826 1.40962C11.5192 0.71535 12.643 0.71535 13.3362 1.40962L14.5913 2.66669C15.2844 3.36096 15.2844 4.48659 14.5913 5.18085L4.67668 15.1111H1.33266C1.08759 15.1111 0.888916 14.9122 0.888916 14.6667V11.3624ZM9.44751 4.9919L11.0159 6.56027L4.03162 13.5556H2.44447V12.006L9.44751 4.9919ZM11.9268 2.50869L10.5466 3.89109L12.115 5.45945L13.4904 4.08178C13.5773 3.9948 13.5773 3.85275 13.4904 3.76577L12.2354 2.50869C12.1499 2.42307 12.0123 2.42307 11.9268 2.50869Z" fill={getComponentThemeValue(customThemeStyles, 'root', 'iconColor', false, 'textColor', masterTheme, theme)} />
  </SvgIcon>
);

PencilIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  customThemeStyles: PropTypes.object,
  theme: PropTypes.string,
  masterTheme: PropTypes.string
};

PencilIcon.defaultProps = {
  theme: 'default',
  customThemeStyles: {},
  masterTheme: 'plus'
};

export default withStyles(Styles)(PencilIcon);
