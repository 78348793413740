/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file EmptyState.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import messages from '../../defaultMessages';
import NoSearchResultIcon from '../../Common/icons/NoSearchResultIcon';
import { getThemeValue } from '../../Common/CustomStyles/customStyleUtils';

const Styles = () => ({
  noDataWrapper: {
    padding: 16
  },
  iconStyle: {
    width: 114,
    margin: '0 auto'
  },
  titleStyle: {
    fontFamily: 'TT Commons',
    fontSize: 18,
    fontWeight: 700,
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'historyCloseBgColor')),
    lineHeight: '24px',
    letterSpacing: '0.24px',
    textAlign: 'center',
    marginBottom: 10
  },
  descriptionStyle: {
    fontFamily: 'TT Commons',
    fontSize: 18,
    fontWeight: 400,
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'historyCloseBgColor')),
    lineHeight: '24px',
    letterSpacing: '0.24px',
    textAlign: 'center',
    wordBreak: 'break-word'
  }
});

const EmptyState = ({
  intl,
  classes
}) => {
  const { formatMessage } = intl;
  return (
    <div className={classes.noDataWrapper}>
      <div className={classes.iconStyle}>
        <NoSearchResultIcon />
      </div>
      <Typography variant="h5" className={classes.titleStyle}>
        {formatMessage(messages.noHistoryDataTitle)}
      </Typography>
      <Typography variant="body1" className={classes.descriptionStyle}>
        {formatMessage(messages.noHistoryDataDescription)}
      </Typography>
    </div>
  );
};

EmptyState.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(Styles)(injectIntl(EmptyState));
