/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of subscription software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 * */

/**
* Braze Plugin
*
* @author Mohamed yasar arabath M
*/
import * as braze from '@braze/web-sdk';
import {
  AUTH_HOME,
  ALL_AUTH_HOME,
  AUTHHOME_APPLICATION,
  EVENT_USER_INTERACTION,
  HEADER_EVENT,
  BRAZE_LOCATION_IN_APP,
  TEMPLATE_A,
  TEMPLATE_B,
  TEMPLATE_C,
  TEMPLATE_D,
  TEMPLATE_F,
  TEMPLATE_G,
  TEMPLATE_H,
  TEMPLATE_V1,
  MESSAGE_CENTER,
  BRAZE_MARKETING_CONTENT_CARD,
  TEMPLATE_E,
  TRUE,
  BRAZE_NO_DATA,
  BRAZE_TARGET_AREA
} from '../../common/constants';
import BrazeUtils from '../../common/utils/BrazeUtils';
import CommonUtils from '../../common/utils/CommonUtils';

export default class BrazePlugin {
  constructor(context) {
    this.context = context;
  }

  /**
   * show eligible content cards
   */
  showInAppMesssage = () => {
    const inAppMessageCards = BrazeUtils.inAppMessageCards;
    const currentLocation = (this.context.commonPlugin.location === AUTH_HOME) ? BRAZE_LOCATION_IN_APP.PPLUS_WEB_AUTHHOME : BRAZE_LOCATION_IN_APP.PPLUS_WEB_EREADER;

    if (inAppMessageCards) {
      const rules = inAppMessageCards.extras?.rules ? JSON.parse(inAppMessageCards.extras.rules) : [];
      const location = inAppMessageCards?.extras?.location;
      inAppMessageCards.rules = rules;
      if (currentLocation === (location && location.toUpperCase())) {
        const eligibleCards = this.context.brazeTemplatePlugin.getEligibleBrazeContentCard([inAppMessageCards]);

        if (eligibleCards.length > 0) {
          braze.showInAppMessage(inAppMessageCards);
        }
      }
    }
  }

  /**
   * show eligible content cards
   */
  showContentCards = () => {
    let cards = BrazeUtils.contentCards;
    if (Array.isArray(cards) && cards.length > 0) {
      cards = cards.map(card => ({
        ...card,
        rules: card.extras?.rules ? JSON.parse(card.extras.rules) : []
      }));
      const eligibleCards = this.context.brazeTemplatePlugin.getEligibleBrazeContentCard(cards);
      const currentLocation = this.context.commonPlugin.location;
      let existingContentCards = this.context.state.brazeContentCards;
      const newContentCards = [];
      eligibleCards.forEach((card) => {
        const { id: cardId, extras: { location } } = card;
        const renderLocation= location && location.toUpperCase();

        if ((currentLocation === renderLocation) || (renderLocation === ALL_AUTH_HOME)) {
          const isExistingCard = existingContentCards.find(
            existingCard => this.getTemplateCardId(existingCard) === cardId
          );
          if (isExistingCard) {
            existingContentCards = existingContentCards.filter(
              existingCard => this.getTemplateCardId(existingCard) !== cardId
            );
          }
          const brazeContentCard = this.getBrazeContentTemplate(card);
          if (brazeContentCard) {
            newContentCards.push(brazeContentCard);
          }
        }
      });

      if (newContentCards.length > 0) {
        const brazeContentCards = [...existingContentCards, ...newContentCards];
        this.context.setState({ brazeContentCards });
      }
    }
  }

  /**
   * Function to fetch braze template card Id
   * @param {*} card
   * @returns
   */
  getTemplateCardId = (card) => {
    const { data } = card;

    return data ? data.cardId : card.cardId;
  }

  /**
   * Returns content card based on the target area
   * @param {*} targetArea
   * @returns
   */
  getContentCardByTargetArea = (targetArea) => {
    let contentCard = null;
    try {
      const { brazeContentCards } = this.context.state;
      const hasBrazeMessageCard = brazeContentCards.length > 0;
      if (hasBrazeMessageCard) {
        const filteredCards = brazeContentCards.filter(({ data }) => data?.targetArea === targetArea);

        if (filteredCards.length > 0) {
          const reOrderedCards = this.context.brazeTemplatePlugin.orderByPriority(filteredCards);

          if (reOrderedCards.length > 0) {
            contentCard = reOrderedCards[0];
          }
        }
      }
    } catch (err) {
      console.log('Error in fetching card for target area', err);
    }
    return contentCard;
  }

  /**
   * Returns Authhome message center cards
   * @returns
   */
  getMessageCenterContentCards = () => {
    let messageCenterCards = [];
    const { brazeContentCards } = this.context.state;
    const hasBrazeMessageCard = brazeContentCards.length > 0;
    if (hasBrazeMessageCard) {
      messageCenterCards = brazeContentCards.filter(({ targetArea }) => targetArea === MESSAGE_CENTER);
    }
    return messageCenterCards;
  }


  /**
   * Function to log content card click event
   * @param {*} card
   * @returns
   */
  logContentCardClick = (cardId) => {
    if (cardId) {
      const card = BrazeUtils.contentCards.find(({ id }) => id === cardId);
      if (card) {
        braze.logContentCardClick(card);
      }
    }
  }

  /**
   * View content card log
   * @param {*} card
   * @returns
   */
  static viewContentCard = (cardId) => {
    if (cardId) {
      const cards = BrazeUtils.contentCards.filter(({ id }) => id === cardId);
      if (cards && cards.length > 0) {
        braze.logCardImpressions(cards, true);
      }
    }
  }

  /**
   * Dismiss content card log
   * @param {*} card
   * @returns
   */
  dismissContentCard = (cardId) => {
    if (cardId) {
      const card = BrazeUtils.contentCards.find(({ id }) => id === cardId);
      if (card) {
        braze.logCardDismissal(card);
      }
    }
  }

  /**
   * Removes content card from state
   * @param {*} cardId
   */
  removeAndDismissContentCard = (cardId) => {
    const { brazeContentCards: allBrazeContentCards } = this.context.state;
    if (allBrazeContentCards) {
      const brazeContentCards = allBrazeContentCards.filter((card) => {
        const { data } = card;
        const id = data ? data.cardId : card.cardId;

        return id !== cardId;
      });
      this.context.setState({ brazeContentCards });
      this.dismissContentCard(cardId);
    }
  }

  /**
   * Returns braze template data
   * @param {*} card
   * @returns
   */
  getBrazeContentTemplate = (card) => {
    const {
      bookshelf
    } = this.context.props;
    let templateData = null;
    const {
      id, title, description, imageUrl: image, extras: {
        targetArea,
        showDismiss,
        template,
        imageUrl,
        styles,
        position,
        action,
        backgroundImage,
        backgroundColor,
        showDescription,
        targetSelector,
        arrowPosition,
        subscribeToEvent,
        campaignClickAction,
        // eslint-disable-next-line camelcase
        utm_source, utm_campaign,
        showCTA,
        subTitle,
        priority,
        styleVariant
      }
    } = card;

    switch (template) {
      case TEMPLATE_A: {
        templateData = {
          data: {
            cardId: id,
            show: true,
            template,
            title,
            description,
            imageUrl,
            position,
            styles: styles ? JSON.parse(styles) : {}
          },
          callbackForBraze: {
            onViewCard: () => BrazePlugin.viewContentCard(id),
            onDismiss: () => this.removeAndDismissContentCard(id)
          }
        };
        break;
      }
      case TEMPLATE_B: {
        templateData = {
          data: {
            cardId: id,
            id: targetArea,
            targetArea,
            template,
            title,
            description,
            imageUrl: image,
            action: action ? JSON.parse(action) : [],
            styles: styles ? JSON.parse(styles) : {}
          },
          callbackForBraze: {
            onDismiss: () => this.removeAndDismissContentCard(id)
          }
        };
        break;
      }
      case TEMPLATE_C: {
        templateData = {
          cardId: id,
          id: BRAZE_MARKETING_CONTENT_CARD,
          targetArea: targetArea || MESSAGE_CENTER,
          template: TEMPLATE_V1,
          title,
          description,
          imageUrl: image,
          action: action ? JSON.parse(action) : [],
          showDismiss: showDismiss || true
        };
        break;
      }
      case TEMPLATE_D: {
        templateData = {
          data: {
            cardId: id,
            show: !subscribeToEvent,
            template,
            title: title?.toLowerCase() !== BRAZE_NO_DATA.toLowerCase() ? title : null,
            description: description?.toLowerCase() !== BRAZE_NO_DATA.toLowerCase() ? description : null,
            backgroundImage,
            backgroundColor,
            arrowPosition,
            action: action ? JSON.parse(action) : [],
            campaignClickAction: campaignClickAction ? JSON.parse(campaignClickAction) : [],
            styles: styles ? JSON.parse(styles) : {},
            targetSelector,
            showDismiss: (showDismiss && showDismiss.toLowerCase() === TRUE) || false,
            subscribeToEvent
          },
          callbackForBraze: {
            onViewCard: () => BrazePlugin.viewContentCard(id),
            onDismiss: () => this.removeAndDismissContentCard(id),
            logContentCardClick: () => this.logContentCardClick(id),
            openEreaderComponentFromBraze: (component) => {
              this.context.commonPlugin.openEreaderComponentFromBraze(component, {
                utm_source,
                utm_campaign
              });
            }
          }
        };
        break;
      }
      case TEMPLATE_E: {
        templateData = {
          data: {
            cardId: id,
            id: targetArea,
            targetArea,
            template,
            title,
            description,
            showDescription: (showDescription && showDescription.toLowerCase() === TRUE) || false,
            backgroundImage: backgroundImage && Array.isArray(JSON.parse(backgroundImage))
              ? JSON.parse(backgroundImage)
              : backgroundImage,
            backgroundColor,
            action: action ? JSON.parse(action) : [],
            styles: styles ? JSON.parse(styles) : {},
            showDismiss: (showDismiss && showDismiss.toLowerCase() === TRUE) || false
          },
          callbackForBraze: {
            onDismiss: () => {
              this.removeAndDismissContentCard(id);
              const { AUTHHOME_HEADER_BANNER } = BRAZE_TARGET_AREA;
              const headerContentCard = this.getContentCardByTargetArea(AUTHHOME_HEADER_BANNER);

              if (this?.context?.changeHeroCTAIfCRBanner) {
                this.context.changeHeroCTAIfCRBanner(headerContentCard, true);
              }
            }
          }
        };
        break;
      }
      case TEMPLATE_F: {
        templateData = {
          data: {
            cardId: id,
            id: targetArea,
            targetArea,
            image: (imageUrl && BrazeUtils.isJSON(imageUrl)) && Array.isArray(JSON.parse(imageUrl))
              ? JSON.parse(imageUrl)
              : imageUrl,
            template,
            title,
            subTitle,
            description,
            showCTA: (showCTA && showCTA.toLowerCase() === TRUE) || false,
            action: action ? JSON.parse(action) : [],
            styles: styles ? JSON.parse(styles) : {},
            showDismiss: (showDismiss && showDismiss.toLowerCase() === TRUE) || false,
            priority,
            styleVariant: styleVariant || ''
          },
          callbackForBraze: {
            onViewCard: () => BrazePlugin.viewContentCard(id),
            onDismiss: () => this.removeAndDismissContentCard(id),
            logContentCardClick: () => this.logContentCardClick(id)
          }
        };
        break;
      }
      case TEMPLATE_G: {
        templateData = {
          data: {
            cardId: id,
            show: !subscribeToEvent,
            template,
            title:
              title?.toLowerCase() !== BRAZE_NO_DATA.toLowerCase()
                ? title
                : null,
            description:
              description?.toLowerCase() !== BRAZE_NO_DATA.toLowerCase()
                ? description
                : null,
            backgroundImage,
            backgroundColor,
            position,
            action: action ? JSON.parse(action) : [],
            campaignClickAction: campaignClickAction
              ? JSON.parse(campaignClickAction)
              : [],
            styles: styles ? JSON.parse(styles) : {},
            showDismiss:
              (showDismiss && showDismiss.toLowerCase() === TRUE) || false,
            subscribeToEvent,
          },
          callbackForBraze: {
            onViewCard: () => BrazePlugin.viewContentCard(id),
            onDismiss: () => this.removeAndDismissContentCard(id),
            logContentCardClick: () => this.logContentCardClick(id),
            openEreaderComponentFromBraze: (component) => {
              this.context.commonPlugin.openEreaderComponentFromBraze(
                component,
                {
                  utm_source,
                  utm_campaign,
                }
              );
            },
          },
        };
        break;
      }
      case TEMPLATE_H: {
        templateData = {
          data: {
            cardId: id,
            id: targetArea,
            targetArea,
            template,
            action: action ? JSON.parse(action) : [],
            title:
              title?.toLowerCase() !== BRAZE_NO_DATA.toLowerCase()
                ? title
                : null,
            styles: styles ? JSON.parse(styles) : {},
            showDismiss:
              (showDismiss && showDismiss.toLowerCase() === TRUE) || false
          },
          callbackForBraze: {
            onViewCard: () => BrazePlugin.viewContentCard(id),
            onDismiss: () => {
              this.removeAndDismissContentCard(id);
              CommonUtils.dispatchGaEvent(AUTHHOME_APPLICATION, EVENT_USER_INTERACTION,
                HEADER_EVENT.HEADER_CLICK_EVENT_ACTION, HEADER_EVENT.TOAST_CLOSE, null);
            },
            logContentCardClick: (actionId) => {
              this.logContentCardClick(id);
              if (actionId && actionId === HEADER_EVENT.TOAST_PEARSONPLUS_CLICK) {
                CommonUtils.dispatchGaEvent(AUTHHOME_APPLICATION, EVENT_USER_INTERACTION,
                  HEADER_EVENT.HEADER_CLICK_EVENT_ACTION, HEADER_EVENT.TOAST_PEARSONPLUS_CLICK, null);
              }
            }
          }
        };
        break;
      }
      default:
        break;
    }

    return templateData;
  }
}
