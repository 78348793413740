/* eslint-disable no-return-assign */
/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file CustomInput.jsx
 * @author Sai Kiran
 */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useState, useEffect, useRef, memo
} from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { Box, Link, Typography } from '@material-ui/core';
// import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Utilities } from '@aquila/core';
import messages from '../../defaultMessages';
import globalContext from '../../Context/globalContext';
import { getThemeValue } from '../../Common/CustomStyles/customStyleUtils';

// Constants
const SCROLL_HEIGHT = 80;
const styles = () => ({
  CustomInputWrapper: {
    fontSize: 16,
    fontStyle: 'normal',
    fontFamily: 'TT Commons',
    fontWeight: 400,
    lineHeight: '20px',
    wordBreak: 'break-word',
    color: (props) => (getThemeValue(props, 'userMessage', 'textColor', false, 'textColor')),
    letterSpacing: '0.24px'
  },
  linkStyle: {
    display: 'block',
    fontWeight: 'bold',
    color: (props) => (getThemeValue(props, 'userMessage', 'textColor', false, 'textColor')),
    cursor: 'pointer',
    fontFamily: 'TT Commons',
    fontSize: 16,
    marginTop: 4
  },
  selectedTextTitle: {
    fontSize: 16,
    fontWeight: '700',
    fontFamily: 'TT Commons',
    color: (props) => (getThemeValue(props, 'userMessage', 'textColor', false, 'textColor'))
  },
  userMessage: {
    maxWidth: '80%',
    display: 'flex',
    borderRadius: 12,
    padding: '8px 8px 8px 16px',
    boxSizing: 'border-box',
    alignItems: 'center',
    background: (props) => (getThemeValue(props, 'userMessage', 'bgColor', false, 'userMsgbgColor')),
    color: (props) => (getThemeValue(props, 'userMessage', 'textColor', false, 'textColor'))
  },
  textEllipsis: {
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  containerEdit: {
    display: 'flex',
    justifyContent: 'center'
  },
  editIcon: {
    cursor: 'pointer',
    minWidth: 30
  },
  avatarStyle: {
    width: 40,
    height: 40,
    borderRadius: 64,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: '0',
    boxSizing: 'border-box',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '12px',
    marginLeft: '16px',
    color: (props) => (getThemeValue(props, 'avatar', 'textColor', false, 'textColor')),
    background: (props) => (getThemeValue(props, 'avatar', 'bgColor', false, 'avatarBgColor'))
  },
  Usermessage_Container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

const CustomInput = memo(({
  classes, inputValue, intl, initials
}) => {
  const { formatMessage } = intl;
  const [isCustomInput, setIsCustomInput] = useState(true);
  const [moreContext, setMoreContext] = useState(false);
  const selectedTextContainer = useRef();

  useEffect(() => {
    if (selectedTextContainer.current && selectedTextContainer.current.scrollHeight > SCROLL_HEIGHT) {
      setMoreContext(true);
    }
  }, []);

  const toggleCustomInput = () => {
    setIsCustomInput((prevState) => !prevState);
  };

  return (
    <div className={classes.Usermessage_Container}>
      <div className={classes.userMessage}>
        <Box className={classes.containerEdit}>
          {/* <EditOutlinedIcon fontSize="small" className={classes.editIcon} /> */}
          <Box>
            <Typography variant="h6" className={classes.selectedTextTitle}>I would like to use this content:</Typography>
            <Typography variant="p" className={`${classes.CustomInputWrapper} ${isCustomInput ? classes.textEllipsis : ''}`} ref={selectedTextContainer}>
              {Utilities.textFromContent(inputValue, globalContext.enableNewMathConfig)}
            </Typography>
            {/* condition that will render 'read more' only if the text.length is greated than 150 chars */}
            {moreContext && (
              isCustomInput
                ? (
                  <Link
                    tabIndex={0}
                    aria-label={messages.seeMore.defaultMessage}
                    autoFocus
                    variant="text"
                    color="primary"
                    href={false}
                    target="_blank"
                    onClick={toggleCustomInput}
                    underline="always"
                    className={classes.linkStyle}
                  >
                    {formatMessage(messages.seeMore)}
                  </Link>
                )
                : (
                  <Link
                    variant="text"
                    color="primary"
                    href={false}
                    target="_blank"
                    onClick={toggleCustomInput}
                    underline="always"
                    className={classes.linkStyle}
                  >
                    {formatMessage(messages.seeLess)}
                  </Link>
                )
            )}
          </Box>
        </Box>
        <div className={classes.avatarStyle}>{initials}</div>
      </div>
    </div>
  );
}, (prevProps, nextProps) => (JSON.stringify(prevProps) === JSON.stringify(nextProps)));

CustomInput.propTypes = {
  intl: intlShape.isRequired,
  inputValue: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  initials: PropTypes.string.isRequired
};

export default memo(withStyles(styles)(injectIntl(CustomInput)));
