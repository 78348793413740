/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to course
 *
 * @file Course.js
 * @author Hari Gangadharan
 */

import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';
import Product from '../../products/model/Product';
import PathUtils from '../../../common/utils/PathUtils';
import ProductUtils from '../../../common/utils/ProductUtils';

/**
 * A mobx model for instructor
 *
 */
const Instructor = types.model(
  'Instructor',
  {
    id: types.string,
    firstName: types.string,
    lastName: types.string
  }
);

/**
 * A mobx model for course
 *
 */
const Course = types.model(
  'Course',
  {
    annotationExists: types.maybeNull(types.boolean),
    id: types.string,
    courseId: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    code: types.maybeNull(types.string),
    organizationId: types.maybeNull(types.string),
    source: types.maybeNull(types.string),
    key: types.maybeNull(types.string),
    role: types.optional(types.string, 'student'),
    platform: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    startDate: types.maybeNull(types.integer),
    endDate: types.maybeNull(types.integer),
    isStudent: types.optional(types.boolean, true),
    instructors: types.maybeNull(types.array(Instructor)),
    products: types.array(Product),
    lastUpdateTime: types.maybeNull(types.string)
  }
).views(self => ({
  getProduct() {
    return self.products && self.products.length ? self.products[0] : {};
  },
  getBasePath(isBasePathChange, serverSideEnabled) {
    return self.getProduct().getBasePath(isBasePathChange, serverSideEnabled);
  },
  getConfig(locale, bundleId, redirectUrl, isCanadianProxy, featureFlags = false, userPreferences = false, ismlm = false, LDUserFlagsFetched = false) {
    let showBackNav = false;
    const bookFormats = [ constants.CONTENT_TYPE_PDF, constants.CONTENT_TYPE_EPUB ];

    if (self.getProduct().model === constants.ETEXT_PDF && redirectUrl || !bundleId) {
      showBackNav = true;
    }
    return {
      ...self.getProduct().getConfig(locale, bundleId, featureFlags, userPreferences, ismlm, LDUserFlagsFetched),
      showShareNote: !self.isStudent,
      allowTocEdit: (bookFormats.includes(self.getProduct().format)) ? false : !self.isStudent,
      allowNotesCopy: !self.isStudent && self.getProduct().platform === constants.BUNDLED_PLATFORM,
      showBackNav: (self.getProduct().model === constants.ETEXT_PDF) ? showBackNav : !bundleId
    };
  },

  /**
   * Method to get book information
   *
   */
  getBookInfo(printPageNumber) {
    const businessModelCode = self.getProduct().getBusinessModelCode();
    const {
      legacyBookId: bookId,
      legacyProductId: productId,
      model
    } = self.getProduct();
    const channelId = self.getProduct().getPrimaryChannelId();
    const legacyIds = { bookId, productId };
    const legacyBookId = ProductUtils.getlegacyBookId(businessModelCode, legacyIds);
    return {
      author: self.getProduct().authors,
      title2: self.getProduct().title,
      thumbnailUrl: self.getProduct().thumbnailUrl || constants.DEFAULT_BOOK_COVER,
      title: self.title,
      printPageNumber,
      isDoublePage: false,
      layoutDimensions: {
        rightStart: false
      },
      preferenceObject: {
        sliderVal: '130',
        theme: 'White',
        zoom: 1.3
      },
      legacyBookId,
      productModel: model,
      channelId
    };
  },
  getMenuData() {
    const courseName = self.title ? self.title : self.code;

    return { ...self.getProduct().getMenuData(), courseName };
  }
})).actions(self => ({
  fetch(id, productId, bundleId) {
    if (self.id === id) {
      Framework.getEventManager().publish(constants.COURSE_DATA_FETCHED, {});
    } else {
      Framework.getEventManager().publish(constants.COURSE_SYNC_REQUESTED, {
        courseId: id,
        productId,
        bundleId
      });
    }
  },
  set(course, bundleId) {
    applySnapshot(self, course);
    const courseName = self.title ? self.title : self.code;
    const product = self.getProduct();
    const productId = product.id || '';
    const productTitle = product.title || '';
    const userId = Framework.getStoreRegistry().getStore('user').id;

    if (product.model !== constants.CUSTOM_COLLECTIONS || (product.model === constants.CUSTOM_COLLECTIONS && self.isStudent)) {
      product.doSubscriptionCheck(
        productTitle,
        userId,
        productId,
        course.id,
        courseName
      );
    }
    Framework.getEventManager().publish(constants.COURSE_DATA_FETCHED, {});
    product.updateDataLayer();
  }
}));

export default Course;
