/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2025 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/*
 * A React component for MoreMenuIcon.
 *
 * @author Arifhusain Soudagar
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import { getComponentThemeValue } from '../CustomStyles/customStyleUtils';

const Styles = () => ({
  morMenuIcon: {
    width: 20,
    height: 20
  }
});

const MoreMenuIcon = ({
  classes, theme, masterTheme, customThemeStyles
}) => (
  <SvgIcon
    width="24"
    height="24"
    classes={{
      root: classes.morMenuIcon
    }}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M20 10C21.1046 10 22 10.8954 22 12C22 13.1046 21.1046 14 20 14C18.8954 14 18 13.1046 18 12C18 10.8954 18.8954 10 20 10ZM12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10ZM4 10C5.10457 10 6 10.8954 6 12C6 13.1046 5.10457 14 4 14C2.89543 14 2 13.1046 2 12C2 10.8954 2.89543 10 4 10Z" fill={getComponentThemeValue(customThemeStyles, 'root', 'iconColor', false, 'textColor', masterTheme, theme)} />
  </SvgIcon>

);

MoreMenuIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.string,
  customThemeStyles: PropTypes.object,
  masterTheme: PropTypes.string
};

MoreMenuIcon.defaultProps = {
  theme: 'default',
  customThemeStyles: {},
  masterTheme: 'plus'
};

export default withStyles(Styles)(MoreMenuIcon);
