/* eslint-disable consistent-return */
/* eslint-disable arrow-body-style */

/**
 *  PEARSON PROPRPIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file FreeResponseQuiz.jsx
 * @author Sarathkumar Venkatachalam
 */

/* eslint-disable jsx-a11y/label-has-for */
import React, {
  useState, useEffect, useRef, memo
} from 'react';
import { intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Link,
  Typography
} from '@material-ui/core';
import { Utilities } from '@aquila/core';
import ThemeConstants from '../../Common/ThemeConstants';
import messages from '../../defaultMessages';
import * as constants from '../../Common/constants';
import globalContext from '../../Context/globalContext';
import { getThemeValue } from '../../Common/CustomStyles/customStyleUtils';

const styles = () => ({
  quizContainer__question__title: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
    margin: 0,
    marginBottom: 8
  },
  quizContainer__question__description: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
    margin: 0,
    marginBottom: 8
  },
  visuallyHidden: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 1,
    display: 'block'
  },
  lessContentStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
  },
  quizContainer__options__label__correctAnswer: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: (props) => `${(getThemeValue(props, 'root', 'textColor', false, 'textColor'))} !important`,
    opacity: 'initial !important',
    fontWeight: '400 !important'
  },
  quizContainer__options__label__wrongAnswer: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: (props) => `${(getThemeValue(props, 'root', 'textColor', false, 'textColor'))} !important`,
    opacity: 'initial !important',
    fontWeight: '400 !important',
    padding: '2.5px 0'
  },
  quizContainer__options__label__default: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: (props) => `${(getThemeValue(props, 'root', 'textColor', false, 'textColor'))} !important`,
    padding: '2.5px 0px',
    fontWeight: '400 !important'
  },
  quizContainer__actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: 8
  },
  quizContainer__actions__buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 24
  },
  quizContainer__actions__buttons__skip: {
    textDecoration: 'underline',
    width: 'auto',
    // width: 34,
    height: 24,
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    textDecorationLine: 'underline',
    color: (props) => (getThemeValue(props, 'anchorLink', 'textColor', false, 'anchorLinkColor')),
    cursor: 'pointer'
  },
  quizContainer__actions__buttons__next: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 24px',
    width: 106,
    height: 40,
    background: (props) => (getThemeValue(props, 'secondarySendBtn', 'bgColor', false, 'bgColor')),
    border: '1px solid #BCC1CB',
    borderRadius: 18,
    cursor: 'pointer',
    textTransform: 'none',
    fontSize: 18,
    fontWeight: 700,
    color: (props) => `${(getThemeValue(props, 'root', 'textColor', false, 'textColor'))} !important`,
    fontFamily: 'TT Commons'
  },
  disableRadioLabelStyle: {
    opacity: '0.8'
  },
  disableRadioStyle: {
    opacity: 'initial !important'
  },
  disabledStyle: {
    opacity: '.5',
    color: (props) => `${(getThemeValue(props, 'root', 'textColor', false, 'textColor'))} !important`
  },
  '@keyframes horizontal-shaking': {
    '0%': { transform: 'translateX(0)' },
    '25%': { transform: 'translateX(10px)' },
    '50%': { transform: 'translateX(-10px)' },
    '75%': { transform: 'translateX(10px)' },
    '100%': { transform: 'translateX(0)' }
  },
  feedbackButton: {
    padding: 8
  },
  defaultRadio: {
    width: 20,
    height: 20,
    background: (props) => `url(${ThemeConstants[props.masterTheme][props.theme].defaultRadio}) center no-repeat !important`
  },
  correctAnswerStyle: {
    // color: '#008638 !important',
    width: 20,
    height: 20,
    background: (props) => `url(${ThemeConstants[props.masterTheme][props.theme].correctSelectedBG}) center no-repeat !important`,
    padding: '0px 5px 5px 0px'
  },
  wrongAnswerStyle: {
    // color: '#DB0020 !important',
    width: 20,
    height: 20,
    background: (props) => `url(${ThemeConstants[props.masterTheme][props.theme].wrongSelectedBG}) center no-repeat !important`,
    padding: '5px 5px 5px 0px'
  },
  radioRootStyle: {
    padding: '5px !important',
    alignSelf: 'flex-start',
    marginTop: 0,
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor')),
    '&:hover': {
      background: (props) => `${ThemeConstants[props.masterTheme][props.theme].radioHoverColor} !important`
    },
    '&:focus': {
      background: (props) => `${ThemeConstants[props.masterTheme][props.theme].radioHoverColor} !important`
    },
    '&.Mui-focusVisible': {
      background: (props) => `${ThemeConstants[props.masterTheme][props.theme].radioHoverColor} !important`
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20
    }
  },
  selectedStyle: {
    width: 20,
    height: 20,
    background: (props) => `url(${ThemeConstants[props.masterTheme][props.theme].radioSelectedBG}) center no-repeat !important`
  },
  labelStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    color: (props) => (getThemeValue(props, 'root', 'textColor', false, 'textColor'))
  },
  selectedLabel: {
    fontWeight: '400 !important',
    color: (props) => `${(getThemeValue(props, 'root', 'textColor', false, 'textColor'))} !important`
  },
  labelRootStyle: {
    padding: 0,
    display: 'flex',
    alignItems: 'center'
  },
  correctAnswer: {
    color: '#008638 !important',
    display: 'flex'
  },
  wrongAnswer: {
    color: '#DB0020 !important',
    display: 'flex'
  },
  doneAction: {
    textDecoration: 'underline',
    // width: '100%',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    textDecorationLine: 'underline',
    color: (props) => (getThemeValue(props, 'anchorLink', 'textColor', false, 'anchorLinkColor')),
    cursor: 'pointer',
    textAlign: 'right'
  },
  doneContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 15,
    marginRight: 10
  }
});

const YES = 'yes';
const FreeResponseQuiz = memo((props) => {
  const {
    classes,
    message,
    lessContent,
    isReadyNext,
    isLastQuestion,
    displayQuestion,
    currentQuestion,
    handleNextQuizQuestion,
    intl,
    pageIds,
    disableOptions,
    handleQuizStop,
    showOptions,
    currentQuizQstnIndex,
    chapterData,
    totalQuestionCount
  } = props;
  const { formatMessage } = intl;
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const optionRef = useRef(null);
  const radioGroupRef = useRef(null);
  useEffect(() => {
    if (optionRef && optionRef.current) {
      optionRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (isLastQuestion) handleQuizStop(true);
  }, [isLastQuestion]);

  useEffect(() => {
    if (radioGroupRef.current) {
      radioGroupRef.current.querySelectorAll('label').forEach((item) => {
        item.querySelector('span').removeAttribute('aria-disabled');
      });
    }
  }, [radioGroupRef.current]);

  // storing userAnswer for current question and checking if choosen option is correct
  const handleOptionChoose = (event) => {
    const answerIndex = Number(event.target.value);
    setSelectedAnswerIndex(answerIndex); // preserving user selected answers into an array
  };
  // setting dynamic classes to label tag upon validation
  const getClassName = (optionId, isCorrect) => {
    if (isConfirmed && selectedAnswerIndex !== null) {
      if (selectedAnswerIndex === optionId && (isCorrect && isCorrect.toLowerCase() === YES)) {
        return classes.quizContainer__options__label__correctAnswer;
      }
      if (isCorrect && isCorrect.toLowerCase() === YES) {
        return classes.quizContainer__options__label__correctAnswer;
      }
      if (selectedAnswerIndex === optionId && (isCorrect && isCorrect.toLowerCase() !== YES)) {
        return classes.quizContainer__options__label__wrongAnswer;
      }
    }
    return isConfirmed ? `${classes.quizContainer__options__label__default} ${classes.disableRadioLabelStyle}` : classes.quizContainer__options__label__default;
  };
  const getRadioStyle = (optionId, checked) => {
    // if (isConfirmed) {
    //   if (selectedAnswerIndex === optionId && selectedAnswerIndex === (currentQuestion && currentQuestion.answer.id)) {
    //     return classes.correctAnswerStyle;
    //   }
    //   if (selectedAnswerIndex === optionId && selectedAnswerIndex !== (currentQuestion && currentQuestion.answer.id)) {
    //     return classes.wrongAnswerStyle;
    //   }
    // }
    // eslint-disable-next-line no-nested-ternary
    return isConfirmed ? `${classes.radioRootStyle}` : checked ? `${classes.selectedStyle}` : `${classes.radioRootStyle}`;
  };

  // eslint-disable-next-line consistent-return
  const showCorrectOption = (optionId, isCorrect) => {
    if (isConfirmed && (isCorrect && isCorrect.toLowerCase() === YES) && selectedAnswerIndex !== null) {
      return <span className={classes.correctAnswerStyle}><span className={classes.visuallyHidden}>Correct Answer</span></span>;
    }
    return <span className={classes.defaultRadio} />;
  };

  const validateOption = (optionId, isCorrect) => {
    if (isConfirmed && selectedAnswerIndex !== null) {
      if (selectedAnswerIndex === optionId && (isCorrect && isCorrect.toLowerCase() === YES)) {
        return <span className={classes.correctAnswerStyle}><span className={classes.visuallyHidden}>Correct Answer</span></span>;
      }
      if (selectedAnswerIndex === optionId && (isCorrect && isCorrect.toLowerCase() !== YES)) {
        return <span className={classes.wrongAnswerStyle}><span className={classes.visuallyHidden}>Incorrect Answer</span></span>;
      }
    }
    return <span className={classes.selectedStyle} />;
  };

  const handleNextQuestion = (event) => {
    setIsConfirmed(true);
    const answerIdObj = currentQuestion.options.find((option) => (option.correct_choice && option.correct_choice.toLowerCase() === YES));
    const answerId = answerIdObj && answerIdObj.id;
    const answerDescription = answerIdObj && answerIdObj.distractor;
    const feedbackObj = currentQuestion.options.find((option) => (option.id === selectedAnswerIndex));
    const commandSourceCode = (event && event.key === 'Enter') ? 'type' : 'click';
    const feedback = feedbackObj && feedbackObj.feedback;
    handleNextQuizQuestion(
      currentQuestion && currentQuestion.options[selectedAnswerIndex - 1].distractor,
      selectedAnswerIndex,
      answerId,
      feedback,
      answerDescription,
      false,
      pageIds,
      false,
      commandSourceCode,
      chapterData
    );
    setTimeout(() => {
      if (radioGroupRef.current) {
        radioGroupRef.current.querySelectorAll('label').forEach((item) => {
          if (item.querySelector('span').hasAttributes('tabindex')) {
            item.querySelector('span').removeAttribute('tabindex');
          }
          if (item.querySelector('span').hasAttributes('aria-disabled')) {
            item.querySelector('span').removeAttribute('aria-disabled');
          }
        });
      }
    }, 10);
  };
  // const isFromMultipleChoiceFlow = currentQuestion && currentQuestion.options && currentQuestion.options.length;
  // const getQuestionTitle = () => {
  //   return isFromMultipleChoiceFlow ? `Question ${currentQuizQstnIndex + 1}:` : 'Question:';
  // };
  return (
    <>
      {lessContent ? (
        <Typography className={classes.lessContentStyle}>
          {lessContent}
        </Typography>
      )
        : (
          <div className={classes.questionSpace}>
            {
              message && (
                <Box id="quizQuestions">
                  <Typography className={classes.quizContainer__question__title}>{`${displayQuestion ? `Question ${currentQuizQstnIndex + 1} ${(totalQuestionCount > 0) ? ` of ${totalQuestionCount}` : ''}:` : ''} `}</Typography>
                  <Typography className={classes.quizContainer__question__description}>{Utilities.textFromContent(message, globalContext.enableNewMathConfig)}</Typography>
                </Box>
              )
            }
            {
              !(currentQuestion && currentQuestion.options && currentQuestion.options.length) && !isConfirmed && showOptions && (
                <Box>
                  <div>
                    <div className={classes.quizContainer__actions}>
                      <div className={classes.quizContainer__actions__buttons}>
                        <Link
                          href={false}
                          component="button"
                          variant="body1"
                          className={classes.quizContainer__actions__buttons__skip}
                          onClick={() => {
                            setIsConfirmed(true);
                            handleNextQuizQuestion(null, null, null, null, null, true, pageIds, constants.FREE_RESPONSE, '', chapterData);
                            // handleSkip();
                          }}
                        >
                          {formatMessage(messages.skip)}
                        </Link>
                      </div>
                    </div>
                    <div className={classes.doneContainer}>
                      <Link
                        disabled={isConfirmed || disableOptions}
                        href={false}
                        component="button"
                        variant="body1"
                        className={classes.doneAction}
                        onClick={() => {
                          setIsConfirmed(true);
                          handleQuizStop();
                        }}
                      >
                        {formatMessage(messages.no)}
                      </Link>
                    </div>
                  </div>
                </Box>
              )
            }
            {
              currentQuestion && currentQuestion.options && currentQuestion.options.length && (
                <Box>
                  <RadioGroup
                    innerRef={radioGroupRef}
                    aria-labelledby="quizQuestions"
                    name={`quiz ${classes.quizContainer__question__description}`}
                    value={selectedAnswerIndex}
                    onChange={handleOptionChoose}
                  >
                    {
                      currentQuestion && currentQuestion.options.map((option, index) => (
                        <FormControlLabel
                          value={option.id}
                          disabled={isConfirmed || disableOptions}
                          control={(
                            <Radio
                              inputRef={(index === 0) ? optionRef : null}
                              checked={Number(selectedAnswerIndex) === Number(option.id)}
                              classes={{
                                // checked: getRadioStyle(option.id, true),
                                root: getRadioStyle(option.id),
                                disabled: classes.disableRadioStyle
                              }}
                              icon={showCorrectOption(option.id, option.correct_choice)}
                              checkedIcon={validateOption(option.id, option.correct_choice)}
                            />
                          )}
                          label={Utilities.textFromContent(option.distractor, globalContext.enableNewMathConfig)}
                          classes={{
                            root: classes.labelRootStyle,
                            label: getClassName(option.id, option.correct_choice)
                          }}
                        />
                      ))
}
                  </RadioGroup>
                  {
                    !isConfirmed && !disableOptions && (
                      <div>
                        <div className={classes.quizContainer__actions}>
                          <div className={classes.quizContainer__actions__buttons}>
                            <Button
                              disabled={!selectedAnswerIndex || isConfirmed || disableOptions}
                              onClick={handleNextQuestion}
                              className={classes.quizContainer__actions__buttons__next}
                              classes={{
                                disabled: classes.disabledStyle
                              }}
                            >
                              {formatMessage(messages.submit)}
                            </Button>
                            <Link
                              disabled={isConfirmed || disableOptions}
                              href={false}
                              component="button"
                              variant="text"
                              className={classes.quizContainer__actions__buttons__skip}
                              onClick={() => {
                                setIsConfirmed(true);
                                handleNextQuizQuestion(null, null, null, null, null, true, pageIds, false, '', chapterData);
                                // handleSkip();
                              }}
                            >
                              {formatMessage(messages.skip)}
                            </Link>
                          </div>
                        </div>
                        <div className={classes.doneContainer}>
                          <Link
                            disabled={isConfirmed || disableOptions}
                            href={false}
                            component="button"
                            variant="body1"
                            className={classes.doneAction}
                            onClick={() => {
                              setIsConfirmed(true);
                              handleQuizStop();
                            }}
                          >
                            {formatMessage(messages.no)}
                          </Link>
                        </div>
                      </div>
                    )
                  }
                </Box>
              )
            }
            {
              isReadyNext && (<Typography variant="p" className={classes.questionStyle}>Ready for the next question?</Typography>)
            }
            {/* {
              isLastQuestion && (
                <Typography variant="p" className={classes.questionStyle}>
                  That was the last question. What&apos;s next?
                </Typography>
              )
            } */}
          </div>
        )}
    </>
  );
}, (prevProps, nextProps) => (JSON.stringify(prevProps) === JSON.stringify(nextProps)));

FreeResponseQuiz.defaultProps = {
  isReadyNext: false,
  isLastQuestion: false,
  message: '',
  lessContent: '',
  displayQuestion: true,
  currentQuestion: {},
  handleNextQuizQuestion: () => { },
  pageIds: [],
  disableOptions: false,
  showOptions: true,
  chapterData: {},
  totalQuestionCount: 0
};

FreeResponseQuiz.propTypes = {
  classes: PropTypes.object.isRequired,
  lessContent: PropTypes.string,
  message: PropTypes.string,
  isReadyNext: PropTypes.bool,
  isLastQuestion: PropTypes.bool,
  displayQuestion: PropTypes.bool,
  currentQuestion: PropTypes.object,
  handleNextQuizQuestion: PropTypes.func,
  intl: intlShape.isRequired,
  pageIds: PropTypes.array,
  disableOptions: PropTypes.bool,
  handleQuizStop: PropTypes.func.isRequired,
  showOptions: PropTypes.bool,
  currentQuizQstnIndex: PropTypes.number.isRequired,
  chapterData: PropTypes.object,
  totalQuestionCount: PropTypes.number
};
export default memo(withStyles(styles)(injectIntl(FreeResponseQuiz)));
