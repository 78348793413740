/* eslint-disable no-unused-vars */
/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file SummaryListContainer.jsx
 * @author Sarathkumar Venkatachalam, Vignesh Selvam, Sai Kiran
 */

import React, {
  useState, useRef, useEffect, memo
} from 'react';
import { intlShape, injectIntl } from 'react-intl';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  ClickAwayListener,
  IconButton,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Typography,
  Menu,
  MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Utilities } from '@aquila/core';
import * as constants from '../../Common/constants';
import ReadMore from './ReadMore';
import PositiveFeedback from '../../Components/FeedbackComponent/PositiveFeedback';
import NegativeFeedback from '../../Components/FeedbackComponent/NegativeFeedback';
import MoreMenuIcon from '../../Common/icons/MoreMenuIcon';
import LikeIcon from '../../Common/icons/LikeIcon';
import LikeIconFilled from '../../Common/icons/LikeIconFilled';
import DisLikeIcon from '../../Common/icons/DisLikeIcon';
import DisLikeIconFilled from '../../Common/icons/DisLikeIconFilled';
import messages from '../../defaultMessages';
import FlashCardIcon from '../../Common/icons/FlashCardIcon';
import NoteIcon from '../../Common/icons/NoteIcon';
import CopyIcon from '../../Common/icons/CopyIcon';
// import RegenerateIcon from '../../Common/icons/RegenerateIcon';
import Utils from '../../Common/Utils';
import { eventAction, gaEvents } from '../../analytics/analytics';
import ThemeConstants from '../../Common/ThemeConstants';
import BulletIcon from '../../Common/icons/BulletIcon';
import ParagraphIcon from '../../Common/icons/ParagraphIcon';
import { clickMoreActionsGAEvent, clickThumbsGAEvent } from '../../analytics/analyticsEvents';
import { getThemeValue } from '../../Common/CustomStyles/customStyleUtils';

const styles = () => ({
  orderList: {
    fontSize: '16px',
    paddingInlineStart: 20,
    margin: 0,
    lineHeight: '24px',
    listStyleType: 'disc',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    '& li': {
      fontSize: 16,
      fontFamily: 'TT Commons',
      color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
      marginBottom: 5
    }
  },
  moreIconStyle: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5
  },
  feedbackButton: {
    padding: 8
  },
  moreActionList: {
    border: '1px solid #BCC1CB',
    borderRadius: 4,
    margin: '0 auto',
    listStyle: 'none',
    background: (props) => (getThemeValue(props, 'menuList', 'bgColor', false, 'bgColor')),
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 5px rgb(0 0 0 / 12%)',
    width: 240
  },
  iconRootStyle: {
    minWidth: 20,
    marginRight: 7
  },
  textRootStyle: {
    fontSize: 16,
    fontFamily: 'TT Commons',
    color: (props) => (getThemeValue(props, 'menuList', 'textColor', false, 'textColor'))
  },
  regenerateStyle: {
    fontSize: 16,
    fontFamily: 'TT Commons',
    fontWeight: 700,
    color: '#020917',
    marginRight: 5
  },
  poperStyle: {
    zIndex: '99999 !important'
  },
  iconsTooltip: {
    padding: 8,
    width: 'fit-content',
    background: '#161C29',
    borderRadius: '4px',
    color: 'white',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.24px'
  },
  summaryTitle: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontSize: 16,
    fontWeight: 700,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  summaryDescription: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontSize: 16,
    marginBottom: 10,
    display: 'block',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  tooltipArrow: {
    color: '#161C29'
  },
  listItemRootStyle: {
    height: 24,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 16
  },
  listAlignment: {
    marginBottom: 0
  },
  formatWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  formatStyle: {
    color: (props) => ThemeConstants[props.masterTheme][props.theme].formatColor,
    fontSize: 14,
    fontFamily: 'TT Commons'
  },
  alignmentRight: {
    textAlign: 'right'
  },
  listItemWrapper: {
    '& li': {
      fontSize: 16,
      fontFamily: 'TT Commons',
      color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
      marginBottom: 5
    }
  }
});

const StreamingSummaryListContainer = memo(({
  classes,
  intl,
  bookId,
  summaryData,
  optionSelected,
  handleChatGPTCustomNote,
  pageRange,
  selectedChapter,
  pageId,
  selectedOptionType,
  chapterList,
  pageList,
  handleGAEvents,
  handleSubmitFeedback,
  theme,
  masterTheme,
  isFromCSMenu,
  botData,
  onFormatChange,
  showFormat,
  onCopy,
  onFlashCardClick,
  feedbackIconFocusId,
  pageNums,
  pagesRange,
  selectedContent,
  chapterId,
  chapterTitle,
  topicTitle,
  streamStatus,
  sourceType,
  chapterData,
  customThemeStyles
}) => {
  const { formatMessage } = intl;
  const [showMoreActionOptions, setMoreActionOptions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPositiveEl, setAnchorPositiveEl] = useState(null);
  const [anchorNegativeEl, setAnchorNegativeEl] = useState(null);
  const [showPositiveFeedbackPopUp, setShowPositiveFeedbackPopUp] = useState(false);
  const [showNegativeFeedbackPopUp, setShowNegativeFeedbackPopUp] = useState(false);
  const [isFormat, setIsFormat] = useState(showFormat);
  const [postiveFeedbackFlag, setPostiveFeedbackFlag] = useState(false);
  const [negativeFeedbackFlag, setNegativeFeedbackFlag] = useState(false);
  const [feedbackResponse, setFeedbackResponse] = useState({
    postiveFeedBackSubmitted: false, negativeFeedBackSubmitted: false
  });
  const [feedbackPressed, setFeedbackPressed] = useState({
    postiveFeedBackPressed: false, negativeFeedBackPressed: false
  });
  const likeIconRef = useRef(null);
  const disLikeIconRef = useRef(null);
  const [isClickAway, setIsClickAway] = useState(false);

  useEffect(() => {
    if (feedbackIconFocusId && feedbackIconFocusId.status && likeIconRef.current && likeIconRef.current.dataset) {
      if (feedbackIconFocusId.id === likeIconRef.current.dataset.feedbackId && feedbackIconFocusId.type === 'LIKE') {
        likeIconRef.current.focus();
      } else if (feedbackIconFocusId.id === likeIconRef.current.dataset.feedbackId && feedbackIconFocusId.type === 'DISLIKE') {
        disLikeIconRef.current.focus();
      }
    }
  }, [feedbackIconFocusId]);

  const onMoreActionClick = (event, isMoreActionDialogueOpen = false) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setMoreActionOptions(!showMoreActionOptions);
    if (typeof isMoreActionDialogueOpen === 'boolean' && isMoreActionDialogueOpen) clickMoreActionsGAEvent(handleGAEvents, constants.GPT_EVENTS.SUMMARY, bookId);
  };

  const handleCopy = () => {
    onMoreActionClick();
    if (summaryData.requestId) {
      const copyText = document.getElementById(summaryData.requestId).innerHTML;
      Utils.exportCopyText(copyText);
      onCopy();
    }

    const additionalFields = {
      event_label: 'Copy',
      event_value: null,
      event_action: eventAction.selectOutputType,
      chat_type: constants.GPT_EVENTS.SUMMARY
    };
    gaEvents(handleGAEvents, additionalFields);
  };

  const getChapterDetails = () => {
    let chapterDetails = {
      chapterId: '',
      chapterTitle: ''
    };
    let filteredChapterList = null;
    const pageArray = [];
    switch (selectedOptionType) {
      case constants.CURRENT_PAGE:
      case constants.CURRENT_SECTION:
        chapterDetails = (pageId.length && chapterList.filter((chapter) => chapter.pageIds.find((pageNo) => pageNo === pageId[0]))[0]) || {};
        break;
      case constants.PAGE_RANGE:
        pageList.filter((page) => (page.pageNo >= Number(pageRange.startIndex)
           && page.pageNo <= Number(pageRange.endIndex)) && pageArray.push(page.pageId));
        filteredChapterList = chapterList.filter((chapter) => chapter.pageIds.find((pageNo) => pageArray.find((item) => item === pageNo)));
        if (filteredChapterList.length === 1) {
          chapterDetails = filteredChapterList[0];
        }
        break;
      case constants.CHAPTERS:
        filteredChapterList = chapterList.filter((chapter) => selectedChapter.find((item) => item === chapter.chapterTitle));
        if (filteredChapterList.length === 1) {
          chapterDetails = filteredChapterList[0];
        }
        break;
      case constants.CURRENT_CHAPTER:
        chapterDetails = {
          chapterId,
          chapterTitle
        };
        break;
      default:
        chapterDetails = {
          chapterId: '',
          chapterTitle: ''
        };
        break;
    }
    return chapterDetails || {};
  };

  const handleFlashCard = () => {
    onMoreActionClick();
    onFlashCardClick(summaryData.apiContent, [], chapterData);
    // handleChatGPTFlashCard(text);
    const additionalFields = {
      event_label: 'Save as Flashcard',
      event_value: null,
      event_action: eventAction.selectOutputType,
      chat_type: constants.GPT_EVENTS.SUMMARY
    };
    gaEvents(handleGAEvents, additionalFields);
  };

  const handleShowPositiveFeedbackPopUp = (event) => {
    setFeedbackResponse({ postiveFeedBackSubmitted: !feedbackResponse.postiveFeedBackSubmitted, negativeFeedBackSubmitted: false });
    setFeedbackPressed({ postiveFeedBackPressed: !feedbackPressed.postiveFeedBackPressed, negativeFeedBackPressed: false });
    setAnchorPositiveEl(anchorPositiveEl ? null : event.currentTarget);
    if (!feedbackResponse.postiveFeedBackSubmitted) setShowPositiveFeedbackPopUp(!showPositiveFeedbackPopUp);
    const option = optionSelected === constants.BULLET ? constants.GPT_EVENTS.BULLET_POINT_TYPE : constants.GPT_EVENTS.PARAGRAPH_TYPE;
    clickThumbsGAEvent(handleGAEvents, constants.GPT_EVENTS.UP, !feedbackResponse.postiveFeedBackSubmitted, option, constants.SUMMARY);
  };

  const handleShowNegativeFeedbackPopUp = (event) => {
    setFeedbackResponse({ postiveFeedBackSubmitted: false, negativeFeedBackSubmitted: !feedbackResponse.negativeFeedBackSubmitted });
    setFeedbackPressed({ postiveFeedBackPressed: false, negativeFeedBackPressed: !feedbackPressed.negativeFeedBackPressed });
    setAnchorNegativeEl(anchorNegativeEl ? null : event.currentTarget);
    if (!feedbackResponse.negativeFeedBackSubmitted) setShowNegativeFeedbackPopUp(!showNegativeFeedbackPopUp);
    const option = optionSelected === constants.BULLET ? constants.GPT_EVENTS.BULLET_POINT_TYPE : constants.GPT_EVENTS.PARAGRAPH_TYPE;
    clickThumbsGAEvent(handleGAEvents, constants.GPT_EVENTS.DOWN, !feedbackResponse.negativeFeedBackSubmitted, option, constants.SUMMARY);
  };

  const handleClose = (componentType, submitted = false, feedbackIdRef = null) => {
    if (feedbackIdRef) {
      likeIconRef.current.dataset.feedbackId = feedbackIdRef;
      disLikeIconRef.current.dataset.feedbackId = feedbackIdRef;
    }
    setIsClickAway(false);
    let payloadInfo = {
      type: 'summary',
      requestId: summaryData.requestId,
      responseId: summaryData.responseId,
      userComment: '',
      userSelectedOptions: [],
      data: summaryData.apiContent
    };
    if (componentType === constants.POSITIVE_FEEDBACK) {
      setShowPositiveFeedbackPopUp(false);
      setAnchorPositiveEl(null);
      payloadInfo = { ...payloadInfo, feedbackType: 'LIKE' };
      if (!submitted) likeIconRef.current.focus();
    }
    if (componentType === constants.NEGATIVE_FEEDBACK) {
      setShowNegativeFeedbackPopUp(false);
      setAnchorNegativeEl(null);
      payloadInfo = { ...payloadInfo, feedbackType: 'DISLIKE' };
      if (!submitted) disLikeIconRef.current.focus();
    }
    if (!submitted) {
      if (!postiveFeedbackFlag && componentType === constants.POSITIVE_FEEDBACK) {
        handleSubmitFeedback(payloadInfo, handleClose, false, likeIconRef.current.dataset.feedbackId);
        setPostiveFeedbackFlag(true);
      } else if (!negativeFeedbackFlag && componentType === constants.NEGATIVE_FEEDBACK) {
        handleSubmitFeedback(payloadInfo, handleClose, false, disLikeIconRef.current.dataset.feedbackId);
        setNegativeFeedbackFlag(true);
      }
    }
  };

  const handleSubmitPositiveFeedback = (value) => {
    const payloadInfo = {
      type: 'summary',
      requestId: summaryData.requestId,
      responseId: summaryData.responseId,
      feedbackType: 'LIKE',
      userComment: value,
      userSelectedOptions: [],
      data: summaryData.apiContent
    };
    setPostiveFeedbackFlag(true);
    // const additionalFields = {
    //   event_label: null,
    //   event_value: null,
    //   event_action: eventAction.providePositiveFeedback,
    //   input: value
    // };
    // gaEvents(handleGAEvents, additionalFields);
    handleSubmitFeedback(payloadInfo, handleClose, true, likeIconRef.current.dataset.feedbackId);
  };
  const handleSubmitNegativeFeedback = (value, selectedOptions) => {
    const payloadInfo = {
      type: 'summary',
      requestId: summaryData.requestId,
      responseId: summaryData.responseId,
      feedbackType: 'DISLIKE',
      userComment: value,
      userSelectedOptions: selectedOptions,
      data: summaryData.apiContent
    };
    const additionalFields = {
      event_label: value,
      event_value: null,
      event_action: eventAction.clickSubmitNegativeFeedback,
      multi_input: selectedOptions
    };
    gaEvents(handleGAEvents, additionalFields);
    setNegativeFeedbackFlag(true);
    handleSubmitFeedback(payloadInfo, handleClose, true, disLikeIconRef.current.dataset.feedbackId);
  };

  const optionText = optionSelected === constants.BULLET ? 'bullet point' : 'paragraph';
  let typeText = 'the current eTextbook page';
  if (pageNums && Array.isArray(pageNums) && pageNums.length && !(pagesRange && pagesRange.length) && sourceType) {
    typeText = `${sourceType}${pageNums.length > 1 ? 's' : ''} ${pageNums.join()}`;
  } else if (pageNums && Array.isArray(pageNums) && pageNums.length && !(pagesRange && pagesRange.length)) {
    typeText = `page${pageNums.length > 1 ? 's' : ''} ${pageNums.join()}`;
  } else if (pagesRange && pagesRange.length && pagesRange[0] && pagesRange[0].start && pagesRange[0].end) {
    typeText = `pages ${pagesRange[0].start} - ${pagesRange[0].end}`;
  } else if (pageRange && pageRange.startIndex && pageRange.endIndex) {
    typeText = `pages ${pageRange.startIndex}-${pageRange.endIndex}`;
  } else if (selectedChapter && selectedChapter.length) {
    typeText = `chapter${(selectedChapter.length > 1) ? 's' : ''} `;
    typeText += Utils.concatChapterTitle(selectedChapter);
  } else if (selectedOptionType === constants.CURRENT_SECTION) {
    typeText = 'the current section';
  } else if (selectedOptionType === constants.CURRENT_CHAPTER) {
    typeText = 'the whole chapter';
  } else if (topicTitle) {
    typeText = `${topicTitle}:`;
  }

  const handleNote = () => {
    onMoreActionClick();
    const chapterDetails = getChapterDetails();
    handleChatGPTCustomNote(summaryData.apiContent, chapterDetails.chapterId, chapterDetails.chapterTitle);
    const additionalFields = {
      event_label: 'Save as Note',
      event_value: null,
      event_action: eventAction.selectOutputType,
      chat_type: constants.GPT_EVENTS.SUMMARY
    };
    gaEvents(handleGAEvents, additionalFields);
  };

  const handleFormatChange = () => {
    setIsFormat(false);
    onFormatChange(optionSelected === constants.BULLET ? constants.PARAGRAPH : constants.BULLET, pageRange, selectedChapter, (botData && botData.pageIds) || (summaryData && summaryData.pageIds), (botData && botData.pageNums) || (summaryData && summaryData.pageNums) || pageNums, selectedContent, pagesRange);
  };

  const handleClickAway = (type) => {
    setIsClickAway(true);
    setTimeout(() => {
      handleClose(type);
    }, 200);
  };

  return (
    <Box>
      <Box aria-label={summaryData.streamStatus && summaryData.streamStatus !== constants.DONE ? 'Summary streaming in progress' : 'Summary streaming completed'} />
      {
         summaryData.content && (
           <Box aria-hidden={summaryData.streamStatus && summaryData.streamStatus !== constants.DONE}>
             <Typography variant="paragraph" className={classes.summaryDescription}>
               {
                 isFromCSMenu && !(pageNums && pageNums.length > 0)
                   ? 'Here is the summary of the content of the eTextbook content you selected' : `Here is the summary of the content from ${typeText}`
               }
               , in
               {' '}
               {optionText}
               {' '}
               format:
             </Typography>
             <Typography variant="h6" className={classes.summaryTitle}>
               Summary
             </Typography>
             <ReadMore
               isStreamingMode
               streamStatus={streamStatus}
               summaryData={(
                 <div className={classes.listItemWrapper}>
                   { Utilities.textFromContent(summaryData.content) }
                 </div>
              )}
               masterTheme={masterTheme}
               theme={theme}
               customThemeStyles={customThemeStyles}
               requestId={summaryData.requestId}
             />
           </Box>
         )
       }
      {
        (summaryData.streamStatus && summaryData.streamStatus === constants.DONE) && (
          <Box className={isFormat ? classes.actionContainer : classes.alignmentRight}>
            {
            isFormat && (
            <div className={classes.formatWrapper}>
              <Typography variant="p" className={classes.formatStyle}>Format to</Typography>
              <Tooltip
                classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }}
                title={optionSelected === constants.BULLET ? 'Paragraph' : 'Bullet points'}
                placement="top"
                arrow
              >
                <IconButton
                  className={classes.feedbackButton}
                  onClick={handleFormatChange}
                >
                  {
                    optionSelected === constants.BULLET ? <ParagraphIcon masterTheme={masterTheme} theme={theme} /> : <BulletIcon masterTheme={masterTheme} theme={theme} />
                  }
                </IconButton>
              </Tooltip>
            </div>
            )
          }
            <div>
              <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-label="Report as positive" title="Report as positive" placement="top" arrow>
                <IconButton ref={likeIconRef} className={classes.feedbackButton} onClick={(event) => handleShowPositiveFeedbackPopUp(event)} aria-label="Report as positive" aria-pressed={feedbackPressed.postiveFeedBackPressed}>
                  {feedbackResponse.postiveFeedBackSubmitted ? <LikeIconFilled masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} /> : <LikeIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />}
                </IconButton>
              </Tooltip>
              <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-label="Report as negative" title="Report as negative" placement="top" arrow>
                <IconButton ref={disLikeIconRef} className={classes.feedbackButton} onClick={(event) => handleShowNegativeFeedbackPopUp(event)} aria-label="Report as negative" aria-pressed={feedbackPressed.negativeFeedBackPressed}>
                  {feedbackResponse.negativeFeedBackSubmitted ? <DisLikeIconFilled masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} /> : <DisLikeIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />}
                </IconButton>
              </Tooltip>
              <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} aria-label={constants.MORE_ACTIONS} aria-haspopup title={formatMessage(messages.moreActions)} placement="top" arrow>
                <IconButton className={classes.feedbackButton} onClick={(event) => onMoreActionClick(event, true)} aria-label={constants.MORE_ACTIONS}>
                  <MoreMenuIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />
                </IconButton>
              </Tooltip>
              {/* <Tooltip classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }} title="Regenerate response" placement="top" arrow>
              <IconButton className={classes.feedbackButton} onClick={handleRegenerate}>
                <RegenerateIcon />
              </IconButton>
            </Tooltip> */}
            </div>
          </Box>
        )
       }

      {
         showMoreActionOptions && (
         <Menu
           id="moreActionOptions"
           open={showMoreActionOptions}
           anchorEl={anchorEl}
           classes={{ paper: classes.moreActionList }}
           className={classes.poperStyle}
           keepMounted
           onClose={onMoreActionClick}
         >
           <MenuItem
             button
             onClick={handleFlashCard}
             classes={{
               root: classes.listItemRootStyle
             }}
           >
             <ListItemIcon classes={{ root: classes.iconRootStyle }}>
               <FlashCardIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />
             </ListItemIcon>
             <ListItemText classes={{ primary: classes.textRootStyle }}>
               {formatMessage(messages.saveFlashcards)}
             </ListItemText>
           </MenuItem>
           <MenuItem
             button
             onClick={handleNote}
             classes={{
               root: classes.listItemRootStyle
             }}
           >
             <ListItemIcon classes={{ root: classes.iconRootStyle }}>
               <NoteIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />
             </ListItemIcon>
             <ListItemText classes={{ primary: classes.textRootStyle }}>
               {formatMessage(messages.saveNote)}
             </ListItemText>
           </MenuItem>
           <MenuItem
             button
             classes={{
               root: `${classes.listItemRootStyle} ${classes.listAlignment}`
             }}
             onClick={handleCopy}
           >
             <ListItemIcon classes={{ root: classes.iconRootStyle }}>
               <CopyIcon masterTheme={masterTheme} theme={theme} customThemeStyles={customThemeStyles} />
             </ListItemIcon>
             <ListItemText classes={{ primary: classes.textRootStyle }}>
               {formatMessage(messages.copy)}
             </ListItemText>
           </MenuItem>
         </Menu>
         )
       }
      {
         showPositiveFeedbackPopUp && (
         <ClickAwayListener
           mouseEvent="onMouseDown"
           touchEvent="onTouchStart"
           onClickAway={() => { handleClickAway(constants.POSITIVE_FEEDBACK); }}
         >
           <PositiveFeedback
             handleSubmitPositiveFeedback={handleSubmitPositiveFeedback}
             handleClose={handleClose}
             masterTheme={masterTheme}
             theme={theme}
             isClickAway={isClickAway}
           />
         </ClickAwayListener>
         )
       }
      {
         showNegativeFeedbackPopUp && (
         <ClickAwayListener
           mouseEvent="onMouseDown"
           touchEvent="onTouchStart"
           onClickAway={() => { handleClickAway(constants.NEGATIVE_FEEDBACK); }}
         >
           <NegativeFeedback
             handleSubmitNegativeFeedback={handleSubmitNegativeFeedback}
             handleClose={handleClose}
             masterTheme={masterTheme}
             theme={theme}
             isClickAway={isClickAway}
           />
         </ClickAwayListener>
         )
       }
    </Box>
  );
}, (prevProps, nextProps) => (JSON.stringify(prevProps) === JSON.stringify(nextProps)));

StreamingSummaryListContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  summaryData: PropTypes.object,
  optionSelected: PropTypes.string,
  feedbackIconFocusId: PropTypes.object,
  handleChatGPTCustomNote: PropTypes.func.isRequired,
  pageRange: PropTypes.object,
  selectedChapter: PropTypes.array,
  pageId: PropTypes.array,
  selectedOptionType: PropTypes.string.isRequired,
  chapterList: PropTypes.array.isRequired,
  pageList: PropTypes.array.isRequired,
  handleGAEvents: PropTypes.func.isRequired,
  handleSubmitFeedback: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  masterTheme: PropTypes.string.isRequired,
  isFromCSMenu: PropTypes.string,
  botData: PropTypes.object,
  bookId: PropTypes.string.isRequired,
  showFormat: PropTypes.bool,
  onFormatChange: PropTypes.func,
  onFlashCardClick: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  pageNums: PropTypes.array,
  pagesRange: PropTypes.array,
  selectedContent: PropTypes.string,
  chapterId: PropTypes.string,
  chapterTitle: PropTypes.string,
  topicTitle: PropTypes.string,
  streamStatus: PropTypes.string,
  sourceType: PropTypes.string,
  customThemeStyles: PropTypes.object,
  chapterData: PropTypes.object
};

StreamingSummaryListContainer.defaultProps = {
  pageRange: {},
  selectedChapter: [],
  isFromCSMenu: '',
  botData: {},
  feedbackIconFocusId: {},
  summaryData: {},
  customThemeStyles: {},
  optionSelected: '',
  showFormat: false,
  onFormatChange: () => {},
  pageNums: [],
  pagesRange: [],
  selectedContent: '',
  chapterId: '',
  chapterTitle: '',
  pageId: [],
  topicTitle: '',
  streamStatus: '',
  sourceType: '',
  chapterData: {}
};

export default memo(withStyles(styles)(injectIntl(StreamingSummaryListContainer)));
