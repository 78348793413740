/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * @author Mohamed yasar arabath M
 */

import { getSnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';

import HeroDashboardPlugin from '../../authhome/plugin/HeroDashboardPlugin';
import CommonUtils from '../../../common/utils/CommonUtils';

export default class MLMHeroDashboardPlugin {
  constructor(context) {
    this.context = context;
  }

  getDeeplinkBook = () => {
    const deeplink = { deeplinkBook: null, isCourseFound: false, isValidMLMRoute: false };
    const {
      match,
      bookshelf
    } = this.context?.props;
    const mlmBookData = CommonUtils.getMLMRouteBookData(match) || {};
    const mlmCourseId = mlmBookData?.courseId || null;
    const mlmBookId = mlmBookData?.bookId || null;

    if (mlmBookId || mlmCourseId) {
      deeplink.isValidMLMRoute = true;
      const books = getSnapshot(bookshelf.books);
      deeplink.deeplinkBook = books.find(book => (
        book?.product_entitlements?.expired === false // first checking book was not expired
        && ((book.book_id === mlmBookId || book.product_id === mlmBookId) // second of one checking bookId match
        && book.course_id === mlmCourseId) // second of second checking course_id match
      ));
      if (!deeplink.deeplinkBook) {
        deeplink.deeplinkBook = books.find(book => (
          book?.product_entitlements?.expired === false // first checking book was not expired
          && (book.book_id === mlmBookId || book.product_id === mlmBookId) // second of one checking bookId match
        ));
        deeplink.isCourseFound = false;
      } else {
        deeplink.isCourseFound = true;
      }
    }

    return deeplink;
  }

  setHeroDashboard = () => {
    const {
      bookshelf
    } = this.context?.props;
    const { deeplinkBook, isCourseFound, isValidMLMRoute } = this.getDeeplinkBook();
    const books = getSnapshot(bookshelf.books);
    HeroDashboardPlugin.activeCourseTab = {};

    if (deeplinkBook) {
      HeroDashboardPlugin.activeCourseTab = this.context.coursePlugin.getBookData(deeplinkBook);
      if (!isCourseFound) {
        /**
         * If no course found atleast we need to load the book
         *
         */
        const bookIndex = books.findIndex(book => (book.book_id === deeplinkBook.book_id));
        const bookData = { ...deeplinkBook };
        bookData.book_title = bookData.title;
        bookData.course_id = null;
        bookData.courses = [];
        bookData.hasMultipleCourse = false;
        const renderBooks = [...books];
        renderBooks.splice(bookIndex, 0, bookData);
        bookshelf.set({ books: renderBooks });
        HeroDashboardPlugin.activeCourseTab = this.context.coursePlugin.getBookData(bookData);
      }
    } else if (isValidMLMRoute) {
      // If no bookId found in bookshelf and it is a valid MLM route redirect to subscription error page

      Framework.redirectToErrorPage('SUBSCRIPTION_GENERIC');
    } else {
      // If route is /home2
      const activeBooks = CommonUtils.getActiveBooks(books);

      if (activeBooks.length > 0) {
        HeroDashboardPlugin.activeCourseTab = this.context.coursePlugin.getBookData(activeBooks[0]);
      }
    }

    if (!CommonUtils.isEmpty(HeroDashboardPlugin.activeCourseTab)) {
      const { bmc, courseId } = HeroDashboardPlugin.activeCourseTab;
      const isMLMcourse = CommonUtils.isMyLabSubscription(courseId, bmc);
      HeroDashboardPlugin.activeCourseTab.isMLMcourse = isMLMcourse;
    }
    const { herobanner } = this.context.props;
    this.context.coursePlugin.updateMLMPopularChannels(herobanner.popularChannels, HeroDashboardPlugin.activeCourseTab);

    this.context.heroDashboardPlugin.setMLMHeroDashboard();
  }
}
