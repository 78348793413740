/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Translation file for English language
 *
 * @file en.js
 * @author Manimaran.S
 */

const en = {
  'popularChannelSlider.allChannels': 'All Channels',
  'popularChannelSlider.recommended': 'Recommended',
  'channel.noBundleRelatedtitle': 'Browse Study & Exam Prep related to your other classes.',
  'hero.myetextbook.link': 'library',
  'hero.myetextbook.text': 'Not the right title? Access your eTextbook',
  'hero.ctanewtext': 'Open Study & Exam Prep',
  'hero.welcomeToPPlus': 'Welcome to Pearson+',
  'hero.etextfeaturestitle': 'Find study materials in your eTextbook',
  'hero.etextfeaturesdescription': 'Your eTextbook comes with study materials designed to help you succeed. Study with flashcards and more right in your eTexbook.',
  'aichat.title1': 'Get AI-powered help for anything related to this eTextbook.',
  'aichat.chatDesc1': 'Type a question to start',
  'aichat.title2': 'Get AI-powered help',
  'aichat.chatDesc2': 'Ask about anything related to this eTextbook',
  'subscriptiontext.isSubscribed': 'Part of your subscription',
  'subscriptiontext.nonSubscriber': 'Upgrade for full access',
  'topicConatiner.otherHeader': 'Related practice and video lessons',
  'topicConatiner.exactLiteHeader': 'Related practice',
  'authHome.h1': 'Authenticated Home',
  'moremenu.downloadApp': 'Download the app',
  'moremenu.downloadAppAlreadyDownload': '%Click here% to download the app on another device and automatically sign in or to use your unique QR code to securely sign in on the app you\'ve already installed.',
  'moremenu.downloadAppNotDownload': '%Click here% to download the app and securely sign in with your unique QR code.',
  'channel.noBundletitle': 'Use the Study & Exam Prep for your related & other courses',
  'onboard.next': 'Next',
  'onboard.studyPlan': 'Custom study plan created!',
  'onboard.addCourseAnother': 'Do you want to add another course?',
  'onboard.purchase': 'I need to purchase a Pearson eTextbook',
  'onboard.addCourseWithoutEtext': 'Add course using Study & Exam Prep',
  'onboard.addCourseWithoutEtextBundle': 'Add course using Study & Exam Prep',
  'onboard.doYouWantOtherCourse': 'Do you want to another course?',
  'onboard.mayBeLater': 'Maybe later',
  'onboard.aboutCourse': 'Tell us about your course',
  'onboard.close': 'Close',
  'course.addTitle': 'Add course',
  'bookshelf.TITLE': 'My Bookshelf',
  'common.LOGOUT': 'Logout',
  'common.GO_HOME': 'Go Home',
  'common.PURCHASE': 'Purchase',
  'common.CLOSE': 'Close',
  'courses.WELCOME': 'Hello {userName}, welcome to your {courseName} course!',
  'courses.action.SHOW_ASSIGNMENTS': 'Show Assignments',
  'courses.toc.PUBLISH_SUCCESS': 'Success!',
  'courses.toc.PUBLISH_MESSAGE': 'Students will see your changes the next time they open content.',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'Subscription Not Found',
  'errors.SUBSCRIPTION_GENERIC_DESC': `Your account does not include access to this title. If you think you have received this message in error please contact Pearson Technical Support`,
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'Subscription Not Found',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': `Your subscription does not include access to the eTextbook, however you can subscribe to it right now using a credit card.`,
  'foxit.ABOUT': 'About',
  'foxit.COPY_RIGHT': 'PDF Reader Powered by Foxit. Copyright (C) 2003-2019 by Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/higher-education/products-services-teaching/course-content/textbooks-and-etexts/pearson-etext/accessibility.html',
  'link.PRIVACY': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonPrivacyPolicy.html',
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/',
  'link.TOSURL': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonEULA.html',
  'link.FAQS_NONMOJO': '',
  'link.CHAT_WITH_SUPPORT_NONMOJO': 'https://support.pearson.com/getsupport/s/',
  'link.TERMS_OF_USE_NONMOJO': 'https://www.pearson.com/en-us/legal-information/terms-of-use.html',
  'link.PRIVACY_NOTICE_NONMOJO': 'https://www.pearson.com/en-us/legal-information/privacy-policy.html',
  'link.PEARSON_ACCESSIBILITY_NONMOJO': 'https://www.pearson.com/us/higher-education/products-services-teaching/course-content/textbooks-and-etexts/pearson-etext/accessibility.html',
  'link.ACCESSIBILITY_SUPPORT_NONMOJO': '',
  'menu.myAccount': 'My account',
  'menu.helpCenter': 'Help center',
  'menu.preferencesCenter': 'Preferences center',
  'menu.CONTACT_SUPPORT': 'Contact Support',
  'menu.HELP_SUPPORT': 'Help & support',
  'menu.FAQS': 'FAQs',
  'menu.CHAT_WITH_SUPPORT': 'Chat with support',
  'menu.QUICK_WALKTHROUGH': 'Quick walkthrough',
  'menu.OTP_CODES': 'OTP codes',
  'menu.LAUNCH_CODE': 'Launch code log',
  'menu.SEND_CODE': 'Send user new code via e-mail',
  'menu.SEND_CODE_PHONE': 'Send user new code via text message',
  'menu.LEGAL': 'Legal',
  'menu.PRIVACY_NOTICE': 'Privacy notice',
  'menu.COMMUNITY_GUIDELINES': 'Community guidelines',
  'menu.TITLE_INFO': 'Title info',
  'menu.PEARSON_ACCESSIBILITY': 'Pearson accessibility',
  'menu.ABOUT': 'About',
  'menu.DEVICE_WARNING_MESSAGE': 'Register {deviceLimit} devices. Swap out 1 a month.',
  'menu.ACCESSIBILITY': 'Accessibility',
  'menu.ACCESSIBILITY_SUPPORT': 'Accessibility support',
  'menu.DEVICES': 'Recent devices',
  'menu.THIS_DEVICE': 'This Device',
  'menu.GET_THE_APP_DOWNLOAD': 'Download the Mobile app',
  'menu.MOJO_APP_INFO': 'Head to the app store on your phone or tablet to use Pearson+ offline.',
  'menu.DEVICE_LABEL': 'Device',
  'menu.DEVICE_DISCONNECT': 'Disconnect',
  'menu.FOXIT': 'Powered by FoxIT',
  'menu.TERMS_OF_USE': 'Terms of use',
  'menu.PRIVACY_POLICY': 'Privacy Policy',
  'menu.SIGN_OUT': 'Sign out',
  'menu.PRINT_PAGE': 'Print page',
  'menu.GET_LOOSE_LEAF': 'Get loose leaf',
  'menu.AVAILABLE': 'Also Available',
  'menu.PRINT_VERSION': 'Want the printed version?',
  'menu.COPY_RIGHT': 'Copyright \u00A9 {year} Pearson Education Inc. All Rights Reserved.',
  'menu.tooltip.SCHEDULE': 'Schedule',
  'print.FOOTER_MESSAGE': 'Printed by {fullName}({email}) on {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': 'Hello {userName}',
  'device.SWAPPED_ALREADY': 'You\'ve already swapped this month',
  'device.SWITCH_DEVICE': 'Switch to current device?',
  'device.SWITCH_ONCE': 'Since you can only switch once a month, you won\'t be able to undo this.',
  'device.SEAT_TAKEN': 'That seat’s taken',
  'device.DISCONNECT_DEVICE': 'You can only log in on 3 devices. To continue here, disconnect another device.',
  'device.ALERT_TEXT': 'Remember, you can only swap devices once a month.',
  'device.YOUR_MOJO': 'You’re on your way to mojo',
  'device.DISCONNECT': 'Disconnect',
  'device.CONTINUE': 'Continue',
  'device.HEADER': 'End your other browser session?',
  'device.CLOSE': 'Back to home',
  'device.CODE': 'Continue here by entering the authentication code we sent to {maskedEmail}. Not working? Check your spam folder, {email}, or contact {support}.',
  'device.TIMER': 'You have {time} to enter your code here',
  'device.RESEND': '{resend}',
  'device.FAQ': 'FAQ',
  'device.RESEND_NAME': 'Resend',
  'device.NOTE': 'If you regularly use Pearson+ in 2 places, like at work and at home, make sure to log out whenever you’re done. You can also use the Pearson+ mobile app on up to 2 devices. For more info, see our {faq}.',
  'device.NOTE_HEADER': 'Avoid this next time',
  'device.CONTACT_SUPPORT': 'customer support',
  'device.CONTACT_EMAIL': 'update your email',
  'device.CONTACT_FAQ': 'Pearson+ FAQ',
  'device.INCORRECT': 'Incorrect—double check your code or resend it.',
  'device.MANY_ATTEMPTS': 'Too many attempts, try resending the code',
  'device.EXPIRED': 'Your time expired, try resending the code',
  'device.ACCESS_HEADER': 'You’re in!',
  'device.ACCESS_DESC': 'Your session has been authenticated. Want to update your password while you’re at it?',
  'device.ACCESS_CHANGE_PASSWORD': 'Change password',
  'device.ACCESS_RESET_PASSWORD': 'Reset password',
  'device.THREATONE_TITLE': 'Everything good?',
  'device.THREATONE_DESC': 'We\'re noticing some suspicious activity on your account.',
  'device.THREATONE_DESC_TWO': 'This is a good time to change your password.',
  'device.MAY_BE_LATER': 'Maybe later',
  'device.OKAY': 'Okay',
  'device.ACCOUNT_LOCK_DESC': 'To keep it secure, we\'re logging you out. We\'ve sent a password reset link to the email associated with your account.',
  'device.ACCOUNT_LOCK_TITLE': 'We noticed some fishy activity on your account',
  'device.ACCOUNT_LOCK_TIMER': 'You\'ll be logged out in {time}',
  'navigation.PAGE': 'Page',
  'launchcode.CREATEBY': 'Created by:',
  'launchcode.sentvia': 'Sent via:',
  'launchcode.CODEINLAST': 'Codes in the last 7 days :',
  'launchcode.TIMESENT': 'Time sent: ',
  'launchcode.ACCOUNTEMAIL': 'Account e-mail :',
  'launchcode.LAUNCHDIALOGTITLE': 'OTP Code Log',
  'textmessage.TITLE': 'Enter Phone Number',
  'textmessage.PHONE_NUMBER': 'Phone Number',
  'textmessage.SEND_CODE': 'Send Code',
  'textmessage.INVALID_NUMBER': 'Invalid Phone Number',
  'textmessage.SUCCESS_MESSAGE': 'Code sent',
  'remove.title': 'Remove title?',
  'remove.subTitle': 'You can always get it back later—just use search to re-add it.',
  'remove.buttonText': 'Remove',
  'search.searchPlaceHolderPPlus': 'Search by ISBN, Title, or Author',
  'search.searchPlaceHolderEText': 'Search your library',
  'footer.downloadMobileApp': 'Download the Mobile app',
  'footer.termsOfUse': 'Terms of use',
  'footer.privacy': 'Privacy',
  'footer.cookies': 'Cookies',
  'footer.doNotSellMyPersonalInformation': 'Do not sell my personal information',
  'footer.accessibility': 'Accessibility',
  'footer.patentNotice': 'Patent notice',
  'footer.copyrights': 'Pearson All rights reserved.',
  'channel.videos': 'Videos',
  'channel.questions': 'Questions',
  'channel.descriptionA': 'Supplement your reading with Channels.',
  'channel.descriptionB': 'Get help on tough topics with Channels.',
  'channel.openchannel': 'Open Channel',
  'hero.continueReading': 'Continue reading',
  'hero.openBook': 'Open book',
  'hero.channel.open': 'Open Channel',
  'hero.addATitle': 'Add a title',
  'hero.toc.defaultPlacehoder': '--- select a topic ---',
  'hero.viewInactiveTitles': 'View inactive titles',
  'library.marketingCard': {
    title: 'Choose from over 2,000 eTextbooks',
    subTitle: 'with audio, flashcards, notes, and more.',
    primaryButton: {
      text: 'Find your eTextbook'
    },
    secondaryButton: {
      text: 'View all titles'
    }
  },
  'hero.mappedChannelV2': {
    ariaLabel: 'channel',
    heading: 'Recommended study help Channel',
    callForAction: {
      text: 'Open'
    }
  },
  'hero.openEtextBook': 'Open etextbook',
  'hero.openEtextBookV1': 'Open eTextbook',
  'hero.heroHeading': 'Because you\'re studying <span>{channel_title}</span>',
  'hero.heroHeadingForBundleUser': 'Study & Exam Prep',
  'hero.study.help': 'Get study help',
  'hero.study.jumpBackIn': 'Jump back in',
  'hero.study.numOfUsers': 'Currently there are <b>{num_of_users}</b> students using Channels.',
  'hero.heroBook.sectionHeading': 'Get unstuck in your course',
  'hero.heroBook.channelsSectionHeading': 'Start a new study session',
  'hero.heroBook.sectionHeadingNewUser': 'Study help is here',
  'hero.heroBook.sectionSubHeading': 'Break down tough topics with practice questions and  video explanations tailored to your course.',
  'hero.heroChannel.cardsHeading': 'Continue from where you left off',
  'hero.marketingcard.subheading': 'Study more efficiently with videos that break down tough concepts and extra practice problems, all tailored to your course.',
  'hero.marketingcard1.copy.title': 'Learn with videos',
  'hero.marketingcard1.copy.description': 'Watch bite size video explanations that break down tricky topics.',
  'hero.marketingcard2.copy.title': 'Practice problems',
  'hero.marketingcard2.copy.description': 'Prep for your exam with thousands of exam like questions and video solutions.',
  'hero.marketingcard3.copy.title': 'Get experienced help',
  'hero.marketingcard3.copy.description': 'Post questions and get answers from subject matter experts.',
  'hero.popularTopics.heading': 'Other popular topics in this Channel',
  'hero.popularChannels.heading': 'Popular Channels',
  'hero.popularChannels.headingv1': 'Taking another course?',
  'hero.popularChannels.description': 'Get study help with these top Channels.',
  'hero.popularChannels.descriptionv1': 'Get help in another course by choosing a channel.',
  'hero.popularChannels.descriptionv2': 'Browse popular study help topics.',
  'hero.startReading': 'Start reading',
  'hero.practice': 'Practice',
  'hero.noTitleNoChannel.sectionHeading': 'Become exam ready in record time',
  'hero.herobook.mappedChannelHeading': 'Recommended study help in this Channel',
  'libraryL2.myBooks': 'My Books',
  'libraryL2.archived': 'Archived',
  'libraryL2.back': 'Back',
  'hero.heroHeadingNoMappedChannel': 'Based on what you\'re studying',
  'hero.practiceProblem': 'Practice Problem',
  'nativeAppBanner.drawer.title': 'Open in ...',
  'nativeAppBanner.drawer.pearsonApp': 'Pearson+ App',
  'nativeAppBanner.banner.title': 'Pearson+ app',
  'nativeAppBanner.banner.description': 'Learn on the go',
  open: 'Open',
  continue: 'Continue',
  'hero.promotionalcard.list1': 'Bite size videos that break down tricky topics',
  'hero.promotionalcard.list2': 'Thousands of exam like questions',
  'hero.promotionalcard.list3': 'Get answers from subject matter experts',
  'hero.studyandexamprep': 'Study & Exam Prep',
  'hero.heroBook.sectionSubHeadingV1': 'Break down tough topics with video explanations and practice questions tailored to your course.',
  'hero.heroBook.sectionSubHeadingV2': 'Break down tough topics with concept explanations and practice questions tailored to your course.',
  'hero.SeeAllStudyOptions': 'See all study options',
  'hero.tocLabel': 'View content related to your eTextbook',
  'hero.herobook.mappedChannelHeadingV1': 'Recommended videos and practice questions',
  'hero.popularTopics.headingV1': 'Other popular topics',
  'hero.heroHeadingV1': 'For <span>{replace_value}</span>',
  'hero.learnmore': 'Learn more',
  'hero.subscriptionStatusTitle': 'Pearson+ subscription status',
  'hero.eTextbook': 'eTextbook',
  'hero.mylabOrMastering': 'MyLab or Mastering',
  'hero.goToPage.label': 'Go to page',
  'subscription.eTextbook': 'eTextbook',
  'subscription.mylab': 'MyLab or Mastering',
  'subscription.studyTitle': 'Study & Exam Prep',
  'subscription.tryItOut': 'Try it out',
  'print.printAvailTitle': 'Want to print this page? Here’s how',
  'print.printAvailDesc': 'In your eTexbook, select the account icon in the top right. From the options, choose “Print page” and then choose your print settings.',
  'print.printNotAvailTitle': 'The print option for this eText is not enabled.',
  'print.printNotAvailDesc': 'For convenient offline access, please download your title through the Pearson+ mobile app and enjoy the flexibility of studying anytime, anywhere!',
  'hero.continueStudying': 'Continue studying',
  'hero.header.cta': '{hero_header_cta_text} study tools',
  'hero.header.cta.en': 'Open study tools',
  'hero.marketing.title': 'Find your eTextbook',
  'hero.marketing.subTitle': 'Choose from over 2,000 eTextbooks with audio, flashcards, notes, and more. Only $10.99/mo for a 4-month term.',
  'hero.allyourcourses': 'all your courses',
  'hero.popularchannels.desc': 'Get study help with these top subjects',
  'hero.removecourse.title': 'Remove course from dashboard?',
  'hero.removecourse.description': 'You can always add this course back to your dashboard by clicking "Add course".',
  'hero.removecourse.primaryCtaText': 'Remove',
  'hero.removecourse.secondaryCtaText': 'Cancel',
  'hero.tableOfContents': 'Table of contents',
  'hero.search': 'Search',
  'hero.notes': 'Notes & highlights',
  'hero.subscriptionStatusTitle1': 'What\'s Available for you in Pearson+',
  'hero.emptystate.cta': 'Search eTextbooks',
  'hero.watch_it_again': 'Watch it again',
  'hero.mondly.learnLang.title': 'Learn a new language for free',
  'hero.mondly.learnLang.title2': 'Learn a new language for free!',
  'hero.mondly.subTitle': 'Pearson eTextbook users get three free months of Mondly by Pearson.',
  'hero.mondly.description': 'Most language learning apps only let you learn from English. But it\'s best to learn from your native language, so Mondly allows you to learn from any of our <strong>41 languages</strong>.',
  'hero.mondly.startLearningCTA': 'Start Learning',
  'hero.mondly.marketing.bestApp': 'Best App',
  'hero.mondly.marketing.appStore': 'App Store',
  'hero.mondly.marketing.appYear': 'App of the Year',
  'hero.mondly.marketing.fbStart': 'Facebook FbStart - 2017 EMEA Winner',
  'hero.mondly.marketing.editorChoice': 'Editor Choice',
  'hero.mondly.marketing.googlePlay': 'Google Play',
  'header.menu.learnLanguage': 'Learn a Language',
  'header.menu.moreAtPearson': 'More at Pearson',
  'hero.subscriptionStatusTitle2': 'Your Pearson+ resources',
  'hero.extractingExamDetails': 'Extracting exam details',
  'hero.finishingTheProcess': 'Finishing the process',
  'hero.hereWhatWeGotFromYourSyllabus': 'Here\'s what we got from your syllabus',
  'hero.school': 'School:',
  'hero.class': 'Class:',
  'hero.textbook': 'Textbook:',
  'hero.examSchedule': 'Exam Schedule:',
  'hero.confirm': 'Confirm',
  'hero.personalizedStudyPlan': 'We will use AI to provide you with a personalized study plan.',
  'hero.welcomeToP': 'Welcome to P+!',
  'hero.learnYourWayAround': 'Take a minute now to learn your way around, or pick this up another time in account settings.',
  'hero.youCanAddYourPearsonCourse': 'You can also add your own non-Pearson courses and try out study materials for free.',
  'hero.getExamreadyWithVideoLessons': 'Get exam-ready with video lessons and practice sets customized to your course.',
  'hero.youreTexbook': 'Your eTexbook and all of its tools are right here.',
  'hero.VideosAndPractice': 'There are always more videos and practice questions available in the Study and Exam Prep.',
  'hero.pleaseSelectTheChapters': 'Please select the chapters covered in this exam:',
  'hero.doYouWantToAddACourse': 'Do you want to add a course?',
  'hero.whatsAvailableForYou': 'What\'s Available for You',
  'hero.startStudying': 'Start studying',
  'hero.unlockYourStudyExamPrep': 'Unlock your Study & Exam Prep for other Courses now!',
  'hero.whatsAvailableForYouInPearson': 'What\'s Available for You in Pearson+',
  'onboard.studyMaterials': 'Choose your subject and we\'ll create a dashboard with customized study materials.',
  'hero.weveCreatedADashboard': 'We’ve created a dashboard for your course here.',
  'hero.getExamreadyForYou': 'Get exam-ready for your other courses using Study & Exam Prep.',
  'hero.weVideoLessons': 'We\'ve video lessons and practice sets customized to your needs.',
  'hero.tryForFree': 'Try for free',
  'hero.addCourseUsingStudyExamPrep': 'Add course using Study & Exam Prep',
  'hero.for': 'For',
  'hero.myETextbooks': 'My eTextbooks',
  'header.continueInTheApp': 'Continue in the app',
  'hero.goToMyLabMastering': 'Go to MyLab/Mastering',
  'hero.learnTxt': 'Learn',
  'hero.examPrepTxt': 'Exam prep',
  'hero.aITutorTxt': 'AI Tutor',
  'hero.exploreTxt': 'Explore',
  'hero.myLabMastering': 'MyLab/Mastering',
  'AI.toolTip': 'Ask me about anything in your eTextbook',
  'hero.studyExamPrepAllYourCourses': 'Study & Exam Prep for all your courses',
  'hero.examSchedules': 'Exam schedule',
  'hero.examSchedules2': 'Exam Schedule',
  'hero.uploadYourSyllabus': 'Upload your syllabus',
  'hero.addAnExam': 'Add an exam',
  'hero.UploadYourSyllabus.studyExamPrepPack': 'Upload your syllabus to get the most out of Study & Exam Prep',
  'hero.UploadYourSyllabus.studyExamPrep': 'Upload your syllabus to get the most out of Study & Exam Prep',
  'hero.noSyllabusSharingExamSchedule': 'No syllabus? Sharing your exam schedule works too',
  'hero.selectDropSyllabusFile': 'Select or drop a syllabus file in the PDF or DOCX format',
  'hero.uploadTxt': 'Upload',
  'hero.examName': 'Exam name',
  'hero.completeTxt': 'Complete',
  'hero.exactTopicsChapter': 'You will be able to specify the exact topics for each chapter later.',
  'hero.extractingDataFromSyllabus': 'Extracting data from the syllabus…',
  'hero.uploadingDocument': 'Uploading the document',
  'hero.activatingAIComponents': 'Activating AI components',
  'hero.extractingClassSchoolData': 'Extracting class and school data',
  'hero.extractingTextbookChapters': 'Extracting textbook chapters',
  'hero.getRecommendationsOnWhat': 'Get recommendations on what to study and when',
  'hero.dateFormat': 'DD/MM/YY',
  'header.qrCode.subHeaderTxt': 'Use this QR code to quickly and securely sign in to the Pearson+ app on your mobile device.',
  'header.qrCode.dontHaveApp': 'Don\'t have the app?',
  'header.qrCode.footerTxt': 'Scanning this code will redirect you to the App Store or Google Play. Once the app is downloaded, sign in by scanning it again.',
  'header.qrCode.errorMsg': 'The QR code didn\'t load. Close and try again.',
  'hero.yourETextbook': 'Your eTextbook',
  'onboard.addCourseWithEtextBundle': 'Add course using Study & Exam Prep',
  'onboard.disableTooltipText': 'All Study & Exam Prep courses are added!',
  'subscriptionstatus.bundlecta': 'Access Study & Exam Prep',
  'subscriptionstatus.nonbundlecta': 'Access Study & Exam Prep',
  'channel.genericBundle': 'Use your Study & Exam Prep for help in your other courses',
  'channel.relatedBundle': 'Also use your Study & Exam Prep for help in your other courses',
  'onboard.courseAdded': 'Course added!',
  'hero.flashcards': 'Flashcards',
  'aichat.title': 'AI Study Tool',
  'aichat.placeholder': 'Which topic or concept from your eTextbook can I explain for you?',
  'aichat.charDesc': 'Ask me anything related to <b>%BOOK_TITLE%</b>.',
  'relatedChannelCard.title': 'Enhance your learning with this related resource',
  'relatedChannelCard.badgeText': 'Related Study & Exam Prep',
  'sessionInactivity.title': 'Are you still there?',
  'sessionInactivity.description': 'Your session will time out in <b>%TIMER%</b> due to inactivity.<br/>Would you like to stay signed in?',
  'sessionInactivity.keepMeSignIn': 'Keep me signed in',
  'sessionInactivity.signOut': 'Sign Out',
  'sessionTimeout.title': 'Session timed out',
  'sessionTimeout.description': 'Your session has timed out due to inactivity.',
  'print.qrCode.header': 'Use this QR code to open the Pearson+ app on your mobile device.',
  'print.qrCode.footer': 'Scanning this code will redirect you to the App store or Google Play. Once the app is installed, sign in to download and access your title.'
};

export default en;
