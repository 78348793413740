/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Translation file for Korean language
 *
 * @file ko.js
 * @author Manimaran.S
 */

const ko = {
  'popularChannelSlider.allChannels': 'All Channels',
  'popularChannelSlider.recommended': 'Recommended',
  'channel.noBundleRelatedtitle': 'Browse Study & Exam Prep related to your other classes.',
  'hero.myetextbook.link': 'library',
  'hero.myetextbook.text': 'Not the right title? Access your eTextbook',
  'hero.ctanewtext': 'Open Study & Exam Prep',
  'hero.welcomeToPPlus': 'Welcome to Pearson+',
  'hero.etextfeaturestitle': 'Find study materials in your eTextbook',
  'hero.etextfeaturesdescription': 'Your eTextbook comes with study materials designed to help you succeed. Study with flashcards and more right in your eTexbook.',
  'aichat.title1': 'Get AI-powered help for anything related to this eTextbook.',
  'aichat.chatDesc1': 'Type a question to start',
  'aichat.title2': 'Get AI-powered help',
  'aichat.chatDesc2': 'Ask about anything related to this eTextbook',
  'subscriptiontext.isSubscribed': 'Part of your subscription',
  'subscriptiontext.nonSubscriber': 'Upgrade for full access',
  'topicConatiner.otherHeader': 'Related practice and video lessons',
  'topicConatiner.exactLiteHeader': 'Related practice',
  'authHome.h1': 'Authenticated Home',
  'moremenu.downloadApp': 'Download the app',
  'moremenu.downloadAppAlreadyDownload': '다른 기기에서 앱을 다운로드하려면 %Click here% 그런 다음 자동으로 로그인하거나 본인의 고유 QR 코드를 사용하여 이미 설치한 앱에서 안전하게 로그인하세요.',
  'moremenu.downloadAppNotDownload': '앱을 다운로드하려면 %Click here% 그런 다음 본인의 고유 QR 코드로 안전하게 로그인하세요.',
  'channel.noBundletitle': 'Use the Study & Exam Prep for your related & other courses',
  'onboard.next': '다음',
  'onboard.studyPlan': '맞춤형 학습 플랜이 생성되었습니다!',
  'onboard.addCourseAnother': '다른 코스를 추가하고 싶으세요?',
  'onboard.purchase': 'Pearson e텍스트북을 구입해야 합니다',
  'onboard.addCourseWithoutEtext': 'Add course using Study & Exam Prep',
  'onboard.addCourseWithoutEtextBundle': '학습·시험 준비를 이용하여 코스 추가하기',
  'onboard.doYouWantOtherCourse': '다른 코스를 추가하고 싶으세요?',
  'onboard.mayBeLater': '나중에 변경할게요',
  'onboard.close': 'Close',
  'course.addTitle': '코스 추가',
  'bookshelf.TITLE': '내 책장',
  'common.LOGOUT': '로그아웃',
  'common.GO_HOME': '홈으로 이동',
  'common.PURCHASE': '구입',
  'courses.WELCOME': '안녕하세요 {userName}님, {courseName} 코스에 오신 것을 환영합니다!',
  'courses.action.SHOW_ASSIGNMENTS': '과제 표시',
  'courses.toc.PUBLISH_SUCCESS': '성공!',
  'courses.toc.PUBLISH_MESSAGE': '다음에 학생들이 콘텐츠를 열 때 해당 변경 내용을 볼 수 있습니다.',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': '구독을 찾을 수 없습니다',
  'errors.SUBSCRIPTION_GENERIC_DESC': '귀하의 계정은 이 타이틀에 대한 액세스 권한이 없습니다. 이 메시지가 잘못 수신되었다고 판단되면 피어슨 기술 지원팀에 문의하세요',
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': '구독을 찾을 수 없습니다',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': '귀하의 구독에는 eText에 대한 액세스 권한이 없지만, 신용 카드를 사용해 지금 즉시 구독할 수 있습니다.',
  'foxit.ABOUT': '소개',
  'foxit.COPY_RIGHT': 'Foxit 제공 PDF 리더. 저작권 (C) 2003-2019 by Foxit 소프트웨어(주)',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonPrivacyPolicy.html',
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonEULA.html',
  'menu.GET_THE_APP_DOWNLOAD': '모바일 앱 다운로드하기',
  'menu.CONTACT_SUPPORT': '지원팀 문의',
  'menu.ABOUT': '소개',
  'menu.FOXIT': 'Foxit 제공',
  'menu.TERMS_OF_USE': '이용 약관',
  'menu.PRIVACY_POLICY': '개인 정보 보호 정책',
  'menu.SIGN_OUT': '로그아웃',
  'menu.PRINT_PAGE': '페이지 인쇄',
  'menu.GET_LOOSE_LEAF': '루스 리프 받기',
  'menu.AVAILABLE': '역시 이용 가능',
  'menu.PRINT_VERSION': '인쇄 버전을 원하세요?',
  'menu.COPY_RIGHT': '저작권 © {year} 피어슨 에듀케이션(주) 무단 전재를 금합니다.',
  'menu.tooltip.SCHEDULE': '스케줄',
  'print.WATERMARK': '저작권 © {year} 피어슨 에듀케이션(주)',
  'product.WELCOME': '안녕하세요 {userName}님',
  'device.SWAPPED_ALREADY': '이번 달에 이미 변경했습니다',
  'device.SWITCH_DEVICE': '현재 기기로 변경하시겠어요?',
  'device.SWITCH_ONCE': '한 달 1회 변경할 수 있으므로 이 기기를 취소할 수 없습니다.',
  'device.SEAT_TAKEN': '해당 자리가 찼습니다',
  'device.DISCONNECT_DEVICE': '3대의 기기에 한해 로그인할 수 있습니다. 여기에서 계속하려면 다른 기기를 해제하세요.',
  'device.ALERT_TEXT': '한 달에 한 번만 기기 변경이 가능하다는 점을 명심하세요.',
  'device.YOUR_MOJO': 'mojo로 이동하고 있습니다!',
  'device.DISCONNECT': '연결 해제',
  'device.CONTINUE': '계속하기',
  'device.HEADER': 'End your other browser session?',
  'device.CLOSE': '홈으로 되돌아가기',
  'device.CODE': 'Continue here by entering the authentication code we sent to {maskedEmail}. Not working? Check your spam folder, {email}, or contact {support}.',
  'device.TIMER': '코드 입력 시간이 {time} 남았습니다',
  'device.RESEND': '{resend}',
  'device.FAQ': 'FAQ',
  'device.RESEND_NAME': 'Resend',
  'device.NOTE': 'If you regularly use Pearson+ in 2 places, like at work and at home, make sure to log out whenever you’re done. You can also use the Pearson+ mobile app on up to 2 devices. For more info, see our {faq}.',
  'device.NOTE_HEADER': 'Avoid this next time',
  'device.CONTACT_SUPPORT': '',
  'device.CONTACT_EMAIL': '',
  'device.CONTACT_FAQ': '',
  'device.INCORRECT': '정확하지 않습니다. 코드를 재확인하거나 다시 전송하세요.',
  'device.MANY_ATTEMPTS': '시도 횟수가 너무 많습니다. 코드를 다시 전송하세요',
  'device.EXPIRED': '시간이 만료되었습니다. 코드를 다시 전송하세요',
  'device.ACCESS_HEADER': '성공했습니다!',
  'device.ACCESS_DESC': '세션이 인증되었습니다. 지금 비밀번호를 변경하시겠어요?',
  'device.ACCESS_CHANGE_PASSWORD': '비밀번호 변경',
  'device.ACCESS_RESET_PASSWORD': '비밀번호 재설정',
  'device.THREATONE_TITLE': '아무 문제가 없습니까?',
  'device.THREATONE_DESC': '귀하의 계정에서 일부 의심스러운 활동이 포착되었습니다.',
  'device.THREATONE_DESC_TWO': '지금 비밀번호를 변경하시는 게 좋습니다.',
  'device.MAY_BE_LATER': '나중에 변경할게요',
  'device.OKAY': '확인',
  'device.ACCOUNT_LOCK_DESC': '보안 유지를 위해 귀하를 로그아웃하는 중입니다. 귀하의 계정과 연결된 이메일 주소로 비밀번호 재설정 링크를 전송했습니다.',
  'device.ACCOUNT_LOCK_TITLE': '귀하의 계정에서 일부 의심스러운 활동이 포착되었습니다',
  'device.ACCOUNT_LOCK_TIMER': '에서 귀하는 로그아웃될 예정입니다 {time}',
  'menu.myAccount': '내 계정',
  'menu.helpCenter': '지원 센터',
  'menu.preferencesCenter': '환경 설정 센터',
  'menu.HELP_SUPPORT': '도움말 & 지원',
  'menu.FAQS': '자주 묻는 질문',
  'menu.CHAT_WITH_SUPPORT': '지원팀에 문의하세요',
  'menu.QUICK_WALKTHROUGH': '간단 설명',
  'menu.LEGAL': '법률 정보',
  'menu.PRIVACY_NOTICE': '개인정보 보호 정책',
  'menu.TITLE_INFO': '타이틀 정보',
  'menu.PEARSON_ACCESSIBILITY': 'Pearson 접근성',
  'menu.DEVICE_WARNING_MESSAGE': '{deviceLimit}대의 기기를 등록하세요. 한 달 1회 변경하세요.',
  'menu.ACCESSIBILITY': '접근성',
  'menu.ACCESSIBILITY_SUPPORT': '접근성 지원',
  'menu.DEVICES': '최근 기기',
  'menu.THIS_DEVICE': '이 기기',
  'menu.MOJO_APP_INFO': '오프라인에서 Pearson+를 이용하려면 휴대폰 또는 태블릿에서 앱 스토어로 이동하세요',
  'menu.DEVICE_LABEL': '기기',
  'navigation.PAGE': '페이지',
  'launchcode.CREATEBY': '생성자:',
  'launchcode.sentvia': '로(으로) 전송:',
  'launchcode.CODEINLAST': '지난 7일간 코드:',
  'launchcode.TIMESENT': '전송 시간:',
  'launchcode.ACCOUNTEMAIL': '계정 이메일:',
  'launchcode.LAUNCHDIALOGTITLE': 'OTP 코드 로그',
  'menu.OTP_CODES': 'OTP 코드',
  'menu.LAUNCH_CODE': '코드 로그 시작',
  'menu.SEND_CODE': '사용자에게 이메일로 새 코드를 전송',
  'menu.SEND_CODE_PHONE': '사용자에게 문자 메시지로 새 코드를 전송',
  'menu.COMMUNITY_GUIDELINES': '커뮤니티 지침',
  'textmessage.TITLE': '전화번호 입력',
  'textmessage.PHONE_NUMBER': '전화번호',
  'textmessage.SEND_CODE': '코드 전송',
  'textmessage.INVALID_NUMBER': '휴대폰 번호가 올바르지 않습니다.',
  'textmessage.SUCCESS_MESSAGE': '전송한 코드',
  'search.searchPlaceHolderPPlus': 'Search by ISBN, Title, or Author',
  'search.searchPlaceHolderEText': '나의 목록을 검색하세요',
  'remove.title': '타이틀을 제거하시겠어요?',
  'remove.subTitle': '나중에 언제든 되돌릴 수 있으니 이를 다시 추가하려면 검색을 이용하세요.',
  'remove.buttonText': '제거',
  'footer.downloadMobileApp': '모바일 앱 다운로드하기',
  'footer.termsOfUse': '이용 약관',
  'footer.privacy': '프라이버시',
  'footer.cookies': '쿠키',
  'footer.doNotSellMyPersonalInformation': '제 개인 정보를 판매하지 마세요',
  'footer.accessibility': '접근성',
  'footer.patentNotice': '특허 공지',
  'footer.copyrights': 'Pearson 무단 전재를 금합니다.',
  'channel.videos': '비디오',
  'channel.questions': '질문(들)',
  'channel.descriptionA': '채널을 통해 읽기 능력을 높이세요.',
  'channel.descriptionB': '채널을 통해 어려운 주제에 관한 도움을 받으세요.',
  'channel.openchannel': '채널 열기',
  'hero.continueReading': '계속 읽기',
  'hero.openBook': '책 열기',
  'hero.channel.open': '채널 열기',
  'hero.addATitle': '타이틀 추가하기',
  'hero.toc.defaultPlacehoder': '--- select a topic ---',
  'hero.viewInactiveTitles': '비활성 타이틀 보기',
  'library.marketingCard': {
    title: 'Choose from over 2,000 eTextbooks',
    subTitle: 'with audio, flashcards, notes, and more.',
    primaryButton: {
      text: '나의 e텍스트북 찾기'
    },
    secondaryButton: {
      text: 'View all titles'
    }
  },
  'hero.mappedChannelV2': {
    ariaLabel: 'channel',
    heading: 'Recommended study help Channel',
    callForAction: {
      text: 'Open'
    }
  },
  'hero.openEtextBook': 'e텍스트북 열기',
  'hero.openEtextBookV1': 'e텍스트북 열기',
  'hero.heroHeading': '현재 <span>{channel_title}</span>\n을(를) 공부하고 있기 때문에',
  'hero.heroHeadingForBundleUser': 'Study & Exam Prep',
  'hero.study.help': '학습 지원 받기',
  'hero.study.jumpBackIn': '이전으로',
  'hero.study.numOfUsers': '현재 <b>{num_of_users}</b> 명의 학생들이 채널을 이용하고 있습니다.',
  'hero.heroBook.sectionHeading': '코스에서 막혔던 부분을 시원하게 해결하세요',
  'hero.heroBook.channelsSectionHeading': '새로운 학습 세션을 시작하세요',
  'hero.heroBook.sectionHeadingNewUser': '학습 지원이 여기에 있습니다',
  'hero.heroBook.sectionSubHeading': '코스에 맞춘 비디오 설명과 연습 문제로 어려운 주제를 세분화해서 공부해 보세요.',
  'hero.heroChannel.cardsHeading': '중단한 지점에서 계속 진행하세요',
  'hero.marketingcard.subheading': '코스에 맞춰진, 어려운 개념을 세분화한 비디오와 추가 연습 문제로 학습 효과를 높이세요.',
  'hero.marketingcard1.copy.title': '비디오로 학습하세요',
  'hero.marketingcard1.copy.description': '까다로운 내용을 세분화한 짧은 비디오 설명을 시청하세요.',
  'hero.marketingcard2.copy.title': '연습 문제',
  'hero.marketingcard2.copy.description': '실제 시험의 느낌을 주는 수천 가지 문제와 비디오 솔루션으로 시험을 준비하세요',
  'hero.marketingcard3.copy.title': '경험이 풍부한 분들의 도움을 받으세요',
  'hero.marketingcard3.copy.description': '질문을 올린 후 해당 주제의 전문가 답변을 받아 보세요.',
  'hero.popularTopics.heading': '이 채널에서 인기 있는 다른 주제(topics)',
  'hero.popularChannels.heading': '인기 채널',
  'hero.popularChannels.headingv1': '다른 코스를 듣고 있나요?',
  'hero.popularChannels.description': '최고 수준의 채널에서 학습 지원을 받으세요.',
  'hero.popularChannels.descriptionv1': '채널을 선택하여 다른 코스에서 도움을 받으세요.',
  'hero.popularChannels.descriptionv2': '인기 있는 학습 지원 주제를 둘러보세요.',
  'hero.startReading': '글읽기 시작',
  'hero.practice': '연습',
  'hero.noTitleNoChannel.sectionHeading': '빠른 시간 안에 시험을 준비하세요',
  'hero.herobook.mappedChannelHeading': '이 채널에서 추천되는 학습 지원',
  'libraryL2.myBooks': 'My Books',
  'libraryL2.archived': 'Archived',
  'hero.heroHeadingNoMappedChannel': 'Based on what you\'re studying',
  'hero.practiceProblem': 'Practice Problem',
  'nativeAppBanner.drawer.title': 'Open in ...',
  'nativeAppBanner.drawer.pearsonApp': 'Pearson+ App',
  'nativeAppBanner.banner.title': 'Pearson+ app',
  'nativeAppBanner.banner.description': 'Learn on the go',
  open: 'Open',
  continue: 'Continue',
  'hero.promotionalcard.list1': '까다로운 주제를 단순화한 짧은 비디오',
  'hero.promotionalcard.list2': '실제 시험과 유사한 수천 가지 문제',
  'hero.promotionalcard.list3': '해당 분야의 전문가 답변을 받아 보세요',
  'hero.studyandexamprep': '학습·시험 준비',
  'hero.heroBook.sectionSubHeadingV1': '코스에 맞춘 비디오 설명과 연습 문제로 어려운 주제를 세분화해서 공부해 보세요.',
  'hero.heroBook.sectionSubHeadingV2': 'Break down tough topics with concept explanations and practice questions tailored to your course.',
  'hero.SeeAllStudyOptions': 'See all study options',
  'hero.tocLabel': 'View content related to your eTextbook',
  'hero.herobook.mappedChannelHeadingV1': 'Recommended videos and practice questions',
  'hero.popularTopics.headingV1': 'Other popular topics',
  'hero.heroHeadingV1': 'For <span>{replace_value}</span>',
  'hero.learnmore': '더 알아보기',
  'hero.subscriptionStatusTitle': 'Pearson+ 구독 상태',
  'hero.eTextbook': 'e텍스트북',
  'hero.mylabOrMastering': 'MyLab or Mastering',
  'hero.goToPage.label': '페이지 이동',
  'subscription.eTextbook': 'e텍스트북',
  'subscription.mylab': 'MyLab or Mastering',
  'subscription.studyTitle': '학습·시험 준비',
  'subscription.tryItOut': 'Try it out',
  'print.printAvailTitle': '이 페이지를 프린트하시겠어요? 방법은 다음과 같습니다',
  'print.printAvailDesc': '본인의 e텍스트북에서 우측 상단에 있는 계정 아이콘을 선택합니다. 해당 옵션에서 "페이지 인쇄(Print page)"를 선택한 다음 프린트 설정을 선택하세요.',
  'print.printNotAvailTitle': 'The print option for this eText is not enabled.',
  'print.printNotAvailDesc': 'For convenient offline access, please download your title through the Pearson+ mobile app and enjoy the flexibility of studying anytime, anywhere!',
  'hero.header.cta': '{hero_header_cta_text} study tools',
  'hero.marketing.title': '나의 e텍스트북 찾기',
  'hero.marketing.subTitle': 'Choose from over 2,000 eTextbooks with audio, flashcards, notes, and more. Only $10.99/mo for a 4-month term.',
  'hero.allyourcourses': 'all your courses',
  'hero.popularchannels.desc': 'Get study help with these top subjects',
  'hero.removecourse.title': '대시보드에서 코스를 제거하시겠습니까?',
  'hero.removecourse.description': '"코스 추가"를 클릭하면 대시보드에 이 코스를 언제든 다시 추가하실 수 있습니다.',
  'hero.removecourse.primaryCtaText': '제거',
  'hero.removecourse.secondaryCtaText': '취소',
  'hero.subscriptionStatusTitle1': 'What\'s Available for you in Pearson+',
  'hero.emptystate.cta': 'e텍스트북 검색',
  'hero.watch_it_again': 'Watch it again',
  'hero.mondly.learnLang.title': '무료로 새로운 언어를 배워 보세요',
  'hero.mondly.learnLang.title2': '무료로 새로운 언어를 배워 보세요!',
  'hero.mondly.subTitle': 'Pearson e텍스트북 이용자에게는 Pearson에서 Mondly 3개월 무료 혜택을 드립니다.',
  'hero.mondly.description': '대부분의 언어 학습 앱은 영어를 기반으로만 다른 언어를 배울 수 있습니다. 그러나 가장 좋은 방법은 바로 모국어를 기반으로 학습하는 것입니다. Mondly에서는41개 언어로 다른 언어를 학습할 수 있습니다.',
  'hero.mondly.marketing.bestApp': 'Best App',
  'hero.mondly.marketing.appStore': 'App Store',
  'hero.mondly.marketing.appYear': 'App of the Year',
  'hero.mondly.marketing.fbStart': 'Facebook FbStart - 2017 EMEA Winner',
  'hero.mondly.marketing.editorChoice': 'Editor Choice',
  'hero.mondly.marketing.googlePlay': 'Google Play',
  'hero.extractingExamDetails': '시험 세부 정보 추출',
  'hero.finishingTheProcess': '프로세스 종료',
  'hero.hereWhatWeGotFromYourSyllabus': '다음은 귀하의 수업 계획서에 얻은 내용입니다.',
  'hero.school': '학교:',
  'hero.class': '수업:',
  'hero.textbook': '텍스트북:',
  'hero.examSchedule': '시험 일정:',
  'hero.confirm': '확인',
  'onboard.aboutCourse': '귀하의 코스에 관해 설명해 주세요',
  'onboard.studyMaterials': '주제를 선택하시면 맞춤식 학습 자료가 있는 대시보드를 생성해 드립니다.',
  'hero.personalizedStudyPlan': 'AI를 사용하여 맞춤식 학습 플랜을 제공해 드립니다.',
  'hero.welcomeToP': 'P+에 오신 것을 환영합니다!',
  'hero.learnYourWayAround': '잠시 시간을 내시면 금방 터득할 수 있습니다. 혹은 계정 설정에서 다른 편리한 시간에 다시 시작하세요.',
  'hero.weveCreatedADashboard': '귀하의 코스를 위한 대시보드를 이곳에 생성했습니다.',
  'hero.youCanAddYourPearsonCourse': 'Pearson 코스가 아니라도 코스를 추가할 수 있으며, 학습 자료를 시험 삼아 이용할 수 있습니다.',
  'libraryL2.back': '뒤로',
  'hero.getExamreadyWithVideoLessons': '자신의 코스에 맞춤화된 연습 문제 세트와 비디오 수업으로 시험을 준비하세요.',
  'hero.youreTexbook': '귀하의 e텍스트북과 해당 도구가 모두 이곳에 있습니다.',
  'hero.VideosAndPractice': '학습 시험 준비에서는 언제나 비디오 및 연습 문제용 문제가 많이 제공됩니다.',
  'hero.pleaseSelectTheChapters': '이 시험에서 다루는 챕터를 선택하세요.',
  'hero.tableOfContents': '목차',
  'hero.search': '검색',
  'hero.notes': '메모와 강조 표시',
  'hero.doYouWantToAddACourse': '코스를 추가하고 싶으세요?',
  'hero.whatsAvailableForYou': '내가 이용할 수 있는 것',
  'hero.whatsAvailableForYouInPearson': 'Pearson+에서 내가 이용할 수 있는 것',
  'hero.continueStudying': '학습 계속하기',
  'hero.startStudying': '학습 시작하기',
  'hero.unlockYourStudyExamPrep': 'Unlock your Study & Exam Prep for other Courses now!',
  'header.menu.learnLanguage': '언어를 배워 보세요',
  'hero.subscriptionStatusTitle2': 'Pearson+ 리소스',
  'hero.mondly.startLearningCTA': '학습 시작',
  'hero.getExamreadyForYou': '학습·시험 준비를 이용하여 다른 코스의 시험을 준비하세요.',
  'hero.weVideoLessons': '개인의 필요에 따라 연습 문제 세트와 비디오 수업을 맞춤식으로 이용할 수 있습니다.',
  'hero.tryForFree': '무료로 체험해 보세요',
  'hero.addCourseUsingStudyExamPrep': '학습·시험 준비를 이용하여 코스 추가하기',
  'hero.for': 'For',
  'header.menu.moreAtPearson': 'Pearson에서 더보기',
  'hero.myETextbooks': '나의 e텍스트북',
  'header.continueInTheApp': '앱에서 계속하기',
  'hero.goToMyLabMastering': 'MyLab/Mastering으로 이동',
  'hero.learnTxt': '알아보기',
  'hero.aITutorTxt': 'AI 튜터 선생님',
  'hero.exploreTxt': '탐색',
  'hero.studyExamPrepAllYourCourses': '전체 코스를 위한 학습·시험 준비',
  'hero.examSchedules': '시험 일정',
  'hero.examSchedules2': '시험 일정',
  'hero.uploadYourSyllabus': '수업 계획서 업로드하기',
  'hero.addAnExam': '시험 추가하기',
  'hero.UploadYourSyllabus.studyExamPrepPack': 'Upload your syllabus to get the most out of your Study & Exam Prep',
  'hero.UploadYourSyllabus.studyExamPrep': '수업 계획서를 업로드하여 \'학습 시험 준비를 최대한으로 활용해 보세요',
  'hero.getRecommendationsOnWhat': '어떤 공부를 하고 언제 하는 것이 좋을지 추천받으세요',
  'hero.noSyllabusSharingExamSchedule': '수업 계획서가 없으세요? 시험 일정을 공유하셔도 됩니다',
  'hero.selectDropSyllabusFile': 'PDF 또는 DOCX 형식의 수업 계획서 파일을 선택하거나 드롭하세요',
  'hero.uploadTxt': '업로드',
  'hero.examName': '시험명',
  'hero.completeTxt': '완료',
  'hero.exactTopicsChapter': '나중에 각 챕터의 정확한 주제를 명시할 수 있습니다.',
  'hero.uploadingDocument': '문서 업로드',
  'hero.activatingAIComponents': 'AI 구성 요소 활성화',
  'hero.extractingClassSchoolData': '수업 및 학교 데이터 추출',
  'hero.extractingTextbookChapters': '텍스트북 챕터 추출',
  'hero.examPrepTxt': '시험 준비',
  'hero.extractingDataFromSyllabus': 'シラバスからデータを抽出しています…',
  'hero.myLabMastering': 'MyLab/Mastering',
  'AI.toolTip': 'Ask me about anything in your eTextbook',
  'hero.dateFormat': 'DD/MM/YY',
  'header.qrCode.subHeaderTxt': 'QR 코드를 이용하여 신속하고 안전하게 모바일 기기의 Pearson+ 앱에 로그인하십시오.',
  'header.qrCode.dontHaveApp': '앱이 없으신가요?',
  'header.qrCode.footerTxt': '이 코드를 스캔하면 앱 스토어 또는 구글 플레이로 리디렉션됩니다. 앱을 다운로드하면 다시 스캔하여 로그인하십시오.',
  'header.qrCode.errorMsg': 'QR 코드가 로딩되지 않았습니다. 종료 후 다시 시도해 보세요.',
  'hero.yourETextbook': '나의 e텍스트북',
  'onboard.addCourseWithEtextBundle': 'Add course using Study & Exam Prep',
  'onboard.disableTooltipText': 'All Study & Exam Prep courses are added!',
  'subscriptionstatus.bundlecta': 'Access Study & Exam Prep',
  'subscriptionstatus.nonbundlecta': '학습·시험 준비를 이용하세요',
  'channel.genericBundle': 'Use your Study & Exam Prep for help in your other courses',
  'channel.relatedBundle': 'Also use your Study & Exam Prep for help in your other courses',
  'onboard.courseAdded': '코스가 추가되었습니다!',
  'hero.flashcards': '플래시 카드(들)',
  'aichat.title': 'AI Study Tool',
  'aichat.placeholder': 'Which topic or concept from your eTextbook can I explain for you?',
  'aichat.charDesc': 'Ask me anything related to <b>%BOOK_TITLE%</b>.',
  'relatedChannelCard.title': 'Enhance your learning with this related resource',
  'relatedChannelCard.badgeText': 'Related Study & Exam Prep',
  'sessionInactivity.title': 'Are you still there?',
  'sessionInactivity.description': 'Your session will time out in <b>%TIMER%</b> due to inactivity.<br/>Would you like to stay signed in?',
  'sessionInactivity.keepMeSignIn': 'Keep me signed in',
  'sessionInactivity.signOut': 'Sign Out',
  'sessionTimeout.title': 'Session timed out',
  'sessionTimeout.description': 'Your session has timed out due to inactivity.',
  'print.qrCode.header': '이 QR 코드를 이용하여 모바일 기기의 Pearson+ 앱을 여세요.',
  'print.qrCode.footer': '이 코드를 스캔하면 앱 스토어 또는 구글 플레이로 리디렉션됩니다. 앱을 설치한 후 로그인하여 타이틀을 다운로드하고 이용하세요.'
};

export default ko;
