/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file NoteIcon.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import { getComponentThemeValue } from '../CustomStyles/customStyleUtils';

const Styles = () => ({
  noteIcon: {
    width: 20,
    height: 20
  }
});

const NoteIcon = ({
  classes, theme, masterTheme, customThemeStyles
}) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 20 20"
    classes={{
      root: classes.noteIcon
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.50033 2.50001V4.68751H1.39616C0.993451 4.68751 0.666992 5.01397 0.666992 5.41668C0.666992 5.81939 0.993451 6.14585 1.39616 6.14585H1.50033V7.50001H1.39616C0.993451 7.50001 0.666992 7.82647 0.666992 8.22918C0.666992 8.63189 0.993451 8.95835 1.39616 8.95835H1.50033V10.4167H1.39616C0.993451 10.4167 0.666992 10.7431 0.666992 11.1458C0.666992 11.5486 0.993451 11.875 1.39616 11.875H1.50033V13.3333H1.39616C0.993451 13.3333 0.666992 13.6598 0.666992 14.0625C0.666992 14.4652 0.993451 14.7917 1.39616 14.7917H1.50033V17.5C1.50033 18.4205 2.24652 19.1667 3.16699 19.1667L14.8337 19.1667C15.7541 19.1667 16.5003 18.4205 16.5003 17.5V2.50001C16.5003 1.66668 15.667 0.833344 14.8337 0.833344H3.16699C2.24652 0.833344 1.50033 1.57953 1.50033 2.50001ZM14.8341 2.29168H3.16743C3.05237 2.29168 2.95909 2.38495 2.95909 2.50001L2.95906 4.68751H3.68823C4.09093 4.68751 4.4174 5.01397 4.4174 5.41668C4.4174 5.81939 4.09093 6.14584 3.68823 6.14584H2.95906L2.95909 7.50001H3.68825C4.09096 7.50001 4.41742 7.82647 4.41742 8.22918C4.41742 8.63189 4.09096 8.95834 3.68825 8.95834H2.95909L2.95909 10.4167H3.68826C4.09097 10.4167 4.41743 10.7431 4.41743 11.1458C4.41743 11.5486 4.09097 11.875 3.68826 11.875H2.95909V13.3333H3.68826C4.09097 13.3333 4.41743 13.6598 4.41743 14.0625C4.41743 14.4652 4.09097 14.7917 3.68826 14.7917H2.95909V17.5C2.95909 17.6151 3.05237 17.7083 3.16743 17.7083H14.8341C14.9492 17.7083 15.0424 17.6151 15.0424 17.5V2.50001C15.0424 2.37652 14.9164 2.29168 14.8341 2.29168Z"
      fill={getComponentThemeValue(customThemeStyles, 'root', 'iconColor', false, 'textColor', masterTheme, theme)}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2503 7.56411C10.2503 7.06847 10.6234 6.66668 11.0837 6.66668C11.5439 6.66668 11.917 7.06847 11.917 7.56411V11.6026C11.917 12.0982 11.5439 12.5 11.0837 12.5C10.6234 12.5 10.2503 12.0982 10.2503 11.6026V7.56411Z"
      fill={getComponentThemeValue(customThemeStyles, 'root', 'iconColor', false, 'textColor', masterTheme, theme)}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2711 8.72829C13.6738 8.72658 14.0019 9.09828 14.0038 9.55852C14.0058 10.0188 13.6809 10.3932 13.2782 10.3949L8.90324 10.4136C8.50054 10.4153 8.1725 10.0436 8.17054 9.58333C8.16858 9.1231 8.49345 8.74862 8.89615 8.74691L13.2711 8.72829Z"
      fill={getComponentThemeValue(customThemeStyles, 'root', 'iconColor', false, 'textColor', masterTheme, theme)}
    />
  </SvgIcon>
);

NoteIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.string,
  customThemeStyles: PropTypes.object,
  masterTheme: PropTypes.string
};

NoteIcon.defaultProps = {
  theme: 'default',
  customThemeStyles: {},
  masterTheme: 'plus'
};

export default withStyles(Styles)(NoteIcon);
