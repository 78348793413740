/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file ExpandSquareIcon.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import { getComponentThemeValue } from '../CustomStyles/customStyleUtils';

const Styles = () => ({
  expandIcon: {
    width: 40,
    height: 37
  }
});
const ExpandSquareIcon = ({
  classes, theme, masterTheme, customThemeStyles
}) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 40 37"
    classes={{
      root: classes.expandIcon
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8828 26.551L26.69 26.551C27.2207 26.551 27.6509 26.1208 27.6509 25.5901V18.8634H29.5728V25.5901C29.5728 27.1822 28.2821 28.4729 26.69 28.4729L10.8828 28.4729C9.29069 28.4729 8 27.1822 8 25.5901L8 14.0975C8 12.5054 9.29069 11.2147 10.8828 11.2147H18.0962V13.1366L10.8828 13.1366C10.3521 13.1366 9.92189 13.5668 9.92189 14.0975L9.92189 25.5901C9.92189 26.1208 10.3521 26.551 10.8828 26.551Z"
      fill={getComponentThemeValue(customThemeStyles, 'root', 'iconColor', false, 'textColor', masterTheme, theme)}
    />
    <path
      d="M13.057 24.1582C12.6311 24.1582 12.3145 23.8515 12.3145 23.4572C12.3145 23.2382 12.38 23.0629 12.5547 22.8877L14.8151 20.6315L13.7777 19.6019C13.2536 19.0652 13.5811 18.3752 14.3237 18.2547L17.96 17.6962C18.4623 17.6195 18.8445 18.0028 18.779 18.5066L18.2221 22.1648C18.1019 22.9315 17.4031 23.2601 16.868 22.7015L15.8415 21.672L13.5921 23.9282C13.4283 24.0925 13.2645 24.1582 13.057 24.1582Z"
      fill={getComponentThemeValue(customThemeStyles, 'root', 'iconColor', false, 'textColor', masterTheme, theme)}
    />
    <rect
      width="10.5704"
      height="7.68758"
      rx="0.960947"
      transform="matrix(1 0 0 -1 20.9434 16.2483)"
      fill={getComponentThemeValue(customThemeStyles, 'root', 'iconColor', false, 'textColor', masterTheme, theme)}
    />
  </SvgIcon>
);

ExpandSquareIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  customThemeStyles: PropTypes.object,
  theme: PropTypes.string,
  masterTheme: PropTypes.string
};

ExpandSquareIcon.defaultProps = {
  theme: 'default',
  masterTheme: 'plus',
  customThemeStyles: {}
};

export default withStyles(Styles)(ExpandSquareIcon);
