/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2022 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * ChannelExams for ChannelExamsContainer
 *
 * @file ChannelExamsContainer.jsx
 * @author Mohamed yasar arabath M
 */

import React, { Suspense, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../common/components/ErrorBoundary';
import { HeroGenericShimmerLeftContainer } from './Loader/ComponentLoader';
import CommonUtils from '../../common/utils/CommonUtils';

const ChannelsExams = CommonUtils.lazyWithPreload(() => import('@study/components')
  .then(module => ({ default: module.ChannelsExams })));

const ChannelExamsContainer = ({
  userDetail,
  env,
  channelId,
  locale,
  onBoarding,
  onComplete,
  onMaybeLater,
  onScreenChange,
  onGoToChannels,
  locationInApp,
  isBundleUser,
  mlmCourseIds,
  examsPeriod,
  newUI
}) => {
  const { id: userId } = userDetail;
  const getUserCallback = useCallback(() => ({
    userId,
    token: window?.piSession?.currentToken() || ''
  }), [userId]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<HeroGenericShimmerLeftContainer />}>
        <ChannelsExams
          getUserCallback={getUserCallback}
          env={env}
          locale={locale}
          channelId={channelId}
          onBoarding={onBoarding}
          onComplete={onComplete}
          onMaybeLater={onMaybeLater}
          onScreenChange={onScreenChange}
          onGoToChannels={onGoToChannels}
          locationInApp={locationInApp}
          userBundle={isBundleUser}
          mlmCourseIds={mlmCourseIds}
          examsPeriod={examsPeriod}
          newUi={newUI}
        />
      </Suspense>
    </ErrorBoundary>
  );
};
ChannelExamsContainer.defaultProps = {
  onBoarding: false,
  onComplete: () => { },
  onMaybeLater: () => { },
  onScreenChange: () => { },
  onGoToChannels: () => { },
  locationInApp: null,
  isBundleUser: false,
  mlmCourseIds: [],
  examsPeriod: false,
  newUI: false
};
ChannelExamsContainer.propTypes = {
  userDetail: PropTypes.object.isRequired,
  env: PropTypes.oneOf(['dev', 'qa', 'stg', 'stage', 'perf', 'ppe', 'prod']).isRequired,
  locale: PropTypes.string.isRequired,
  channelId: PropTypes.string.isRequired,
  onBoarding: PropTypes.bool,
  onComplete: PropTypes.func,
  onMaybeLater: PropTypes.func,
  onScreenChange: PropTypes.func,
  onGoToChannels: PropTypes.func,
  locationInApp: PropTypes.string,
  isBundleUser: PropTypes.bool,
  mlmCourseIds: PropTypes.array,
  examsPeriod: PropTypes.bool,
  newUI: PropTypes.bool
};
export default memo(ChannelExamsContainer);
