/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Translation file for Dutch language
 *
 * @file nl.js
 * @author Manimaran.S
 */

const nl = {
  'popularChannelSlider.allChannels': 'All Channels',
  'popularChannelSlider.recommended': 'Recommended',
  'channel.noBundleRelatedtitle': 'Browse Study & Exam Prep related to your other classes.',
  'hero.myetextbook.link': 'library',
  'hero.myetextbook.text': 'Not the right title? Access your eTextbook',
  'hero.ctanewtext': 'Open Study & Exam Prep',
  'hero.welcomeToPPlus': 'Welcome to Pearson+',
  'hero.etextfeaturestitle': 'Find study materials in your eTextbook',
  'hero.etextfeaturesdescription': 'Your eTextbook comes with study materials designed to help you succeed. Study with flashcards and more right in your eTexbook.',
  'aichat.title1': 'Get AI-powered help for anything related to this eTextbook.',
  'aichat.chatDesc1': 'Type a question to start',
  'aichat.title2': 'Get AI-powered help',
  'aichat.chatDesc2': 'Ask about anything related to this eTextbook',
  'subscriptiontext.isSubscribed': 'Part of your subscription',
  'subscriptiontext.nonSubscriber': 'Upgrade for full access',
  'topicConatiner.otherHeader': 'Related practice and video lessons',
  'topicConatiner.exactLiteHeader': 'Related practice',
  'authHome.h1': 'Authenticated Home',
  'moremenu.downloadApp': 'Download the app',
  'moremenu.downloadAppAlreadyDownload': '%Click here% om de app te downloaden op een ander apparaat en automatisch in te loggen of om uw unieke QR-code te gebruiken om veilig op de app in te loggen als u die al geïnstalleerd hebt.',
  'moremenu.downloadAppNotDownload': '%Click here% om de app te downloaden veilig in te loggen met uw unieke QR-code.',
  'channel.noBundletitle': 'Use the Study & Exam Prep for your related & other courses',
  'onboard.next': 'Volgende',
  'onboard.studyPlan': 'Aangepast studieplan aangemaakt!',
  'onboard.addCourseAnother': 'Wilt u een andere cursus toevoegen?',
  'onboard.purchase': 'Ik wil een eTextbook van Pearson kopen',
  'onboard.addCourseWithoutEtext': 'Add course using Study & Exam Prep',
  'onboard.addCourseWithoutEtextBundle': 'Voeg een cursus toe met Studeren en examenvoorbereiding',
  'onboard.doYouWantOtherCourse': 'Wilt u een andere cursus toevoegen?',
  'onboard.mayBeLater': 'Misschien later',
  'onboard.close': 'Close',
  'course.addTitle': 'Cursus toevoegen',
  'bookshelf.TITLE': 'Mijn boekenplank',
  'common.GO_HOME': 'Naar de startpagina',
  'common.LOGOUT': 'Uitloggen',
  'common.PURCHASE': 'Kopen',
  'courses.action.SHOW_ASSIGNMENTS': 'Opdrachten tonen',
  'courses.toc.PUBLISH_MESSAGE': 'Deelnemers zien uw wijzigingen de volgende keer als ze de inhoud bekijken.',
  'courses.toc.PUBLISH_SUCCESS': 'Veel succes!',
  'courses.WELCOME': 'Hallo {userName}, welkom bij uw {courseName} cursus!',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  // eslint-disable-next-line max-len
  'errors.SUBSCRIPTION_GENERIC_DESC': 'Uw account bevat geen toegang tot deze titel. Als u denkt dat u dit bericht niet had moeten ontvangen, neem dan contact op met Pearson Technical Support',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'Abonnement niet gevonden',
  // eslint-disable-next-line max-len
  'errors.SUBSCRIPTION_REGISTRATION_DESC': 'Uw abonnement bevat geen toegang tot de eText, maar u kunt zich hiervoor wel aanmelden met een creditcard',
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'Abonnement niet gevonden',
  'foxit.ABOUT': 'Over',
  'foxit.COPY_RIGHT': 'PDF Reader ondersteund door Foxit. Auteursrecht (C) 2003-2019 van Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonPrivacyPolicy.html',
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonEULA.html',
  'menu.GET_THE_APP_DOWNLOAD': 'Download de mobiele app',
  'menu.ABOUT': 'Over',
  'menu.AVAILABLE': 'Ook beschikbaar',
  'menu.CONTACT_SUPPORT': 'Contact opnemen met Ondersteuning',
  'menu.COPY_RIGHT': 'Copyright \u00A9 {year} Pearson Education, Inc. Alle rechten voorbehouden.',
  'menu.FOXIT': 'Ondersteund door Foxit',
  'menu.GET_LOOSE_LEAF': 'Neem een los blad',
  'menu.PRINT_PAGE': 'Pagina afdrukken',
  'menu.PRINT_VERSION': 'Wilt u de afgedrukte versie?',
  'menu.PRIVACY_POLICY': 'Privacybeleid',
  'menu.SIGN_OUT': 'Uitloggen',
  'menu.TERMS_OF_USE': 'Gebruiksvoorwaarden',
  'menu.tooltip.SCHEDULE': 'Schema',
  'print.FOOTER_MESSAGE': 'Printed by {fullName}({email}) on {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': 'Hallo {userName}',
  'device.SWAPPED_ALREADY': 'U heeft deze maand al geruild',
  'device.SWITCH_DEVICE': 'Overschakelen naar huidig apparaat?',
  'device.SWITCH_ONCE': 'Aangezien u maar één keer per maand kunt overstappen, kunt u dit niet ongedaan maken.',
  'device.SEAT_TAKEN': 'Die plek is bezet',
  'device.DISCONNECT_DEVICE': 'U kunt maar op 3 apparaten inloggen. Koppel een ander apparaat los om hier verder te gaan.',
  'device.ALERT_TEXT': 'Onthoud, u kunt slechts één keer per maand tussen apparaten switchen.',
  'device.YOUR_MOJO': 'U bent op weg naar mojo',
  'device.DISCONNECT': 'Loskoppelen',
  'device.CONTINUE': 'Doorgaan',
  'device.HEADER': 'End your other browser session?',
  'device.CLOSE': 'Terug naar Startpagina',
  'device.CODE': 'Continue here by entering the authentication code we sent to {maskedEmail}. Not working? Check your spam folder, {email}, or contact {support}.',
  'device.TIMER': 'U moet {time} uw code hier invoeren',
  'device.RESEND': '{resend}',
  'device.FAQ': 'FAQ',
  'device.RESEND_NAME': 'Resend',
  'device.NOTE': 'If you regularly use Pearson+ in 2 places, like at work and at home, make sure to log out whenever you’re done. You can also use the Pearson+ mobile app on up to 2 devices. For more info, see our {faq}.',
  'device.NOTE_HEADER': 'Avoid this next time',
  'device.CONTACT_SUPPORT': '',
  'device.CONTACT_EMAIL': '',
  'device.CONTACT_FAQ': '',
  'device.INCORRECT': 'Onjuist. Controleer uw code of verstuur deze opnieuw.',
  'device.MANY_ATTEMPTS': 'Teveel pogingen. Vraag een nieuwe code op',
  'device.EXPIRED': 'Dit duurde te lang. Vraag een nieuwe code op',
  'device.ACCESS_HEADER': 'U bent binnen!',
  'device.ACCESS_DESC': 'Uw sessie is geverifieerd. Wilt u uw wachtwoord nu bijwerken?',
  'device.ACCESS_CHANGE_PASSWORD': 'Wachtwoord wijzigen',
  'device.ACCESS_RESET_PASSWORD': 'Wachtwoord opnieuw instellen',
  'device.THREATONE_TITLE': 'Ziet alles er goed uit?',
  'device.THREATONE_DESC': 'We hebben verdachte activiteiten op uw account waargenomen.',
  'device.THREATONE_DESC_TWO': 'We raden u aan uw wachtwoord te wijzigen.',
  'device.MAY_BE_LATER': 'Misschien later',
  'device.OKAY': 'OK',
  'device.ACCOUNT_LOCK_DESC': 'Om het te beveiligen, loggen we u uit en hebben we u een link gestuurd waarmee u uw wachtwoord dat bij het e-mailadres waarmee uw account geregistreerd is, opnieuw kunt instellen.',
  'device.ACCOUNT_LOCK_TITLE': 'We hebben verdachte activiteiten op uw account waargenomen',
  'device.ACCOUNT_LOCK_TIMER': 'U wordt uitgelogd {time}',
  'menu.myAccount': 'Mijn account',
  'menu.helpCenter': 'Helpcentrum',
  'menu.preferencesCenter': 'Voorkeurenoverzicht',
  'menu.HELP_SUPPORT': 'Help & ondersteuning',
  'menu.FAQS': 'Veelgestelde vragen',
  'menu.CHAT_WITH_SUPPORT': 'Chat met Ondersteuning',
  'menu.QUICK_WALKTHROUGH': 'Snelle uitleg',
  'menu.LEGAL': 'Wettelijk',
  'menu.PRIVACY_NOTICE': 'Privacyverklaring',
  'menu.TITLE_INFO': 'Titelgegevens',
  'menu.PEARSON_ACCESSIBILITY': 'Toegankelijkheid Pearson',
  'menu.DEVICE_WARNING_MESSAGE': 'Registreer {deviceLimit} apparaten. Switch één keer per maand.',
  'menu.ACCESSIBILITY': 'Toegankelijkheid',
  'menu.ACCESSIBILITY_SUPPORT': 'Toegankelijkheidsondersteuning',
  'menu.DEVICES': 'Onlangs gebruikte apparaten',
  'menu.THIS_DEVICE': 'Dit apparaat',
  'menu.MOJO_APP_INFO': 'Ga naar de app store op je telefoon of tablet om Pearson+ offline te gebruiken.',
  'menu.DEVICE_LABEL': 'Apparaat',
  'navigation.PAGE': 'Pagina',
  'launchcode.CREATEBY': 'Aangemaakt door:',
  'launchcode.sentvia': 'Verstuurd via:',
  'launchcode.CODEINLAST': 'Codes in de afgelopen 7 dagen:',
  'launchcode.TIMESENT': 'Tijd verstuurd:',
  'launchcode.ACCOUNTEMAIL': 'E-mailadres van account:',
  'launchcode.LAUNCHDIALOGTITLE': 'OTP-Code log',
  'menu.OTP_CODES': 'OTP-codes',
  'menu.LAUNCH_CODE': 'Code log lanceren',
  'menu.SEND_CODE': 'Stuur gebruiker een nieuwe code via e-mail',
  'menu.SEND_CODE_PHONE': 'Stuur gebruiker een nieuwe code via een tekstbericht',
  'menu.COMMUNITY_GUIDELINES': 'Richtlijnen vanuit de gemeenschap',
  'textmessage.TITLE': 'Voer telefoonnummer in',
  'textmessage.PHONE_NUMBER': 'Telefoonnummer',
  'textmessage.SEND_CODE': 'Stuur code',
  'textmessage.INVALID_NUMBER': 'Ongeldig telefoonnummer.',
  'textmessage.SUCCESS_MESSAGE': 'Code verstuurd',
  'search.searchPlaceHolderPPlus': 'Search by ISBN, Title, or Author',
  'search.searchPlaceHolderEText': 'Uw lijst doorzoeken',
  'remove.title': 'Titel verwijderen?',
  'remove.subTitle': 'U kunt deze altijd later weer toevoegen door middel van de zoekfunctie.',
  'remove.buttonText': 'Verwijderen',
  'footer.downloadMobileApp': 'Download de mobiele app',
  'footer.termsOfUse': 'Gebruiksvoorwaarden',
  'footer.privacy': 'Privacy',
  'footer.cookies': 'Cookies',
  'footer.doNotSellMyPersonalInformation': 'Verkoop mijn persoonsgegevens niet',
  'footer.accessibility': 'Toegankelijkheid',
  'footer.patentNotice': 'Patiëntverklaring',
  'footer.copyrights': 'Pearson Alle rechten voorbehouden.',
  'channel.videos': 'Video\'s',
  'channel.questions': 'Vragen',
  'channel.descriptionA': 'Toegang tot extra content met Channels.',
  'channel.descriptionB': 'Hulp bij moeilijke onderwerpen met Channels.',
  'channel.openchannel': 'Channel openen',
  'hero.continueReading': 'Lees verder',
  'hero.openBook': 'Boek openen',
  'hero.channel.open': 'Channel openen',
  'hero.addATitle': 'Voeg een titel toe',
  'hero.toc.defaultPlacehoder': '--- select a topic ---',
  'hero.viewInactiveTitles': 'Inactieve titels weergeven',
  'library.marketingCard': {
    title: 'Choose from over 2,000 eTextbooks',
    subTitle: 'with audio, flashcards, notes, and more.',
    primaryButton: {
      text: 'Vind uw eTextbook hier'
    },
    secondaryButton: {
      text: 'View all titles'
    }
  },
  'hero.mappedChannelV2': {
    ariaLabel: 'channel',
    heading: 'Recommended study help Channel',
    callForAction: {
      text: 'Open'
    }
  },
  'hero.openEtextBook': 'eTextbook openen',
  'hero.openEtextBookV1': 'eTextbook openen',
  'hero.heroHeading': 'Omdat u in <span>{channel_title}</span> studeert',
  'hero.heroHeadingForBundleUser': 'Study & Exam Prep',
  'hero.study.help': 'Hulp krijgen bij het studeren',
  'hero.study.jumpBackIn': 'Kom terug',
  'hero.study.numOfUsers': 'Op dit moment gebruiken <b>{num_of_users}</b>  cursisten Channels.',
  'hero.heroBook.sectionHeading': 'Doorloop uw cursus op een soepele manier',
  'hero.heroBook.channelsSectionHeading': 'Start een nieuwe studiesessie',
  'hero.heroBook.sectionHeadingNewUser': 'Hulp bij studeren is beschikbaar',
  'hero.heroBook.sectionSubHeading': 'Verslind diverse onderwerpen met oefenvragen en uitleg in video\'s, speciaal voor uw cursus.',
  'hero.heroChannel.cardsHeading': 'Doorgaan waar u gebleven bent',
  'hero.marketingcard.subheading': 'Studeer efficiënter met video\'s die moeilijke onderwerpen behapbaar maken en met extra praktijkoefeningen. Allemaal op maat gemaakt.',
  'hero.marketingcard1.copy.title': 'Leren met video\'s',
  'hero.marketingcard1.copy.description': 'Bekijk hapklare video\'s met uitleg over lastige onderwerpen.',
  'hero.marketingcard2.copy.title': 'Praktijkoefeningen',
  'hero.marketingcard2.copy.description': 'Bereid uw examen voor met duizenden oefenvragen en uitlegvideo\'s.',
  'hero.marketingcard3.copy.title': 'Hulp van ervaringsdeskundigen',
  'hero.marketingcard3.copy.description': 'Onze deskundigen beantwoorden al uw vragen.',
  'hero.popularTopics.heading': 'Andere populaire onderwerpen in dit Channel',
  'hero.popularChannels.heading': 'Populaire Channels',
  'hero.popularChannels.headingv1': 'Volgt u een andere cursus?',
  'hero.popularChannels.description': 'Hulp bij studeren met deze populaire Channels.',
  'hero.popularChannels.descriptionv1': 'Kies een ander Channel voor hulp bij een ander onderwerp.',
  'hero.popularChannels.descriptionv2': 'Blader door populaire studieonderwerpen.',
  'hero.startReading': 'Beginnen met lezen',
  'hero.practice': 'Oefenen',
  'hero.noTitleNoChannel.sectionHeading': 'Bereid u voor op uw examen in no time',
  'hero.herobook.mappedChannelHeading': 'Kanaal met aanbevolen hulp bij studeren',
  'libraryL2.myBooks': 'My Books',
  'libraryL2.archived': 'Archived',
  'hero.heroHeadingNoMappedChannel': 'Based on what you\'re studying',
  'hero.practiceProblem': 'Practice Problem',
  'nativeAppBanner.drawer.title': 'Open in ...',
  'nativeAppBanner.drawer.pearsonApp': 'Pearson+ App',
  'nativeAppBanner.banner.title': 'Pearson+ app',
  'nativeAppBanner.banner.description': 'Learn on the go',
  open: 'Open',
  continue: 'Continue',
  'hero.promotionalcard.list1': 'Hapklare video\'s met uitleg over lastige onderwerpen',
  'hero.promotionalcard.list2': 'Duizenden oefenvragen',
  'hero.promotionalcard.list3': 'Onze deskundigen beantwoorden al uw vragen',
  'hero.studyandexamprep': 'Studeren en examenvoorbereiding',
  'hero.heroBook.sectionSubHeadingV1': 'Verslind diverse onderwerpen met oefenvragen en uitleg in video\'s, speciaal voor uw cursus.',
  'hero.heroBook.sectionSubHeadingV2': 'Break down tough topics with concept explanations and practice questions tailored to your course.',
  'hero.SeeAllStudyOptions': 'See all study options',
  'hero.tocLabel': 'View content related to your eTextbook',
  'hero.herobook.mappedChannelHeadingV1': 'Recommended videos and practice questions',
  'hero.popularTopics.headingV1': 'Other popular topics',
  'hero.heroHeadingV1': 'For <span>{replace_value}</span>',
  'hero.learnmore': 'Meer informatie',
  'hero.subscriptionStatusTitle': 'Status abonnement op Pearson+',
  'hero.eTextbook': 'eTextbook',
  'hero.mylabOrMastering': 'MyLab or Mastering',
  'subscription.eTextbook': 'eTextbook',
  'subscription.mylab': 'MyLab or Mastering',
  'subscription.studyTitle': 'Studeren en examenvoorbereiding',
  'subscription.tryItOut': 'Try it out',
  'print.printAvailTitle': 'Wilt u deze pagina uitprinten? Zo doet u dat',
  'print.printAvailDesc': 'Selecteer in uw eTextbook het accountpictogram rechts bovenin. In de opties kiest u "Pagina afdrukken" en selecteert u uw gewenste printinstellingen.',
  'print.printNotAvailTitle': 'The print option for this eText is not enabled.',
  'print.printNotAvailDesc': 'For convenient offline access, please download your title through the Pearson+ mobile app and enjoy the flexibility of studying anytime, anywhere!',
  'hero.header.cta': '{hero_header_cta_text} study tools',
  'hero.marketing.title': 'Vind uw eTextbook hier',
  'hero.marketing.subTitle': 'Choose from over 2,000 eTextbooks with audio, flashcards, notes, and more. Only $10.99/mo for a 4-month term.',
  'hero.allyourcourses': 'all your courses',
  'hero.popularchannels.desc': 'Get study help with these top subjects',
  'hero.removecourse.title': 'Cursus verwijderen van dashboard?',
  'hero.removecourse.description': 'U kunt deze cursus weer terugzetten op uw dashboard via "Cursus toevoegen"',
  'hero.removecourse.primaryCtaText': 'Verwijderen',
  'hero.removecourse.secondaryCtaText': 'Annuleren',
  'hero.subscriptionStatusTitle1': 'What\'s Available for you in Pearson+',
  'hero.emptystate.cta': 'eTextbooks zoeken',
  'hero.watch_it_again': 'Watch it again',
  'hero.mondly.learnLang.title': 'Gratis een nieuwe taal leren',
  'hero.mondly.learnLang.title2': 'Gratis een nieuwe taal leren!',
  'hero.mondly.subTitle': 'Gebruikers van Pearson eTextbook krijgen van Pearson drie maanden gratis toegang tot Mondly.',
  'hero.mondly.description': 'Bij de meeste apps leert u een vreemde taal vanuit het Engels. Het is natuurlijk veel handiger als u vanuit uw eigen taal kunt leren. Bij Mondly kunt u kiezen uit 41 talen.',
  'hero.mondly.startLearningCTA': 'Beginnen met leren',
  'hero.mondly.marketing.bestApp': 'Best App',
  'hero.mondly.marketing.appStore': 'App Store',
  'hero.mondly.marketing.appYear': 'App of the Year',
  'hero.mondly.marketing.fbStart': 'Facebook FbStart - 2017 EMEA Winner',
  'hero.mondly.marketing.editorChoice': 'Editor Choice',
  'hero.mondly.marketing.googlePlay': 'Google Play',
  'header.menu.moreAtPearson': 'Meer van Pearson',
  'hero.extractingExamDetails': 'Bezig met uitpakken gegevens examen',
  'hero.finishingTheProcess': 'Het proces afronden',
  'hero.hereWhatWeGotFromYourSyllabus': 'Dit hebben we uit uw syllabus gehaald',
  'hero.school': 'School:',
  'hero.class': 'Klas:',
  'hero.textbook': 'Tekstboek:',
  'hero.examSchedule': 'Examenplanning:',
  'hero.confirm': 'Bevestigen',
  'onboard.aboutCourse': 'Vertel ons meer over uw cursus',
  'onboard.studyMaterials': 'Kies uw vak en wij maken een dashboard met studiematerialen die op uw behoeften zijn afgestemd.',
  'hero.personalizedStudyPlan': 'Aan de hand van AI bieden we u een op maat gemaakt studieplan.',
  'hero.welcomeToP': 'Welkom bij P+!',
  'hero.learnYourWayAround': 'Neem even de tijd om alles even rustig door te nemen of ga later verder in de accountinstellingen.',
  'hero.weveCreatedADashboard': 'Hier vindt u een dashboard voor uw cursus.',
  'hero.youCanAddYourPearsonCourse': 'U kunt ook uw eigen cursussen buiten Pearson toevoegen en gratis studiematerialen uitproberen.',
  'libraryL2.back': 'Terug',
  'hero.getExamreadyWithVideoLessons': 'Bereid u voor op uw examen met onze video\'s en oefensets die op uw cursus zijn afgestemd.',
  'hero.youreTexbook': 'Uw eTextbook en alle tools staan hier voor u klaar.',
  'hero.VideosAndPractice': 'Er zijn altijd meer video\'s en oefenvragen beschikbaar in het gedeelte voorbereiding op studie en examen.',
  'hero.pleaseSelectTheChapters': 'Selecteer de hoofdstukken waar dit examen over gaat:',
  'hero.tableOfContents': 'Inhoudsopgave',
  'hero.search': 'Zoeken',
  'hero.notes': 'Notities en markeringen',
  'hero.doYouWantToAddACourse': 'Wilt u een cursus toevoegen?',
  'hero.whatsAvailableForYou': 'Wat u zoal ter beschikking hebt',
  'hero.whatsAvailableForYouInPearson': 'Wat u zoal ter beschikking hebt in Pearson+',
  'hero.continueStudying': 'Blijf studeren',
  'hero.startStudying': 'Begin met studeren',
  'hero.unlockYourStudyExamPrep': 'Unlock your Study & Exam Prep for other Courses now!',
  'header.menu.learnLanguage': 'Een taal leren',
  'hero.subscriptionStatusTitle2': 'Uw middelen van Pearson+',
  'hero.goToPage.label': 'Ga naar pagina',
  'hero.getExamreadyForYou': 'Bereid u voor op uw examen voor uw andere cursussen met Studeren en examenvoorbereiding.',
  'hero.weVideoLessons': 'We hebben videos en oefensets samengesteld die op uw behoeften zijn afgestemd.',
  'hero.tryForFree': 'Gratis uitproberen',
  'hero.addCourseUsingStudyExamPrep': 'Voeg een cursus toe met Studeren en examenvoorbereiding',
  'hero.for': 'Voor',
  'hero.myETextbooks': 'Mijn eTextbooks',
  'header.continueInTheApp': 'Doorgaan in de app',
  'hero.goToMyLabMastering': 'Ga naar MyLab/Mastering',
  'hero.learnTxt': 'Studeren',
  'hero.aITutorTxt': 'AI-begeleider',
  'hero.exploreTxt': 'Ontdek',
  'hero.studyExamPrepAllYourCourses': 'Studeren en examenvoorbereiding voor al uw cursussen',
  'hero.examSchedules': 'Examenplanning',
  'hero.examSchedules2': 'Examenplanning',
  'hero.uploadYourSyllabus': 'Upload uw syllabus',
  'hero.addAnExam': 'Een examen toevoegen',
  'hero.UploadYourSyllabus.studyExamPrepPack': 'Upload your syllabus to get the most out of your Study & Exam Prep',
  'hero.UploadYourSyllabus.studyExamPrep': 'Upload uw syllabus om optimaal te profiteren van Studeren en examenvoorbereiding',
  'hero.getRecommendationsOnWhat': 'Ontvang aanbevelingen over wat u kunt studeren en wanneer',
  'hero.noSyllabusSharingExamSchedule': 'Hebt u geen syllabus, dan kunt u ook uw examenschema delen',
  'hero.selectDropSyllabusFile': 'Selecteer een syllabus of plaats een bestand in pdf- of word-indeling',
  'hero.uploadTxt': 'Uploaden',
  'hero.examName': 'Naam examen',
  'hero.completeTxt': 'Voltooid',
  'hero.exactTopicsChapter': 'U kunt later de precieze onderwerpen van elk hoofdstuk opgeven.',
  'hero.uploadingDocument': 'Het document uploaden',
  'hero.activatingAIComponents': 'AI-componenten activeren',
  'hero.extractingClassSchoolData': 'Bezig met uitpakken gegevens van klas en school',
  'hero.extractingTextbookChapters': 'Bezig met uitpakken hoofdstukken tekstboek',
  'hero.examPrepTxt': 'Examenvoorbereiding',
  'hero.extractingDataFromSyllabus': 'Bezig met uitpakken gegevens uit de syllabus...',
  'hero.myLabMastering': 'MyLab/Mastering',
  'AI.toolTip': 'Ask me about anything in your eTextbook',
  'hero.dateFormat': 'DD/MM/YY',
  'header.qrCode.subHeaderTxt': 'Log via deze QR-code snel en veilig in op de Pearson+-app op uw mobiele toestel.',
  'header.qrCode.dontHaveApp': 'Hebt u de app niet?',
  'header.qrCode.footerTxt': 'Als u deze code scant, komt u direct in de App Store of Google Play terecht. Zodra u de app hebt gedownload, kunt u inloggen door de code nogmaals te scannen.',
  'header.qrCode.errorMsg': 'De QR-code is niet geladen. Sluit en probeer het nog eens.',
  'hero.yourETextbook': 'Uw eTextbook',
  'onboard.addCourseWithEtextBundle': 'Add course using Study & Exam Prep',
  'onboard.disableTooltipText': 'All Study & Exam Prep courses are added!',
  'subscriptionstatus.bundlecta': 'Access Study & Exam Prep',
  'subscriptionstatus.nonbundlecta': 'Open Studeren en examenvoorbereiding',
  'channel.genericBundle': 'Use your Study & Exam Prep for help in your other courses',
  'channel.relatedBundle': 'Also use your Study & Exam Prep for help in your other courses',
  'onboard.courseAdded': 'Cursus toegevoegd!',
  'hero.flashcards': 'Flashcards',
  'aichat.title': 'AI Study Tool',
  'aichat.placeholder': 'Which topic or concept from your eTextbook can I explain for you?',
  'aichat.charDesc': 'Ask me anything related to <b>%BOOK_TITLE%</b>.',
  'relatedChannelCard.title': 'Enhance your learning with this related resource',
  'relatedChannelCard.badgeText': 'Related Study & Exam Prep',
  'sessionInactivity.title': 'Are you still there?',
  'sessionInactivity.description': 'Your session will time out in <b>%TIMER%</b> due to inactivity.<br/>Would you like to stay signed in?',
  'sessionInactivity.keepMeSignIn': 'Keep me signed in',
  'sessionInactivity.signOut': 'Sign Out',
  'sessionTimeout.title': 'Session timed out',
  'sessionTimeout.description': 'Your session has timed out due to inactivity.',
  'print.qrCode.header': 'Open de Pearson+-app op uw mobiele toestel via deze QR-code.',
  'print.qrCode.footer': 'Als u deze code scant, komt u direct in de App Store of Google Play terecht. Zodra u de app hebt geïnstalleerd, logt u in om uw titel te lezen.'
};

export default nl;
