/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Mobx model(s) related to ChannelRecommendationsByBook
 *
 * @file ChannelRecommendationsByBook.js
 * @author Manimaran S
 */

import { applySnapshot, getSnapshot, types } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';
import ChannelAssets from './ChannelAssets';
import CommonUtils from '../../../common/utils/CommonUtils';
import RecommendedTopic from './RecommendedTopic';

const ChannelData = types.model('ChannelData', {
  thumbnail: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  group: types.maybeNull(types.string),
  numberOfUsers: types.maybeNull(types.number),
  learnUrl: types.maybeNull(types.string)
});

const ChannelRecommendationsByBook = types.model(
  'ChannelRecommendationsByBook',
  {
    channelData: types.maybeNull(ChannelData),
    recommendedAssets: types.maybeNull(types.array(ChannelAssets)),
    popularTopics: types.maybeNull(types.array(RecommendedTopic)),
    isError: types.optional(types.boolean, false)
  }
).views(self => ({
  /**
   * Constructs and returns necessary data for mapped channel for Hero book
   *
   * @param {*} MAX_ASSET_COUNT
   * @returns
   */
  getBookMappedChannel(MAX_ASSET_COUNT, isHeroVariant2 = false) {
    const {
      MAPPEDCHANNELASSET,
      MAPPED_CHANNEL_ASSET_CARD,
      POPULAR_CHANNEL_ASSETS_CARD
    } = constants.MAPPED_CHANNEL_TEMPLATES;
    const { recommendedAssets, popularTopics } = self;
    const language = Framework.getStoreRegistry().getStore('language');
    const { CHANNEL_TYPES, PRACTICE_SET_ICON, CHANNEL_TYPE_RESPONSE } = constants;
    let mappedChannel = null;
    /**
    Check for recommended assets first
    if not found we will check for mapped channel in the channel data
    else we assume there is no channel for that books
    */
    if (recommendedAssets && recommendedAssets.length > 0) {
      const displayHeading = isHeroVariant2 ? language.getMessage('hero.herobook.mappedChannelHeadingV1')
        : language.getMessage('hero.herobook.mappedChannelHeading');

      const cards = recommendedAssets.slice(0, MAX_ASSET_COUNT).map((response, index) => {
        const {
          description,
          icon,
          section,
          sectionColor,
          title,
          thumbnailUrl
        } = CommonUtils.getRecommendedAssetsData(response);
        const card = {
          ariaLabel: response.assetContentType,
          description,
          callForAction: {
            type: CHANNEL_TYPES.RECOMMENDED_ASSETS,
            cardIndex: index
          },
          icon,
          section,
          sectionColor,
          thumbnailUrl,
          title
        };

        return card;
      });
      mappedChannel = {
        template: MAPPED_CHANNEL_ASSET_CARD.TEXT,
        heading: displayHeading,
        cards
      };
    } else if (popularTopics && popularTopics.length > 0) {
      const channelType = {
        EXAM_PREP: CHANNEL_TYPE_RESPONSE.PRACTICE_SET,
        GUIDED: CHANNEL_TYPE_RESPONSE.TOPIC
      };
      const heading = isHeroVariant2 ? language.getMessage('hero.popularTopics.headingV1')
        : language.getMessage('hero.popularTopics.heading');

      const cards = popularTopics.slice(0, MAX_ASSET_COUNT).map((response, index) => {
        const card = {
          ariaLabel: response.type,
          callForAction: {
            type: CHANNEL_TYPES.POPULAR_TOPICS,
            cardIndex: index
          },
          icon: response.type === CHANNEL_TYPES.EXAM_PREP
            ? MAPPEDCHANNELASSET.ICONS.STACKICON : MAPPEDCHANNELASSET.ICONS.LISTICON,
          section: channelType[response.type] || '',
          numOfAssets: response.numOfAssets,
          thumbnailUrl: response.type === CHANNEL_TYPES.EXAM_PREP
            ? PRACTICE_SET_ICON : response.thumbUrl,
          title: response.topicTitle
        };

        return card;
      });

      mappedChannel = {
        heading,
        cards,
        template: POPULAR_CHANNEL_ASSETS_CARD.TEXT
      };
    }

    return mappedChannel;
  },
  /**
   * Constructs and returns general mapped channel for hero book
   *
   * @returns
   */
  getGeneralMappedChannel(isHeroVariant2 = false) {
    const { channelData } = self;
    const language = Framework.getStoreRegistry().getStore('language');
    const description = channelData.numberOfUsers
      ? language.getMessage('hero.study.numOfUsers').replace(
        '{num_of_users}', channelData.numberOfUsers.toLocaleString() || ''
      ) : null;
    const heroBannerStore = Framework.getStoreRegistry().getStore('herobanner');
    const heroBanner = getSnapshot(heroBannerStore);
    const channel = CommonUtils.getRecentlyUsedChannel()?.location;
    const recentlyUsedChannelId = channel?.id || null;
    let CTAText = isHeroVariant2
      ? language.getMessage('hero.SeeAllStudyOptions')
      : language.getMessage('hero.study.help');
    if ((heroBanner.channelId && recentlyUsedChannelId) && (heroBanner.channelId.toLowerCase() === recentlyUsedChannelId.toLowerCase()) && !isHeroVariant2) {
      CTAText = language.getMessage('hero.study.jumpBackIn');
    }
    let mappedChannel = null;

    if (channelData) {
      mappedChannel = {
        thumbnailUrl: channelData.thumbnail,
        title: channelData.title,
        description,
        callForAction: {
          text: CTAText
        }
      };
    }

    return mappedChannel;
  },
  getChannelTitle() {
    const { channelData } = self;
    const { title } = channelData || {};

    return title;
  },
  getAssets(MAX_ASSET_COUNT) {
    let assets = {};
    const language = Framework.getStoreRegistry().getStore('language');
    const { recommendedAssets, popularTopics } = self;

    if (recommendedAssets && recommendedAssets.length > 0) {
      const cards = recommendedAssets.slice(0, MAX_ASSET_COUNT).map((response) => {
        const {
          description,
          section,
          title,
          thumbnailUrl,
          sectionColor,
          isVideo
        } = CommonUtils.getRecommendedAssetsData(response);
        const card = {
          label: section,
          title,
          description,
          thumbnailUrl,
          cardProps: {
            url: response.url
          },
          overlayCta: response?.completed ? { text: language.getMessage('hero.watch_it_again') } : null,
          isCompleted: response?.completed,
          styles: {
            color: sectionColor || null
          },
          isVideo
        };

        return card;
      });
      assets = {
        template: constants.ASSET_TYPES.RECOMMENDED_ASSETS,
        cards
      };
    } else if (popularTopics && popularTopics.length > 0) {
      const channelType = {
        EXAM_PREP: constants.CHANNEL_TYPE_RESPONSE.PRACTICE_SET,
        GUIDED: constants.CHANNEL_TYPE_RESPONSE.TOPIC
      };
      const {
        BLUE,
        GREEN
      } = constants.ASSET_CARD_SECTION_COLOR;

      const cards = popularTopics.slice(0, MAX_ASSET_COUNT).map((response) => {
        let description = null;
        if (response && response.topicDuration) {
          description = (response.topicDuration.split(':').length > 2)
            ? response.topicDuration.replace(/^0/, '').concat(constants.HRS)
            : response.topicDuration.replace(/^0/, '').concat(constants.MIN);
        }

        const card = {
          label: channelType[response.type] || '',
          title: response.topicTitle,
          description,
          thumbnailUrl: response.type === constants.CHANNEL_TYPES.EXAM_PREP
            ? constants.PRACTICE_SET_ICON : response.thumbUrl,
          cardProps: {
            url: response.url
          },
          overlayCta: null,
          isCompleted: false,
          styles: {
            color: response.type === constants.CHANNEL_TYPES.EXAM_PREP
              ? GREEN : BLUE
          },
          isVideo: response.type !== constants.CHANNEL_TYPES.EXAM_PREP
        };

        return card;
      });

      assets = {
        title: language.getMessage('hero.popularTopics.headingV1'),
        template: constants.ASSET_TYPES.POPULAR_ASSETS,
        cards
      };
    }

    return assets;
  }
})).actions(self => ({
  /**
   * fetch recommendation
   *
   */
  fetch(data) {
    this.setByBookError(false);
    Framework.getEventManager().publish(constants.CHANNEL_RECOMMENDATIONS_BY_BOOK_REQUESTED, data);
  },
  /**
   * Function to set recommendations
   *
   * @param {*} recommendations
   */
  set(recommendations) {
    let {
      // eslint-disable-next-line prefer-const
      popularTopics,
      recommendedAssets
    } = recommendations || {};
    const { topic, assets } = (recommendedAssets?.length > 0 && recommendedAssets[0]) || {};
    const { assets: assetsList } = topic || {};

    if (assetsList && assetsList.length > 0) {
      recommendedAssets = assetsList.map((assetId) => {
        if (assets[assetId]) {
          return CommonUtils.constructAssetData(assets[assetId]);
        }

        return null;
      });
    } else {
      recommendedAssets = [];
    }

    applySnapshot(self, {
      channelData: null,
      recommendedAssets: CommonUtils.filterAssets(recommendedAssets),
      popularTopics
    });
    if (recommendedAssets?.length <= 0 && popularTopics?.length <= 0) {
      const herobanner = Framework.getStoreRegistry().getStore('herobanner');
      herobanner.setAssets(herobanner.getAssetsInitialState(), false);
    } else {
      Framework.getEventManager().publish(constants.CHANNEL_RECOMMENDATIONS_BY_BOOK_FETCHED, {});
    }
  },
  setByBookError(isError = true) {
    // eslint-disable-next-line no-param-reassign
    self.isError = isError;
  },
  setError(err) {
    console.log(err, 'error in Channel recommendations');
    this.setByBookError();
    const herobanner = Framework.getStoreRegistry().getStore('herobanner');
    herobanner.setAssets(herobanner.getAssetsInitialState(), false);
  }
}));

export default ChannelRecommendationsByBook;
