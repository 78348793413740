/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * @author Gladson Samuel S
 */

import React, { Suspense } from 'react';
import Framework from '@greenville/framework';
import { getSnapshot } from 'mobx-state-tree';
import xss from 'xss';
import {
  AUTH_HOME,
  MLM_AUTH_HOME,
  CHANNEL_QUERY_PARAM,
  CHANNEL_ASSET_QUERY_PARAM,
  CHANNEL_CHANNEL_QUERY_PARAM,
  CHANNEL_SECTION_QUERY_PARAM,
  ACTIVE,
  ACTIVITY_TYPE,
  BUNDLE,
  CHANNEL_LINKS,
  CONTENT_TYPE_CITE,
  CONTENT_TYPE_EPUB,
  ETEXT_PDF,
  EVENT_AUTHHOMEEVENT,
  GO_TO_PAGE_MAX_LENGTH,
  HERO_BANNER_TYPES,
  HERO_CONTENT_CARD_POSITIONS,
  HERO_ICONS,
  HERO_STYLES,
  LIBRARY_EVENT,
  LOCATION_IN_APP,
  PPLUS_SUBSCRIPTIONS,
  READ,
  SET_HERO_DASHBOARD,
  TOC_SEPERATOR_CHAPTER,
  ASSET_FETCHED,
  ASSET_FAILED,
  CHANNEL_TOC_FAILED,
  CHANNEL_TOC_FETCHED,
  CHANNEL_TOC_REQUESTED,
  AUTHHOME_APPLICATION,
  COURSE_TAB_EVENT,
  MONDLY_CARD_POSITIONS,
  EVENT_USER_INTERACTION,
  AUTH_HOME_CATEGORY,
  CHANNEL_CARD_EVENT,
  DEFAULT_TEMPLATE,
  VARIANT_B,
  ONBOARDING_HEI_AB_TESTING_EVENT,
  HEADER_EVENT,
  COURSE_COLLECTION_SERVICE_REQUESTED,
  HTTP_REQUEST_METHODS,
  EXAM_PREP_FLOW,
  AI_STUDY_TOOL_EVENT,
  CHANNEL_MAPPING_TYPES,
  ETEXT_RELATED_CHANNEL,
  STUDY_EXAM_COMPONENT_POSITION,
  ETEXT_MAPPEDCHANNEL_LITE,
  HERO_STYLES_V1,
  AI_CHAT_VARIANT,
  VARIANT_V1,
  CHANNEL_EVENT
} from '../../../common/constants';
import ProductUtils from '../../../common/utils/ProductUtils';
import CommonUtils from '../../../common/utils/CommonUtils';
import env from '../../../common/env';
import LoadingContainer from '../../../common/components/LoadingContainer';
import ChannelExamsContainer from '../ChannelExamsContainer';
import { HeroGenericShimmer, ChannelsSliderLoader } from '../Loader/ComponentLoader';

const PopularChannelsSlider = CommonUtils.lazyWithPreload(() => import('@authhome/library')
  .then(module => ({ default: module.PopularChannelsSlider })));

const HeroDashboard = CommonUtils.lazyWithPreload(() => import('@authhome/library')
  .then(module => ({ default: module.HeroDashboard })));

export default class HeroDashboardPlugin {
  constructor(context, location = AUTH_HOME) {
    this.context = context;
    this.userLastVisitedId = '';
    this.userChannelRecentTopic = {};
    this.isInitialLoad = false;
    this.init();
    this.location = location;
    this.isTocClicked = false;
    this.AIStudyToolIsInputDirty = false;
  }

  static activeCourseTab = null;

  static isBundleUser = false;

  static hasChannelSubscription = false;

  init() {
    Framework.getEventManager().on(SET_HERO_DASHBOARD, props => this.setHeroDashboard(props));
    Framework.getEventManager().on(ASSET_FETCHED, () => this.setTableOfContentData());
    Framework.getEventManager().on(ASSET_FAILED, () => this.setTableOfContentError());
    Framework.getEventManager().on(CHANNEL_TOC_FETCHED, (response) => {
      this.setChannelTOCData(response);
    });
    Framework.getEventManager().on(CHANNEL_TOC_FAILED, () => this.setTableOfContentError());
  }

  renderHeroDashboard = (isScreenResBelow1024 = false) => {
    const isAHNewUi = this.context.commonPlugin.isAHNewUi();
    const { herobanner, user, language } = this.context.props;
    const { flags } = this.context.state;
    const { template, courseId, isMLMcourse } = HeroDashboardPlugin.activeCourseTab || {};
    const {
      bannerType,
      channelId,
      heroHeading,
      heroSubHeading,
      assets,
      subscriptionStatus,
      tableOfContents,
      titleInfo,
      goToPage,
      heroContentCard
    } = getSnapshot(herobanner);
    const overRideConfig = this.location === MLM_AUTH_HOME ? { showPopularChannels: isAHNewUi } : {};
    const config = this.getConfig(bannerType, { channelId, ...overRideConfig, isScreenResBelow1024 });
    const promotionalLinksData = herobanner.getPromotionalLinks(channelId);
    const promotionalLinks = (promotionalLinksData && promotionalLinksData.length > 1) ? {
      title: this.getStudyPrepOrPackText(),
      listContent: promotionalLinksData
    } : null;
    HeroDashboardPlugin.isBundleUser = CommonUtils.hasSubscription(user, [ACTIVE], [BUNDLE]);
    HeroDashboardPlugin.hasChannelSubscription = CommonUtils.isChannelsSubscriber();
    this.setSubscriptionStatusData({
      subscriptionStatus,
      config
    });
    this.setNotificationData(titleInfo);
    const productModelName = this.getProductModelName(titleInfo);
    const renderTitleInfo = { ...titleInfo, productModelName };
    let studyExamPrepCardPosition = this.context.getContainerData(
      'heroView', STUDY_EXAM_COMPONENT_POSITION, HERO_CONTENT_CARD_POSITIONS.BOTTOM
    );

    if (this.context.commonPlugin.isAHNewUi()) {
      studyExamPrepCardPosition = flags?.showStudyExamPrepCardAtTop
        ? HERO_CONTENT_CARD_POSITIONS.TOP : HERO_CONTENT_CARD_POSITIONS.BOTTOM;
    }

    return (
      <Suspense fallback={<HeroGenericShimmer withoutWrapper />}>
        <LoadingContainer isReadyToRender={!!bannerType} loader={<HeroGenericShimmer withoutWrapper />}>
          <HeroDashboard
            className="Hero-dashboard-section-container"
            config={config}
            data={{
              bannerType,
              heroHeading,
              heroSubHeading,
              assets,
              styles: this.getHeroStyles(template, isAHNewUi),
              promotionalCard: herobanner.getPromotionalCardData(),
              subscriptionStatus: {
                ...subscriptionStatus,
                cta: this.getSubscriptionStatusCardCta(bannerType)
              },
              heroHeader: {
                title: this.getHeroTopicContainerHeading(),
                cta: this.getHeroHeaderCTA(),
                description: this.getHeroDescription(),
                isSubscribed: CommonUtils.isChannelsSubscriber(),
                subscriptionText: this.getSubscriptionText()
              },
              tableOfContents,
              heroContentCard: {
                ...heroContentCard,
                goToPage,
                titleInfo: renderTitleInfo
              },
              popularChannels: this.getPopularChannelList(herobanner.getPopularChannels()),
              marketingCard: this.getMarketingCardData(),
              mondlyMarketingCard: this.getMondlyMarketingCardData(bannerType === HERO_BANNER_TYPES.MARKETING_TEMPLATE),
              promotionalLinks,
              aiChatInputData: this.getAiInputData(config, renderTitleInfo),
              relatedChannelCard: this.getRelatedChannelCardData(),
              studyExamPrepCardPosition
            }}
            callbacks={{
              onHeroHeaderCTAClick: this.onHeroHeaderCTAClick,
              handleTocClick: this.onTocClick,
              handleChapterIdChange: this.handleChapterIdChange,
              onAssetCardClick: this.onAssetCardClick,
              handleHeroThumbnailClick: this.handleHeroThumbnailClick,
              handleCtaClick: this.handleCtaClick,
              handleIconsClick: this.handleIconsClick,
              onTitleInfoBookCTAClick: this.onTitleInfoBookCTAClick,
              onTitleInfoNotificationCTAClick: this.onTitleInfoNotificationCTAClick,
              onTitleInfoRemoveClick: this.onTitleInfoBookCTAClick,
              onGoToPageNoChange: this.onGoToPageNoChange,
              onGoToPageInputBlur: this.onGoToPageInputBlur,
              onPromotionalLinksClick: this.onPromotionalLinksClick,
              onSubStatusCardLinksClick: this.onSubStatusCardLinksClick,
              onRemoveCourse: this.onRemoveCourse,
              onPopularChannelCardClick: this.onPopularChannelCardClick,
              onHEIStartLearningClick: this.onHEIStartLearningClick,
              onPromotionalCardCTAClick: this.onPromotionalCardCTAClick,
              onSubStatusCardCTAClick: this.onSubStatusCardCTAClick,
              onAIChatbotSend: this.onAIChatbotSend,
              onAIInputChange: this.onAIInputChange,
              onRelatedChannelClick: this.onRelatedChannelClick,
              onSeeAllClick: () => this.context.onSeeAll(false)
            }}
            components={{
              ExamPrepComponent: this.renderExamPrepComponent(user, channelId, courseId, isMLMcourse),
              PopularChannelSlider: this.renderPopularChannelSlider()
            }}
            onInit={this.context.initPageTracker}
            locale={language.currentLanguage}
          />
        </LoadingContainer>
      </Suspense>
    );
  }

  renderPopularChannelSlider = () => {
    const { herobanner } = this.context.props;
    const isAHNewUi = this.context.commonPlugin.isAHNewUi();
    const isBundleUser = HeroDashboardPlugin.isBundleUser;
    const cards = CommonUtils.getActiveChannelCards(herobanner?.popularChannels?.channelCards);
    const isLoaded = !!cards && !!herobanner.bannerType;
    let varient = DEFAULT_TEMPLATE;
    const { currentPopularChannelFilter } = this.context.state;

    if (!isAHNewUi && CommonUtils.isHEIUser()) {
      varient = VARIANT_B;
    }
    return (
      <LoadingContainer
        isReadyToRender={isLoaded}
        loader={<ChannelsSliderLoader />}
      >
        <Suspense fallback={<ChannelsSliderLoader />}>
          <PopularChannelsSlider
            channelCards={this.context.commonPlugin.getSortedPopularChannels(herobanner.popularChannels?.channelCards, this.context.state.currentPopularChannelFilter)}
            headingText={CommonUtils.getChannelContainerCopytext(herobanner.popularChannels?.channelCards, isBundleUser, isAHNewUi)}
            styleVaraint={varient}
            onPopularChannelCardClick={(index) => {
              if (this.location === MLM_AUTH_HOME) {
                this.onMLMPopularChannelCardClick(index);
                return;
              }
              this.context.coursePlugin.handlePopularChannelCardClick(index);
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            }}
            onPopularChannelFilterItemClick={(title) => {
              this.context.commonPlugin.setPopulatChannelFilter(title);
            }}
            gap={18}
            filterList={this.context.commonPlugin.getPopularChannelsFilterData(herobanner.popularChannels?.channelCards, currentPopularChannelFilter)}
            isSubscribed={CommonUtils.isChannelsSubscriber()}
            subscriptionText={this.getSubscriptionText()}
            variant={this.context.commonPlugin.getVariant()}
            config={this.context.commonPlugin.getPopularChannelsConfig()}

          />
        </Suspense>
      </LoadingContainer>
    );
  }

  renderExamPrepComponent = (user, channelId, courseId, isMLMcourse) => {
    const { language } = this.context.props || {};
    const courseIds = this.location === MLM_AUTH_HOME && courseId && isMLMcourse ? [courseId] : [];
    const isAHNewUi = this.context.commonPlugin.isAHNewUi();
    const examsPeriod = this.context.getContainerData(
      'heroView',
      STUDY_EXAM_COMPONENT_POSITION,
      HERO_CONTENT_CARD_POSITIONS.BOTTOM
    ) === HERO_CONTENT_CARD_POSITIONS.TOP;

    return (
      <ChannelExamsContainer
        userDetail={getSnapshot(user)}
        env={env.ENVIRONMENT}
        channelId={channelId}
        locale={language.currentLanguage}
        onGoToChannels={() => {
          let locationInApp = LOCATION_IN_APP.EXAMPREP_CONTAINER;
          if (isAHNewUi) {
            locationInApp = CHANNEL_CARD_EVENT.EXAMPREP_CARD_CONTAINER_FIELD;
          }

          this.tiggerChannelCardElement(locationInApp);
          this.handleCourseTabEvents();
        }
        }
        locationInApp={EXAM_PREP_FLOW.EXAM_PREP_CONTAINER}
        isBundleUser={HeroDashboardPlugin.isBundleUser}
        mlmCourseIds={courseIds}
        examsPeriod={examsPeriod}
        onComplete={() => {
          if (examsPeriod) {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }
        }}
        newUI={isAHNewUi}
      />
    );
  };

  setHeroDashboard({
    fetchChannels = false,
    courseList,
    isInitialLoad = false
  }) {
    this.userChannelRecentTopic = {};
    this.isTocClicked = false;
    this.setActiveCourseTab(courseList);
    const { template = HERO_BANNER_TYPES.MARKETING_TEMPLATE } = HeroDashboardPlugin.activeCourseTab || {};
    const { herobanner } = this.context.props;
    herobanner.reset();
    const heroBannerStore = getSnapshot(herobanner);
    let heroStoreData = { ...heroBannerStore };
    heroStoreData.config = this.getConfig(template, { channelId: heroStoreData.channelId });
    heroStoreData = { ...heroStoreData, ...this.constructHeroData(template, heroStoreData?.config) };

    herobanner.setHeroBanner(
      heroStoreData,
      fetchChannels && heroStoreData.bannerType === HERO_BANNER_TYPES.BOOK_WITH_CHANNEL
    );
    this.isInitialLoad = isInitialLoad;
    this.tiggerChannelCardElement(CHANNEL_CARD_EVENT.CHANNEL_CARD_ELEMENT_VIEW_EVENT_ACTION, true);
    this.tiggerABTestingEvent(heroStoreData.bannerType, isInitialLoad);
  }

  setMLMHeroDashboard() {
    this.userChannelRecentTopic = {};
    const { template = HERO_BANNER_TYPES.MARKETING_TEMPLATE } = HeroDashboardPlugin.activeCourseTab || {};
    const { herobanner } = this.context.props;
    herobanner.reset();
    const heroBannerStore = getSnapshot(herobanner);
    let heroStoreData = { ...heroBannerStore };
    heroStoreData.config = this.getConfig(template, { channelId: heroStoreData.channelId, showPopularChannels: false });
    heroStoreData = { ...heroStoreData, ...this.constructHeroData(template, heroStoreData?.config) };
    this.context.authhomeAIStudyToolPlugin.fetchHeroBookAsset();

    herobanner.setHeroBanner(
      heroStoreData,
      heroStoreData.bannerType === HERO_BANNER_TYPES.BOOK_WITH_CHANNEL
    );
  }

  constructHeroData = (template, config = {}) => {
    let data = {};
    const {
      BOOK,
      BOOK_WITH_CHANNEL,
      CHANNEL,
      MARKETING_TEMPLATE
    } = HERO_BANNER_TYPES;

    if (template === BOOK) {
      data = this.getEtextOnlyData(config);
    } else if (template === BOOK_WITH_CHANNEL) {
      data = this.getBookWithChannelData(config);
    } else if (template === CHANNEL) {
      data = this.getChannelOnlyData(config);
    } else {
      data = {
        bannerType: MARKETING_TEMPLATE
      };
    }

    return data;
  }

  setActiveCourseTab = (courseList) => {
    const { coursecollection } = this.context.props || {};
    let activeCourseTab = null;

    if (courseList && courseList?.length > 0) {
      const activeCourse = courseList.find(course => course.active);
      let courseTabProps = {};

      if (activeCourse && activeCourse.collection && activeCourse.collection.length > 0) {
        coursecollection.setActiveCourse(activeCourse);
        courseTabProps = { ...activeCourse.collection[0] };
        courseTabProps.isMLMcourse = activeCourse.isMLMcourse;
      }
      activeCourseTab = courseTabProps;
    }

    HeroDashboardPlugin.activeCourseTab = activeCourseTab;
  }

  constructBookLinks = () => {
    const { language } = this.context.props;

    return [
      {
        icon: HERO_ICONS.TOC_ICON,
        text: language.getMessage('hero.tableOfContents')
      },
      {
        icon: HERO_ICONS.SEARCH_ICON,
        text: language.getMessage('hero.search')
      },
      {
        icon: HERO_ICONS.FLASHCARD_ICON,
        text: language.getMessage('hero.flashcards')
      }
    ];
  }

  constructBookData = ({
    heroBook = {},
    config = {}
  }) => {
    const isAHNewUi = this.context.commonPlugin.isAHNewUi();
    const { language } = this.context.props;
    const {
      heroContentCard: heroContentCardConfig,
      componentsToRender
    } = config || {};
    const heroContentCard = {};
    heroContentCard.variant = isAHNewUi ? VARIANT_V1 : DEFAULT_TEMPLATE;
    heroContentCard.heading = language.getMessage('hero.yourETextbook');
    heroContentCard.title = heroBook.title;
    heroContentCard.thumbnailImage = heroBook.cover_image_url;
    heroContentCard.titleInfo = {
      author: heroBook.author,
      bookTitle: heroBook.title,
      courseName: this.getCourse(heroBook.courses).course_name,
      coverImageUrl: heroBook.cover_image_url,
      isbn: heroBook.isbn,
      productModelName: heroBook.product_model_name,
      book_id: heroBook.book_id,
      product_id: heroBook.product_id,
      course_id: heroBook.course_id,
      notificationsData: heroBook.bannerData || null,
      showRemoveEntitlement: heroBook.showRemoveEntitlement
    };
    if (heroContentCardConfig?.showGoToPage) {
      heroContentCard.goToPage = {
        placeholder: language.getMessage('hero.goToPage.label'),
        label: language.getMessage('hero.goToPage.label'),
        value: heroBook.last_accessed_print_page_number,
        initialValue: heroBook.last_accessed_print_page_number,
        maxLength: GO_TO_PAGE_MAX_LENGTH
      };
    }
    if (componentsToRender?.showToc) {
      heroContentCard.tableOfContents = {
        chapterTitle: heroBook.last_location_read || language.getMessage('hero.toc.defaultPlacehoder'),
        chapterId: heroBook.last_location_read_id || '',
        initialChapterId: heroBook.last_location_read_id || ''
      };
    }
    if (heroContentCardConfig?.showCallForAction) {
      heroContentCard.callForAction = {
        text: language.getMessage('hero.openEtextBookV1'),
        ariaLabel: language.getMessage('hero.openEtextBookV1'),
        isPrimary: isAHNewUi
      };
    }
    if (heroContentCardConfig.showLinks) {
      heroContentCard.links = this.constructBookLinks();
      heroContentCard.eTextFeaturesCopy = {
        title: language.getMessage('hero.etextfeaturestitle'),
        description: language.getMessage('hero.etextfeaturesdescription')
      };
    }
    if (heroContentCardConfig.showMyEtextbookLink) {
      heroContentCard.myEtextbookLinkData = {
        text: language.getMessage('hero.myetextbook.text'),
        linkText: language.getMessage('hero.myetextbook.link')
      };
    }

    return heroContentCard;
  }

  constructChannelOnlyData = ({
    heroChannel = {},
    config = {}
  }) => {
    const isAHNewUi = this.context.commonPlugin.isAHNewUi();
    const { language } = this.context.props;
    const {
      heroContentCard: heroContentCardConfig
    } = config || {};
    const {
      title,
      thumbnailUrl
    } = heroChannel || {};
    const heroContentCard = {};
    heroContentCard.heading = language.getMessage('hero.startStudying');
    heroContentCard.title = title;
    heroContentCard.thumbnailImage = thumbnailUrl;
    heroContentCard.variant = isAHNewUi ? VARIANT_V1 : DEFAULT_TEMPLATE;

    if (heroContentCardConfig?.showCallForAction) {
      heroContentCard.callForAction = {
        text: language.getMessage('channel.openchannel'),
        ariaLabel: language.getMessage('channel.openchannel'),
        isPrimary: isAHNewUi
      };
    }
    if (heroContentCardConfig?.showDelete) {
      heroContentCard.removeCourseDialogData = {
        title: language.getMessage('hero.removecourse.title'),
        description: language.getMessage('hero.removecourse.description'),
        primaryCtaText: language.getMessage('hero.removecourse.primaryCtaText'),
        secondaryCtaText: language.getMessage('hero.removecourse.secondaryCtaText')
      };
    }

    return heroContentCard;
  }

  getAiInputData = (config, titleInfo) => {
    const isAHNewUi = this.context.commonPlugin.isAHNewUi();
    const { language } = this.context.props;
    const { flags } = this.context.state;
    const { componentsToRender } = config || {};
    const bookTitle = (titleInfo && titleInfo.bookTitle) || '';

    if (!componentsToRender?.showAiChatInput) return {};

    if (!isAHNewUi) {
      return {
        variant: AI_CHAT_VARIANT.A,
        placeholder: language.getMessage('aichat.placeholder'),
        title: language.getMessage('aichat.title'),
        chatDescription: language.getMessage('aichat.charDesc').replace('%BOOK_TITLE%', bookTitle),
        showTitleBadge: true
      };
    }

    if (isAHNewUi && CommonUtils.localStringCompare(flags.AHAIStudyToolVariant, AI_CHAT_VARIANT.B)) {
      return {
        variant: AI_CHAT_VARIANT.B,
        title: language.getMessage('aichat.title1'),
        chatDescription: language.getMessage('aichat.chatDesc1')
      };
    }

    if (isAHNewUi && CommonUtils.localStringCompare(flags.AHAIStudyToolVariant, AI_CHAT_VARIANT.C)) {
      return {
        variant: AI_CHAT_VARIANT.C,
        title: language.getMessage('aichat.title2'),
        chatDescription: language.getMessage('aichat.chatDesc2')
      };
    }

    return {};
  };

  getRelatedChannelCardData = () => {
    const heroBook = this.getHeroBook() || {};
    const isRelatedMapping = CommonUtils.localStringCompare(
      heroBook.channel_mapping_type, CHANNEL_MAPPING_TYPES.RELATED
    );

    if (!isRelatedMapping || !heroBook.channel_id) return null;

    const { herobanner, language } = this.context.props || {};
    const {
      thumbnailUrl,
      title: channelTitle = '',
      isMostRelated
    } = herobanner.getChannelData(heroBook.channel_id) || {};

    if (!isMostRelated) return null;

    return {
      title: language.getMessage('relatedChannelCard.title'),
      thumbnailUrl,
      channelTitle,
      badgeText: language.getMessage('relatedChannelCard.badgeText')
    };
  }

  getMarketingCardData = () => {
    const {
      language
    } = this.context.props;
    const data = {
      title: language.getMessage('hero.marketing.title'),
      subTitle: this.context.getContainerData('heroView', 'marketing_subtitle') || language.getMessage('hero.marketing.subTitle'),
      primaryCTA: {
        text: language.getMessage('hero.emptystate.cta'),
        role: 'link'
      },
      coverImageUrl: `${env.PLUS_URL}/auth-home/media/images/eTextbook_marketing_image.png`
    };

    return data;
  }

  getMondlyMarketingCardData = (isVariant1 = false) => {
    const { language } = this.context.props;
    return {
      title: language.getMessage('hero.mondly.learnLang.title'),
      subTitle: language.getMessage('hero.mondly.subTitle'),
      // eslint-disable-next-line max-len
      description: language.getMessage('hero.mondly.description'),
      btnText: language.getMessage('hero.mondly.startLearningCTA'),
      imgSrc: 'https://static.studychannel.pearsonprd.tech/components/channelswidget/mondlyByPearson.png',
      startLearningCTAUrl: 'https://www.mondly.com/offer/highered2',
      mondlyMarketingList: isVariant1 ? [
        {
          name: language.getMessage('hero.mondly.marketing.bestApp'),
          place: language.getMessage('hero.mondly.marketing.appStore'),
          imgSrc: `${env.PLUS_URL}/auth-home/media/images/mondlyBestApp.png`
        },
        {
          name: language.getMessage('hero.mondly.marketing.appYear'),
          place: language.getMessage('hero.mondly.marketing.fbStart'),
          imgSrc: `${env.PLUS_URL}/auth-home/media/images/mondlyEditorChoice.png`
        },
        {
          name: language.getMessage('hero.mondly.marketing.editorChoice'),
          place: language.getMessage('hero.mondly.marketing.googlePlay'),
          imgSrc: `${env.PLUS_URL}/auth-home/media/images/mondlyAppYear.png`
        }
      ] : null
    };
  }


  getChannelOnlyData = (config = {}) => {
    const isAHNewUi = this.context.commonPlugin.isAHNewUi();
    const { herobanner, language } = this.context.props;
    const {
      CHANNEL
    } = HERO_BANNER_TYPES;
    const {
      channelId,
      title
    } = HeroDashboardPlugin.activeCourseTab;
    const data = {};
    const channel = herobanner.getChannelData(channelId);
    const {
      componentsToRender
    } = config || {};
    const {
      showToc,
      showHeroContentCard
    } = componentsToRender || {};
    const heading = isAHNewUi ? language.getMessage('hero.welcomeToPPlus') : title;
    data.heroHeading = config.showHeroHeading ? heading : null;
    data.channelId = channelId || null;
    data.bannerType = CHANNEL;

    if (showHeroContentCard) {
      data.heroContentCard = this.constructChannelOnlyData({
        heroChannel: channel,
        config
      });
    }
    if (showToc) {
      data.tableOfContents = null;
      Framework.getEventManager().publish(CHANNEL_TOC_REQUESTED, { courseId: data.channelId });
    }

    return data;
  }

  getBookWithChannelData = (config = {}) => {
    const isAHNewUi = this.context.commonPlugin.isAHNewUi();
    const {
      BOOK_WITH_CHANNEL
    } = HERO_BANNER_TYPES;
    const {
      title
    } = HeroDashboardPlugin.activeCourseTab;
    const {
      language
    } = this.context.props || {};
    const data = {};
    const heroBook = this.getHeroBook();
    const {
      componentsToRender,
      heroContentCard: heroContentCardConfig
    } = config;
    const {
      showToc,
      showHeroContentCard
    } = componentsToRender || {};
    const {
      showGoToPage
    } = heroContentCardConfig || {};
    const heading = isAHNewUi ? language.getMessage('hero.welcomeToPPlus') : this.getHeroHeading(heroBook, title);
    const subHeading = this.getHeroSubHeading(heroBook);
    data.heroHeading = config.showHeroHeading ? heading : null;
    data.heroSubHeading = config.showHeroSubHeading ? subHeading : null;
    data.channelId = heroBook?.channel_id || null;
    data.bannerType = BOOK_WITH_CHANNEL;
    const {
      tableOfContents,
      titleInfo,
      goToPage,
      ...heroBookData
    } = this.constructBookData({
      heroBook,
      config
    });

    data.titleInfo = titleInfo;
    if (showHeroContentCard) {
      data.heroContentCard = heroBookData;
    }
    data.tableOfContents = showToc ? tableOfContents : null;
    data.goToPage = showGoToPage ? goToPage : null;

    return data;
  }

  getEtextOnlyData = (config = {}) => {
    const isAHNewUi = this.context.commonPlugin.isAHNewUi();
    const {
      BOOK
    } = HERO_BANNER_TYPES;
    const {
      title
    } = HeroDashboardPlugin.activeCourseTab;
    const {
      language
    } = this.context.props || {};
    const data = {};
    const heroBook = this.getHeroBook();
    const {
      heroContentCard: heroContentCardConfig,
      componentsToRender
    } = config;
    const {
      showToc,
      showHeroContentCard
    } = componentsToRender || {};
    const {
      showGoToPage
    } = heroContentCardConfig || {};
    const heading = isAHNewUi ? language.getMessage('hero.welcomeToPPlus') : this.getHeroHeading(heroBook, title);
    const subHeading = this.getHeroSubHeading(heroBook);
    data.heroHeading = config.showHeroHeading ? heading : null;
    data.heroSubHeading = config.showHeroSubHeading ? subHeading : null;
    data.channelId = heroBook?.channel_id || null;
    data.bannerType = BOOK;
    const {
      tableOfContents,
      titleInfo,
      goToPage,
      ...heroBookData
    } = this.constructBookData({
      heroBook,
      config
    });

    data.heroContentCard = showHeroContentCard ? heroBookData : null;
    data.titleInfo = titleInfo;
    data.tableOfContents = showToc ? tableOfContents : null;
    data.goToPage = showGoToPage ? goToPage : null;

    return data;
  }

  getHeroHeading = (heroBook, title) => {
    let heading = title;
    const { language } = this.context.props || {};
    const BMC = heroBook?.product_entitlements?.business_model_code;
    const courseId = heroBook?.course_id;
    const courseName = heroBook?.courseName;
    const isMyLabSubscription = CommonUtils.isMyLabSubscription(courseId, BMC);

    if (isMyLabSubscription && courseName) {
      heading = language.getMessage('hero.heroHeadingV1')?.replace('{replace_value}', courseName);
    }

    return heading;
  };

  // eslint-disable-next-line class-methods-use-this
  getHeroSubHeading = (heroBook) => {
    let heading = null;
    const courseName = heroBook?.courseName;

    if (courseName) {
      heading = courseName;
    }

    return heading;
  };

  getHeroHeaderCTA = () => {
    const isAHNewUi = this.context.commonPlugin.isAHNewUi();
    const { herobanner, language } = this.context.props;
    const { channelId, config } = getSnapshot(herobanner);
    const {
      title
    } = herobanner.getChannelData(channelId) || {};
    const { heroHeader } = config || {};
    let CTAObject = null;
    if (heroHeader?.showCTA) {
      CTAObject = {
        text: isAHNewUi
          ? language.getMessage('hero.ctanewtext')
          : language.getMessage(
            'hero.header.cta'
          )?.replace(
            '{hero_header_cta_text}',
            title || ''
          )
      };
    }

    return CTAObject;
  };

  getHeroDescription = () => {
    const { herobanner, language } = this.context.props;
    const { config } = getSnapshot(herobanner);
    const { heroHeader } = config || {};

    if (heroHeader?.showDescription && !HeroDashboardPlugin.isBundleUser) {
      const heroBook = this.getHeroBook();
      const isExactLiteMapping = heroBook
      && this.context.coursePlugin.isExactLiteMapping(heroBook.channel_mapping_type);

      return isExactLiteMapping
        ? language.getMessage('hero.heroBook.sectionSubHeadingV2')
        : language.getMessage('hero.heroBook.sectionSubHeadingV1');
    }

    return null;
  }

  getHeroTopicContainerHeading = () => {
    const { language } = this.context.props;

    const heroBook = this.getHeroBook();
    const isExactLiteMapping = heroBook
    && this.context.coursePlugin.isExactLiteMapping(heroBook.channel_mapping_type);

    return isExactLiteMapping
      ? language.getMessage('topicConatiner.exactLiteHeader')
      : language.getMessage('topicConatiner.otherHeader');
  }

  findBook({
    bookId,
    productId,
    courseId
  }) {
    let bookData = null;
    const { bookshelf } = this.context.props;
    const { books } = getSnapshot(bookshelf);
    bookData = books.find(book => (
      book.book_id === bookId
        && book.product_id === productId
        && book.course_id === courseId
    ));

    return bookData;
  }

  getConfig(template, props = {}) {
    const {
      BOOK,
      BOOK_WITH_CHANNEL,
      CHANNEL,
      MARKETING_TEMPLATE
    } = HERO_BANNER_TYPES;
    const { ldClient, herobanner, bookshelf } = this.context.props;
    const { books } = getSnapshot(bookshelf);
    const heroBook = this.getHeroBook();
    const {
      channelId,
      showPopularChannels: overRideShowPopularChannels = true,
      isScreenResBelow1024
    } = props || {};
    const {
      showExams,
      isMostRelated
    } = herobanner.getChannelData(channelId) || {};
    const isHEIUser = CommonUtils.isHEIUser();
    const { flags } = this.context?.state;
    const {
      showAITutor,
      isAIReviewUser,
      enableAITutorOnAuthHome
    } = flags || {};
    const isHEIUserContent = isHEIUser && flags.showHEIUserContent;
    const hasChannelOrBundleSub = CommonUtils.isChannelsSubscriber();
    const isAITutorEnabled = CommonUtils.isAHAITutorEnabled(
      enableAITutorOnAuthHome,
      showAITutor,
      isAIReviewUser,
      heroBook?.isAITutorSupported
    );
    const isNotPdf = !(heroBook && heroBook.product_model === ETEXT_PDF);
    const isAHNewUi = this.context.commonPlugin.isAHNewUi();
    const showSeeAll = flags?.enableSeeAllLinkeText && books?.length > 1;

    let config = {
      variant: isAHNewUi ? VARIANT_V1 : DEFAULT_TEMPLATE,
      showHeroHeading: false,
      showHeroSubHeading: false,
      componentsToRender: {
        showHeroHeader: false,
        showHeroContentCard: false,
        showPromotionalCTA: false,
        showSubscriptionStatusCard: false,
        showPromotionalLinks: false,
        showAssets: false,
        showToc: false,
        showPromotionalCard: false,
        showPopularChannels: false,
        showMarketingCard: false,
        showExamPrepCard: false,
        showMondlyMarketingCard: false,
        showPopularChannelSlider: false,
        showAiChatInput: false,
        showRelatedChannelCard: false
      },
      heroContentCard: {
        showInfo: false,
        showLinks: false,
        showGoToPage: false,
        showDelete: false,
        showCallForAction: false,
        position: '',
        showMyEtextbookLink: false
      },
      heroHeader: {
        showTitle: false,
        showCTA: false,
        showDescription: false
      },
      mondlyMarketingCard: { position: MONDLY_CARD_POSITIONS.COL2 }
    };

    switch (template) {
      case BOOK:
        config = {
          ...config,
          showHeroHeading: true,
          showHeroSubHeading: isAHNewUi,
          componentsToRender: {
            showHeroContentCard: true,
            showSubscriptionStatusCard: !isAHNewUi,
            showMondlyMarketingCard: isHEIUserContent,
            showPopularChannelSlider: this.location !== MLM_AUTH_HOME && !isScreenResBelow1024 && !isAHNewUi,
            showPromotionalCard: !hasChannelOrBundleSub && !isAHNewUi,
            showAiChatInput: isNotPdf && isAITutorEnabled && this.location === MLM_AUTH_HOME,
            showRelatedChannelCard: !!isMostRelated && this.location !== MLM_AUTH_HOME && !isAHNewUi
          },
          heroContentCard: {
            showInfo: true,
            showLinks: true,
            showGoToPage: this.showGoToPage({ heroBook }),
            showCallForAction: true,
            position: HERO_CONTENT_CARD_POSITIONS.COL1,
            showMyEtextbookLink: showSeeAll,
            variant: isAHNewUi ? VARIANT_V1 : DEFAULT_TEMPLATE
          },
          heroAIchatCard: { position: MONDLY_CARD_POSITIONS.COL1 }
        };
        break;
      case BOOK_WITH_CHANNEL:
        config = {
          ...config,
          showHeroHeading: true,
          showHeroSubHeading: isAHNewUi,
          componentsToRender: {
            showHeroContentCard: true,
            showSubscriptionStatusCard: !isAHNewUi,
            showPromotionalLinks: hasChannelOrBundleSub && !isAHNewUi,
            showAssets: true,
            showToc: ldClient.variation('enableTocInHeroView', true),
            showExamPrepCard: showExams,
            showMondlyMarketingCard: isHEIUserContent,
            showPopularChannelSlider: isHEIUserContent && !isAHNewUi,
            showPromotionalCard: !hasChannelOrBundleSub && !isAHNewUi,
            showAiChatInput: isNotPdf && isAITutorEnabled && this.location === MLM_AUTH_HOME
          },
          heroContentCard: {
            showInfo: true,
            showGoToPage: this.showGoToPage({ heroBook }),
            showCallForAction: true,
            position: HERO_CONTENT_CARD_POSITIONS.COL2,
            showMyEtextbookLink: showSeeAll,
            variant: isAHNewUi ? VARIANT_V1 : DEFAULT_TEMPLATE
          },
          heroHeader: {
            showTitle: true,
            showCTA: true,
            showDescription: !HeroDashboardPlugin.isBundleUser && !isAHNewUi,
            showSubscriptionStatus: isAHNewUi
          },
          heroAIchatCard: { position: MONDLY_CARD_POSITIONS.COL2 },
          mondlyMarketingCard: { position: MONDLY_CARD_POSITIONS[isAHNewUi ? 'COL1' : 'COL2'] }
        };
        break;
      case CHANNEL:
        config = {
          ...config,
          showHeroHeading: true,
          componentsToRender: {
            showHeroContentCard: true,
            showSubscriptionStatusCard: !isAHNewUi,
            showPromotionalLinks: hasChannelOrBundleSub && !isAHNewUi,
            showAssets: true,
            showToc: true,
            showExamPrepCard: showExams,
            showMondlyMarketingCard: isHEIUserContent,
            showPopularChannelSlider: isHEIUserContent && !isAHNewUi,
            showPromotionalCard: !hasChannelOrBundleSub && !isAHNewUi
          },
          heroContentCard: {
            showDelete: true,
            showCallForAction: true,
            position: HERO_CONTENT_CARD_POSITIONS.COL2,
            variant: isAHNewUi ? VARIANT_V1 : DEFAULT_TEMPLATE
          },
          heroHeader: {
            showTitle: true,
            showCTA: true,
            showSubscriptionStatus: isAHNewUi
          },
          mondlyMarketingCard: { position: MONDLY_CARD_POSITIONS[isAHNewUi ? 'COL1' : 'COL2'] }
        };
        break;
      case MARKETING_TEMPLATE:
        config = {
          ...config,
          componentsToRender: {
            showPopularChannels: overRideShowPopularChannels,
            showMarketingCard: !isHEIUser,
            showMondlyMarketingCard: isHEIUser,
            showPopularChannelSlider: isHEIUserContent && !flags.showHEIUserContent
          },
          mondlyMarketingCard: { position: MONDLY_CARD_POSITIONS.COL1 }
        };
        break;
      default:
        break;
    }

    return config;
  }

  getHeroBook = () => {
    const {
      bookId,
      productId,
      courseId
    } = HeroDashboardPlugin.activeCourseTab || {};
    const heroBook = this.findBook({ bookId, productId, courseId });

    return heroBook;
  }

  getStudyPrepOrPackText = () => {
    const { language } = this.context.props;

    return language.getMessage('subscription.studyTitle');
  }

  getSubscriptionText = () => {
    const { language } = this.context.props;
    const text = CommonUtils.isChannelsSubscriber()
      ? 'subscriptiontext.isSubscribed'
      : 'subscriptiontext.nonSubscriber';

    return language.getMessage(text);
  };

  showGoToPage = ({
    heroBook
  }) => {
    // eslint-disable-next-line camelcase
    const { printPageAvailable, book_type } = heroBook;
    const { flags } = this.context?.state;
    const { ldClient } = this.context?.props;
    // eslint-disable-next-line camelcase
    const isBronte = book_type === CONTENT_TYPE_EPUB;
    // eslint-disable-next-line camelcase
    const isCITE = book_type === CONTENT_TYPE_CITE;
    const isBVBronte = ProductUtils.isBVBronteBook(book_type);

    let showGoToPage = false;

    if (flags?.enableGoToPage && printPageAvailable) {
      if (isBronte) {
        showGoToPage = ldClient.variation('enablePrintPageNavigationBronte', false);
      } else if (ldClient.variation('enablePrintPageNavigation', false)
        && !isCITE
        && !isBVBronte
      ) {
        showGoToPage = true;
      }
    }

    return showGoToPage;
  }

  /**
  * Function to set falgs
  * @param {array} courses
  */
  getCourse = (courses) => {
    let courseDetails = {
      course_id: null,
      course_name: null,
      course_start_date: null,
      course_end_date: null,
      user_role: null
    };
    if (courses && Array.isArray(courses) && courses.length > 0) {
      const course = courses[0];
      courseDetails = { ...courseDetails, ...course };
    }

    return courseDetails;
  }

  setNotificationData = (titleInfo, overRideNotification = false) => {
    const {
      herobanner
    } = this.context.props || {};
    const isNotificationSet = titleInfo && titleInfo.notificationsData && !overRideNotification;
    const heroBook = this.getHeroBook();
    const heroNotification = heroBook && heroBook.bannerData;

    if (!isNotificationSet && heroNotification) {
      herobanner.setNotification(heroNotification);
    }
  }

  getProductModelName = (titleInfo) => {
    const productModelName = titleInfo && titleInfo.productModelName;

    if (productModelName) {
      return productModelName;
    }

    const heroBook = this.getHeroBook();

    return (heroBook && heroBook.product_model_name) || null;
  }

  setSubscriptionStatusData = (props = {}) => {
    const { user, herobanner } = this.context?.props || {};
    const {
      subscriptionStatus,
      config
    } = props || {};
    const {
      componentsToRender
    } = config || {};
    const { dataFetched } = this.context?.state || {};
    const isUserFetched = user && user.gpsSubscriptions;
    const heroBook = this.getHeroBook() || {};
    const data = this.getSubscriptionStatusData({ heroBook });
    const isSubscriptionStatusSet = subscriptionStatus?.listContent?.length > 0;

    if (isUserFetched && dataFetched && !isSubscriptionStatusSet && componentsToRender?.showSubscriptionStatusCard) {
      herobanner.setSubscriptionStatus(data);
    }
  }

  /**
   * Constructs and returns subscription status data
   *
   * @param {Object} param0
   * @returns
   */
  getSubscriptionStatusData = (props = {}) => {
    const { heroBook } = props || {};
    const { language } = this.context.props;
    const isBundleUser = HeroDashboardPlugin.isBundleUser;
    const hasChannelSubscription = HeroDashboardPlugin.hasChannelSubscription;
    const subscriptions = [];
    const title = language.getMessage('hero.subscriptionStatusTitle2');

    let hasActiveBooks = false;

    if (!heroBook || CommonUtils.isEmpty(heroBook)) {
      const { bookshelf } = this.context.props || {};
      const { books } = getSnapshot(bookshelf) || {};
      hasActiveBooks = CommonUtils.hasActiveBooks(books);
    }

    /**
     * For all users eTextbook
     *
     */
    subscriptions.push({
      id: PPLUS_SUBSCRIPTIONS.ETEXTBOOK,
      text: language.getMessage('hero.eTextbook'),
      checked: !CommonUtils.isEmpty(heroBook) ? true : hasActiveBooks
    });

    /**
      * For all other users - Study & Exam Prep
      *
    */
    if (!isBundleUser) {
      subscriptions.push({
        id: PPLUS_SUBSCRIPTIONS.STUDY_EXAM_PREP,
        text: language.getMessage('hero.studyandexamprep'),
        checked: hasChannelSubscription
      });
    }

    /**
     * If bundle user copy - Study & Exam Prep Pack
     *
    */
    if (isBundleUser) {
      subscriptions.push({
        id: PPLUS_SUBSCRIPTIONS.STUDY_EXAM_PREP_PACK,
        text: language.getMessage('hero.heroHeadingForBundleUser'),
        checked: isBundleUser
      });
    }

    return {
      title,
      listContent: subscriptions
    };
  }

  getSubscriptionStatusCardCta = (bannerType) => {
    const { language } = this.context.props;
    let cta = null;

    if (bannerType === HERO_BANNER_TYPES.BOOK) {
      cta = {
        text: HeroDashboardPlugin.isBundleUser ? language.getMessage('subscriptionstatus.bundlecta')
          : language.getMessage('subscriptionstatus.nonbundlecta'),
        isPrimary: true
      };
    }

    return cta;
  }

  /**
   * Sets table of content data for hero book
   *
   */
  setTableOfContentData() {
    let data = {};
    let book = null;
    const { asset, herobanner } = this.context.props;
    const { tableOfContents } = getSnapshot(herobanner);
    let chapterId = (tableOfContents && tableOfContents.chapterId) || '';
    book = this.findHeroBook();
    if (book) {
      const isPdf = book.product_model === ETEXT_PDF;
      const role = ((
        book.courses
        && book.courses.length > 0
        && book.courses[0].user_role)
        || (book.product_entitlements?.user_role
        ));
      const isAudioBook = book.showAudio;
      if (isPdf) {
        data = asset.getAssetTocPdf(null, role, false, isAudioBook);
      } else {
        data = getSnapshot(asset);
      }
      data = this.tocTraverseToMapUniqueId(data);
      chapterId = this.userLastVisitedId || chapterId;
      herobanner.setTableOfContents({ data, chapterId });
    }
  }

  constructChannelTOC = (tocData, userRecentTopicId) => {
    const tocNode = {};
    tocNode.id = tocData.id;
    tocNode.title = tocData.title;

    if (userRecentTopicId === tocData.id) {
      this.userChannelRecentTopic.id = tocData.id;
      this.userChannelRecentTopic.title = tocData.title;
    }

    if (tocData.topics?.length > 0) {
      tocNode.children = [];
      tocData.topics.forEach((topicData) => {
        tocNode.children.push(this.constructChannelTOC(topicData, userRecentTopicId));
      });
    }

    return tocNode;
  }

  setChannelTOCData(response = {}) {
    const {
      userRecentTopicId,
      chapters
    } = response || {};
    const { herobanner } = this.context.props || {};
    let chapterId = null;
    let chapterTitle = null;
    const data = {};
    data.children = [];

    if (chapters && chapters.length > 0) {
      chapters.forEach((tocData) => {
        data.children.push(this.constructChannelTOC(tocData, userRecentTopicId));
      });

      const isNewUser = !userRecentTopicId && CommonUtils.isEmpty(this.userChannelRecentTopic || {});
      let firstTopic = null;

      if (chapters?.length > 0) {
        const firstChapter = chapters[0];
        if (firstChapter?.topics?.length > 0) {
          firstTopic = firstChapter?.topics[0];
        }
      }

      const chapterData = !isNewUser ? this.userChannelRecentTopic : firstTopic;
      const {
        id,
        title
      } = chapterData;

      chapterId = id;
      chapterTitle = title;
    }

    herobanner.setTableOfContents({
      data,
      chapterId,
      chapterTitle,
      isVariant2: true
    });
  }

  /**
   * Sets isTOCFetchFailed as true when asset call fails
   *
   */
  setTableOfContentError() {
    const { herobanner } = this.context.props;
    herobanner.setTableOfContents({ isTOCFetchFailed: true });
  }

  /**
   * Find the Hero book in library
   *
   * @returns
   */
  findHeroBook() {
    let bookData;
    const { BOOK, BOOK_WITH_CHANNEL } = HERO_BANNER_TYPES;
    const { bookshelf, herobanner } = this.context.props;
    const { books } = getSnapshot(bookshelf);
    const { bannerType, titleInfo } = herobanner;
    if (bannerType === BOOK || bannerType === BOOK_WITH_CHANNEL) {
      bookData = books.find(book => (
        book.book_id === titleInfo.book_id
          && book.product_id === titleInfo.product_id
          && book.course_id === titleInfo.course_id
      ));
    }

    return bookData;
  }

  /**
   * TO Traverse tree and update unque id with separator
   *
   * @param {*} tocData
   */
  tocTraverseToMapUniqueId(tocChildren) {
    const { herobanner } = this.context.props;
    const { tableOfContents } = getSnapshot(herobanner);
    const chapterId = (tableOfContents && tableOfContents.chapterId) || '';
    const tocLevelChildList = {};
    tocLevelChildList.id = tocChildren.id;
    tocLevelChildList.title = tocChildren.title;
    tocLevelChildList.children = [];
    (tocChildren.children).forEach((tocData, parentIndex) => {
      if (typeof tocData.display === 'undefined' || tocData.display) {
        const childList = this.constructTree(tocData, (parentIndex + 1), chapterId);
        tocLevelChildList.children.push(childList);
      }
    });

    return tocLevelChildList;
  }

  /**
   * Method for constructing tree structured TOC
   *
   * @param {*} tocData
   * @returns
   */
  constructTree(tocData, parentIndex, chapterId) {
    const tocNode = {};
    if (tocData.id === chapterId) {
      this.userLastVisitedId = `${tocData.id}${TOC_SEPERATOR_CHAPTER}${parentIndex}`;
    }
    tocNode.id = `${tocData.id}${TOC_SEPERATOR_CHAPTER}${parentIndex}`;
    tocNode.title = tocData.title;
    tocNode.children = [];
    if (tocData.children) {
      tocData.children.forEach((tocDataChildren, tocDataChildrenIndex) => {
        if (typeof tocDataChildren.display === 'undefined' || tocDataChildren.display) {
          const childNode = this.constructTree(tocDataChildren, `${parentIndex}.${(tocDataChildrenIndex + 1)}`, chapterId);
          tocNode.children.push(childNode);
        }
      });
    }

    return tocNode;
  }

  static isTocFetched = () => {
    const heroBannerStore = Framework.getStoreRegistry().getStore('herobanner');
    const heroBanner = getSnapshot(heroBannerStore);

    return !!heroBanner?.TableOfContents?.data;
  }

  // eslint-disable-next-line class-methods-use-this
  getHeroStyles = (
    template = HERO_BANNER_TYPES.MARKETING_TEMPLATE,
    isAHNewUi = false
  ) => (isAHNewUi ? HERO_STYLES_V1[template] : HERO_STYLES[template]);

  /**
   * Makes asset call for the hero book required for TOC
   *
   * @param {Object} book
   */
  fetchAsset(book) {
    const { asset } = this.context.props;
    const id = CommonUtils.isIntegratedBMC(book.product_entitlements.business_model_code)
      ? book.book_id : book.product_id;

    if (book) {
      asset.fetchHeroTOC(id, book.course_id, null, book.book_type, false, true);
    }
  }

  /**
   * To get clean chapterId in case of duplicate case
   *
   */
  getCleanChapterId = () => {
    const { herobanner } = this.context.props;
    const { tableOfContents } = getSnapshot(herobanner);
    let renderChapterId = (tableOfContents && tableOfContents.chapterId) || '';
    if (renderChapterId && renderChapterId.includes(TOC_SEPERATOR_CHAPTER)) {
      renderChapterId = renderChapterId.split(TOC_SEPERATOR_CHAPTER)[0];
    }

    if (tableOfContents && (renderChapterId === tableOfContents.initialChapterId)) {
      renderChapterId = '';
    }

    return renderChapterId ? encodeURI(renderChapterId) : '';
  }

  /**
   * Returns the current go to page number of hero book
   *
   * @returns
   */
  getGoToPageNo = () => {
    const { herobanner } = this.context.props;
    const { goToPage } = getSnapshot(herobanner);
    const { value, initialValue } = goToPage || {};
    let renderValue = value;

    if (renderValue?.includes('-')) {
      renderValue = renderValue.replace(/^-+|-+$/g, '');
    }

    return encodeURI(initialValue === renderValue ? encodeURI('') : encodeURI(renderValue));
  }

  handleCourseTabEvents = (fromLocation = '') => {
    if (fromLocation === LOCATION_IN_APP.LIBRARY_L2) {
      return;
    }
    const isAHNewUi = this.context.commonPlugin.isAHNewUi();
    const {
      template = HERO_BANNER_TYPES.MARKETING_TEMPLATE,
      channelId,
      channelMappingType,
      productId = null
    } = HeroDashboardPlugin.activeCourseTab || {};
    const {
      flags
    } = this.context.state || {};
    const eventLabel = this.isInitialLoad
      ? COURSE_TAB_EVENT.LOADED_COURSE_EVENT_LABEL : COURSE_TAB_EVENT.OTHER_COURSE_EVENT_LABEL;
    const isRelatedMapping = CommonUtils.localStringCompare(
      channelMappingType || '', CHANNEL_MAPPING_TYPES.RELATED
    );
    const isExactLiteMapping = flags.enableExactLiteMapping && CommonUtils.localStringCompare(
      channelMappingType || '', CHANNEL_MAPPING_TYPES.EXACTLITE
    );
    let currentTemplate = template;

    if (isExactLiteMapping) currentTemplate = ETEXT_MAPPEDCHANNEL_LITE;
    else if (isRelatedMapping) currentTemplate = ETEXT_RELATED_CHANNEL;

    const customFields = {
      container_value: COURSE_TAB_EVENT[currentTemplate],
      channel_id: channelId || null
    };
    if (isAHNewUi) {
      customFields.book_id = HeroDashboardPlugin?.activeCourseTab?.bookId || null;
      customFields.product_id = productId;
    }
    CommonUtils.dispatchGaEvent(
      AUTHHOME_APPLICATION,
      EVENT_USER_INTERACTION,
      COURSE_TAB_EVENT.COURSE_TAB_CLICK_EVENT_ACTION,
      eventLabel,
      customFields
    );
  }

  /**
   * *********************************************
   * Callbacks
   * ********************************************
   */


  /**
   * Handle TOC click
   */
  handleTocClick = (fetchAsset = true) => {
    const { herobanner } = this.context.props || {};
    const {
      bannerType
    } = getSnapshot(herobanner);
    const {
      BOOK,
      BOOK_WITH_CHANNEL
    } = HERO_BANNER_TYPES;
    const customFields = {
      is_ghost_account: CommonUtils.isGhostAccount(),
      location_in_app: LOCATION_IN_APP.COURSE_TAB
    };
    const book = this.findHeroBook();
    if (fetchAsset && book && (bannerType === BOOK || bannerType === BOOK_WITH_CHANNEL)) {
      this.fetchAsset(book);
    }
    CommonUtils.dispatchGaEvent(
      LIBRARY_EVENT.EVENT_CATEGORY,
      EVENT_AUTHHOMEEVENT,
      LIBRARY_EVENT.ETEXT_FEATURE_ELEMENT_CLICK_EVENT_ACTION,
      LIBRARY_EVENT.TOC_EVENT_LABEL,
      customFields
    );
    this.handleCourseTabEvents();
  }

  handleChapterIdChange = (chapterId, chapterTitle, parentChapterTitle) => {
    const { herobanner } = this.context.props;

    herobanner.setTableOfContents({ chapterId, chapterTitle, parentChapterTitle });
    this.context.studyHelpPlugin.fetchHeroMappedChannel();
  }

  /**
   * Handles CTA click in Hero
   *
   * @param {*} isFromInfoModal
   */
  handleCtaClick = (isFromInfoModal = false) => {
    const {
      BOOK_WITH_CHANNEL,
      BOOK,
      CHANNEL
    } = HERO_BANNER_TYPES;
    const { herobanner } = this.context.props;
    const { bannerType, channelId } = getSnapshot(herobanner);

    switch (bannerType) {
      case BOOK_WITH_CHANNEL:
      case BOOK: {
        const book = this.findHeroBook();
        if (book) {
          const goToPageNo = this.getGoToPageNo();
          this.context.handleBookClick(
            book,
            READ,
            book.course_id,
            null,
            null,
            LOCATION_IN_APP.COURSE_TAB,
            { goToPageNo }
          );
          CommonUtils.handleInfoBookCTAEvents(book, true, LOCATION_IN_APP.COURSE_TAB, isFromInfoModal);
        }
      }
        break;
      case CHANNEL: {
        const {
          url,
          learnUrl,
          exploreUrl,
          showExams
        } = herobanner.getChannelData(channelId);
        this.tiggerChannelCardElement(CHANNEL_CARD_EVENT.CHANNEL_CONTAINER_FIELD);
        this.handleCourseTabEvents();
        if (url || learnUrl || (url && showExams) || exploreUrl) {
          const renderURL = url || learnUrl || CommonUtils.getExamPrepUrl(url) || exploreUrl;
          window.open(`${renderURL}?${CHANNEL_CHANNEL_QUERY_PARAM}`);
        }
      }
        break;
      default:
        break;
    }
  }

  /**
   * Handles thumbnail click in Hero
   */
  handleHeroThumbnailClick = () => {
    this.handleCtaClick();
  }

  /**
   * Handles title info modal CTA click
   */
  onTitleInfoBookCTAClick = () => {
    this.handleCtaClick(true);
  }

  /**
   * Handles handle Icons Click on Hero
   */
  handleIconsClick = (type) => {
    const book = this.findHeroBook();
    const customFields = {
      is_ghost_account: CommonUtils.isGhostAccount(),
      location_in_app: LOCATION_IN_APP.COURSE_TAB
    };

    if (type === ACTIVITY_TYPE.REMOVE_FROM_COURSE) {
      // Instrumentation
      CommonUtils.dispatchGaEvent(
        LIBRARY_EVENT.EVENT_CATEGORY,
        EVENT_AUTHHOMEEVENT,
        LIBRARY_EVENT.ETEXT_FEATURE_ELEMENT_CLICK_EVENT_ACTION,
        LIBRARY_EVENT.REMOVE_ICON_EVENT_LABEL, customFields
      );
    } else if (type === ACTIVITY_TYPE.INFO) {
      CommonUtils.dispatchGaEvent(
        LIBRARY_EVENT.EVENT_CATEGORY,
        EVENT_AUTHHOMEEVENT,
        LIBRARY_EVENT.ETEXT_FEATURE_ELEMENT_CLICK_EVENT_ACTION,
        LIBRARY_EVENT.INFO_EVENT_LABEL, customFields
      );
    } else if (book && type !== ACTIVITY_TYPE.INFO) {
      this.context.handleBookClick(book, type, book.course_id, null, null, LOCATION_IN_APP.COURSE_TAB);
    }
  }

  /**
   * Handles title info Notification primary or secondary CTA click
   *
   * @param {*} event
   * @param {*} isDismissed
   * @param {*} launchURL
   * @param {*} target
   * @param {*} bannerId
   */
  onTitleInfoNotificationCTAClick = (event, isDismissed, launchURL, target, bannerId) => {
    const book = this.findHeroBook();
    this.context.handleSubscriptionBannerClick(
      event,
      book,
      isDismissed,
      launchURL,
      target,
      bannerId,
      LOCATION_IN_APP.HERO
    );
    this.setNotificationData(null, true);
  }

  /**
   * Handles title info modal Remove from library CTA click
   */
  onTitleInfoRemoveClick = () => {
    const book = this.findHeroBook();

    this.context.handleRemoveTitlePopup(book, null, true, LOCATION_IN_APP.HERO);
  }

  /**
   * Handles Go to page number text field change
   *
   * @param {String} updatedGoToPageNo
   */
  onGoToPageNoChange = (updatedGoToPageNo) => {
    const { herobanner } = this.context.props;
    let sanitizedGoToPageNo = xss(updatedGoToPageNo);
    if (sanitizedGoToPageNo?.includes('-')) sanitizedGoToPageNo = sanitizedGoToPageNo?.replace(/^-+/g, '');

    herobanner.setGoToPage(sanitizedGoToPageNo);
  }

  /**
   * Handles go to page input box blur
   *
   */
  onGoToPageInputBlur = () => {
    const { herobanner } = this.context.props;
    const { goToPage } = getSnapshot(herobanner);
    const { value } = goToPage;
    let renderValue = value;

    if (!renderValue?.includes('-')) return;

    renderValue = renderValue.replace(/^-+|-+$/g, '');

    if (renderValue?.length > 0) {
      herobanner.setGoToPage(renderValue);
    }
  }

  onAssetCardClick = (cardIndex, cardProps = {}) => {
    const {
      url
    } = cardProps;
    const isAHNewUi = this.context.commonPlugin.isAHNewUi();
    let locationInApp = CHANNEL_CARD_EVENT.RECOMMENDED_CONTAINER_FIELD;
    if (isAHNewUi) {
      locationInApp = CHANNEL_CARD_EVENT.TOPICS_CARD_CONTAINER_FIELD;
    }
    this.tiggerChannelCardElement(locationInApp);
    if (url && CommonUtils.isAbsoluteURL(url)) {
      window.open(CommonUtils.getUrlWithUTMParams(url, CHANNEL_ASSET_QUERY_PARAM));
    } else {
      window.open(CommonUtils.getUrlWithUTMParams(`${env.STUDY_CHANNEL_URL}${url}`, CHANNEL_ASSET_QUERY_PARAM));
    }
    this.handleCourseTabEvents();
  }

  onHeroHeaderCTAClick = () => {
    const { herobanner } = this.context.props;
    const {
      MARKETING_TEMPLATE,
      CHANNEL,
      BOOK_WITH_CHANNEL
    } = HERO_BANNER_TYPES;
    const {
      bannerType,
      channelId
    } = getSnapshot(herobanner);
    const {
      url,
      learnUrl,
      exploreUrl,
      showExams
    } = herobanner.getChannelData(channelId) || {};
    const { EVENT_CATEGORY, ETEXT_CLICK_EVENT_ACTION, EXEXT_VIEW_ETEXTBOOK_EVENT_LABEL } = LIBRARY_EVENT;
    const isAHNewUi = this.context.commonPlugin.isAHNewUi();

    const customFields = {
      is_ghost_account: CommonUtils.isGhostAccount(),
      location_in_app: LOCATION_IN_APP.COURSE_TAB,
      container_value: CommonUtils.getCurrentScreenEventLabel(this.context.state.flags)
    };

    let locationInApp = CHANNEL_CARD_EVENT.PRIMARY_CTA_FIELD;
    if (isAHNewUi) {
      locationInApp = CHANNEL_CARD_EVENT.CHANNEL_CTA_CONTAINER_FIELD;
    }

    this.tiggerChannelCardElement(locationInApp);
    switch (bannerType) {
      case MARKETING_TEMPLATE:
        CommonUtils.dispatchGaEvent(EVENT_CATEGORY, EVENT_AUTHHOMEEVENT, ETEXT_CLICK_EVENT_ACTION, EXEXT_VIEW_ETEXTBOOK_EVENT_LABEL, customFields);
        window.open(env.BOOKSHELF_CONFIG.purchase);
        break;
      case BOOK_WITH_CHANNEL:
        if (url) {
          window.open(`${url}?${CHANNEL_QUERY_PARAM}`);
        }
        break;
      case CHANNEL:
        if (url || learnUrl || (url && showExams) || exploreUrl) {
          const renderURL = url || learnUrl || CommonUtils.getExamPrepUrl(url) || exploreUrl;
          window.open(`${renderURL}?${CHANNEL_QUERY_PARAM}`);
        }
        break;
      default:
        break;
    }
    if (bannerType !== MARKETING_TEMPLATE) this.handleCourseTabEvents();
  }

  onPromotionalCardCTAClick = () => {
    window.open(`${env.STUDY_CHANNEL_URL}?CEP=plusAuthHP_Generic`);
  }

  onPromotionalLinksClick = (id = '') => {
    const { herobanner } = this.context.props;
    const {
      channelId
    } = getSnapshot(herobanner);
    const {
      learnUrl,
      aiTutorUrl,
      showExams,
      url
    } = herobanner.getChannelData(channelId);
    this.tiggerChannelCardElement(CHANNEL_CARD_EVENT.STUDY_EXAMPREP_CARD_FIELD);

    switch (id) {
      case CHANNEL_LINKS.LEARN:
        if (learnUrl) window.open(`${learnUrl}?${CHANNEL_SECTION_QUERY_PARAM}`);
        break;
      case CHANNEL_LINKS.AI_TUTOR:
        if (aiTutorUrl) window.open(`${aiTutorUrl}?${CHANNEL_SECTION_QUERY_PARAM}`);
        break;
      case CHANNEL_LINKS.EXAM_PREP:
        if (showExams) window.open(`${CommonUtils.getExamPrepUrl(url)}?${CHANNEL_SECTION_QUERY_PARAM}`);
        break;
      default:
        break;
    }
    this.handleCourseTabEvents();
  }

  onSubStatusCardLinksClick = (id) => {
    const { herobanner } = this.context.props;
    const {
      CHANNEL,
      BOOK_WITH_CHANNEL
    } = HERO_BANNER_TYPES;
    const {
      bannerType,
      channelId
    } = getSnapshot(herobanner);
    const {
      url
    } = herobanner.getChannelData(channelId) || {};

    switch (id) {
      case PPLUS_SUBSCRIPTIONS.ETEXTBOOK:
        window.open(env.BOOKSHELF_CONFIG.purchase);
        break;
      case PPLUS_SUBSCRIPTIONS.STUDY_EXAM_PREP:
      case PPLUS_SUBSCRIPTIONS.STUDY_EXAM_PREP_PACK:
        this.tiggerChannelCardElement(CHANNEL_CARD_EVENT.STATUS_CARD_FIELD);
        this.handleCourseTabEvents();
        if ((bannerType === BOOK_WITH_CHANNEL || bannerType === CHANNEL) && url) {
          window.open(url);
        } else {
          window.open(`${env.STUDY_CHANNEL_URL}/subscription`);
        }
        break;
      default:
        break;
    }
  }

  onRemoveCourse = () => {
    const { channelId } = HeroDashboardPlugin.activeCourseTab || {};
    Framework.getEventManager().publish(COURSE_COLLECTION_SERVICE_REQUESTED,
      { method: HTTP_REQUEST_METHODS.DELETE, payload: { name: channelId } });
  }

  onHEIStartLearningClick = () => {
    const isAHNewUi = this.context.commonPlugin.isAHNewUi();
    const eventLabel = isAHNewUi
      ? HEADER_EVENT.PROMO_HEI_MONDLY
      : HEADER_EVENT.PROMO_LEARN_LANGUAGE_HEI_USER_LABEL;
    CommonUtils.dispatchGaEvent(AUTHHOME_APPLICATION, EVENT_USER_INTERACTION, HEADER_EVENT.HEADER_CLICK_EVENT_ACTION, eventLabel, null);
  }

  onPopularChannelCardClick = (index) => {
    if (this.location === MLM_AUTH_HOME) {
      this.onMLMPopularChannelCardClick(index);
    } else {
      this.context.coursePlugin.handlePopularChannelCardClick(index);

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  tiggerChannelCardElement = (locationInApp, isInitialLoad = false) => {
    const { template = HERO_BANNER_TYPES.MARKETING_TEMPLATE } = HeroDashboardPlugin.activeCourseTab || {};
    const { flags } = this.context.state;

    if (template === HERO_BANNER_TYPES.CHANNEL
      || template === HERO_BANNER_TYPES.BOOK_WITH_CHANNEL
      || template === HERO_BANNER_TYPES.BOOK
    ) {
      const { herobanner } = this.context.props;
      const { channelId } = getSnapshot(herobanner);

      const customFields = {
        is_ghost_account: CommonUtils.isGhostAccount(),
        channel_id: channelId || null,
        container_value: CommonUtils.getCurrentScreenEventLabel(flags)
      };
      if (!isInitialLoad) {
        customFields.location_in_app = locationInApp;
      }
      const eventAction = isInitialLoad
        ? CHANNEL_CARD_EVENT.CHANNEL_CARD_ELEMENT_VIEW_EVENT_ACTION
        : CHANNEL_CARD_EVENT.CHANNEL_CARD_ELEMENT_CLICK_EVENT_ACTION;
      CommonUtils.dispatchGaEvent(
        AUTH_HOME_CATEGORY,
        EVENT_AUTHHOMEEVENT,
        eventAction,
        CHANNEL_CARD_EVENT.CHANNEL_CARD_ELEMENT_EVENT_LABEL,
        customFields
      );
    }
  }

  tiggerABTestingEvent = (bannerType, isInitialLoad) => {
    if (!isInitialLoad) {
      return;
    }
    const isHEIUser = CommonUtils.isHEIUser();
    const { flags } = this.context?.state;

    if (isHEIUser && bannerType !== HERO_BANNER_TYPES.MARKETING_TEMPLATE) {
      const eventLabel = flags.showHEIUserContent
        ? ONBOARDING_HEI_AB_TESTING_EVENT.AB_TESTING_GROUP_B_EVENT_LABEL
        : ONBOARDING_HEI_AB_TESTING_EVENT.AB_TESTING_GROUP_A_EVENT_LABEL;
      CommonUtils.logABTestingEvent(eventLabel, ONBOARDING_HEI_AB_TESTING_EVENT.HEI_AH_FIELD);
    }
  }

  onSubStatusCardCTAClick = () => {
    this.tiggerChannelCardElement(CHANNEL_CARD_EVENT.PRIMARY_CTA_FIELD);
    window.open(`${env.STUDY_CHANNEL_URL}?CEP=plusAuthHP_Generic`);
  }

  onAIChatbotSend = (chatText, isFromKeyBoard = false) => {
    if (this.context.commonPlugin.isAHNewUi()) {
      const {
        flags
      } = this.context.state || {};
      const isAIChatBVariant = CommonUtils.localStringCompare(flags?.AHAIStudyToolVariant, AI_CHAT_VARIANT.B);
      const isAIChatCVariant = CommonUtils.localStringCompare(flags?.AHAIStudyToolVariant, AI_CHAT_VARIANT.C);

      // 531 Click Chat GPT
      this.context?.authhomeAIStudyToolPlugin?.handleClickChatGptEvent(
        isAIChatBVariant
          ? LOCATION_IN_APP.HOME2_TEXTINPUT : LOCATION_IN_APP.HOME2_CARD
      );

      if (isAIChatBVariant) {
        // Event 544: Click Send
        this.context?.authhomeAIStudyToolPlugin?.handleClickChatGptSendEvent(
          isFromKeyBoard
            ? AI_STUDY_TOOL_EVENT.COMMAND_SOURCE_CODE.TYPE : AI_STUDY_TOOL_EVENT.COMMAND_SOURCE_CODE.CLICK
        );
      }

      if (isAIChatCVariant) {
        // 738
        this.onAIInputChange(null, true);
      }
    }

    this.context.setState({
      executeCommand: '',
      openBotContainer: true
    }, () => {
      this.context.setState(
        { executeCommand: chatText }
      );
    });
  }

  onAIInputChange = (chatText, triggerEvent = false) => {
    // 738

    if (!this.AIStudyToolIsInputDirty || triggerEvent) {
      const {
        flags
      } = this.context.state || {};
      const isAIChatBVariant = CommonUtils.localStringCompare(flags?.AHAIStudyToolVariant, AI_CHAT_VARIANT.B);
      const eventLabel = isAIChatBVariant
        ? AI_STUDY_TOOL_EVENT.EVENT_LABEL.AH_TEXT_INPUT : AI_STUDY_TOOL_EVENT.EVENT_LABEL.AH_ACTIVATION_CTA;

      this.AIStudyToolIsInputDirty = true;
      const customFields = {
        location_in_app: AI_STUDY_TOOL_EVENT.AI_STUDY_TOOL_LOCATION_IN_APP
      };
      CommonUtils.dispatchGaEvent(
        AUTH_HOME_CATEGORY,
        EVENT_AUTHHOMEEVENT,
        AI_STUDY_TOOL_EVENT.AI_STUDY_TOOL_CONVERSATION_EVENT_ACTION,
        this.context.commonPlugin.isAHNewUi() ? eventLabel : null,
        customFields
      );
    }
  }

  onTocClick = () => {
    const { herobanner } = this.context.props;
    const { tableOfContents } = getSnapshot(herobanner);
    const isMLM = this.location === MLM_AUTH_HOME;
    const isTableOfContentsFetched = !tableOfContents?.isTOCFetchFailed
      && (tableOfContents?.data && !CommonUtils.isEmpty(tableOfContents.data));

    /**
     * Fetch asset for hero book on click of the TOC dropdown
     * If it is not home2 route or
     * If tableOfContents is not already fetched on load
     */
    const fetchAsset = !isMLM || !isTableOfContentsFetched;

    if (!this.isTocClicked) {
      this.handleTocClick(fetchAsset);
      this.isTocClicked = true;
    }
  }

  /**
   * Handle related channel card click
   *
   */
  onRelatedChannelClick = () => {
    const { herobanner } = this.context.props || {};
    const { channelId, popularChannels } = getSnapshot(herobanner);
    const channelCards = CommonUtils.getActiveChannelCards(popularChannels?.channelCards, true);
    const mostRelatedChannelIdx = channelCards.findIndex(card => card.channelId === channelId);

    this.context.coursePlugin.handlePopularChannelCardClick(mostRelatedChannelIdx, null, true);
  }

  /**
   * Return Empty state channel card list
   *
   */
  getPopularChannelList = (channels) => {
    const isAHNewUi = this.context.commonPlugin.isAHNewUi();

    if (!isAHNewUi) {
      return channels;
    }

    const { description, headingText, channelCards } = channels || {};

    return {
      description,
      headingText,
      channelCards,
      variant: this.context.commonPlugin.getVariant(),
      isSubscribed: CommonUtils.isChannelsSubscriber(),
      subscriptionText: this.getSubscriptionText(),
      config: this.context.commonPlugin.getPopularChannelsConfig()
    };
  };
  /**
   * If a MLM user click on the channel card, it will be redirect to the channel page
   * @param {*} index
   */

  onMLMPopularChannelCardClick = (index) => {
    const { herobanner } = this.context.props;
    let findChannelCard = null;
    if (index === 0 || index) {
      const popularChannelsData = getSnapshot(herobanner).popularChannels;
      const popularCards = CommonUtils.getActiveChannelCards(popularChannelsData.channelCards, false);
      findChannelCard = popularCards[index];
      if (findChannelCard) {
        window.open(`${findChannelCard?.url}`);

        const { flags } = this.context.state;
        const customFields = {
          is_ghost_account: CommonUtils.isGhostAccount(),
          location_in_app: CHANNEL_CARD_EVENT.CHANNELS_CONTAINER_FIELD,
          channel_id: findChannelCard.channelId || null,
          container_value: CommonUtils.getCurrentScreenEventLabel(flags)
        };

        CommonUtils.dispatchGaEvent(
          AUTH_HOME_CATEGORY,
          EVENT_AUTHHOMEEVENT,
          CHANNEL_CARD_EVENT.CHANNEL_CARD_ELEMENT_CLICK_EVENT_ACTION,
          CHANNEL_CARD_EVENT.CHANNEL_CARD_ELEMENT_EVENT_LABEL,
          customFields
        );
      }
    }
  }
}
