/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file analyticsEvents.js
 * @author Arifhusain Soudagar
 */

import { BOT_EVENTS, GPT_EVENTS, IMAGE } from '../common/constants';
import getPromptVersionData from '../Common/CustomUtils';
import { eventAction, studyChannelCommonFields } from './analytics';

const event = {
  eventName: 'gptevent',
  eventCategory: 'GPT'
};

//* ****** Analytic Event common fields ****** *//
const commonFields = {
  event: event.eventName,
  event_category: event.eventCategory,
  location_in_app: 'GPT',
  transaction_local_dt: new Date().toISOString().replace('Z', '')
};

//* ****** A/B Ereader event ****** *//
const eventEReader = {
  eventName: 'ereaderevent',
  eventCategory: GPT_EVENTS.EREADER
};

//* ******  Math keybard common fields ****** *//
const mathKeyboardCommonFields = {
  event: eventEReader.eventName,
  event_category: eventEReader.eventCategory,
  transaction_local_dt: new Date().toISOString().replace('Z', ''),
  location_in_app: GPT_EVENTS.GPT
};

//* ******  Channel Video common fields ****** *//
const channelVideoCommonFields = {
  event: eventEReader.eventName,
  event_category: eventEReader.eventCategory,
  transaction_local_dt: new Date().toISOString().replace('Z', ''),
  location_in_app: GPT_EVENTS.GPT
};

//* ****** Analytic Event Call - 538 ****** *//
export const clickThumbsGAEvent = (callback, label, selected, option, chatType) => {
  callback(BOT_EVENTS, {
    event_action: eventAction.clickThumbs,
    event_label: label,
    event_value: selected ? GPT_EVENTS.SELECTED : GPT_EVENTS.DESELECTED,
    types_of_output: option,
    chat_type: chatType,
    ...commonFields
  });
};

//* ****** Analytic Event Call - 659 ****** *//
export const openChatHistoryGAEvent = (callback, bookId) => {
  callback(BOT_EVENTS, {
    event_action: eventAction.openChatHistory,
    event_label: null,
    event_value: null,
    book_id: bookId,
    ...commonFields
  });
};
//* ****** Analytic Event Call - 660 ****** *//
export const closeChatHistoryGAEvent = (callback, bookId) => {
  callback(BOT_EVENTS, {
    event_action: eventAction.closeChatHistory,
    event_label: null,
    event_value: null,
    book_id: bookId,
    ...commonFields
  });
};
//* ****** Analytic Event Call - 661 ****** *//
export const inputTextForChatHistoryFilterGAEvent = (callback, bookId, searchTerm, countOfResults) => {
  if (searchTerm) {
    callback(BOT_EVENTS, {
      event_action: eventAction.inputTextForChatHistoryFilter,
      event_label: null,
      event_value: null,
      book_id: bookId,
      search_term: searchTerm,
      count_of_results: countOfResults,
      ...commonFields
    });
  }
};
//* ****** Analytic Event Call - 662 ****** *//
export const openThreadFromChatHistoryGAEvent = (callback, chatAge, bookId, chatId) => {
  callback(BOT_EVENTS, {
    event_action: eventAction.openThreadFromChatHistory,
    event_label: chatAge,
    event_value: null,
    book_id: bookId,
    chat_id: chatId,
    ...commonFields
  });
};
//* ****** Analytic Event Call - 663 ****** *//
export const confirmRenameOfThreadInChatHistoryGAEvent = (callback, chatAge, bookId, chatId) => {
  callback(BOT_EVENTS, {
    event_action: eventAction.confirmRenameOfThreadInChatHistory,
    event_label: chatAge,
    event_value: null,
    book_id: bookId,
    chat_id: chatId,
    ...commonFields
  });
};
//* ****** Analytic Event Call - 664 ****** *//
export const deleteThreadInChatHistoryGAEvent = (callback, chatAge, bookId, chatId) => {
  callback(BOT_EVENTS, {
    event_action: eventAction.deleteThreadInChatHistory,
    event_label: chatAge,
    event_value: null,
    book_id: bookId,
    chat_id: chatId,
    ...commonFields
  });
};
//* ****** Analytic Event Call - 665 ****** *//
export const undoDeleteThreadInChatHistoryGAEvent = (callback, chatAge, bookId, chatId) => {
  callback(BOT_EVENTS, {
    event_action: eventAction.undoDeleteThreadInChatHistory,
    event_label: chatAge,
    event_value: null,
    book_id: bookId,
    chat_id: chatId,
    ...commonFields
  });
};

//* ****** Analytic Event Call - 685 Click to Open Math Keyboard ****** *//
export const openMathKeyboardGAEvent = (callback, bookId, chatId) => {
  callback(BOT_EVENTS, {
    event_action: eventAction.openMathKeyboard,
    event_value: null,
    event_label: null,
    book_id: bookId,
    chat_id: chatId,
    ...mathKeyboardCommonFields
  });
};

//* ****** Analytic Event Call - 686 Press First key In Math Keyboard ****** *//
export const onKeyPressMathKeyboardGAEvent = (callback, bookId, chatId) => {
  callback(BOT_EVENTS, {
    event_action: eventAction.keyPressInMathKeyboard,
    event_value: null,
    event_label: null,
    book_id: bookId,
    chat_id: chatId,
    ...mathKeyboardCommonFields
  });
};

//* ****** Analytic Event Call - 687 Send Math from Math Keyboard to Text Input ****** *//
export const onSendMathTextGAEvent = (callback, bookId, chatId) => {
  callback(BOT_EVENTS, {
    event_action: eventAction.sendMathTextFromMathKeyboard,
    event_value: null,
    event_label: null,
    book_id: bookId,
    chat_id: chatId,
    ...mathKeyboardCommonFields
  });
};

//* ****** Analytic Event Call - 703 User clicks opt out of features ****** *//
export const userClicksOptOutFeature = (callback, value = '0', bookId) => {
  callback(BOT_EVENTS, {
    event_action: eventAction.userClicksOptOutFeature,
    event_value: value,
    event_label: 'channels_opt_out',
    published_language: GPT_EVENTS.ENGLISH_US,
    book_id: bookId,
    ...channelVideoCommonFields
  });
};

//* ****** Analytic Event Call - 704 User sees opt out link of features ****** *//
export const userSeesOptOutFeature = (callback, bookId, isOptIn) => {
  callback(BOT_EVENTS, {
    event_action: eventAction.userSeesOptOutFeature,
    event_value: null,
    event_label: isOptIn ? 'Channels_opt_in' : 'channels_opt_out',
    published_language: GPT_EVENTS.ENGLISH_US,
    book_id: bookId,
    ...channelVideoCommonFields
  });
};

//* ****** Analytic Event Call - 705 Users clicks on reference link ****** *//
export const clickOnReferenceLink = (callback, chatId, linksCount, targetUrl, bookId) => {
  callback(BOT_EVENTS, {
    event_action: eventAction.clickOnReferenceLink,
    event_value: null,
    event_label: null,
    ...channelVideoCommonFields,
    event: event.eventName,
    event_category: event.eventCategory,
    location_in_app: GPT_EVENTS.GPT,
    book_id: bookId,
    links: `${linksCount}`,
    chat_id: chatId,
    published_language: GPT_EVENTS.ENGLISH_US,
    asset_type: GPT_EVENTS.SOURCE_HYPERLINK,
    target_url: targetUrl
  });
};

//* ****** Analytic Event Call - 423 See in channels link clicked ****** *//
export const openChannelsFromEReaderEvent = (
  callback,
  bookId,
  productId,
  isChannelsBundleSubscriber,
  appActivityAreaCode,
  userEntitlementMix
) => {
  callback(BOT_EVENTS, {
    ...studyChannelCommonFields,
    event_action: eventAction.openChannelsFromEReader,
    event_label: GPT_EVENTS.VIDEO_CTA,
    event_value: null,
    location_in_app: GPT_EVENTS.GPT,
    book_id: bookId,
    product_id: productId,
    asset_number: null,
    channels_p_bundle_subs: isChannelsBundleSubscriber,
    app_activity_area_code: appActivityAreaCode || null,
    user_entitlement_mix: userEntitlementMix || null
  });
};

//* ****** Analytic Event Call - 656 Search query for channels assets returns results ****** *//
export const fetchChannelsSearchResultsGAEvent = (
  callback,
  esScore,
  searchTerm,
  countOfResults
) => {
  callback(BOT_EVENTS, {
    event: eventEReader.eventName,
    event_category: eventEReader.eventCategory,
    event_action: eventAction.fetchChannelsSearchResults,
    event_value: esScore,
    event_label: GPT_EVENTS.VIDEO,
    content_categories: GPT_EVENTS.CHAN_VID,
    search_term: searchTerm,
    count_of_results: countOfResults,
    location_in_app: GPT_EVENTS.GPT
  });
};

//* ****** Analytic Event Call - 557 User selects topic suggestion ****** *//
export const clickTopicSuggestionsEvent = (callback, label, bookId, data) => {
  callback(BOT_EVENTS, {
    event_action: eventAction.clickTopicSuggestions,
    event_label: label,
    event_value: null,
    book_id: bookId,
    ...getPromptVersionData(data),
    ...commonFields
  });
};

//* ****** Analytic Event Call - 501 User sees Subscription Pop Up ****** *//
export const userSeesSubscriptionPopUpEvent = (callback, premiumVideo) => {
  const {
    assetId, assetTitle, assetSource, channelFlow, channelType, topic, topicId, subject
  } = premiumVideo;
  callback(BOT_EVENTS, {
    event_action: eventAction.userSeesSubscriptionPopUp,
    event_label: null,
    event_value: null,
    asset_id: assetId,
    asset_title: assetTitle,
    asset_source: assetSource,
    asset_type: GPT_EVENTS.FREEMIUM,
    channel_flow: channelFlow,
    channel_type: channelType,
    topic,
    topic_id: topicId,
    subject,
    user_signed_in: 'true',
    location_in_app: GPT_EVENTS.GPT,
    ...studyChannelCommonFields
  });
};

//* ****** Analytic Event Call - 502 Click Subscription wall ****** *//
export const clickSubscriptionWallEvent = (callback, premiumVideo, label) => {
  const {
    assetId, assetTitle, assetSource, channelFlow, channelType, topic, topicId, subject
  } = premiumVideo;
  callback(BOT_EVENTS, {
    event_action: eventAction.clickSubscriptionWall,
    event_label: label,
    event_value: null,
    asset_id: assetId,
    asset_title: assetTitle,
    asset_source: assetSource,
    asset_type: GPT_EVENTS.FREEMIUM,
    channel_flow: channelFlow,
    channel_type: channelType,
    topic,
    topic_id: topicId,
    subject,
    user_signed_in: 'true',
    location_in_app: GPT_EVENTS.GPT,
    ...studyChannelCommonFields
  });
};

//* ****** Analytic Event Call - 758 Click Media Card ****** *//
export const clickMediaCardGAEvent = (callback, bookId, contentId, promptVersion) => {
  callback(BOT_EVENTS, {
    event_action: eventAction.clickMediaCard,
    event_value: null,
    event_label: null,
    tab_id: null,
    asset_type: IMAGE,
    book_id: bookId,
    content_id: contentId,
    ...promptVersion,
    ...commonFields
  });
};

//* ****** Analytic Event Call - 535 Click Suggestions ****** *//
export const clickSuggestionGAEvent = (callback, bookId, clickText) => {
  callback(BOT_EVENTS, {
    event_action: eventAction.clickSuggestion,
    event_value: null,
    event_label: clickText,
    published_language: GPT_EVENTS.ENGLISH_US,
    book_id: bookId,
    event: event.eventName,
    event_category: event.eventCategory,
    transaction_local_dt: new Date().toISOString().replace('Z', '')
  });
};

//* ****** Analytic Event Call - 770 Click Suggestions ****** *//
export const clickMoreActionsGAEvent = (callback, chatType, bookId) => {
  callback(BOT_EVENTS, {
    event_action: eventAction.clickMoreActions,
    event_value: null,
    event_label: null,
    chat_type: chatType,
    book_id: bookId,
    ...commonFields
  });
};
