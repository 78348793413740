/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file closeIcon.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import { getComponentThemeValue } from '../CustomStyles/customStyleUtils';

const Styles = () => ({
  closeIcon: {
    width: 14,
    height: 14
  }
});

const CloseIcon = ({
  classes, masterTheme, theme, style, customThemeStyles
}) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 14 14"
    classes={{
      root: style || classes.closeIcon
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L8.41421 7L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L7 8.41421L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579Z"
      fill={getComponentThemeValue(customThemeStyles, 'root', 'iconColor', false, 'textColor', masterTheme, theme)}
    />
  </SvgIcon>
);

CloseIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  customThemeStyles: PropTypes.object,
  theme: PropTypes.string,
  masterTheme: PropTypes.string,
  style: PropTypes.object
};

CloseIcon.defaultProps = {
  theme: 'default',
  masterTheme: 'plus',
  customThemeStyles: {},
  style: ''
};

export default withStyles(Styles)(CloseIcon);
